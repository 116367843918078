import React, { Component, Fragment } from 'react'
import { Settings } from '../Settings/Settings'
import { Api } from '../Api/Api'
import Loading from '../Utility/Loading/Loading'
import _ from 'lodash'
import moment from 'moment'
import { NotificationManager } from '../Notifications/NotifcationManager'
import './JoinBooking.scss'
import ContentBox from '../Utility/ContentBox/ContentBox'
import IntroText from '../Utility/IntroText/IntroText'
import Notice from '../Utility/Notice/Notice'
import FormElementText from '../Form/Element/Text'
import Button from '../Form/Element/Button'
import FormElementSelect from '../Form/Element/Select'
import FormElementCheckbox from '../Form/Element/Checkbox'

class JoinBooking extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            booking: {},
            saving: false,
            do_join: false,
            code: this.props.match.params.token,
            guest: {
                email: '',
                forename: '',
                surname: '',
                choices: [],
                marketing_opt_in: false,
                sms_opt_in: false,
                phone_opt_in: false,
                variation_id: null,
            },
            has_email: false,
            can_complete: false,
            logged_in: false,
            login_choice: null,
            logged_in_user: {},
        }
    }

    componentDidMount () {
        document.getElementById('og_site_name').value = Settings.getBrandName()
        document.getElementById('og_title').value = 'Join my ' + Settings.getBrandName() + ' Booking!'
        this.updateJoin()
    }

    updateJoin () {
        Api.get('rest/booking/join/' + this.state.code,
          { join: this.state.do_join === true ? 1 : 0, variation_id: this.props.variation }).
          then((response) => {
              if (response.ok) {
                  let meta = response.data.meta
                  let choices = {}
                  if (response.data.data.can_use_invite === true) {
                      response.data.data.choices.map((c) => {
                          c.choices.map((i) => {
                              let has_selectable = false
                              i.items.map((ii) => {
                                  if (has_selectable === false && ii.selectable === true) {
                                      has_selectable = true
                                  }
                              })
                              if (has_selectable) {
                                  choices[i.id] = ''
                              }
                          })
                      })
                  }
                  let guest = {
                      forename: '',
                      surname: '',
                      email: '',
                      choices: choices,
                      marketing_opt_in: false,
                      sms_opt_in: false,
                      phone_opt_in: false,
                      variation_id: meta.variation_id,
                  }
                  this.setState({
                      guest: guest,
                      loading: false,
                      booking: response.data.data,
                      logged_in: false,
                      logged_in_user: meta.customer,
                  })
                  return
              }

              this.setState({ error: response.error, loading: false })
          })
    }

    handleFieldChange (e) {
        let guest = JSON.parse(JSON.stringify(this.state.guest))
        guest[e.target.name] = e.target.value
        this.setState({ guest: guest, can_complete: this.validate(guest) })
    }

    validate (guest) {
        let valid = true
        if (!guest.forename || guest.forename.length < 3) {
            valid = false
        }
        if (guest.surname !== '' && guest.surname.length < 3) {
            valid = false
        }
        if (guest.email !== '' && guest.email.indexOf('@') === -1) {
            valid = false
        }
        let empty = false
        Object.keys(guest.choices).map((k) => {
            if (guest.choices[k] === '') {
                empty = true
            }
        })
        if (empty === true) {
            valid = false
        }
        if (guest.email.indexOf('@') > -1) {
            this.setState({ has_email: true })
        }
        else {
            this.setState({ has_email: false })
        }

        return valid
    }

    handleSave (e) {
        e.preventDefault()
        let guest = _.extend({}, this.state.guest)
        this.setState({ saving: true })
        Api.postJson('rest/booking/join/' + this.state.code, guest).then((response) => {
            this.setState({ saving: false })
            if (response.status === 409) {
                NotificationManager.showError('You are already added to this booking!')
                return
            }
            if (response.ok) {
                NotificationManager.showSuccess('Added to booking!')
                document.location.href = '/guest/' + response.data.data.unique_code
            }
        })
    }

    render () {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <Notice variant="error" name="pre-order-error">{this.state.error}</Notice>
        }
        let booking = this.state.booking
        if (!this.state.guest.variation_id) {
            return <Notice variant="error" name="pre-order-error">
                Sorry, the link you have been provided will no longer work. Please ask your lead booker to provide a
                link which is specific to a package on your booking.
            </Notice>
        }
        return <Fragment>
            <ContentBox name="join-booking">
                <IntroText name="join-booking">{booking.lead_booker} has invited you to join their booking
                    at <strong>{booking.bar.data.branded_name}</strong> on {moment(booking.booking_date).
                      format('MMMM Do YYYY')} at {moment(booking.booking_date).format('HH:mm')}.
                    <br/><br/>Enter your details below and choose your options. Enter an e-mail address if you want to
                    get e-mailed a copy of your choices.
                </IntroText>
                <Notice name="dietary" variant="info">Please contact us directly to discuss allergy information, dietary requirements or special requests.
                    Do not add this information to the name boxes below as our kitchen teams will not see these!<br /><br />
                    <a href={Settings.getAllergensUrl()} target="_blank">Click Here</a> to view our current allergens list</Notice>
                <div className="join-booking--details">
                    <FormElementText name="forename" placeholder="Forename *" value={this.state.guest.forename}
                                     onChange={(e) => this.handleFieldChange(e)}/>
                    <FormElementText name="surname" placeholder="Surname" value={this.state.guest.surname}
                                     onChange={(e) => this.handleFieldChange(e)}/>
                    <FormElementText email={true} name="email" placeholder="E-mail Address"
                                     onChange={(e) => this.handleFieldChange(e)}
                                     value={this.state.guest.email}/>
                </div>
                {this.state.has_email && <div className="join-booking--opt-ins">
                    <p className="terms-text">Please specify which ways you would like to be contacted
                        by {Settings.getBrandName()} for
                        marketing and
                        sales updates:</p>
                    <FormElementCheckbox onChange={(e) => {
                        let guest = JSON.parse(JSON.stringify(this.state.guest))
                        guest.marketing_opt_in = e.target.value
                        this.setState({ guest: guest })
                    }} name="marketing_opt_in" label="E-Mail" true_value={true} false_value={false}
                                         value={this.state.guest.marketing_opt_in} label_after={true}/>
                    <FormElementCheckbox onChange={(e) => {
                        let guest = JSON.parse(JSON.stringify(this.state.guest))
                        guest.sms_opt_in = e.target.value
                        this.setState({ guest: guest })
                    }} name="sms_opt_in" label="SMS" true_value={true} false_value={false} label_after={true}
                                         value={this.state.guest.sms_opt_in}/>
                    <FormElementCheckbox onChange={(e) => {
                        let guest = JSON.parse(JSON.stringify(this.state.guest))
                        guest.phone_opt_in = e.target.value
                        this.setState({ guest: guest })
                    }} name="phone_opt_in" label="Phone" true_value={true} false_value={false} label_after={true}
                                         value={this.state.guest.phone_opt_in}/>
                </div>}
                <div className="join-booking--options">
                    {this.state.booking.choices.map((choice) => {
                        return <div key={choice.group_id}>
                            {choice.choices.map((ci) => {
                                let options = []
                                ci.items.map((i) => {
                                    if (i.selectable === true && !Settings.isBespokePlu(parseInt(i.plu))) {
                                        let title = i.title
                                        if (i.premium > 0) {
                                            title = title + ' (+' + Settings.currencyFormatter.format(i.premium) + ')'
                                        }
                                        options.push({
                                            key: i.plu + '',
                                            value: title,
                                        })
                                    }
                                })
                                if (options.length === 0) {
                                    return
                                }
                                return <div className="join-booking--options--choice" key={ci.id}>
                                    <p>{choice.title}</p>
                                    <FormElementSelect name={'' + choice.group_id} onChange={(e) => {
                                        let guest = JSON.parse(JSON.stringify(this.state.guest))
                                        guest.choices[ci.id] = e.target.value
                                        this.setState({ guest: guest, can_complete: this.validate(guest) })
                                    }}
                                                       selectedValue={this.state.guest.choices[ci.id]}
                                                       includeBlankOption={true} id={choice.group_id}
                                                       blankOptionText={' - ' + ci.title + '- '}
                                                       values={options}/>
                                </div>
                            })}</div>
                    })}
                </div>
                <Button name="save" disabled={this.state.can_complete === false || this.state.saving}
                        onClick={(e) => this.handleSave(e)}
                        text={this.state.saving ? <Loading/> : 'Save'}/>
            </ContentBox>
        </Fragment>
    }

}

export default JoinBooking