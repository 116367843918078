import React, {Component, Fragment} from 'react';
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import Button from "../../../Form/Element/Button";
import FormElementText from "../../../Form/Element/Text";
import FormElementSelect from "../../../Form/Element/Select";
import {BookingData} from "../../Data/BookingData";
import _ from 'lodash';
import {Settings} from "../../../Settings/Settings";
import Notice from "../../../Utility/Notice/Notice";
import './LeaveDetails.scss';
import {Api} from "../../../Api/Api";
import Center from "../../../Utility/Center/Center";

export default class LeaveDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            user: {
                forename: this.props.booking.forename,
                surname: this.props.booking.surname,
                email: this.props.booking.email,
                bar_id: props.booking.bar.data.id
            },
            saved: false
        }
    }

    handleSave(e) {
        e.preventDefault();
        this.setState({saving: true});
        let data = _.extend({}, this.state.user);
        data.group = 'covid_interest';
        Api.post('customers/register/group', data).then((response) => {
            if (response.ok) {
                this.setState({saved: true, saving: false});
                return;
            }
            this.setState({saving: false, error: response.error});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.saving !== this.state.saving) {
            this.updateButtons();
        }
    }

    handleFieldChange(e) {
        let user = _.extend({}, this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    componentDidMount() {
        this.updateButtons();
    }

    updateButtons() {
        if (this.state.saved) {
            ModalHelper.setButtons(<Fragment>
                <Button text="Close" name="cancel" onClick={() => {
                    ModalHelper.closeModal();
                }} variant="outline"/>
            </Fragment>);
        } else {
            ModalHelper.setButtons(<Fragment>
                <Button text="Cancel" name="cancel" onClick={() => {
                    ModalHelper.closeModal();
                }} variant="outline"/>
                <Button text="Save" name="save-details" onClick={(e) => this.handleSave(e)}
                        disabled={this.state.saving}/>
            </Fragment>);
        }
    }

    render() {
        if (this.state.saved) {
            return <div className="leave-details">
                <Center>
                    <img src="/assets/icons/happy-face.png" alt="Thanks!"/>
                </Center>
                <Notice name="thanks">Thanks for saving your details. We'll be in contact soon!</Notice>
            </div>
        }
        return <div className="leave-details">
            <Notice name="leave-details">Leave your details below and we'll be in contact when
                the {Settings.brand === 'revolution' ? 'party' : 'fiesta'} starts again</Notice>
            {this.state.error ? <Notice name="error" variant="error">{this.state.error}</Notice> : null}
            <form onSubmit={(e) => this.handleSave(e)}>
                <FormElementText name="forename" value={this.state.user.forename} disabled={this.state.saving}
                                 onChange={(e) => this.handleFieldChange(e)} placeholder="Forename"/>
                <FormElementText name="surname" value={this.state.user.surname}
                                 disabled={this.state.saving}
                                 onChange={(e) => this.handleFieldChange(e)}
                                 placeholder="Surname"/>
                <FormElementSelect name="bar_id" disabled={this.state.saving}
                                   blankOptionText={" - My Local " + Settings.getBrandName() + "- "}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   values={BookingData.getBarList()} includeBlankOption={true}
                                   selectedValue={this.state.user.bar_id}/>
                <FormElementText name="email" email={true} value={this.state.user.email}
                                 disabled={this.state.saving}
                                 onChange={(e) => this.handleFieldChange(e)} placeholder="E-mail address"/>
            </form>
        </div>
    }

}