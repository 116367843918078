import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import './EditGuest.scss';
import {ModalHelper} from "../../../../../Utility/Modal/AppModal";
import Button from "../../../../../Form/Element/Button";
import IntroText from "../../../../../Utility/IntroText/IntroText";
import {Settings} from "../../../../../Settings/Settings";
import Remove from "./Remove";
import SendReminder from "./SendReminder";
import PreOrder from "./PreOrder";
import {EventDispatcher} from "../../../../../Events/Dispatcher";
import EditGuestDetails from "./EditGuestDetails";

export default class EditGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cog_open: false,
            action: '',
            guest: this.props.guest,
            booking: this.props.booking
        }
    }

    componentDidMount() {
        this.updateButtons();
        this.update_listener = EventDispatcher.subscribe('booking-data-update', (data) => {
            data.guests.data.map((guest) => {
                if (guest.id === this.props.guest.id) {
                    this.setState({booking: data, guest: guest});
                    return;
                }
            })
            this.setState({booking: data});
        });
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update_listener);
    }

    updateButtons() {
        ModalHelper.setButtons(<Fragment>
            <Button text="Close" name="close-modal" onClick={(e) => {
                e.preventDefault();
                ModalHelper.closeModal();
            }} variant="outline"/>
        </Fragment>);
        ModalHelper.setCloseOnOverlayClick(false);
    }

    setAction(e, action) {
        e.preventDefault();
        this.setState({action: action});
    }

    render() {
        let guest = this.state.guest;
        let customer = guest.customer.data;
        const backAction = () => {
            this.setState({action: null});
        }
        let variations = this.props.guest.variations.data.map((v) => {
            return v.id;
        });
        let can_preorder = variations.length > 0;
        return <div className="edit-guest">
            <IntroText title={customer.full_name} name="guest-name"/>
            <div className="edit-guest__details">
                <p className="edit-guest__details__item details--phone">
                    <span>{customer.phone}</span>
                </p>
                <p className="edit-guest__details__item details--email">
                    <span>{customer.email}</span>
                </p>
                {guest.share > 0 || guest.deposit_total > 0 ? <p className="edit-guest__details__item details--balance">
                    <span>Paid: {Settings.currencyFormatter.format(guest.deposit_total)} / {Settings.currencyFormatter.format(guest.share)}</span>
                </p> : null}
                {this.props.host ?
                    <p className="edit-guest__details__item details--host">This guest is the booking host</p> : null}
            </div>
            {!this.state.action ? <Fragment>
                <div className="booking-overview">
                    <div className="booking-actions">
                        {can_preorder ? <div className="booking-actions--action">
                            <a href="#preorder" onClick={(e) => this.setAction(e, 'preorder')}>
                                <span>View/Edit Pre Order</span>
                                <Button text={<Fragment><i className="fa fa-arrow-right"/></Fragment>} circular={true}
                                        name="view-pre-order" variant="circular"/>
                            </a>
                        </div> : null}
                        {!this.props.host ? <div className="booking-actions--action">
                            <a href="#edit" onClick={(e) => this.setAction(e, 'edit')}>
                                <span>Edit Guest</span>
                                <Button text={<Fragment><i className="fa fa-arrow-right"/></Fragment>} circular={true}
                                        name="send-reminder" variant="circular"/>
                            </a>
                        </div> : null}
                        <div className="booking-actions--action">
                            <a href="#reminder" onClick={(e) => this.setAction(e, 'reminder')}>
                                <span>Send Reminder</span>
                                <Button text={<Fragment><i className="fa fa-arrow-right"/></Fragment>} circular={true}
                                        name="send-reminder" variant="circular"/>
                            </a>
                        </div>
                        <div className="booking-actions--action">
                            <a href="#remove" onClick={(e) => this.setAction(e, 'remove')}>
                                <span>Remove from booking</span>
                                <Button text={<Fragment><i className="fa fa-times"/></Fragment>} circular={true}
                                        name="remove-from-booking" variant="circular"/>
                            </a>
                        </div>
                    </div>
                </div>
            </Fragment> : null}

            {this.state.action ? <Fragment>
                {this.state.action === 'edit' ?
                    <EditGuestDetails booking={this.state.booking} guest={guest} goBack={() => backAction()}/> : null}
                {this.state.action === 'remove' ?
                    <Remove booking={this.state.booking} guest={guest} goBack={() => backAction()}/> : null}
                {this.state.action === 'reminder' ?
                    <SendReminder booking={this.state.booking} guest={guest} goBack={() => backAction()}/> : null}
                {this.state.action === 'preorder' ?
                    <PreOrder guest={guest} booking={this.state.booking} goBack={() => backAction()}/> : null}
            </Fragment> : null}
        </div>
    }
}

EditGuest.propTypes = {
    guest: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired,
    host: PropTypes.bool
}