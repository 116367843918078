import React, {Component, Fragment} from 'react';
import Loading from "../../Utility/Loading/Loading";
import PropTypes from 'prop-types';
import {Api} from "../../Api/Api";
import PayAmount from "../Elements/Payment/PayAmount";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import BookingSummary from "../ManageBooking/BookingSummary/BookingSummary";
import PaymentSummary from "../Elements/Payment/PaymentSummary";
import Notice from "../../Utility/Notice/Notice";
import './DepositRequest.scss';
import Button from "../../Form/Element/Button";

class DepositRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            deposit: {},
            response: {}
        }
    }

    componentDidMount() {
        this.updateDeposit();
    }

    updateDeposit() {
        Api.post('rest/deposit/payment/' + this.props.code, {include: 'deposit_request'}).then((response) => {
            if (response.ok) {
                let newState = {deposit: response.data.data, loading: false};
                if (Object.keys(this.state.response).length === 0) {
                    let success = response.data.data.deposit_request.data.success.data;
                    if (success.id) {
                        newState.response = {
                            auth: success.auth_code,
                            display_amount: response.data.data.deposit_request.data.amount,
                            success: true
                        };
                    } else {
                        let fail = response.data.data.deposit_request.data.fail.data;
                        if (fail.id) {
                            newState.response = {
                                auth: 'N/A',
                                display_amount: response.data.data.deposit_request.data.amount,
                                success: false
                            };
                        }
                    }
                }
                this.setState(newState);
                return;
            }
            this.setState({error: response.error, loading: false});
        })
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <Fragment>
                <div className="deposit-request">
                    <div className="row">
                        <div className="col-md-12">
                            <Notice name="deposit-request-error" variant="error">
                                {this.state.error}
                            </Notice>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
        let request = this.state.deposit.deposit_request.data;
        let booking = request.booking.data;

        if (booking.status_id === 4) {
            return <Notice variant="error" name="booking-status">
                Payment cannot be taken as this booking has been cancelled. Please contact us if you need assistance.
            </Notice>
        }

        return <Fragment>
            <div className="deposit-request">
                <div className="row">
                    <div className="col-lg-3 col-md-5 col-sm-12 col-xs-12">

                        <ContentBox loading={this.state.loading} title="Booking Summary" name="booking-summary">
                            <BookingSummary booking={booking} hide_deposit={true}/>
                        </ContentBox>
                    </div>
                    <div className="col-lg-9 col-md-7 col-sm-12 col-xs-12">
                        <ContentBox loading={this.state.loading}>
                            {this.state.response.auth && <Fragment>
                                <PaymentSummary payment_data={this.state.response}/>
                            </Fragment>}
                            {request.completed === false && this.state.response.id && <div>
                                <Notice name="payment-error" variant="error">
                                    {this.state.response.timeout === true ? <div>
                                        <p>Sorry, we weren't able to take your payment - we didn't get a response in a
                                            timely fashion from your bank. This is usually temporary so please try again
                                            later.</p>
                                    </div> : <div>
                                        {this.state.response.result_data && this.state.response.result_data.RESULT === '110' ?
                                            <div>
                                                <p>Sorry, we weren't able to take your payment- your bank declined the
                                                    transaction. We unfortunately are not given the reason for this so
                                                    please check the details are correct, you have sufficient funds and
                                                    the card is not locked/frozen and try again.</p>
                                                <p>&nbsp;</p>
                                                <p>If you have a corporate card please note your many company may
                                                    restrict where it can be used- bars & restaurants are frequently
                                                    blocked in this way.</p>
                                                <p>&nbsp;</p>
                                                <p>If the problem persists please contact your bank for the reason of
                                                    this decline and to check any merchant restrictions which may be in
                                                    place. Alternatively we also welcome payment by BACS, just get in
                                                    contact with us to arrange this.</p>
                                            </div> : <div>
                                                <p>Sorry, we weren't able to take your payment- your bank declined the
                                                    transaction.</p>

                                                <p>&nbsp;</p>
                                                <p>We unfortunately are not given the reason for this so please check
                                                    the
                                                    details
                                                    are correct, you have sufficient funds and the card is not
                                                    locked/frozen and
                                                    try
                                                    again. If the problem continues please try another card or get in
                                                    contact
                                                    with
                                                    your bank for the reason the transaction was declined.</p></div>}
                                    </div>}
                                    <p>&nbsp;</p>
                                    <Button text="Try Again" name="try-again" onClick={() => {
                                        document.location.reload();
                                    }}/>
                                </Notice>
                            </div>}
                            {request.completed === false && !this.state.response.id ?
                                <PayAmount booking={booking} amount={request.amount}
                                           deposit_request_token={this.props.code}
                                           token={booking.payment_token} onComplete={(data) => {
                                    console.log(data);
                                    this.setState({response: data}, () => this.updateDeposit());
                                }}/> : null}
                        </ContentBox>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

DepositRequest.propTypes = {
    code: PropTypes.string.isRequired
}
export default DepositRequest;