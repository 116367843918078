import React, {Fragment} from 'react';
import {Settings} from "../../../Settings/Settings";
import './ShareBooking.scss';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

export default function ShareBooking(props) {
    let booking = props.booking;
    if (!booking) {
        return <span/>
    }
    let link = Settings.getBookingsUrl() + '/join/' + booking.invite_code;
    let title = 'Join my party at ' + Settings.getBrandName() + '! ' + link
    return <Fragment>
        <div className="share-booking">

            <div className="share-links">
                <a href={"whatsapp://send?text=" + title} data-action="share/whatsapp/share" className="whatsapp"><i
                    className="fa fa-whatsapp"/></a>
                <Link to={'/ical/' + props.booking.manage_key}
                      target="_blank"
                      rel="noreferrer"
                      className="calendar"
                >
                    <i className="fa fa-calendar"/>
                </Link>
            </div>
        </div>
    </Fragment>
}

ShareBooking.propTypes = {
    booking: PropTypes.object.isRequired
}