import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Loading from "../../../Utility/Loading/Loading";
import PayAmount from "./PayAmount";
import PaymentSummary from "./PaymentSummary";
import {EventDispatcher} from "../../../Events/Dispatcher";
import './PayBooking.scss';
import Button from "../../../Form/Element/Button";
import {BookingData} from "../../Data/BookingData";
import Notice from "../../../Utility/Notice/Notice";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import {NotificationManager} from "../../../Notifications/NotifcationManager";

class PayBookingSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: this.props.code ? this.props.code : this.props.match.params.code,
            loading: true,
            error: false,
            amount: 0,
            payment_data: null,
            type: null
        }
    }

    componentDidMount() {
        this.updateBooking();
        if (this.props.type) {
            this.setType(this.props.type)
        }
        if (window.hj) {
            window.hj('enquiry-payment', '/enquiry-payment');
        }
    }

    updateBooking() {
        BookingData.update({}, (data) => {
            if (data.can_auto_confirm === false) {
                NotificationManager.showError('Sorry, that time is no longer available. Please change your time and try again');
                BookingData.update({step: 1});
                ModalHelper.closeModal();
            }
            this.setState({loading: false});
        }, true)
    }

    setType(type) {
        this.setState({loading: true, error: null});
        this.setState({type: type}, () => {
            BookingData.update({deposit_type: type}, () => {
                this.setState({amount: BookingData.booking_data.payment_amount, loading: false});
            });
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (BookingData.booking_data.can_auto_confirm === false) {
            return <div>
                <Notice name="no-booking-available" variant="error">Sorry, that booking is no longer available. Please
                    choose another date and try again</Notice>
            </div>
        }
        const amount = this.state.amount;
        return <Fragment>
            <div className="pay-booking">
                {this.state.error ? <>
                        <Notice variant="error" title="error" name="payment-error">Error: {this.state.error}</Notice>
                        <hr/>
                    </>
                    : null}
                {this.state.payment_data ?
                    <PaymentSummary payment_data={this.state.payment_data} booking={this.state.booking}/> : null}


                {this.state.type && amount > 0 ? <PayAmount booking={this.state.booking} amount={amount}
                                                            deposit_request_token={this.state.code}
                                                            token={'session'} onComplete={(payment_data) => {
                    if (payment_data.success === true) {
                        BookingData.update({}, (data) => {
                            if (this.props.onComplete) {
                                this.props.onComplete(data);
                            }
                            BookingData.completeBooking();
                            this.setState({loading: true});
                            this.updateBooking();
                            ModalHelper.closeModal();
                            EventDispatcher.dispatch('update-booking');
                            this.setState({
                                payment_data: data,
                                type: ''
                            });
                        }, true);
                    } else {
                        this.setState({type: null, error: payment_data.message});
                    }
                }}/> : null}
                {!this.state.type ? <div className="payment-amount-select" id="payment_buttons">
                    {this.props.booking.payment_options.map((opt) => {
                        return <Button key={opt.type}
                                       onClick={() => this.setType(opt.type)}
                                       text={opt.text}
                                       name={`pay-deposit ${opt.type}`}/>
                    })}
                </div> : null}
            </div>
        </Fragment>
    }
}

PayBookingSession.propTypes = {
    onComplete: PropTypes.func,
    type: PropTypes.string || null
}
export default PayBookingSession;
