import React, {Component} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import Overview from "./Overview/Overview";
import Payment from "./../EditBooking/Tabs/Payment/Payment";
import {EventDispatcher} from "../../Events/Dispatcher";
import cx from "classnames";
import Icon from "../../Utility/Icon/Icon";
import './../EditBooking/Tabs/Tabs.scss';
import IntroText from "../../Utility/IntroText/IntroText";
import {Settings} from "../../Settings/Settings";
import './ReBook.scss';
import Notice from "../../Utility/Notice/Notice";

class ReBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            code: this.props.token,
            booking: {},
            show_rebooked: false,
            tab: window.location.hash ? window.location.hash.replace('#', '') : 'overview'
        }
    }

    componentDidMount() {
        this.updateBooking();
        this.listener = window.addEventListener('hashchange', () => this.checkHash());
        this.show_rebooked = EventDispatcher.subscribe('show-rebooked', (value) => this.setState({show_rebooked: value}));
        this.booking_change_listener = EventDispatcher.subscribe('booking-update', () => this.updateBooking());
    }

    checkHash() {
        this.setState({tab: window.location.hash ? window.location.hash.replace('#', '') : 'overview'});
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', () => this.checkHash());
        EventDispatcher.unsubscribe(this.booking_change_listener);
        EventDispatcher.unsubscribe(this.show_rebooked);
    }

    updateBooking() {
        this.setState({loading: true});
        let params = {include: 'guests,guests.customer,options'};
        if (this.state.password) {
            params.password = this.state.password;
        }
        this.setState({booking_loading: true});
        Api.get('rest/booking/manage/' + this.state.code, params).then((response) => {
            if (response.ok) {
                this.setState({
                    booking: response.data.data,
                    loading: false,
                    requires_password: false,
                    booking_loading: false
                });
                return;
            }
            this.setState({error: response.error});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }

        let booking = this.state.booking;
        return <div className="booking-detail re-book">
            <IntroText name="re-book">
                <p>Oh {booking.forename}, We've missed you! We're getting ready to re-open and you can now
                    re-arrange your postponed booking. We can't wait to get back open and start serving
                    you {Settings.getBrandDrink()}'s.</p>
            </IntroText>
            <div className="booking-tabs">
                <div className="booking-tabs--tabs">
                    <div className={cx('booking-tabs--tab tab--overview', {'active': this.state.tab === 'overview'})}>
                        <a href="#overview" onClick={(e) => this.setState({tab: 'overview'})}>
                            <Icon alt="Overview" icon="Overview"/>
                            Overview
                        </a>
                    </div>
                    {this.state.booking.pbr > 0 === true ?
                        <div className={cx('booking-tabs--tab tab--payment', {'active': this.state.tab === 'payment'})}>
                            <a href="#payment" onClick={(e) => this.setState({tab: 'payment'})}>
                                <Icon alt="Payment" icon="Payment"/>
                                Payment</a>
                        </div> : null}
                </div>
            </div>
            {this.state.show_rebooked ?
                <Notice name="rebooked" variant="success">
                    You're booked in! We actually can't wait to see you again!
                </Notice> : null}
            {this.state.tab === 'overview' ? <Overview booking={booking}/> : null}
            {this.state.tab === 'payment' ? <Payment booking={booking}/> : null}
        </div>
    }
}

export default ReBook;