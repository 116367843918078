import {Api} from "../Api/Api";
import React, {useCallback, useEffect, useState} from "react";
import Loading from "../Utility/Loading/Loading";
import Button from "../Form/Element/Button";
import Notice from "../Utility/Notice/Notice";
import './StaffLogin.scss';

function StaffLogin(props) {
    const [loading, setLoading] = useState(true);
    const [code, setCode] = useState(null);
    const [state, setState] = useState(null);
    const checkForCode = useCallback(() => {
        const string = props.location.search.substr(0, 1) === '?' ?
            props.location.search.substr(1)
            :
            props.location.search;

        if (string) {
            const parts = string.split('&').map((part) => {
                const urlPart = part.split('=', 2);
                if (urlPart[0] === 'code') {
                    setCode(urlPart[1]);
                }
                if (urlPart[0] === 'state') {
                    setState(urlPart[1]);
                }
            });
        } else {
            setLoading(false);
        }
    }, [props.location.search]);
    useEffect(() => {
        if (code && state) {
            setLoading(true);
            Api.postJson('rest/authentication/auth-bridge', {code: code, state: state}).then((response) => {
                if (response.ok) {
                    window.localStorage.setItem('staff_login', JSON.stringify(response.data.data));
                    document.location.href = '/staff/data-collect';
                    return;
                }
                setLoading(false);
            })
        }
    }, [code, state]);
    useEffect(() => {
        checkForCode();
    }, [checkForCode]);
    if (loading) {
        return <Loading/>
    }
    return <div className="staff-login-wrapper">
        <Notice name="staff-login">
            Click login below to authenticate with your R-Cloud username & password
        </Notice>
        <Button text="Login" name="login" variant="full-width" onClick={() => {
            setLoading(true);
            Api.getJson('rest/authentication/auth-url', {url: 'https://bookings-rev.ssl.test/staff/login'}).then((response) => {
                if (response.ok) {
                    document.location.href = response.data.url;
                    return;
                }
                setLoading(false);
            });
        }}/>
    </div>
}

export default StaffLogin;