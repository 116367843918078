import React from 'react';
import PropTypes from 'prop-types';
import TypeSelect from '../Elements/TypeSelect';
import { BookingData } from '../Data/BookingData';
import BookingComplete from '../Elements/BookingComplete/BookingComplete';
import CompleteBooking from '../Elements/CompleteBooking';
import FormElementText from '../../Form/Element/Text';
import FormElementDatePicker from '../../Form/Element/DatePicker';
import FormElementTextArea from '../../Form/Element/TextArea';
import Button from '../../Form/Element/Button';
import BookingStep from '../../Utility/BookingStep/BookingStep';
import Notice from '../../Utility/Notice/Notice';
import TermsText from '../Elements/TermsText';
import UserData from '../Elements/UserData';
import FormElementTimeSelect from '../../Form/Element/FormElementTimeSelect';
import moment from "moment/moment";
import PreBookingInfo from "../Elements/PreBookingInfo";

export default function GenericConsumerEnquiry (props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}/>;
    }
    const getError = (field) => {
        if (!booking[field]) {
            return null;
        }
        let error = BookingData.getFieldError(field);
        if (error) {
            return error.message;
        }
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} name="pre-booking-info" type="generic_enquiry" hot_jar_url="enquiry-info"
                            booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({ 'step': 2 })} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>;
    }
    if (booking.step === 2) {
        return <BookingStep step={2} name="user-data" type="generic_enquiry" hot_jar_url="enquiry-personal-details"
                            booking={props.booking}>
            <FormElementTextArea name="special_requirements" onChange={(e) => {
                BookingData.update({ special_requirements: e.target.value });
            }} placeholder="Any special requirements?"
                                 value={props.booking.special_requirements
                                     ? props.booking.special_requirements
                                     : ''}
                                 defferredUpdate={true}/>
            <UserData booking={props.booking} updating={props.updating}
                      include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                           placeholder="Date"
                                                           minDate={moment(props.booking.min_booking_date).toDate()}
                                                           openToDate={BookingData.getOpenToDate()} include_booking_time={true}
                      />}
            />
            <TermsText booking={props.booking}/>
            <CompleteBooking complete_button_text="Send Enquiry" booking={props.booking}/>
        </BookingStep>;
    }
    return <BookingStep step={999} name="no-step" type="generic_enquiry" hot_jar_url="enquiry-error"
                        booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>;
}

GenericConsumerEnquiry.propTypes = {
    booking: PropTypes.object.isRequired,
    updating: PropTypes.bool.isRequired
};
