import React, {useEffect, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";

export default function ViewBar(props) {
    const [bar, setBar] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Api.get('rest/bar/' + props.slug + '/opening-times', {}).then((response) => {
            if (response.ok) {
                setBar(response.data.data);
                setLoading(false);
            }
        })
    }, []);

    if (loading) {
        return <Loading/>
    }

    return <div>
        <h2>{bar.branded_name}</h2>
        <p>{bar.address_parts.join(', ')}</p>
        <pre>{JSON.stringify(bar, null, 2)}</pre>
    </div>

}