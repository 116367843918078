import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";

class FormElementTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({value: nextProps.value});
        }
    }

    static defaultProps = {
        defferredUpdate: false
    }

    onChange(e) {
        this.setState({value: e.target.value});
        if (this.props.defferredUpdate !== true) {
            this.props.onChange({
                target: {
                    name: e.target.name,
                    value: e.target.value
                }
            });
        }
    }


    componentDidMount() {
        window.dispatchEvent(new CustomEvent('form_element_initialise', {
            detail: {
                type: 'text',
                ref: this.ref.current
            }
        }));
    }

    componentWillUnmount() {
        window.dispatchEvent(new CustomEvent('form_element_destroy', {
            detail: {
                type: 'text',
                ref: this.ref.current
            }
        }));
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
            return;
        }
        if (this.props.defferredUpdate === true) {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        var className = this.props.className;
        if (!className) {
            className = 'field__element';
        }
        var id = this.props.id;
        if (!id) {
            id = this.props.name;
        }
        return <div ref={this.ref} className={cx('field', 'field--textarea', 'field--' + this.props.name)}>
            <div className="field__element-wrap">
            <textarea name={this.props.name} id={id} value={this.state.value}
                                             className={className} onChange={e => this.onChange(e)}
                                             disabled={this.props.disabled}
                                             onBlur={e => this.onBlur(e)} placeholder={this.props.placeholder}
                                             onFocus={this.props.onFocus} maxLength={this.props.maxLength}/>
            </div>
        </div>
    }
}

FormElementTextArea.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    maxLength: PropTypes.number
};

export default FormElementTextArea;