import React, {useEffect, useState} from 'react';
import './PreOrder.scss';
import PropTypes from 'prop-types';
import Package from "./Package";
import {EventDispatcher} from "../../../Events/Dispatcher";
import _ from 'lodash';
import cx from 'classnames';
import IntroText from "../../../Utility/IntroText/IntroText";

export default function PreOrder(props) {
    useEffect(() => {
        EventDispatcher.dispatch('update_height', {update: _.uniqueId('update')});
    });
    let has_package = false;
    let has_custom = false;
    let initial_type = 'package';
    const [type, setType] = useState(initial_type);
    let options = props.booking.available_options.data.filter((opt) => {
        return opt.display_type === type;
    });
    useEffect(() => {
        if (!has_package && has_custom) {
            initial_type = 'custom';
        }
        setType(initial_type);
    }, []);
    props.booking.available_options.data.map((opt) => {
        if (opt.display_type === 'package') {
            has_package = true;
        }
        if (opt.display_type === 'custom') {
            has_custom = true;
        }
        return opt;
    });
    return <div className="pre-order">   
        {props.intro_text || props.sub_text ?
            <IntroText name="pre-order-intro" title={props.intro_text}>{props.sub_text}</IntroText> : null}
        {(has_package && has_custom) ? <div className="type-select">
            {has_package ?
                <button className={cx('package', {active: type === 'package'})} onClick={() => setType('package')}>
                    Packages
                </button> : null}
            {has_custom ?
                <button className={cx('custom', {active: type === 'custom'})} onClick={() => setType('custom')}>
                    Custom
                </button> : null}
        </div> : null}
        <div className="products">
            {options.map((option, index) => {
                return <Package option={option} booking={props.booking} key={index}/>
            })}
        </div>
    </div>

}
PreOrder.defaultProps = {
    skip_text: 'Skip',
    next_step: 4
};
PreOrder.propTypes = {
    intro_text: PropTypes.string,
    sub_text: PropTypes.string,
    skip_text: PropTypes.string,
    next_step: PropTypes.number,
    updating: PropTypes.bool.isRequired
}