import React, {useEffect, useState} from 'react';
import FormBarSelect from "../Form/Element/FormBarSelect";
import {Settings} from "../Settings/Settings";
import FormRow from "../Form/Row";
import FormElementSelect from "../Form/Element/Select";
import {Api} from "../Api/Api";
import Loading from "../Utility/Loading/Loading";
import moment from "moment";
import './MenuAllergens.scss'

const MenuAllergens = (props) => {
    const [bar, setBar] = useState(parseInt(props.bar_id) ? props.bar_id : '');
    const [type, setType] = useState(props.type ? props.type : '');
    const [loading, setLoading] = useState(false);
    const [bars, setBars] = useState([]);
    const [files, setFiles] = useState([]);
    const [types, setTypes] = useState([
        {key: 'food', value: 'Food'},
        {key: 'drink', value: 'Drink'},
        {key: 'party', value: 'Party'},
    ]);
    useEffect(() => {
        if (type && bar) {
            setLoading(true);
            setFiles([]);
            Api.get('menus/allergens/website', {bar_id: bar, type: type}).then((response) => {
                setLoading(false);
                if (response.ok) {
                    response.data.map((item) => {
                        if (item.type === 'Allergen') {
                            setFiles(item.pdfs);
                        }
                    })
                }
            })
        }
    }, [bar, type]);
    return (
        <>
            <FormRow label="Location">
                <FormBarSelect onChange={(e) => {
                    setBar('' + e.target.value);
                }} selectedValue={bar} name="bar_id"
                               brand={Settings.brand} value={bar}/>
            </FormRow>
            <FormRow label="Menu Type">
                <FormElementSelect name="type" onChange={(e) => setType(e.target.value)} values={types}
                                   includeBlankOption={true} selectedValue={type}/>
            </FormRow>
            {loading ? <Loading/> :
                <ul className='menu-list'>
                    {(files.length === 0 && bar && type) &&
                        <li className="menu-item">Sorry, we don't have any allergen information for this menu type
                            currently. Please check
                            again later</li>}
                    {files.map((f) => (
                        <li key={f.uri} className="menu-item">
                            <i className="fa fa-info"/>
                            <div className='menu-item-wrapper'>
                                <p>
                                    <b>Menu</b>: &nbsp;
                                    <a href={f.uri} target="_blank">{f.menu_name}</a>
                                </p>
                                <p>
                                    <b>Date</b>: &nbsp;
                                    {moment(f.bar_start_date).format('DD/MM/YYYY')} - {moment(f.bar_end_date).format('DD/MM/YYYY')}
                                </p>
                                <p>
                                    <b>Last modified</b>: &nbsp;
                                    {moment(f.date_uploaded).format('DD/MM/YYYY')}
                                </p>
                                <div className="menu-item-divider"/>
                            </div>
                        </li>)
                    )}
                </ul>
            }
        </>
    )
}

export default MenuAllergens;