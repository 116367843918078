import React, {useEffect, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";
import Notice from "../Utility/Notice/Notice";

const EmailVerification = ({code}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    useEffect(() => {
        Api.post('/customers/email-verify/' + code, {}).then((response) => {
            if (response.ok) {
                setMessage('Thanks! Your e-mail is now verified');
                setLoading(false);
                return;
            }
            setError(response.error);
            setLoading(false);
        })
    }, [code]);
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Notice name="error">{error}</Notice>
    }
    return <div>
        <Notice name="thanks" variant="info">{message}</Notice>
    </div>
}

export default EmailVerification;