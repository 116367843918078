import {Api} from "../../Api/Api";
import {Settings} from "../../Settings/Settings";
import _ from 'lodash';
import {EventDispatcher} from "../../Events/Dispatcher";
import Diff from "../../Utility/Diff";
import {NotificationManager} from "../../Notifications/NotifcationManager";
import {ModalHelper} from "../../Utility/Modal/AppModal";
import React from "react";
import AddCardToSession from "../Elements/Payment/AddCardToSession";
import PayBookingSession from "../Elements/Payment/PayBookingSession";
import moment from "moment/moment";

export const BookingData = {
    react_class: null,
    listeners: [],
    session_id: null,
    booking_data: {},
    bars: [],
    height: null,
    user: {},
    params: {
        force_create_new_session: true,
        can_close: false,
        force_location: false
    },
    updating: false,
    payment_type: null,
    init: function (react_class) {
        this.react_class = react_class;
        this.sendMessage('loaded', true);
        window.addEventListener('message', (message) => {
            if (message.data.type === 'params') {
                let params = message.data.params;
                Settings.environment = params.env;
                this.setParams(params);
                this.react_class.setState({params: params});
                let pre_filled_data = params.pre_filled_data ? params.pre_filled_data : {};
                if (!pre_filled_data.step && !pre_filled_data.session_id) {
                    pre_filled_data.step = 1;
                }
                this.getSettings(() => {
                    this.update(pre_filled_data, () => {
                        this.react_class.setState({loading: false});
                    });
                });
            }

            if (message.data.type === 'apple_pay_complete') {
                EventDispatcher.dispatch('apple-pay-complete', message.data);
            }
        });
        EventDispatcher.subscribe('post_booking_update', (data) => {
            this.sendMessage('booking_update', data);
        });
    },
    checkUser: function (callback) {
        let user_data = window.localStorage.getItem('user');

        Api.get('customers/me', {include: Settings.getCustomerIncludes().join(',')}).then((response) => {
            if (response.ok) {
                BookingData.user = response.data.data;
                callback(response.data.data);
            } else {
                callback(false);
            }
        });
    },
    loggedIn: function () {
        return !!BookingData.user.id;
    },
    getSettings: function (callback) {
        callback();
    },
    loadFiles: function (files, callback) {
        let head = document.getElementsByTagName('head')[0];
        for (let key in files) {
            let file = files[key];
            if (file.type === 'css') {
                let link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = file.url;
                head.appendChild(link);
            }
            if (file.type === 'js') {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = file.url;
                head.appendChild(script);
            }
        }
        if (callback) {
            callback();
        }
    },
    in_card: false,
    setPaymentType: function (type) {
        BookingData.payment_type = type;
    },
    completeBooking: function (callback) {
        if (this.booking_data.take_payment && !this.booking_data.has_payment) {
            ModalHelper.openModal(<PayBookingSession code={this.booking_data.deposit_token}
                                                     booking={this.booking_data} type={BookingData.payment_type}/>);
            return;
        }
        if (this.booking_data.requires_card && this.booking_data.has_stored_card === false && !this.booking_data.take_payment) {
            ModalHelper.openModal(<AddCardToSession session_id={this.booking_data.session_id}
                                                    fee={this.booking_data.no_show_fee}
                                                    pp_fee={this.booking_data.no_show_fee_pp}
                                                    message={this.booking_data.card_capture_message}/>);
            return;
        }
        let data = {};
        if (this.updating) {
            setTimeout(() => {
                this.completeBooking(callback);
            }, 200);
            return;
        }
        this.updating = true;
        data.brand = this.params.brand;
        if (this.session_id) {
            data.session_id = this.session_id;
        }
        this.react_class.setState({updating: true, saving_booking: true});
        Api.post('rest/booking/session/complete', data).then((response) => this.handleBookingResponse(response, callback));
    },
    handleBookingResponse: function (response, callback) {
        this.updating = false;
        this.react_class.setState({updating: false, saving_booking: false});
        if (!response) {
            return;
        }
        if (response.ok) {
            let data = response.data;
            if (!this.session_id) {
                this.session_id = data.data.session_id;
            }

            if (data.data.bars && data.data.bars.data.length) {
                this.bars = data.data.bars.data;
            }
            this.setData(data.data);
            data.data.flash_messages.map((message) => {
                if (message.type === 'error') {
                    NotificationManager.showError(message.message);
                }
                if (message.type === 'message') {
                    NotificationManager.showInfo(message.message);
                }
                return message;
            })
            if (callback) {
                callback(data.data);
            }
            return;
        }
        NotificationManager.showError(response.error);
        this.setData(this.booking_data);
        if (callback) {
            callback(this.booking_data);
        }
    },
    subscribe: function (callback) {
        return this.listeners.push(callback);
    },
    unsubscribe: function (index) {
        this.listeners.splice(index, 1);
    },
    setParams: function (params) {
        this.params = _.extend(this.params, params);
    },
    canClose: function () {
        return this.params.can_close;
    },
    closeBooking: function () {
        this.sendMessage('close_booking', true);
    },
    updateVariation: function (id, qty, choices, callback) {
        if (!choices) {
            let variation = this.booking_data.variations.data.find((booking_var) => {
                return booking_var.variation_id == id;
            });
            if (variation) {
                choices = variation.choices;
            } else {
                choices = [];
            }
        }
        this.update({variations: [{qty: qty, variation_id: id, choices: choices}]}, callback)
    },
    update: function (data, callback, force_update) {
        var keys = Object.keys(data);
        if (typeof data.forename !== 'undefined' && data.forename === '' && keys.length === 1) {
            return;
        }
        if (this.updating) {
            setTimeout(() => {
                this.update(data, callback);
            }, 200);
            return;
        }
        let has_update = force_update === true;
        Object.keys(data).map((key) => {
            if (JSON.stringify(this.booking_data[key]) !== JSON.stringify(data[key])) {
                has_update = true;
            }
            return key;
        });
        if (has_update === false) {
            if (callback) {
                callback(this.booking_data);
            }
            return;
        }
        this.updating = true;
        data.brand = this.params.brand;
        let include = [];
        if (this.bars.length === 0) {
            include.push('bars');
        }
        if (include.length) {
            data.include = include.join(',');
        }
        if (this.session_id) {
            data.session_id = this.session_id;
        } else {
            data.form_version = '2.0';
        }

        data.booking_flow_version = 2;
        this.react_class.setState({updating: true});
        Api.post('rest/booking/session', data).then((response) => this.handleBookingResponse(response, callback));
    },
    setUpdating: function () {
        this.react_class.setState({updating: true})
    },
    getBarList: function () {
        return _.map(this.bars, (bar) => {
            return {
                key: bar.id,
                value: bar.bar_name
            }
        });
    },
    setData: function (data) {
        let previous = _.extend({}, this.booking_data);
        this.booking_data = data;
        let react_class_state = {booking: data};
        let user_data = this.react_class.state.user_data;
        if (data.forename) {
            user_data.forename = data.forename;
        }
        if (data.surname) {
            user_data.surname = data.surname;
        }
        if (data.email) {
            user_data.email = data.email;
        }
        if (data.bar && data.bar.data.bar_id) {
            user_data.bar_id = data.bar.data.bar_id;
        }
        if (Object.keys(user_data).length > 0) {
            react_class_state.user_data = user_data;
        }
        let diff = Diff(previous, data);
        if (JSON.stringify(previous.bar) !== JSON.stringify(data.bar)) {
            diff.bar = data.bar;
        }
        EventDispatcher.dispatch('pre_booking_update', {
            previous: previous,
            booking: data,
            diff: diff
        });
        this.react_class.setState(react_class_state);
        this.listeners.map((callback) => {
            callback(data);
            return callback;
        });
        EventDispatcher.dispatch('post_booking_update', {
            previous: previous,
            booking: data,
            diff: diff
        });
    },
    getTypeList() {
        return this.booking_data.available_types.data.sort((t1, t2) => {
            return t1.position > t2.position;
        }).map((type) => {
            return {
                key: type.id,
                value: type.front_end_name
            }
        });
    },
    getBookingCategoryList() {
        return this.booking_data.booking_type_categories.data;
    },
    getOpenToDate(allow_minimum_booking_date = false) {
        let open_to_date = moment().toDate();
        if (this.booking_data.date) {
            open_to_date = moment(this.booking_data.date).toDate();
        } else if (this.booking_data.min_booking_date && moment(this.booking_data.min_booking_date) && allow_minimum_booking_date) {
            open_to_date = moment(this.booking_data.min_booking_date).toDate();
        }
        return open_to_date
    },
    isFunction: function (functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    },
    sendMessage: function (type, data) {
        window.parent.postMessage({
            id: _.uniqueId('message'),
            type: type,
            value: data,
            iframe_id: this.params.iframe_id
        }, '*');
    },
    getFieldError(field) {
        return _.find(_.extend([], this.booking_data.fields_required), (required) => {
            return required.field === field;
        });
    },
    getValidationError(field) {
        return _.find(_.extend([], this.booking_data.validation_errors), (required) => {
            return required.field === field;
        });
    },
    getValidationErrorMessage(field) {
        let error = this.getValidationError(field);
        if (error) {
            return error.message;
        }
        return null;
    },
    getCurrentSlot() {
        return _.find(this.booking_data.all_slots.data, (slot) => {
            return slot.time === this.booking_data.time;
        })
    },
    inIframe: function () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    },
    createDepositRequest(callback) {
        this.react_class.setState({updating: true});
        Api.post('rest/booking/session/deposit', {session_id: this.session_id, include: 'gp'}).then((response) => {
            if (response.ok) {
                if (callback) {
                    callback(response.data.data);
                }
            }
            this.react_class.setState({updating: false});
        });
    }
}