import React, {Component} from 'react';
import Loading from "../../../Utility/Loading/Loading";
import {Api} from "../../../Api/Api";
import IntroText from "../../../Utility/IntroText/IntroText";
import moment from "moment";
import TimeSelectButton from "../../../Form/Element/TimeSelectButton";
import FormElementText from "../../../Form/Element/Text";
import Button from "../../../Form/Element/Button";

class BrunchUpgrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            callback_slots: {},
            selected_callback_slot: null,
            notes: '',
            has_call: false,
            is_editing_call: false,
            existing_call_id: null,
            has_errors: false,
        }
    }

    selectSlot(selected_slot) {
        this.setState({selected_callback_slot: selected_slot})
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({loading: true, errors: false})
        Api.post('rest/booking/manage/' + this.props.booking.manage_key + '/brunch-upgrade', {
            callback_slot: this.state.selected_callback_slot,
            notes: this.state.notes,
            id: this.state.existing_call_id
        }).then((response) => {
            if (response.status !== 200) {
                this.setState({has_errors: true})
            }
            this.loadBrunchUpgrade()
        }).catch(() => {
            this.setState({has_errors: true})
        })
    }

    componentDidMount() {
        this.loadBrunchUpgrade()
    }

    loadBrunchUpgrade() {
        Api.get('rest/booking/manage/' + this.props.booking.manage_key + '/brunch-upgrade', {})
            .then((response) => {
                if (response.status !== 200) {
                    this.props.onInvalidUpgrade();
                }
                if (response.data.meta.can_upgrade) {
                    if (response.data.data.length > 0) {
                        let callback_slots = response.data.data.splice(0, 12);
                        this.setState({
                            callback_slots: callback_slots,
                            loading: false,
                            is_editing_call: false
                        })

                        if (response.data.meta.has_existing_call) {
                            this.setState({
                                has_call: true,
                                selected_callback_slot: response.data.meta.existing_call.date,
                                notes: response.data.meta.existing_call.notes,
                                existing_call_id: response.data.meta.existing_call.id
                            })
                        } else {
                            this.setState({
                                selected_callback_slot: callback_slots.find(slot => slot.asap === true).date
                            })
                        }
                    } else {
                        this.setState({loading: false, errors: true})
                    }
                } else {
                    this.props.onInvalidUpgrade();
                }

            }).catch(() => {
                this.props.onInvalidUpgrade();
            });
    }


    render() {
        if (this.state.loading) {
            return <Loading/>
        }

        let selected_date_start, selected_date_end = moment();

        if (this.state.selected_callback_slot) {
            selected_date_start = moment(this.state.selected_callback_slot);
            selected_date_end = moment(this.state.selected_callback_slot).add(2, 'hours');
        }

        return <div>
            <h1>Brunch Upgrade</h1>

            {this.state.has_errors && <div className="error"><small>There was a problem submitting your request. Please try again or contact the bar.</small></div>}

            {this.state.callback_slots.length > 0 ?
            <div>
                <IntroText name="brunch-upgrade">Take your brunch to the next level!</IntroText>

                {(this.state.has_call && !this.state.is_editing_call) && <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>Thank you for registering your interest in upgrading your brunch. <br/>
                                We will attempt to call you
                                between {selected_date_start.format('HH:mm')} and {selected_date_end.format('HH:mm')} on {selected_date_start.format('dddd Do MMMM')}.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-holder text-center">
                                <Button
                                    name="edit_callback"
                                    text="Change Callback Time"
                                    onClick={() => this.setState({is_editing_call: true})}
                                    disabled={this.state.loading}/>
                            </div>
                        </div>
                    </div>
                </div>}

                {(!this.state.has_call || this.state.is_editing_call) &&
                <div className="slot-holder choose-callback-time brunch-upgrade-choose-callback-time">
                    <IntroText name="choose-time">Choose your time below, and we'll give you a call to discuss
                        further.</IntroText>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="time-slot-holder clearfix">
                                {this.state.callback_slots.map((callback_slot) => {
                                    let date = moment(callback_slot.date);
                                    let day = date.format('dddd Do');
                                    if (date.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
                                        day = 'Today';
                                    } else if (date.format('DD/MM/YYYY') === moment().add(1, 'day').format('DD/MM/YYYY')) {
                                        day = 'Tomorrow';
                                    }
                                    let additional = 'between ' + callback_slot.start + ':00 and ' + callback_slot.end + ':00'
                                    if (callback_slot.end === 17) {
                                        additional = 'between ' + callback_slot.start + ':00 and 16:45'
                                    }
                                    return <TimeSelectButton
                                        text={day}
                                        additional={additional}
                                        key={callback_slot.date}
                                        onClick={() => {
                                            this.selectSlot(callback_slot.date)
                                        }}
                                        selected={this.state.selected_callback_slot === callback_slot.date}
                                        active={true}
                                        stacked={true}
                                        isSelectable={true}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <FormElementText
                                name="notes"
                                value={this.state.notes}
                                placeholder="Notes (optional)"
                                onChange={(e) => this.setState({'notes': e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-holder pull-right">
                                <Button
                                    name="submit"
                                    text={this.state.is_editing_call ? 'Change Callback Request' : 'Request Callback'}
                                    onClick={(e) => this.handleSubmit(e)}
                                    disabled={this.state.loading}/>
                            </div>
                        </div>
                    </div>
                </div>}
            </div> : <div className="warning">There are no callback times available before your booking.</div> }
        </div>
    }
}

export default BrunchUpgrade;