import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../Form/Element/Button";
import Center from "../../../../../Utility/Center/Center";
import {Api} from "../../../../../Api/Api";
import Notice from "../../../../../Utility/Notice/Notice";
import {NotificationManager} from "../../../../../Notifications/NotifcationManager";

export default function SendReminder(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const sendReminder = (type) => {
        setLoading(true);
        Api.post('rest/booking/' + props.booking.id + '/reminder/' + type, {guest_id: props.guest.id}).then((response) => {
            setLoading(false);
            if (response.ok) {
                props.goBack();
                NotificationManager.showSuccess('Reminder has been sent!');
                return;
            }
            setError(response.error);
        })
    }
    let variations = props.guest.variations.data.map((v) => {
        return v.id;
    });
    return <div className="send-reminder">
        {error ? <Notice name="error" variant="error">{error}</Notice> : null}
        <Center>
            {/*{props.guest.share > 0 ? <Button text="Send Payment Reminder" name="send-payment-reminder"*/}
            {/*                                 onClick={() => sendReminder('payment')}*/}
            {/*                                 disabled={loading}/> : null}*/}
            {variations.length > 0 ? <Button text="Send Pre Order Reminder" name="send-pre-order-reminder"
                                             onClick={() => sendReminder('pre-order')} disabled={loading}/> : null}
            <Button text="Send Booking Details" name="send-details-reminder"
                    onClick={() => sendReminder('guest-details')} disabled={loading}/>
            {props.guest.accepted === false ? <Button text="Send Invite Reminder" name="invite-reminder"
                                                      onClick={() => sendReminder('invite-reminder')}
                                                      disabled={loading}/> : null}

            <Button text="Cancel" name="cancel" onClick={(e) => props.goBack(e)} variant="outline" disabled={loading}/>
        </Center>
    </div>
}

SendReminder.propTypes = {
    goBack: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired,
    guest: PropTypes.object.isRequired
}