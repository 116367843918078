import React from 'react';
import PropTypes from 'prop-types';
import {Settings} from "../../../Settings/Settings";
import moment from "moment";
import './BookingListing.scss';
import cx from 'classnames';
import Link from "react-router-dom/Link";
import {BookingData} from "../../../Bookings/Data/BookingData";

export default function BookingListing(props) {
    let balance;
    let people = props.booking.people;
    let perPersonBalance = props.booking.per_person_balance;
    let perPersonDeposit = props.booking.per_person_deposit;

    if (props.expanded === true) {
        let status_class = 'booking-listing--status--' + props.booking.status_class;
        let type = 'guest';
        if (props.booking.co_hosts_ids.indexOf(BookingData.user.id) >= 1) {
            type = 'co-host';
        }
        if (props.booking.lead_booker_id === BookingData.user.id) {
            type = 'lead-booker';
            balance = (perPersonBalance + perPersonDeposit) * people;
        }
        return <div className="booking-listing booking-listing-expanded">
            <span className={'booking-listing--attendee-type type--' + type}/>
            <span className="booking-listing--booking-name">{props.booking.party_name}</span>
            <span className="booking-listing--ref-code">REF: {props.booking.ref_code}</span>
            <div className="booking-info grid">
                <div className="grid__item booking-info__item booking-info--date">
                    <span>
                        <img src="/assets/icons/Date.svg" alt="Date"/>
                        {moment(props.booking.booking_date).format(Settings.booking_date_format)}
                    </span>
                </div>
                <div className="grid__item booking-info__item booking-info--people">
                    <span>
                        <img src="/assets/icons/Guests.svg" alt="Guests"/>
                        {props.booking.people} People
                    </span>
                </div>
                <div className="grid__item booking-info__item booking-info--bar">
                    <span>
                        <img src="/assets/icons/Bar.svg" alt="Bar"/>
                        {props.booking.bar.data.bar_name}
                    </span>
                </div>
                <div className="grid__item booking-info__item booking-info--balance">
                    <span>
                        <img src="/assets/icons/Total-Package.svg" alt="Balance"/>
                        {Settings.currencyFormatter.format(balance)}
                    </span>
                </div>
            </div>
            {props.booking.status_text ? <div
                className={cx('booking-listing--status', status_class)}>{props.booking.status_text}</div> : null}
            <Link className="booking-listing--link" to={"/edit-booking/" + props.booking.id}>View Details</Link>
        </div>
    }
    return <div className="booking-listing bookings-listing--collapsed clearfix">
        <span className="booking-listing--booking-name">{props.booking.party_name}</span>
        <i className="fa fa-arrow-right"/>
    </div>
}

BookingListing.propTypes = {
    booking: PropTypes.object.isRequired,
    expanded: PropTypes.bool
}