import 'string.prototype.repeat';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c0f259398df6471591a36c8aa79864f2@sentry-relay.revbars.cloud/4",
    integrations: [new Integrations.BrowserTracing()],
    environment: `${process.env.REACT_ENV}`,
    tracesSampleRate: 0.2,
});
ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();