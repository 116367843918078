import React, {Component} from 'react';
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import Center from "../../Utility/Center/Center";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import Notice from "../../Utility/Notice/Notice";
import IntroText from "../../Utility/IntroText/IntroText";
import FormElementPassword from "../../Form/Element/Password";
import Button from "../../Form/Element/Button";

export default class VerifyForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            loading: true,
            error: false,
            reset_error: false,
            saving: false,
            user: {}
        }
    }

    componentDidMount() {
        Api.get('customers/forgot-password/' + this.props.token + '/verify').then((response) => {
            if (response.ok) {
                this.setState({user: response.data.data, loading: false});
                return;
            }

            this.setState({error: true, loading: false});
        });
    }

    handleReset(e) {
        e.preventDefault();
        this.setState({saving: true});
        Api.post('customers/forgot-password/' + this.props.token + '/process', {password: this.state.password}).then((response) => {
            if (response.ok) {
                this.setState({complete: true, loading: false});
                document.location = '/';
                return;
            }

            this.setState({reset_error: response.error, loading: false, saving: false});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }

        if (this.state.error) {
            return <ContentBox title="Oooops" name="login" back_url="/login">
                <Center>
                    <div className="col-md-10">
                        <Notice name="expired" variant="error">
                            Sorry, that link has either expired or does not exist
                        </Notice>
                    </div>
                </Center>
            </ContentBox>
        }

        return <ContentBox title="Verify Password" name="login" back_url="/login">
            <Center>
                <div className="col-md-6">
                    {this.state.reset_error ?
                        <Notice name="expired" variant="error">
                            {this.state.reset_error}
                        </Notice> : null}
                    <form onSubmit={(e) => this.handleReset(e)}>
                        <IntroText name="choose-password">
                            Nearly there! Choose a new password and you'll be in!
                        </IntroText>
                        <FormElementPassword name="password" value={this.state.password}
                                             placeholder="New Password"
                                             onChange={(e) => this.setState({password: e.target.value})}/>
                        <Button text="Change Password" name="change-password" disabled={this.state.saving}/>
                    </form>
                </div>
            </Center>
        </ContentBox>
    }
}