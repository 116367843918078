import React, {Component, Fragment} from 'react';
import Booking from "../../Bookings/Booking";
import {BookingData} from "../../Bookings/Data/BookingData";
import Loading from "../../Utility/Loading/Loading";
import './NewBooking.scss';
import IntroText from "../../Utility/IntroText/IntroText";
import {EventDispatcher} from "../../Events/Dispatcher";
import Notice from "../../Utility/Notice/Notice";
import Button from "../../Form/Element/Button";
import {Settings} from "../../Settings/Settings";

export default class NewBooking extends Component {
    constructor(props) {
        super(props);
        let session_id = window.localStorage.getItem('last_booking_session');
        let session_deposit_refunded = window.localStorage.getItem('last_booking_session_deposit_refunded_' + session_id);
        this.state = {
            loading: false,
            session_id: session_id ? session_id : null,
            deposit_refunded: (session_deposit_refunded ?? 'false') === 'true',
        }
    }

    componentDidMount() {
        this.update = EventDispatcher.subscribe('post_booking_update', (data) => {
            if (data.booking.session_id) {
                window.localStorage.setItem('last_booking_session', data.booking.session_id);
                if (data.booking.is_deposit_refunded) {
                    this.setState({deposit_refunded: true})
                    window.localStorage.setItem('last_booking_session_deposit_refunded_' + data.booking.session_id, 'true');
                    document.location.reload();
                }
            }
        })
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update);
    }

    resetSession() {
        window.localStorage.removeItem('last_booking_session_deposit_refunded_' + this.state.session_id);
        window.localStorage.removeItem('last_booking_session');
        window.localStorage.removeItem('selected_day_part');
        document.location.reload();
        // this.setState({loading: true, session_id: null}, () => this.setState({loading: false}));
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        let data = {user_id: BookingData.user_id, brand: Settings.brand};
        if (this.state.session_id !== null) {
            data.session_id = this.state.session_id;
        }
        return <div>
            <IntroText name="booking-intro">
                Lunch? Dinner? Or some cheeky cocktails with the squad? Whatever you’re in the mood for, remember to
                book a table with us first to avoid disappointment. Fill in your details below and we may need to give
                you a buzz to confirm your booking.
            </IntroText>
            {this.state.session_id ? (this.state.deposit_refunded ?
                <div>
                    <Notice name="deposit-refunded" variant="error">Sorry, your last booking is no longer available.
                        Please choose another date and try again.
                        <Button text="Start New Booking" name="start-new" variant="text"
                                onClick={(e) => this.resetSession(e)}/>
                    </Notice>
                </div>
                : <Fragment>
                <Notice name="restarted-session">
                    We noticed you didn't complete your last booking, we've picked it up where you left off!
                    <Button text="Start New Booking" name="start-new" variant="text"
                            onClick={(e) => this.resetSession(e)}/>
                </Notice>
            </Fragment>) : null}
            <Booking data={data} debug={this.props.debug}/>
        </div>
    }
}