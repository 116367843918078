import React, {Component, Fragment} from 'react';
import Center from "../../Utility/Center/Center";
import IntroText from "../../Utility/IntroText/IntroText";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import FormElementText from "../../Form/Element/Text";
import Button from "../../Form/Element/Button";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: '',
            error: null,
            done: false,
            not_exist: false
        }
    }

    handleVerify(e) {
        e.preventDefault();
        this.setState({loading: true, error: false, done: false});
        Api.post('customers/forgot-password', {email: this.state.email}).then((response) => {
            if (response.ok) {
                if (response.data.new === true) {
                    this.setState({done: false, loading: false, not_exist: true});
                    return;
                }
                this.setState({done: true, loading: false, email: ''});
                return;
            }

            this.setState({error: response.error, loading: false});
        })
    }

    handleRegister(e) {
        e.preventDefault();
        this.setState({loading: true, done: false, error: false, not_exist: false});
        Api.post('customers/register', {
            email: this.state.email
        }).then((response) => {
            if (response.ok) {
                this.setState({done: true, email: '', loading: false});
                return;
            }

            this.setState({error: response.error, loading: false});
        });
    }


    render() {
        return <ContentBox title="Forgot Password" name="login" back_url="/login">
            <Center>
                <div className="col-md-6">
                    <form onSubmit={(e) => this.handleVerify(e)}>
                        <IntroText name="enter-email">
                            Oh dear, you've forgotten your password? Enter your e-mail below and we'll get you sorted!
                        </IntroText>
                        {this.state.not_exist ? <Notice
                            name="success" variant="muted">
                            <Fragment>Hmm.. we don't seem to have a record for you on file. Would you like to
                                register instead?
                                <Button text="Yes" name="register-instead" onClick={(e) => this.handleRegister(e)}
                                        variant="outline" disabled={this.state.loading}/>
                            </Fragment>
                        </Notice> : null}
                        {this.state.done ?
                            <Notice name="success" variant="success">
                                Cool! Check your e-mail, click on the link and you'll be in!
                            </Notice> : null}
                        {this.state.error ?
                            <Notice name="success" variant="error">
                                {this.state.error}
                            </Notice> : null}
                        <FormElementText name="email" value={this.state.email}
                                         placeholder="E-mail address"
                                         onChange={(e) => this.setState({email: e.target.value})}/>
                        <Button text="Reset my password" name="reset-password" onClick={(e) => this.handleVerify(e)}
                                disabled={this.state.loading}/>
                    </form>
                </div>
            </Center>
        </ContentBox>
    }
}