import React, {Fragment} from 'react';
import {Settings} from "../../../Settings/Settings";
import './PaymentSummary.scss';
import Notice from "../../../Utility/Notice/Notice";

export default function PaymentSummary(props) {
    let payment = props.payment_data;
    return <Fragment>
        {payment.success === true ? <Notice name="payment-success" variant="success">
                <Fragment>
                    <i className="fa fa-check"/>
                    <p>
                        Payment of <strong>{Settings.currencyFormatter.format(payment.display_amount)}</strong> has been
                        approved. <br/><br/>Auth code: <strong>{payment.auth}</strong>
                    </p>
                </Fragment>
            </Notice> :
            <Notice name="payment-failed" variant="error">
                <Fragment>
                    <i className="fa fa-times"/>
                    <p>
                        There was an error in processing your payment. Please check your details and try again.
                    </p>
                </Fragment>
            </Notice>}
    </Fragment>
}