import Notice from "../../Utility/Notice/Notice";
import moment from "moment";
import React from "react";

export default function PaymentNotice(props) {
    return (props.booking.requires_balance || props.booking.requires_deposit) && <Notice name={"booking-payment-required " + props.additional_class} variant="info">
        {props.booking.party_type === 91 ?
            <span>Simply pay the deposit to confirm your booking! Any balance will be collected on arrival.</span> : (
                moment(props.booking.balance_due_date).isAfter(moment())
                    ?
                    <span>Simply pay the deposit to confirm your booking! Please pay the remaining balance by {moment(props.booking.balance_due_date).format('DD MMM YYYY')}</span>
                    : <span>We'll need to collect the full balance from you today to confirm your booking.</span>
            )}</Notice>
}