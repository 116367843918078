import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './BookingSummary.scss';
import {Settings} from "../../../Settings/Settings";
import {Link} from "react-router-dom";
import {EventDispatcher} from "../../../Events/Dispatcher";
import Notice from "../../../Utility/Notice/Notice";

export default function BookingSummary(props) {
    let booking = props.booking;
    return <Fragment>
        <Notice name="booking-summary">
            <Fragment>
                <p>
                    <strong>Date</strong>
                    <span>{moment(booking.booking_date).format('MMMM Do YYYY')} at {moment(booking.booking_date).format('HH:mm')}</span>
                </p>
                <p>
                    <strong>Location</strong>
                    <span>{props.booking.bar.data.bar_name}</span>
                </p>
                <p>
                    <span>{props.booking.party_name}</span>
                </p>
                <p>
                    <strong>Reference</strong>
                    <span>{props.booking.ref_code}</span>
                </p>
                <p>
                    <strong>Status</strong>
                    <span>{props.booking.status} <a href="#" onClick={(e) => {
                        e.preventDefault();
                        EventDispatcher.dispatch('open-modal', {
                            content: <Fragment>
                                <p>Your booking is currently <strong>{props.booking.status}</strong></p>
                                {Settings.getStatusDescription(props.booking.status_id)}
                            </Fragment>
                        })
                    }}><i className="fa fa-info"/></a></span>
                </p>
                {!props.hide_deposit ? <Fragment>
                    {props.booking.deposit_balance > 0 ? <p>
                        <strong>Deposit Balance</strong>
                        <span>{Settings.currencyFormatter.format(props.booking.deposit_balance)} <Link
                            to={'/manage/' + booking.manage_key + '/pay/deposit'}
                            className="small-save-button">Pay</Link></span>
                    </p> : null}
                    {props.booking.balance > 0 ? <p>
                        <strong>Full Balance</strong>
                        <span>{Settings.currencyFormatter.format(props.booking.balance)} <Link
                            to={'/manage/' + booking.manage_key + '/pay/balance'}
                            className="small-save-button">Pay</Link>
                </span>
                    </p> : null}
                </Fragment> : null}
            </Fragment>
        </Notice>
    </Fragment>
}

BookingSummary.propTypes = {
    booking: PropTypes.object.isRequired,
    hide_deposit: PropTypes.bool
}