import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import './Overview.scss';
import {Settings} from "../../../../Settings/Settings";
import Button from "../../../../Form/Element/Button";
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import EditBookingDetails from "../../EditDetails/EditBookingDetails";
import Center from "../../../../Utility/Center/Center";
import CancelBooking from "../../CancelBooking/CancelBooking";
import Notice from "../../../../Utility/Notice/Notice";
import ReBook from "../../ReBook/ReBook";

export default function Overview(props) {
    return <Fragment>
        <div className="booking-overview">
            <span className="booking-overview__name">{props.booking.party_name}</span>
            <span className="booking-overview__ref">REF: {props.booking.ref_code}</span>
            <div className="booking-info grid">
                <div className="grid__item booking-info__item booking-info--date">
                    <span>
                        <img src="/assets/icons/Date.svg" alt="Date"/>
                        {moment(props.booking.booking_date).format(Settings.booking_date_format)}
                    </span>
                </div>
                <div className=" grid__item booking-info__item booking-info--people">
                    <span>
                        <img src="/assets/icons/Guests.svg" alt="Guests"/>
                        {props.booking.people} Guests
                    </span>
                </div>
                <div className="grid__item booking-info__item booking-info--bar">
                    <span>
                        <img src="/assets/icons/Bar.svg" alt="Bar"/>
                        <a href={props.booking.bar.data.full_url} target="blank">{props.booking.bar.data.bar_name}</a>
                    </span>
                </div>
                <div className="grid__item booking-info--balance booking-info__item">
                    <span>
                        <img src="/assets/icons/Total-Package.svg" alt="Balance"/>
                        {Settings.currencyFormatter.format(props.booking.balance)}
                    </span>
                </div>
            </div>
            {props.booking.status_id === 4 ?
                <Notice variant="error" name="booking-status">This booking has been cancelled</Notice> :
                <>
                    {props.booking.status_id === 5 ?
                        <Notice variant="warning" name="booking-postponed">This booking is currently
                            postponed</Notice> : null}
                    <Center>
                        <div className="action-buttons">
                            {props.booking.status_id === 5 ?
                                <Button text="Choose New Date" name="edit-booking-details" onClick={() => {
                                    ModalHelper.openModal(<ReBook booking={props.booking}/>, 'full-page');
                                }}/> :
                                <Button text="Edit Booking Details" name="edit-booking-details" onClick={() => {
                                    ModalHelper.openModal(<EditBookingDetails booking={props.booking}/>, 'full-page');
                                }}/>}
                            <Button text="Cancel Booking" name="cancel-booking" variant="outline" onClick={() => {
                                ModalHelper.openModal(<CancelBooking booking={props.booking}/>, 'full-page');
                            }}/>
                        </div>
                    </Center>
                </>
            }


            {props.booking.status_id !== 4 && props.booking.status_id !== 5 ? <Fragment>
                    <p className="line-through-text"><span>Get started with your booking</span></p>
                    <div className="booking-actions">
                        {/*{props.booking.guests.data.length < props.booking.people ? <div className="booking-actions--action">*/}
                        {/*    <a href="#guests">*/}
                        {/*        <span>Invite Your Guests</span>*/}
                        {/*        <Button text={<i className="fa fa-plus"/>} name="invite-guests" variant="circular"/>*/}
                        {/*    </a>*/}
                        {/*</div> : null}*/}
                        {props.booking.deposit_balance > 0 ? <div className="booking-actions--action">
                            <a href="#payment">
                                <span>Pay Your Deposit</span>
                                <Button text={<i className="fa fa-plus"/>} name="invite-guests" variant="circular"/>
                            </a>
                        </div> : null}
                        {props.booking.balance > 0 ? <div className="booking-actions--action">
                            <a href="#payment">
                                <span>Pay Your Final Balance</span>
                                <Button text={<i className="fa fa-plus"/>} name="invite-guests" variant="circular"/>
                            </a>
                        </div> : null}
                    </div>
                </Fragment>
                : null}
        </div>
    </Fragment>
}

Overview.propTypes = {
    booking: PropTypes.object.isRequired
}