import React, {Component} from 'react';
import {Api} from "../../../Api/Api";
import Loading from "../../../Utility/Loading/Loading";
import Tabs from "./Tabs/Tabs";
import Overview from "./Tabs/Overview/Overview";
import Payment from "./../Tabs/Payment/Payment";
import {EventDispatcher} from "../../../Events/Dispatcher";
import Notice from "../../../Utility/Notice/Notice";
import './../EditBooking.scss';
import $ from 'jquery';
import PreOrder from "./Tabs/PreOrder/PreOrder";
import BrunchUpgrade from "../BrunchUpgrade/BrunchUpgrade";

class EditBookingStandalone extends Component {
    constructor(props) {
        super(props);
        let tab = window.location.hash ? window.location.hash.replace('#', '') : 'overview';
        if (props.action) {
            tab = props.action;
        }
        this.state = {
            loading: true,
            booking: {},
            error: null,
            admin: false,
            tab: tab
        }
    }

    componentDidMount() {
        this.updateBooking();
        this.listener = window.addEventListener('hashchange', () => this.checkHash());
        this.booking_change_listener = EventDispatcher.subscribe('booking-update', () => this.updateBooking());
        this.booking_data_update_listener = EventDispatcher.subscribe('booking-data', (data) => {
            EventDispatcher.dispatch('booking-data-update', data);
            this.setState({booking: data, loading: false});
        });
        $('body').addClass('edit-booking');

    }

    checkHash() {
        this.setState({tab: window.location.hash ? window.location.hash.replace('#', '') : 'overview'});
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', () => this.checkHash());
        EventDispatcher.unsubscribe(this.booking_change_listener);
        EventDispatcher.unsubscribe(this.booking_data_update_listener);
        $('body').removeClass('edit-booking');
    }

    updateBooking() {
        this.setState({loading: true});
        Api.get('rest/my-bookings/token/' + this.props.code, {}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('booking-data-update', response.data.data);
                this.setState({booking: response.data.data, loading: false});
                return;
            }

            this.setState({error: response.error, loading: false});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <Notice name="booking-error">{this.state.error}</Notice>
        }
        let booking = this.state.booking;
        return <div className="booking-detail">
            <Tabs tab={this.state.tab} changeTab={(tab) => this.setState({tab: tab})} show_payment={booking.can_customer_pay && booking.pbr > 0}
                  show_options={booking.options.data.length > 0} show_guests={false} show_pre_order={booking.has_customer_pre_order}/>
            {this.state.tab === 'overview' ? <Overview booking={booking} token={this.props.code}/> : null}
            {this.state.tab === 'payment' ? <Payment booking={booking} token={this.props.code}/> : null}
            {this.state.tab === 'pre-order' ? <PreOrder booking={booking} token={this.props.code}/> : null}
            {this.state.tab === 'brunch-upgrade' && <BrunchUpgrade booking={booking} onInvalidUpgrade={() => this.setState({tab: 'overview'})} />}
        </div>
    }
}

export default EditBookingStandalone;