import React, {Component} from 'react';
import Center from "../../../../Utility/Center/Center";
import Notice from "../../../../Utility/Notice/Notice";
import SinglePackage from "./SinglePackage";
import Button from "../../../../Form/Element/Button";
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import AddNewPackage from "./Add/AddNewPackage";

export default class Packages extends Component {
    render() {
        return <div className="edit-booking__packages">
            {this.props.booking.options.data.length === 0 ?
                <Center>
                    <img src="/assets/icons/sad-face.png" alt="All guests added!"/>
                    <Notice name="no-packages">You haven't got anything added to your booking yet.</Notice>
                </Center>
                : <h1>Your Package</h1>}
            {this.props.booking.options.data.map((opt) => {
                return <SinglePackage option={opt} key={opt.id} booking={this.props.booking}/>
            })}

            <Notice name="add-package">Fancy something else?</Notice>
            <Button text="View All Packages" name="view-all-packages" onClick={() => {
                ModalHelper.openModal(<AddNewPackage booking={this.props.booking}/>, 'full-page');
            }}/>
            <pre>{JSON.stringify(this.props.booking, null, 2)}</pre>
        </div>
    }
}
