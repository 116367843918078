import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ModalHelper} from "../../../../../Utility/Modal/AppModal";
import Button from "../../../../../Form/Element/Button";
import Notice from "../../../../../Utility/Notice/Notice";
import FormElementSelect from "../../../../../Form/Element/Select";
import {Settings} from "../../../../../Settings/Settings";
import _ from 'lodash';
import {Api} from "../../../../../Api/Api";
import {EventDispatcher as AdminModal, EventDispatcher} from "../../../../../Events/Dispatcher";

export default class AddEditSingleOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.option.choices,
            saving: false,
            flattened_package: [],
            can_change_quantity: true,
        }
    }

    componentDidMount() {
        this.updateButtons();
        this.createFlattenedUiObject();
    }

    createFlattenedUiObject()  {
        let total_choice_groups = 0, total_choices = 0, total_selected = 0;

        let head_count = this.props.option.quantity;
        let pre_order_items = [];

        let option_group_tabs = {
            all_open: true,
            all_closed: false
        }

        let flattened_package= {
            options: [],
            total_to_select: 0,
            total_selected: 0,
            total_choices: 0,
            variation_id: this.props.option.variation_id,
        }

        // Go through pre order and reset item numbers so they don't cause any issues if incorrect
        this.state.choices.forEach( (item) => {
            var identifier = item.choice_id + '_' + item.choice_item_id + '_' + item.plu;
            if (pre_order_items[identifier] !== undefined) {
                pre_order_items[identifier] = (pre_order_items[identifier] + 1);
                item.item_number = pre_order_items[identifier];
            } else {
                pre_order_items[identifier] = 1;
                item.item_number = 1;
            }
        })

        this.props.option.available_choices.forEach((choice_group) => {
            choice_group.choices.forEach((choice) => {
                let option = {
                    choices: [],
                    title: choice.title,
                    total_to_select: head_count,
                    total_selected: 0,
                    has_solo_choice: false,
                    choice_id: choice_group.group_id,
                }
                option.choice_group_id = choice.id;
                option_group_tabs[choice.id] = true;
                choice.items.forEach((item, index) => {
                    let package_choice = {
                        title: item.title,
                        display_title: item.title + (item.premium ? ' (+' + Settings.currencyFormatter.format(item.premium) + ')' : ''),
                        premium: item.premium,
                        selected: 0,
                        choice_item_id: choice.id,
                        choice_id: choice_group.group_id,
                        plu: item.plu,
                        id: item.id,
                        is_selectable: item.selectable,
                        pid: choice_group.group_id + '_' + choice.id + '_' + item.plu,
                        total_selected: 0,
                    }
                    option.choices.push(package_choice)
                    total_choices++;
                })
                total_choice_groups++;
                if (option.choices.length === 1) {
                    option.has_solo_choice = true;
                    option.total_selected = head_count;
                    option.choices[0].selected = head_count;
                    total_selected += head_count;
                }
                flattened_package.options.push(option)
            })
        })



        if (total_choices > 40 && total_choice_groups > 3) {
            option_group_tabs['all_open'] = false;
            option_group_tabs['all_collapsed'] = true;
            Object.keys(option_group_tabs).forEach(function (tab) {
                option_group_tabs[tab] = false;
            })
        }

        this.state.choices.forEach((saved_choice) => {
            var choice = null;
            var pid = saved_choice.choice_id + '_' + saved_choice.choice_item_id + '_' + saved_choice.plu;
            choice = flattened_package.options.find(function (option) {
                return option.choice_group_id === saved_choice.choice_item_id;
            })
            if (choice && !choice.has_solo_choice) {
                var found_choice_item = choice.choices.find(function (choice_item) {
                    return choice_item.pid === pid;
                })
                if (found_choice_item) {
                    choice.total_selected = (choice.total_selected + 1);
                    found_choice_item.selected = (found_choice_item.selected + 1);
                    total_selected++;
                }
            }
        })

        // Update our totals and we're done
        flattened_package.total_choices = total_choices;
        flattened_package.total_choice_groups = total_choice_groups;
        flattened_package.total_selected = total_selected;
        flattened_package.total_to_select = flattened_package.options.length * head_count;


        this.setState({flattened_package: flattened_package, option_tabs: option_group_tabs})
    }

    updateButtons() {
        ModalHelper.setButtons(
            <Fragment>
                <Button variant="outline" text="Cancel" onClick={() => ModalHelper.closeModal()} name="cancel"/>
                <Button text="Save" onClick={(e) => this.handleSave(e)} name="save-guest" disabled={this.state.saving}/>
            </Fragment>
        )
    }

    changeChoiceQuantity(choice, direction, can_action) {
        if (!this.state.can_change_quantity || !can_action) {
            return;
        }
        let pre_order = JSON.parse(JSON.stringify(this.state.choices))
        let vsp = JSON.parse(JSON.stringify(this.state.flattened_package));

        let found_choice = null, found_options = null;

        // Grab the choice and its group that we're adding/removing
        vsp.options.forEach((option) => {
            let result = option.choices.find(function (c) {
                return c.pid === choice.pid;
            })

            if (result) {
                found_choice = result;
                found_options = option;
            }
        })

        // Change our visually grouped state
        if (direction === 'increase') {
            found_choice.selected = (found_choice.selected + 1);
            found_options.total_selected = (found_options.total_selected + 1);
            vsp.total_selected = (vsp.total_selected + 1);
        } else if (direction === 'decrease') {
            found_choice.selected = (found_choice.selected - 1);
            found_options.total_selected = (found_options.total_selected - 1);
            vsp.total_selected = (vsp.total_selected - 1);
        }

        // Change regular pre order state required to save to booking
        pre_order = this.chooseOption(pre_order, choice.choice_id, choice.choice_item_id, found_choice.selected, choice.plu, direction)
        this.setState({flattened_package: vsp, choices: pre_order})
    }

    getChoice(choice_id, choice_item_id, index) {
        let option = this.state.choices.find((c) => {
            return c.choice_id === choice_id && c.choice_item_id === choice_item_id && index === c.item_number;

        });
        if (option) {
            return option.plu;
        }
        return '';
    }

    getItem(choice_group_id, choice_id, plu) {
        let return_item = {
            name: '',
            premium: 0
        };
        this.props.option.available_choices.forEach((choice_group) => {
            choice_group.choices.forEach((choice) => {
                choice.items.forEach((item) => {
                    if (choice_group.group_id.toString() === choice_group_id.toString() &&
                        choice.id.toString() === choice_id.toString() &&
                        item.plu.toString() === plu.toString()) {
                        return_item = item;
                    }
                });
            });
        });
        return return_item;
    }

    chooseOption(oc, choice_id, choice_item_id, index, plu, direction = 'increase') {
        let choices = JSON.parse(JSON.stringify(oc));
        let found = false;
        let po_item = this.getItem(choice_id, choice_item_id, plu);
        let premium = po_item.premium;
        let item_name = po_item.title;
        if (direction === 'decrease') {
            index = (index + 1);
        }
        if (!plu) {
            choices = choices.filter((c) => {
                return !(c.choice_id === choice_id && c.choice_item_id === choice_item_id && c.item_number === index);
            });
            found = true;
        } else {
            choices = choices.map((c) => {
                if (c.choice_id === choice_id && c.choice_item_id === choice_item_id && c.item_number === index && c.plu.toString() === plu.toString()) {
                    c.plu = plu;
                    c.premium = premium;
                    found = true;
                }
                return c;
            });
        }

        if (found === false) {
            if (direction === 'increase') {
                choices.push({
                    id: null,
                    choice_id: choice_id,
                    choice_item_id: choice_item_id,
                    title: item_name,
                    plu: plu,
                    premium: premium,
                    item_number: index
                });
            }
        } else {
            if (direction === 'decrease') {
                choices = choices.filter(function (c) {
                    return !(c.choice_id === choice_id &&
                        c.choice_item_id === choice_item_id &&
                        c.item_number === index &&
                        c.plu === plu
                    )
                })
            }
        }

        return choices;
    }

    toggleTab(choice_id) {
        let option_tabs = JSON.parse(JSON.stringify(this.state.option_tabs));
        option_tabs[choice_id] = !this.state.option_tabs[choice_id];
        let all_open = true;
        let all_collapsed = true
        Object.keys(option_tabs).forEach(function (option) {
            if (option !== 'all_open' && option !== 'all_collapsed') {
                if (option_tabs[option] === false) {
                    all_open = false;
                }
                if (option_tabs[option] === true) {
                    all_collapsed = false;
                }
            }
        })
        option_tabs['all_open'] = all_open;
        option_tabs['all_collapsed'] = all_collapsed;
        this.setState({option_tabs: option_tabs})
    }

    handleSave() {
        this.setState({saving: true}, () => this.updateButtons());
        Api.postJson('rest/my-bookings/token/' + this.props.token + '/item/' + this.props.option.variation_id, {items: this.state.choices}).then((response) => {
            this.setState({saving: false}, () => this.updateButtons());
            if (response.ok) {
                AdminModal.closeModal()
                EventDispatcher.dispatch('booking-update');
            }
            if (response.status === 423) {
                document.location.reload();
            }
        });
    }

    optionHasSelectableItems(option) {
        var canSelectItem = false;
        option.choices.forEach((choice) => {
            if (choice.is_selectable) {
                canSelectItem = true;
            }
        })
        return canSelectItem;
    }


    render() {
        let items = [];
        for (let x = 1; x <= this.props.option.quantity; x++) {
            items.push(x);
        }
        return <div className="row"><div className="col-md-10 col-md-offset-1">
            <div className="pre-order--modal--add-edit-single">
            {this.props.option.description ? <Notice name="pre-order--item--description">
                {this.props.option.description}
            </Notice> : null}
            <Notice name="pre-order--item--instructions">
                Choose your items from below.
            </Notice>
                <div className="pre-order--item--options">
                    {this.state.flattened_package.options && this.state.flattened_package.options.map((option, index) => {
                        let all_choices_made = option.total_selected === option.total_to_select;
                        let solo_choice = option.has_solo_choice;
                        return this.optionHasSelectableItems(option) && !solo_choice && <div className="list-group" style={{marginBottom: '10px'}} key={index}>
                            <div
                                className={"list-group-item cursor-pointer " + (all_choices_made ? 'list-group-item-success' : 'list-group-item-warning')}
                                onClick={() => this.toggleTab(option.choice_group_id)}
                            >
                                <h3 className="list-group-item-heading" style={{margin: 0}}>
                                    <span
                                        style={{marginRight: '10px'}}
                                        className={"fa " + (this.state.option_tabs[option.choice_group_id] ? "fa-chevron-down" : "fa-chevron-right")}
                                    ></span>
                                    <strong>{option.title}</strong>
                                    <small style={{fontSize: '0.7em', marginTop: '0.5em'}} className={"pull-right " + (all_choices_made ? 'text-success' : 'text-danger')}>
                                                <span
                                                    className={"fa " + (all_choices_made ? 'fa-check' : 'fa-clock-o')}
                                                    style={{marginRight: '5px'}}
                                                />
                                        <span>{option.total_selected}/{option.total_to_select} choices selected</span>
                                    </small>
                                </h3>
                            </div>
                            {this.state.option_tabs[option.choice_group_id] && option.choices.map((choice, ci) => {
                                return choice.is_selectable && <div
                                    className={"pre-order--item--choice-group list-group-item" + (choice.selected === 0 ? ' text-muted' : '')}
                                    style={{background: choice.selected > 0 ? '#f6fbf4' : 'none'}}
                                    key={index + '-' + ci}
                                >
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <span
                                                className={"fa fa-circle " + (choice.selected > 0 ? "text-success" : "text-muted")}
                                                style={{marginRight: '8px', color: choice.selected > 0 ?  '' : 'lightgray'}}
                                            />
                                            {choice.display_title}
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <div className="add-edit-options-qty-selector">
                                            {this.state.can_change_quantity ? <span
                                                onClick={() => this.changeChoiceQuantity(choice, 'decrease', choice.selected > 0 && !solo_choice)}
                                                className={"fa fa-minus quantity-selector--icon"
                                                    + ((option.has_solo_choice || choice.selected === 0 || !this.state.can_change_quantity) ? ' quantity-selector--disabled' : '')
                                                }
                                            /> : <span className="fa fa-spin fa-circle-o-notch" /> }
                                            <span className={"quantity-selector--number" + (option.has_solo_choice ? ' quantity-selector--disabled' : '')}>{choice.selected}</span>
                                            {this.state.can_change_quantity ?
                                                <span
                                                    onClick={() => this.changeChoiceQuantity(choice, 'increase', !all_choices_made && !solo_choice)}
                                                    className={"fa fa-plus quantity-selector--icon"
                                                        + ((option.has_solo_choice || all_choices_made|| !this.state.can_change_quantity) ? ' quantity-selector--disabled' : '')
                                                    } /> : <span className="fa fa-spin fa-circle-o-notch" /> }
                                            </div>
                                        </div>
                                </div>
                                </div>
                            })}
                        </div>
                    })}
                </div>
        </div></div>
        </div>
    }
}

AddEditSingleOption.propTypes = {
    option: PropTypes.object.isRequired
}