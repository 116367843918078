import React, {useCallback, useEffect, useState} from 'react';
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";
import './ContractCopy.scss';


const ContractCopy = (props) => {
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState(null);
    const [error, setError] = useState(null);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [scrollSent, setScrollSent] = useState(false);

    useEffect(() => {
        if (scrollPercentage >= 95) {
            if (!scrollSent) {
                setScrollSent(true);
                window.top.postMessage({type: 'viewed'}, '*');
                Api.post('customers/societies/society-portal/contract/' + props.match.params.code + '/' + props.match.params.admin_code + '/viewed').then((response) => {

                })
            }
        }
    }, [scrollPercentage])

    const checkData = useCallback(() => {
        window.top.postMessage({type: 'get_content'}, '*');
        window.onmessage = (e) => {
            if (e.data.type) {
                let data = e.data;
                if (data.type === 'contract_data') {
                    setContract(data.data);
                    setLoading(false);
                }
                if (data.type === 'still_loading') {
                    setTimeout(() => {
                        window.top.postMessage({type: 'get_content'}, '*');
                    }, 200);
                }
            }
        }
    }, [props.match.params.code])

    useEffect(() => {
        if (props.match.params.code) {
            window.addEventListener('scroll', (e) => {
                let height = window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight || 0;
                let scroll = window.pageYOffset ||
                    document.body.scrollTop ||
                    document.documentElement.scrollTop || 0;
                let doc_height = Math.max(
                    document.body.scrollHeight || 0,
                    document.documentElement.scrollHeight || 0,
                    document.body.offsetHeight || 0,
                    document.documentElement.offsetHeight || 0,
                    document.body.clientHeight || 0,
                    document.documentElement.clientHeight || 0
                );
                let position = (
                    (scroll + height) / doc_height
                ) * 100;
                setScrollPercentage(position);
            });
            checkData();
            // Api.get('customers/societies/society-portal/contract/' + props.match.params.code + '/' + props.match.params.admin_code, {}).then((response) => {
            //     if (response.ok) {
            //         setContract(response.data.data);
            //         setLoading(false);
            //         return;
            //     }
            //     setError(response.error);
            // });
        }
    }, [props.match.params.code, props.match.params.admin_code]);
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <div>
            <Notice name="contract-error" variant="error">{error}</Notice>
        </div>
    }
    return <div className="contract-copy">
        {scrollPercentage < 95 ? <i className="fa fa-arrow-down scroll-down-arrow"/> : null}
        <div dangerouslySetInnerHTML={{__html: contract.rendered_contract}} style={{margin: '10px'}}/>
    </div>
}

export default ContractCopy;