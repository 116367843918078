import React from 'react';
import PropTypes from 'prop-types';
import {Settings} from "../../Settings/Settings";
import moment from "moment";
import nl2br from "react-nl2br";
import {Api} from "../../Api/Api";

const BookingDebug = (props) => {
    const types = [
        {
            title: 'Session ID',
            value: props.booking.session_id,
            type: 'string'
        },
        {
            title: 'Type',
            value: props.booking.party_type_name,
            type: 'string'
        },
        {
            title: 'Capability',
            value: props.booking.capability_name,
            type: 'string'
        },
        {
            title: 'Covers',
            value: props.booking.no_people,
            type: 'string'
        },
        {
            title: 'Date',
            value: props.booking.booking_date,
            type: 'date'
        },
        {
            title: 'Requested Time',
            value: props.booking.requested_time,
            type: 'string'
        },
        {
            title: 'Can Complete',
            value: props.booking.can_complete,
            type: 'boolean'
        },
        {

            title: 'No Complete Reason',
            value: props.booking.no_complete_reason,
            type: 'string'
        },
        {
            title: 'Can fallback',
            value: props.booking.can_fallback,
            type: 'boolean'
        },
        {
            title: 'Can Auto Confirm',
            value: props.booking.can_auto_confirm,
            type: 'boolean'
        },
        {
            title: 'No Autoconfirm Reason',
            value: props.booking.no_autoconfirm_reason,
            type: 'string'
        },
        {
            title: 'No Show Fee',
            value: props.booking.no_show_fee,
            type: 'currency'
        },
        {
            title: 'Min Spend',
            value: props.booking.min_spend,
            type: 'currency'
        },
        {
            title: 'Deposit Required',
            value: props.booking.requires_deposit,
            type: 'boolean'
        },
        {
            title: 'Deposit Amount',
            value: props.booking.deposit,
            type: 'currency'
        },
        {
            title: 'Deposit Due Date',
            value: props.booking.deposit_due_date,
            type: 'date',
        },
        {
            title: 'Full Payment',
            value: props.booking.full_payment_required,
            type: 'boolean'
        },
        {
            title: 'Take Payment Now',
            value: props.booking.take_payment,
            type: 'boolean'
        },
        {
            title: 'Deposit Type',
            value: props.booking.payment_type,
            type: 'string'
        },
        {
            title: 'Tokenise Card',
            value: props.booking.requires_card,
            type: 'boolean'
        },
        {
            title: 'PBR',
            value: props.booking.value,
            type: 'currency'
        },
        {
            title: 'Bar',
            value: props.booking.bar && props.booking.bar.data ? props.booking.bar.data.bar_name : null,
            type: 'string'
        },
        {
            title: 'Pre order required',
            value: props.booking.pre_order_required,
            type: 'boolean'
        },
        {
            title: 'Can Fallback',
            value: props.booking.can_fallback,
            type: 'boolean'
        },
        {
            title: 'Can Cater For',
            value: props.booking.can_cater_for,
            type: 'boolean'
        },
        {
            title: 'Step',
            value: props.booking.step,
            type: 'string'
        },
        {
            title: 'Allow Standing',
            value: props.booking.allow_standing,
            type: 'boolean'
        },
        {
            title: 'Standing Available',
            value: props.booking.standing_available,
            type: 'boolean'
        },
        {
            title: 'Is Standing Type',
            value: props.booking.is_standing_type,
            type: 'boolean'
        },
        {
            title: 'Proposed Tables',
            value: props.booking.selected_tables.data,
            type: 'array'
        },
        {
            title: 'Available Slots',
            value: props.booking.time_slots,
            type: 'array'
        },
        {
            title: 'Callback slots',
            value: props.booking.callback_slots.data.map((s) => {
                return JSON.stringify(s);
            }),
            type: 'array'
        },
        {
            title: 'All Slots',
            value: props.booking.all_slots.data.map((d) => {
                if (d.available) {
                    if (d.price > 0) {
                        return d.time + ': Available (' + Settings.currencyFormatter.format(d.price) + ')'
                    }
                    return d.time + ': Available'
                }
                return d.time + ': ' + d.unavailable_reason;
            }),
            type: 'array',
            className: 'larger'
        },
        {
            title: 'Has Fixed Tables',
            value: props.booking.show_fixed_message,
            type: 'boolean'
        }
    ];
    return <div className="debug-info">
        {types.map((t) => {
            let value = t.value;
            if (t.type === 'string') {
                if (!value) {
                    value = 'N/A';
                }
            }
            if (t.type === 'boolean') {
                value = value ? 'Yes' : 'No';
            }
            if (t.type === 'currency') {
                value = Settings.currencyFormatter.format(value);
            }
            if (t.type === 'date') {
                value = value && moment(value) ?
                    <span>{moment(value).format('DD/MM/YYYY HH:mm')} ({moment(value).fromNow()})</span> : 'N/A'
            }
            if (t.type === 'array') {
                value = value.length ? nl2br(value.sort().join("\n")) : 'N/A';
            }
            let className = 'debug';
            if (t.className) {
                className = className + ' ' + t.className;
            }
            return <div className={className} key={t.title}>
                <div className="debug-title">{t.title}</div>
                <div className="debug-value">{value}</div>
            </div>
        })}
        <button className="btn" onClick={() => {
            let problem = window.prompt('Briefly describe what the error is');
            if (problem) {
                Api.post('rest/booking/session/log-error', {
                    error: problem,
                    'session_id': props.booking.session_id
                }).then((response) => {
                    if (response.ok) {
                        alert('Report saved');
                    } else {
                        alert(response.error);
                    }
                });
            }
        }}>Report error
        </button>
    </div>
}
BookingDebug.propTypes = {
    booking: PropTypes.object.isRequired
}
export default BookingDebug;