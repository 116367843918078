import React, {Component, Fragment} from 'react';
import Booking from "../../Bookings/Booking";
import Loading from "../../Utility/Loading/Loading";
import './../NewBooking/NewBooking.scss';
import {EventDispatcher} from "../../Events/Dispatcher";
import Notice from "../../Utility/Notice/Notice";
import Button from "../../Form/Element/Button";
import {Settings} from "../../Settings/Settings";

export default class NewAppBooking extends Component {
    constructor(props) {
        super(props);
        let session_id = window.localStorage.getItem('last_booking_session');
        this.state = {
            loading: false,
            session_id: session_id ? session_id : null
        }
    }

    componentDidMount() {
        this.update = EventDispatcher.subscribe('post_booking_update', (data) => {
            if (data.booking.session_id) {
                window.localStorage.setItem('last_booking_session', data.booking.session_id);
            }
        })
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update);
    }

    resetSession() {
        window.localStorage.removeItem('last_booking_session');
        window.localStorage.removeItem('selected_day_part');
        document.location.reload();
        // this.setState({loading: true, session_id: null}, () => this.setState({loading: false}));
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        let data = {brand: Settings.brand};
        if (this.state.session_id !== null) {
            data.session_id = this.state.session_id;
        } else {
            data = {
                app_user_id: this.props.data.app_id,
                bar_id: this.props.data.bar_id,
                source: 'app',
                brand: Settings.brand
            }
        }
        return <div>
            {this.state.session_id ? <Fragment>
                <Notice name="restarted-session">
                    We noticed you didn't complete your last booking, we've picked it up where you left off!
                    <Button text="Start New Booking" name="start-new" variant="text"
                            onClick={(e) => this.resetSession(e)}/>
                </Notice>
            </Fragment> : null}
            <Booking data={data}/>
        </div>
    }
}