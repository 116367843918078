import React, {Component} from 'react';
import {Api} from "../../../Api/Api";

class IcsCalendar extends Component {
    componentDidMount() {
        Api.get('/rest/ical/' + this.props.token, {}).then((response) => {
            if (response.ok) {
                const url = window.URL.createObjectURL(new Blob([response.data.ical]));
                const link = document.createElement('a');
                link.href = url;
                link.download = 'booking-ics-calendar-' + this.props.token + '.ics';
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.close()
            } else {
                window.location.href = '/';
            }
        }).catch((error) => {
            window.location.href = '/';
        });
    }

    render () {
        return <div>
        </div>
    }
}

export default IcsCalendar;