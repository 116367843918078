import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './Notice.scss';

export default function Notice(props) {
    let variant = null;
    if (props.variant) {
        variant = 'notice--' + props.variant;
    }
    return <div className={cx('notice', variant, 'notice--' + props.name)}>
        {props.children}
    </div>
}

Notice.propTypes = {
    variant: PropTypes.string,
    name: PropTypes.string.isRequired
}