import PropTypes from "prop-types";
import React, {Fragment, useRef, useEffect} from "react";

function Years({ handleChange, years, selectedYear }) {
    const selectedRef = useRef(null);
    useEffect(() => {
        if(!selectedRef.current) return;
        selectedRef.current.scrollIntoView({behavior:'smooth', block: "center"});
    },[selectedRef])
    return (
        <div className='dob-picker_container-options_years'>
            {years.map((year, idx) => {
                return (
                    <Fragment key={year}>
                        {(idx % 20 === 0) &&
                            <h5 className='dob-picker_container-options_years-group'>
                                {`Year ${year} - ${year - 20}`}
                            </h5>
                        }
                        <button
                            ref={year === selectedYear ? selectedRef : null}
                            className={`dob-picker_container-options_years-button ${year === selectedYear ? 'selected' : ''}`}
                            onClick={() => handleChange(year)}
                        >
                            {year}
                        </button>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default Years;

Years.propTypes = {
    handleChange: PropTypes.func.isRequired,
    years: PropTypes.array.isRequired,
    selectedYear: PropTypes.string,
}