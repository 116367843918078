import React from 'react';
import FormElementSelect from "./Select";
import moment from 'moment';

export default function FormElementTimeSelect(props) {
    let startTime = props.start ? props.start : '10:00';
    let endTime = props.end ? props.end : '00:00';
    let minuteStep = props.minuteStep ? props.minuteStep : 15;
    let start = moment(startTime, 'HH:mm');
    let end = moment(endTime, 'HH:mm');
    let times = [];
    if (end.isBefore(start)) {
        end = end.add(1, 'day');
    }
    while (start.isSameOrBefore(end)) {
        times.push({
            key: start.format('HH:mm'),
            value: start.format('HH:mm')
        });
        start.add(minuteStep, 'minute');
    }
    return <FormElementSelect values={times} {...props} />
}