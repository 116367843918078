import {Settings} from "../../../Settings/Settings";
import moment from "moment/moment";
import React from "react";

export default function BalanceAmount (props) {
    return props.booking.value > 0 && <div className="amounts">
        <div className="deposit-main">{Settings.currencyFormatter.format(props.booking.value)} total</div>
        <div
            className="deposit-sub">
            {props.booking.party_type === 91 ? <span>Balance payable on arrival</span> : (
                moment(props.booking.balance_due_date).isAfter(moment())
                    ? <span>Balance payable by {moment(props.booking.balance_due_date).format('DD MMM YYYY')}</span>
                    : <span>Balance payable today</span>
            )}
        </div>
    </div>
}