import React, {useEffect, useState} from 'react';
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";

const SocietyAdmin = (props) => {
    const [loading, setLoading] = useState(true);
    const [society, setSociety] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (society === null) {
            Api.get('customers/society-portal/society/' + props.match.params.id).then((response) => {
                setLoading(false);
                if (response.ok) {
                    setSociety(response.data.data);
                    return;
                }
                setError(response.error);
            });
        }
    }, [society]);
    if (loading) {
        return <Loading/>
    }

    if (society) {

    }

    if (error) {
        return <div>
            <Notice name="society-error" variant="error">{error}</Notice>
        </div>
    }
    return <div>
        {JSON.stringify(props.match.params.id)}
    </div>
}

export default SocietyAdmin;