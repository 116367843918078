import React, {Fragment, useEffect, useState} from 'react';
import FormElementText from "../../../../Form/Element/Text";
import FormElementPassword from "../../../../Form/Element/Password";
import Notice from "../../../../Utility/Notice/Notice";
import Button from "../../../../Form/Element/Button";
import './StandaloneSignup.scss';
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import {Api} from "../../../../Api/Api";
import {NotificationManager} from "../../../../Notifications/NotifcationManager";

export default function StandaloneSignup(props) {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const handleSave = () => {
        setLoading(true);
        Api.post('customers/register/standalone', {
            booking_token: props.token,
            email: props.email,
            password: password
        }).then((response) => {
            if (response.ok) {
                ModalHelper.closeModal();
                NotificationManager.showSuccess('You are now registered!');
                document.location.href = '/edit-booking/' + props.booking.id;
            }
        });
    }
    const updateButtons = () => {
        ModalHelper.setButtons(<Fragment>
            <Button text="Cancel" name="cancel" variant="outline" onClick={(e) => {
                ModalHelper.closeModal();
            }}/>
            <Button text="Register" name="register" onClick={(e) => handleSave(e)}
                    disabled={loading}/>
        </Fragment>);
    }
    useEffect(() => updateButtons())
    return <div className="signup-standalone">
        <Notice name="signup-text">Enter a password below to get signed in!</Notice>
        <FormElementText name="email" disabled={true} value={props.email} placeholder="E-Mail"/>
        <FormElementPassword name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                             placeholder="Password"/>
    </div>
}