import React, {useRef, useState, useEffect} from 'react';
import PropTypes from "prop-types";
import './Collapse.scss'

const Collapse = ({title, divider, children}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [height, setHeight] = useState(0);
    const [currentHeight, setCurrentHeight] = useState(0)
    const ref = useRef(null);

    const handleToggle = e => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
        setHeight(ref?.current?.clientHeight + 10);
    };

    useEffect(() => {
        setCurrentHeight(isExpanded ? height : 0)
    }, [isExpanded]);

    useEffect(() => {
        setCurrentHeight(isExpanded ? ref?.current?.clientHeight + 10 : 0)
    }, [children]);

    return (
        <li className='collapse'>
            <h3 className='collapse-title' onClick={handleToggle}>
                <span>
                    {title}
                </span>
                <i className={`fa fa-angle-${isExpanded ? 'up' : 'down'}`}/>
            </h3>
            {divider && <hr className='divider'/>}
            <div className="collapse-children-wrapper" style={{height: currentHeight + "px"}}>
                <div className="children" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
    )
};

export default Collapse;

Collapse.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    divider: PropTypes.bool
}