import React, {Component, Fragment} from 'react';
import FormElementText from "../../Form/Element/Text";
import FormElementPassword from "../../Form/Element/Password";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import './Login.scss';
import Button from "../../Form/Element/Button";
import {Api} from "../../Api/Api";
import {NotificationManager} from "../../Notifications/NotifcationManager";
import {Redirect} from "react-router-dom";
import Notice from "../../Utility/Notice/Notice";
import IntroText from "../../Utility/IntroText/IntroText";
import {Settings} from "../../Settings/Settings";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            logged_in: false,
            error: null,
            post_login_url: null
        }
    }

    validateEmail(email) {
        let pattern = new RegExp(/@.*?\./);
        return pattern.test(email);
    };

    handleLogin(e) {
        e.preventDefault();
        let email = this.state.email;
        if (!this.state.email) {
            NotificationManager.showError('E-mail address is required');
            return;
        }
        if (!this.state.password) {
            NotificationManager.showError('Password is required');
            return;
        }
        if(!this.validateEmail(email)){
            NotificationManager.showError(`Email address "${email}" is invalid`);
            return;
        }
        this.setState({loading: true});
        Api.post('customers/authenticate', {
            email: this.state.email,
            password: this.state.password,
            join_token: this.props.join_token
        }).then((response) => {
            if (response.ok) {
                let state = {logged_in: true};

                if (response.data.meta && response.data.meta.post_login_url) {
                    state.post_login_url = response.data.meta.post_login_url;
                }
                this.setState(state);

                return;
            }
            this.setState({loading: false, error: response.data.errors.message});
        });
    }

    handleGoogleResponse(response) {
        let token = response.getAuthResponse().id_token;
        let brand_id = Settings.getBrandId();
        Api.post('customers/authenticate/google', {token: token, brand_id: brand_id}).then((response) => {

        });
    }

    render() {
        if (this.state.logged_in) {
            if (this.state.post_login_url) {
                return <Redirect to={this.state.post_login_url}/>
            }
            if (this.props.join_token) {
                return <Redirect to={'/join/' + this.props.join_token}/>
            }
            return <Redirect to="/"/>
        }
        return <Fragment>
            <ContentBox name="login">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-xs-12">
                        {this.state.error ?
                            <Notice name="login-failed"
                                    variant="error">{this.state.error}</Notice> : null}
                        <IntroText name="login-intro">
                            Login to your account
                        </IntroText>
                        <form onSubmit={(e) => this.handleLogin(e)} className="login-form">
                            <FormElementText name="email" onChange={(e) => this.setState({email: e.target.value})}
                                             value={this.state.email} placeholder="E-mail Address"/>
                            <div className="login__password">
                                <FormElementPassword name="password"
                                                     onChange={(e) => this.setState({password: e.target.value})}
                                                     value={this.state.password} placeholder="Password"/>
                                <a href="/forgot-password">Forgot?</a>
                            </div>
                            <Button name="login" text="Login" variant="continue" disabled={this.state.loading}/>
                            <a className="btn btn--outline btn--register" href="/register">New Here? Register</a>
                        </form>
                    </div>
                </div>
            </ContentBox>
            {/*<p className="line-through-text"><span>or</span></p>*/}
            {/*<div className="login-options">*/}
            {/*    <GoogleLogin onSuccess={(response) => this.handleGoogleResponse(response)} onFailure={(response) => {*/}
            {/*        console.log('fail', arguments);*/}
            {/*    }} clientId="526630406270-lpj7rbucmagiaurdjdsff6ge5pn3jiv9.apps.googleusercontent.com"/>*/}
            {/*    <Button text="Sign in with facebook" name="facebook-sign-in"/>*/}
            {/*</div>*/}
        </Fragment>
    }
}

export default Login;