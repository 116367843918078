import React, {Component} from 'react';
import {Api} from "../../../Api/Api";

class PaymentCallback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params[0]
        }
    }

    componentDidMount() {
        this.checkPayment();
    }

    checkPayment() {
        Api.get('rest/deposit/' + this.state.id, {}).then((response) => {
        })
    }

    render() {
        return <div>

        </div>
    }
}

export default PaymentCallback;