import React, {Component} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import gb from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import PropTypes from 'prop-types';
import './DatePicker.scss';
import cx from 'classnames';
import {EventDispatcher} from "../../Events/Dispatcher";

registerLocale('en-GB', gb)

class FormElementDatePicker extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    handleChange(e) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: moment(e).format('YYYY-MM-DD')
            }
        })
    }

    componentDidMount() {
        EventDispatcher.dispatch('form_element_initialise', {
            detail: {
                type: 'datepicker',
                ref: this.ref.current
            }
        });
    }

    componentWillUnmount() {
        EventDispatcher.dispatch('form_element_destroy', {
            detail: {
                type: 'datepicker',
                ref: this.ref.current
            }
        });
    }

    render() {
        const CustomInput = ({value, onClick}) => {
            if (!value) {
                value = this.props.placeholder;
            }
            return <button type="button" className="datepicker-input" onClick={onClick} disabled={this.props.disabled}>
                {value}
            </button>
        };
        return <div
            className={cx('field', 'field--date', 'field--' + this.props.name, {'field--error': this.props.error})}>
            <div className="field__element-wrap">
                <DatePicker onChange={e => this.handleChange(e)}
                            disabled={this.props.disabled}
                            withPortal={!this.props.inline}
                            fixedHeight
                            customInputRef={this.ref}
                            customInput={<CustomInput forwardedRef={this.ref}/>}
                            placeholderText={this.props.placeholder}
                            openToDate={this.props.openToDate}
                            minDate={this.props.minDate ? this.props.minDate : new Date()}
                            maxDate={this.props.maxDate ? this.props.maxDate : null}
                            filterDate={(date) => this.props.filterDate ? this.props.filterDate(date) : () => {
                            }}
                            selected={this.props.value ? moment(this.props.value).toDate() : null}
                            locale="en-GB" dateFormat="dd/MM/yyyy" className="form-control"/>
            </div>
            {this.props.error ? <div className="field__error">{this.props.error}</div> : null}
        </div>
    }
}

FormElementDatePicker.propTypes = {
    placeholder: PropTypes.string,
    filterDate: PropTypes.func,
    minDate: PropTypes.object,
    openToDate: PropTypes.object,
    inline: PropTypes.bool
};
export default FormElementDatePicker;
