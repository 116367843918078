import React, {Fragment, useEffect, useState} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreOrder from "../Elements/PreOrder/PreOrder";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import UserData from "../Elements/UserData";
import moment from 'moment';
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";
import FormElementSelect from "../../Form/Element/Select";
import TypeSelect from "../Elements/TypeSelect";

export default function OnlineMasterclass(props) {
    let booking = props.booking;
    const [isFallbackFormOpen, setFallbackFormOpen] = useState(false)
    useEffect(() => {
        setFallbackFormOpen(false)
    }, [booking]);

    let fallback = <Fragment>
        <BookingStep step={99} name="fallback" type="online_masterclass" booking={props.booking}
                     hot_jar_url="enquiry-fallback-to-callback">
            <UserData booking={props.booking} hide_callback={true} step={99}
                      include_pre_booking={<PreBookingInfo
                          booking={props.booking}
                          updating={props.updating}
                          include_booking_time={true}
                          step={99}
                      />}
                      additional_info={<p>Fill in your details
                          below and we'll get back to you asap!</p>}/>
            <TermsText booking={props.booking}/>
        </BookingStep>
        <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                    skip_text="Send Enquiry" can_skip={props.booking.can_complete} continue={true} show_deposit={false}
                    continueFunction={() => BookingData.completeBooking()}/>
    </Fragment>

    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }
    let open_to_date = moment().toDate();
    if (props.booking.date) {
        open_to_date = moment(props.booking.date).toDate();
    } else if (props.booking.min_booking_date && moment(props.booking.min_booking_date)) {
        open_to_date = moment(props.booking.min_booking_date).toDate();
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} name="pre-booking-info" type="online_masterclass" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({ 'step': 2 })} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        let booking_date = moment(props.booking.date);
        booking_date = booking_date.add(booking_date.day(6), 'day');
        let message = 'Whoops! We don\'t serve do at table masterclass on ' + moment(props.booking.date).format('dddd') + ', do you want to check a different day?';
        let show_alternative = true;
        if (booking.can_cater_for === true) {
            message = 'Oh dear! Looks like we are fully booked for Online Masterclass on this day. Do you want to try another day?';
        }
        if (booking.date && ((booking.alternative_dates.length === 0 && booking.time_slots.length === 0) || booking.can_cater_for === false)) {
            return <Fragment>
                <BookingStep step={2} name="time-select" type="online_masterclass" booking={props.booking}
                             hot_jar_url="enquiry-availability-none">
                    <Notice name="no-brunch">
                        We can't auto confirm your online masterclass at the moment, would you like to submit an
                        enquiry instead?
                    </Notice>
                    {isFallbackFormOpen ? fallback : <Button text="Yes" name="do-callback" onClick={() => setFallbackFormOpen(true)}/>}
                </BookingStep>
            </Fragment>
        }
        return <Fragment>
            <BookingStep step={2} name="time-select" type="online_masterclass" booking={props.booking}
                         hot_jar_url="enquiry-availability-check">
                <PreBookingInfo booking={props.booking} updating={props.updating}
                                minDate={moment(props.booking.min_booking_date).toDate()}
                                openToDate={open_to_date}
                />
                {props.booking.date && <TimeSelect booking={props.booking} updating={props.updating} show_price={true} stacked={true}
                            show_alternative={true} allow_fallback={false}
                            unavailable_message={message}/>}
            </BookingStep>
        </Fragment>
    }

    if (booking.step === 3) {
        let skip_text = 'Skip';
        if (props.booking.value > 0) {
            skip_text = 'Continue';
        }
        return <Fragment>
            <BookingStep step={3} name="pre-order" type="online_masterclass" hot_jar_url="enquiry-pre-orders" booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="Sweet, we've added your masterclass, would you like to change anything?"
                          can_skip={true}
                          skip_text={skip_text}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={'Continue'} can_skip={props.booking.can_last_step}/>
        </Fragment>
    }
    if (booking.step === 4) {
        let skip_text = 'Save Booking';
        if (props.booking.deposit === 0) {
            skip_text = 'Complete Booking';
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="online_masterclass" hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <h2>Almost Done</h2>
                <ShortSummary booking={props.booking} show_end_time={true}/>
                <UserData booking={props.booking}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return fallback
    }
    return <BookingStep step={999} name="no-step" type="online_masterclass" hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}