import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DateOfBirthPicker from "./DateOfBirthPicker";
import FormElementText from "../../Form/Element/Text";

function NumericDateOfBirthPicker({name, yearLimit, value, handleChange}) {
    const [year, setYear] = useState(undefined)
    const [month, setMonth] = useState(undefined)
    const [day, setDay] = useState(undefined)
    const [error, setError] = useState('');

    useEffect(() => {
        if(year && month && day) {
            if(year.length === 4) {
                setError('')
                let date = `${year + '/'}${month + '/'}${day}`
                if (yearLimit && moment(date).isAfter(moment().subtract(yearLimit, 'years'))) {
                    setError(`You must be at least ${yearLimit} years old to sign up`)
                } else {
                    if (moment(date, 'YYYY/M/D').isValid()) {
                        handleChange(date);
                    }
                }
            }
        }

        if(year && month) {
            let daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
            if(day && day > daysInMonth) {
                let monthName = moment().month((month - 1)).format("MMMM")
                if (parseInt(month) === 2) {
                    setError(`There are only ${daysInMonth} days in ${monthName} in ${year}`)
                } else {
                    setError(`There are only ${daysInMonth} days in ${monthName}`)
                }
            }
        }
    }, [year, month, day]);

    useEffect(() => {
        if (value) {
            const initialDateIso = moment(value, 'YYYY-MM-DD');
            if (initialDateIso) {
                setYear(parseInt(initialDateIso.format('YYYY')));
                setMonth(parseInt(initialDateIso.format('MM')))
                setDay(parseInt(initialDateIso.format('D')))
                return;
            }

            const initialDateUk = moment(value, 'DD/MM/YYYY');
            if (initialDateUk) {
                setYear(parseInt(initialDateUk.format('YYYY')));
                setMonth(parseInt(initialDateUk.format('MM')))
                setDay(parseInt(initialDateUk.format('D')))
            }
        }
    }, [value]);

    const handleYearChange = (e) => {
        if (e.target.value.length !== 4) {
            setError('Year should be 4 digits long')
            handleChange(null)
        }
        setYear(e.target.value);
    };
    const handleMonthChange = (e) => {
        const value = e.target.value;
        setError('')
        if (value > 12 || value < 1) {
            handleChange(null)
            setError('The month is invalid')
        }
        setMonth(e.target.value);
    };
    const handleDayChange = (e) => {
        const value = e.target.value;
        setError('')
        if (value > 31 || value < 1) {
            handleChange(null)
            setError('The day is invalid')
        }
        setDay(e.target.value);
    };

    return (
        <div className='dob-picker field field--text'>
            <div className='dob-picker_pills'>
                <FormElementText
                    className={`field__element dob-picker_pills-input`}
                    name={`${name}_day`}
                    placeholder='Day'
                    value={day}
                    autoComplete='off'
                    onChange={handleDayChange}
                    type={'number'}
                    inputMode={'numeric'}
                />
                <FormElementText
                    className={`field__element dob-picker_pills-input`}
                    name={`${name}_month`}
                    placeholder='Month'
                    value={month}
                    autoComplete='off'
                    onChange={handleMonthChange}
                    type={'number'}
                    inputMode={'numeric'}
                />
                <FormElementText
                    className={`field__element dob-picker_pills-input`}
                    name={`${name}_year`}
                    placeholder='Year'
                    value={year}
                    autoComplete='off'
                    onChange={handleYearChange}
                    type={'number'}
                    inputMode={'numeric'}
                />
            </div>
            {error !== '' && <div className="field field--text">
                <span className='dob-picker_error'>{error}</span>
            </div>}
        </div>
    )
}

export default NumericDateOfBirthPicker;

DateOfBirthPicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    yearLimit: PropTypes.number,
    inputTypePills: PropTypes.bool
}