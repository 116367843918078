import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import FormElementCheckbox from "../../../Form/Element/Checkbox";
import {BookingData} from "../../Data/BookingData";
import {Settings} from "../../../Settings/Settings";
import './OptIns.scss';

export default function OptIns(props) {
    return <Fragment>
        <div className="opt-ins clearfix">
            <p>Please specify which ways you would like to be contacted by {Settings.getBrandName()} for marketing and
                sales updates:</p>
            <FormElementCheckbox onChange={(e) => BookingData.update({opt_in: e.target.value, step: props.step || props.booking.step})}
                                 label="E-Mail" true_value="1" false_value="0" label_after={true}
                                 name="opt_in" value={props.booking.opt_in}/>
            <FormElementCheckbox onChange={(e) => BookingData.update({sms_opt_in: e.target.value, step: props.step || props.booking.step})}
                                 label="SMS" true_value="1" false_value="0" label_after={true}
                                 name="sms_opt_in" value={props.booking.sms_opt_in}/>
            <FormElementCheckbox onChange={(e) => BookingData.update({phone_opt_in: e.target.value, step: props.step || props.booking.step})}
                                 label="Phone" true_value="1" false_value="0" label_after={true}
                                 name="phone_opt_in" value={props.booking.phone_opt_in}/>
        </div>
    </Fragment>
}

OptIns.propTypes = {
    booking: PropTypes.object.isRequired
}