import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";

function Days({handleChange, days, selectedDay}) {
    const selectedRef = useRef(null);
    useEffect(() => {
        if(!selectedRef.current) return;
        selectedRef.current.scrollIntoView({behavior:'smooth', block: "center"});
    },[selectedRef])
    return (
        <div className='dob-picker_container-options_days'>
            {days.length > 0 ? days.map((day, idx) =>
                <button
                    ref={selectedDay === idx + 1 ? selectedRef : null}
                    key={idx}
                    className={`dob-picker_container-options_days-button ${selectedDay === idx + 1 ? 'selected' : ''}`}
                    onClick={() => handleChange(day)}
                >
                    {day}
                </button>
            ) :
            <h5 className='dob-picker_container-options_days-notice'>Please select year and month first</h5>}
        </div>
    )
}

export default Days;

Days.propTypes = {
    handleChange: PropTypes.func.isRequired,
    days: PropTypes.array.isRequired,
    selectedDay: PropTypes.number,
}