import React from 'react';
import './Header.scss';
import PropTypes from 'prop-types';

export default function Header(props) {

    return <div className="header-container">
        <div className="header-nav">{props.show_back ?
            <button className="back-button"
                    onClick={() => props.onBack()}>
                <i className="fa fa-arrow-left"/>
            </button>
            : <span>&nbsp;</span>}</div>
        <div className="header-title">{props.title}</div>
        <div className="header-actions">
            {props.show_help ? <button className="help-button" onClick={() => props.onHelp()}>
                <i className="fa fa-question-circle"/>
            </button> : null}
            {/*{props.show_close ? <button className="close-button" onClick={() => props.onClose()}>*/}
            {/*    <i className="fa fa-close"/>*/}
            {/*</button> : null}*/}
        </div>
    </div>
}

Header.defaultProps = {
    show_close: false,
    show_help: false,
    show_back: false
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
    show_close: PropTypes.bool,
    onClose: PropTypes.func,
    show_help: PropTypes.bool,
    onHelp: PropTypes.func,
    show_back: PropTypes.bool,
    onBack: PropTypes.func
}