import React from 'react';
import './AllergensDeclaration.scss'

function AllergensDeclaration() {

    return (
        <div>
            <h2><strong>Declaration</strong></h2>
            <p>
                For your safety, if you or anybody in your group has any allergies or intolerances, you must:
            </p>

            <ul>
                <li>
                    Notify us of your allergy/intolerance each and every time you order.
                </li>
                <li>
                    On arrival, check our allergens menu as these are subject to change. This includes, but is not
                    limited to, pre-booked parties or pre-orders of food.
                </li>
            </ul>
            <p>
                Allergens listed within ‘contains’ are known to be present within the product. Allergens listed within
                ‘may contain’ may be present within the product due to the presence of these allergens in our suppliers’
                production environment.
            </p>
            <p>
                Products that are deep-fried carry an increased risk of cross-contamination.
            </p>
            <p>
                Revolution Bars Group will take reasonable steps to mitigate cross-contamination within our kitchens,
                bars, and pubs; however, we cannot guarantee that all dishes and drinks will be free from allergens due
                to them being in the same environment. Our team members cannot advise on or guarantee the suitability of
                a dish or associated risk based on your allergies or intolerances.
            </p>
        </div>
    )
}

export default AllergensDeclaration;