import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import FormElementText from '../../../../../Form/Element/Text'
import Button from '../../../../../Form/Element/Button'
import { Settings } from '../../../../../Settings/Settings'
import './AddEditGuest.scss'
import { ModalHelper } from '../../../../../Utility/Modal/AppModal'
import _ from 'lodash'
import { Api } from '../../../../../Api/Api'
import { EventDispatcher } from '../../../../../Events/Dispatcher'
import Notice from '../../../../../Utility/Notice/Notice'

export default class AddEditGuest extends Component {
    constructor (props) {
        super(props)
        this.state = {
            guest: props.guest,
            saving: false,
            remove_text: 'Remove Guest',
            remove: false,
            can_complete: false,
        }
    }

    componentDidMount () {
        this.checkFields()
    }

    setButtons () {
        ModalHelper.setButtons(<Fragment>
            <Button variant="outline" text="Cancel" onClick={() => ModalHelper.closeModal()} name="cancel"/>
            <Button text="Save" onClick={(e) => this.handleSave(e)} name="save-guest"
                    disabled={this.state.saving || this.state.can_complete === false}/>
        </Fragment>)
    }

    handleSave (e) {
        e.preventDefault()
        this.setState({ saving: true }, this.setButtons)
        let guest = _.extend({}, this.state.guest)
        guest.variation_id = this.props.variation_id
        Api.postJson('rest/my-bookings/token/' + this.props.token + '/pre-order', guest).then((response) => {
            this.setState({ loading: false }, this.setButtons)
            if (response.ok) {
                ModalHelper.closeModal()
                EventDispatcher.dispatch('booking-data', response.data.data)
            }
            if (response.status === 423) {
                document.location.reload()
            }
        })
    }

    handleFieldChange (e) {
        let guest = _.extend({}, this.state.guest)
        guest[e.target.name] = e.target.value
        this.setState({ guest: guest }, this.checkFields)
    }

    checkFields () {
        let can_complete = true
        if (!this.state.guest.forename) {
            can_complete = false
        }
        this.setState({ can_complete: can_complete }, () => this.setButtons())
    }

    chooseOption (choice_id, choice_item_id, plu, group_name, item) {

        let found = false
        let guest = _.extend({}, this.state.guest)
        guest.choices = guest.choices.map(function (c) {
            if (c.choice_id === choice_id && c.choice_item_id === choice_item_id) {
                c.plu = plu
                c.plu_name = item.title
                c.premium = item.premium
                found = true
            }
            return c
        })
        if (!found) {
            guest.choices.push({
                guest_id: this.props.guest.id,
                variation_id: this.props.variation_id,
                choice_id: choice_id,
                choice_item_id: choice_item_id,
                name: group_name,
                plu_name: item.title,
                plu: plu,
                item_number: 0,
                premium: item.premium,
            })
        }
        this.setState({ guest: guest })
    };

    getChoice (choice_id, choice_item_id) {
        let guest = this.state.guest
        let option = guest.choices.find((c) => {
            if (c.choice_id === choice_id && c.choice_item_id === choice_item_id) {
                return true
            }
            return null
        })
        if (option) {
            return option.plu
        }
        return null
    }

    render () {
        var guest = this.state.guest
        return <div className="pre-order--add-edit-guest">
            <h3> Select your pre order options below</h3>
            <Notice name="dietary" variant="info">Please contact us directly to discuss allergy information, dietary requirements or special requests.
                Do not add this information to the name boxes below as our kitchen teams will not see these!
                <br /><br />
                <a href={Settings.getAllergensUrl()} target="_blank">Click Here</a> to view our current allergens list
            </Notice>
            <div className="pre-order--add-edit-guest--name">
                <FormElementText name="forename" value={guest.forename} onChange={(e) => this.handleFieldChange(e)}
                                 placeholder="Forename (Required)"/>
                <FormElementText name="surname" value={guest.surname} onChange={(e) => this.handleFieldChange(e)}
                                 placeholder="Surname"/>
            </div>
            <div className="booking-fieldsets">
                {this.props.choices.map((c) => {
                    let has_selectable = false
                    c.choices.map(function (choice) {
                        choice.items.map(function (i) {
                            if (i.selectable) {
                                has_selectable = true
                            }
                        })
                    })
                    if (has_selectable === false) {
                        return null
                    }
                    return <fieldset key={c.group_id} className={c.choices.length < 2 ? 'single-fieldset' : ''}>
                        <legend>{c.title}</legend>
                        {c.choices.map((choice) => {
                            let selected = this.getChoice(c.group_id, choice.id)

                            let has_selectable = false
                            choice.items.map(function (i) {
                                if (i.selectable) {
                                    has_selectable = true
                                }
                            })
                            if (!has_selectable) {
                                return null
                            }
                            return <div className="choice--choice" key={choice.id}>
                                {choice.title !== c.title ? <p>{choice.title}</p> : null}
                                {choice.items.map((i) => {
                                    let title = i.title
                                    if (i.premium) {
                                        title = title + ' (+' + Settings.currencyFormatter.format(i.premium) + ')'
                                    }
                                    return !Settings.isBespokePlu(parseInt(i.plu)) &&
                                        <p key={i.plu} className={c.choices.length < 2 ? 'single-fieldset-option' : ''}>
                                            <label><input type="radio" name={choice.id} value={i.plu}
                                                          checked={selected == i.plu} onChange={(e) => {
                                                this.chooseOption(c.group_id, choice.id, i.plu, c.title, i)
                                            }}/> {title}</label>
                                        </p>
                                })}
                            </div>
                        })}
                    </fieldset>
                })}
            </div>
        </div>
    }
}
AddEditGuest.propTypes = {
    guest: PropTypes.object.isRequired,
}