import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../../Form/Element/Button'
import { BookingData } from '../../Data/BookingData'
import Notice from '../../../Utility/Notice/Notice'

const FallbackToCallbackButton = (props) => {
    let booking = props.booking
    if (booking.can_fallback === true && booking.callback_slots.data.length > 0) {
        return <Fragment>
            <Button variant="full-width" text="Let us ring you back to discuss further options" name="submit-enquiry" onClick={() => {
                props.handleClick ? props.handleClick() : BookingData.update({ step: 99 })
            }}/></Fragment>
    }

    if (booking.can_fallback === true && booking.callback_slots.data.length === 0 && booking.bar.data) {
        return <Fragment>
            <Notice name="too-close-for-callback" variant="error">It's quite close to that booking time.
                We may be able to sort something still if you ring us on
                {' '}
                <strong>{booking.bar.data.party_booking_phone}</strong> or email us at
                {' '}
                <strong>{booking.bar.data.party_booking_email}</strong></Notice>
        </Fragment>
    }

    return <span/>
}

FallbackToCallbackButton.propTypes = {
    booking: PropTypes.object.isRequired,
}

export default FallbackToCallbackButton