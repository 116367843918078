import React from 'react'
import {useMutation, useQuery, useQueryClient} from "react-query";
import './StudentSignup.scss';
import Notice from "../../Utility/Notice/Notice";
import Loading from "../../Utility/Loading/Loading";
import Button from "../../Form/Element/Button";
import {Api} from "../../Api/Api";

function GraduateStatus({user_id, email}) {
    const queryClient = useQueryClient();
    const {data, error, isFetched} = useQuery({
        queryKey: [{url: '/api/customers/student/student-status', params: {id: user_id, email: email}}]
    })

    const mutation = useMutation({
        mutationFn: () => {
            return Api.post('customers/student/graduate-status', {app_user_id: user_id, email: email})
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [{
                    url: '/api/customers/student/student-status',
                    params: {id: user_id, email: email}
                }]
            })
        }
    })
    if (isFetched === false) {
        return <Loading/>
    }
    if (error) {
        return <div className="graduate-signup">
            {error && <Notice name="error" variant="error">{error.toString()}</Notice>}
        </div>
    }
    const studentData = data.data;
    if (studentData.is_graduate === true) {
        return <div className="graduate-signup">
            <Notice name="intro-copy">Hey Graduate! You should have your Grad offers in the Revs app quicker than the time it takes to make a cheeky Vodka Rev Bull..</Notice>
        </div>
    }
    return <div className="graduate-signup">
        {mutation.error && <Notice name="error" variant="error">{mutation.error}</Notice>}
        <Notice name="intro-copy">Congrats, you've officially entered adulthood and now every day feels like a 9am
            lecture... Just kidding, it’s not all bad, especially not at Revs. As a Revs Graduate you can still feel
            like a student, with access to all your student discounts for a whole extra year! We’ll see you at the bar
            with your new co-workers!</Notice>
        <Button text="I'm a graduate" name="save" variant="full-width" onClick={() => mutation.mutate()} disabled={mutation.isLoading}/>
        <hr />
        <Notice name="intro-copy">
            Not graduated yet but still want to enjoy our student offers? Tap below to fill in your details to get started.
        </Notice>
        <Button text="I'm still a student" name="save" variant="full-width" onClick={() => {
            document.location.href = '/student/' + user_id + '/' + email
        }}/>
    </div>
}

export default GraduateStatus;