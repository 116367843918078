import React from 'react';
import PropTypes from 'prop-types';
import {Settings} from "../../../../Settings/Settings";
import './SinglePackage.scss';

export default function SinglePackage(props) {
    let option = props.option;
    let variation = props.option.variation.data;
    return <div className="booking-option">
        {variation.image.data ?
            <img src={variation.image.data.url} className="booking-option__image" alt={option.title}/> : null}
        <div className="booking-option__details">
            <div className="booking-option__details__title"><a href="#package">{option.title}
                <img src="/assets/icons/Info.svg" alt={option.title}/>
            </a></div>
            <div className="booking-option__details__qty">
                <span
                    className="booking-option__details__qty">Qty: {option.head_count ? option.head_count : props.booking.people} | </span>
                <span
                    className="booking-option__details__price">{Settings.currencyFormatter.format(option.price)}</span>
            </div>
        </div>
        <div className="booking-option__edit"><i className="fa fa-cog"/></div>
    </div>
}

SinglePackage.propTypes = {
    option: PropTypes.object.isRequired
}