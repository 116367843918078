import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Header from "../../Header/Header";
import Loading from "../../../Utility/Loading/Loading";
import {Api} from "../../../Api/Api";
import PayAmount from "./PayAmount";
import PaymentSummary from "./PaymentSummary";
import {Settings} from "../../../Settings/Settings";
import {EventDispatcher} from "../../../Events/Dispatcher";
import './PayBooking.scss';
import Button from "../../../Form/Element/Button";

class PayBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: this.props.code ? this.props.code : this.props.match.params.code,
            loading: true,
            error: false,
            payment_data: null,
            type: this.props.type ? this.props.type : ''
        }
    }

    componentDidMount() {
        this.updateBooking();
        if (window.hj) {
            window.hj('enquiry-payment', '/enquiry-payment');
        }
    }

    updateBooking() {
        Api.get('rest/deposit/manage', {token: this.state.code}).then((response) => {
            if (response.ok) {
                this.setState({booking: response.data.data, loading: false});
            } else {
                this.setState({error: true, loading: false});
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <Fragment>
                <div id="main_booking_container" className="clearfix">
                    <Header title="Pay Deposit"/>
                    <p className="error">Unfortunately an error has occurred. Please try again later.</p>
                </div>
            </Fragment>
        }
        let amount = 0;
        if (this.state.type === 'deposit') {
            amount = this.state.booking.deposit_balance;
        }

        if (this.state.type === 'balance') {
            amount = this.state.booking.balance;
        }
        return <Fragment>
            <div className="pay-booking">

                {this.state.payment_data ?
                    <PaymentSummary payment_data={this.state.payment_data} booking={this.state.booking}/> : null}


                {amount > 0 ? <PayAmount booking={this.state.booking} amount={amount}
                                         token={this.state.code} onComplete={(data) => {
                    if (this.props.onComplete) {
                        this.props.onComplete(data);
                    }
                    this.setState({loading: true});
                    this.updateBooking();
                    EventDispatcher.dispatch('update-booking');
                    this.setState({
                        payment_data: data,
                        type: ''
                    });
                }}/> : null}

                {!this.state.type ? <div className="payment-amount-select">
                    {this.state.booking.deposit_balance > 0 ? <Button
                        onClick={() => this.setState({type: 'deposit'})}
                        text={'Pay Deposit - ' + Settings.currencyFormatter.format(this.state.booking.deposit_balance)}
                        name="pay-deposit"/> : null}
                    {this.state.booking.balance > 0 ? <Button
                        onClick={() => this.setState({type: 'balance'})}
                        text={'Pay Balance - ' + Settings.currencyFormatter.format(this.state.booking.balance)}
                        name="pay-deposit"/> : null}
                </div> : null}
            </div>
        </Fragment>
    }
}

PayBooking.propTypes = {
    onComplete: PropTypes.func,
    type: PropTypes.string
}
export default PayBooking;
