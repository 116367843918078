import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormElementSelect from './Select'
import Loading from '../../Utility/Loading/Loading'
import { Api } from '../../Api/Api'
import { EventDispatcher } from '../../Events/Dispatcher'
import { ModalHelper } from '../../Utility/Modal/AppModal'
import BarFinder from '../../Utility/BarFinder/BarFinder'
import cx from 'classnames'
import './FormBarSelect.scss'

class FormBarSelect extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            bars: [],
        }
        this.ref = React.createRef()
    }

    componentDidMount () {
        this.updateBars(() => {
            EventDispatcher.dispatch('form_element_initialise', {
                detail: {
                    type: 'bar_select',
                    ref: this.ref.current,
                },
            })
        })
    }

    componentWillUnmount () {
        EventDispatcher.dispatch('form_element_destroy', {
            detail: {
                type: 'bar_select',
                ref: this.ref.current,
            },
        })
    }

    updateBars (callback) {
        Api.getAbsolute('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            if (response.ok) {
                this.setState({ bars: response.data.filter(bar => bar.frontend_visible === true), loading: false }, () => {
                    if (callback) {
                        callback()
                    }
                })
            }
        })
    }

    handleChange (e) {
        if (this.props.multiple) {
            this.handleMultiChange(e)
            return
        }
        let returnValue = e
        let returnType = this.props.returnType ? this.props.returnType : 'bar_id'
        if (returnType === 'cost_centre') {
            let bar = this.state.bars.find(function (b) {
                return b.id === parseInt(e.target.value)
            })
            if (bar) {
                returnValue.target.value = bar.cost_centre
            }
        }
        if (returnType === 'object') {
            let bar = this.state.bars.find(function (b) {
                return b.id === parseInt(e.target.value)
            })
            if (bar) {
                returnValue.target.value = bar
            }
        }

        this.props.onChange(returnValue)
    }

    handleMultiChange (e) {
        let returnValue = e
        let returnType = this.props.returnType ? this.props.returnType : 'bar_id'
        if (returnType === 'cost_centre') {
            returnType.target.value.map(function (i) {

            })
            let bar = this.state.bars.find(function (b) {
                return b.id === parseInt(e.target.value)
            })
            if (bar) {
                returnValue.target.value = bar.cost_centre
            }
        }
        if (returnType === 'object') {
            let bar = this.state.bars.find(function (b) {
                return b.id === parseInt(e.target.value)
            })
            if (bar) {
                returnValue.target.value = bar
            }
        }

        this.props.onChange(returnValue)
    }

    findClosestBar () {
        ModalHelper.openModal(<BarFinder bars={this.state.bars} brand={this.props.brand} onChange={(e) => {
            this.handleChange(e)
            ModalHelper.closeModal()
        }}/>)
    }

    render () {
        if (this.state.loading) {
            return <Loading/>
        }
        let bars = this.state.bars.map(function (b) {
            return {
                key: b.id,
                value: b.name,
                bar: b,
            }
        })
        bars = bars.filter(function (b) {
            return b.bar.open === true
        })
        if (this.props.include_central !== true) {
            bars = bars.filter(function (b) {
                return b.bar.central_bar === false
            })
        }
        if (this.props.brand) {
            bars = bars.filter((b) => {
                return b.bar.brand_short_name === this.props.brand
            })
        }
        return <div ref={this.ref} className={cx('bar-select', { 'has-locator': this.props.allow_location })}>
            <FormElementSelect name={this.props.name} onChange={(e) => this.handleChange(e)}
                               useSelect2={true} multiple={this.props.multiple}
                               selectedValue={this.props.value}
                               values={bars} includeBlankOption={true}
                               blankOptionText={this.props.blankOptionText}/>
            {this.props.allow_location &&
              <button className="btn btn-sm" onClick={(e) => {
                  e.preventDefault()
                  this.findClosestBar()
              }}><i className="fa fa-map-pin"/></button>}
        </div>
    }
}

FormBarSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    multiple: PropTypes.bool,
    brand: PropTypes.string,
    blankOptionText: PropTypes.string,
    allow_location: PropTypes.bool,
    returnType: PropTypes.oneOf(['bar_id', 'cost_centre', 'object']),
}

FormBarSelect.defaultProps = {
    multiple: false,
    allow_location: false,
    blankOptionText: '- Select - ',
}

export default FormBarSelect