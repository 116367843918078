import 'intl';
import {EventDispatcher} from "../Events/Dispatcher";
import $ from 'jquery';
import {Settings} from "../Settings/Settings";
import {NotificationManager} from "../Notifications/NotifcationManager";
import {captureMessage} from "@sentry/browser";

export const Api = {
    getHeaders() {
        return {
            ApiKey: process.env.REACT_APP_API_KEY,
            'X-Brand': Settings.getBrandId()
        };
    },
    getUrl() {
        return Settings.getApiUrl();
    },
    async get(url, params) {
        var endpoint = (url.substring(0, 4) === 'http' ? '' : this.getUrl()) + url;
        if (params) {
            endpoint = endpoint + '?' + this.serialize(params);
        }
        var request = new Request(endpoint, {
            headers: new Headers(this.getHeaders())
        });
        return await fetch(request).then(this.handleResponse).catch(this.handleError);
    },
    async getJson(url, params) {
        var endpoint = this.getUrl() + url;
        if (params) {
            endpoint = endpoint + '?' + this.serialize(params);
        }
        let headers = this.getHeaders();
        headers['Content-Type'] = 'application/json';
        var request = new Request(endpoint, {
            headers: headers
        });
        return await fetch(request).then(this.handleResponse).catch(this.handleError);
    },
    async delete(url) {
        var endpoint = this.getUrl() + url;
        let headers = this.getHeaders();
        return await fetch(endpoint, {
            headers: headers,
            method: 'DELETE'
        }).then(this.handleResponse);
    },
    handleError(error) {
        if (process.env.REACT_APP_ENV === 'local') {
            console.error(error.message);
        } else {
            console.error('Application error occurred');
        }
    },
    async handleResponse(r) {
        let json = await r.json();
        let error = null;
        if (json.errors && json.errors.message) {
            error = json.errors.message;
        }
        if (!error && json.error) {
            error = json.error;
        }
        if (json.errors && json.errors.fields && json.errors.fields.length) {
            let errorParts = [];
            json.errors.fields.map((field) => {
                errorParts.push(field.error);
                return field;
            });
            error = errorParts.join(', ');
        }
        let ok = r.ok;
        if (r.status === 401) {
            EventDispatcher.dispatch('logout');
        } else {
            if (r.status >= 400 && r.status < 600 && error) {
                NotificationManager.showError(error.error);
                ok = false;
            }
            if (r.status === 504) {
                captureMessage('Timeout received - ' + r.url);
                NotificationManager.showError('We are having issues at the moment, please beear with us');
                ok = false;
            }
        }
        return {
            ok: ok,
            data: json,
            error: error,
            status: r.status,
            statusText: r.statusText
        }
    },

    async getAbsolute(endpoint, params) {
        if (params) {
            endpoint = endpoint + '?' + this.serialize(params);
        }
        var request = new Request(endpoint, {
            headers: new Headers(this.getHeaders())
        });
        return await fetch(request).then(this.handleResponse)
    },
    async post(url, params) {
        var endpoint = this.getUrl() + url;
        let headers = this.getHeaders();
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return await fetch(endpoint, {
            headers: headers,
            method: 'POST',
            body: this.serialize(params)
        }).then(this.handleResponse).catch(this.handleError);
    },
    async postJson(url, params) {
        var endpoint = this.getUrl() + url;
        let headers = this.getHeaders();
        headers['Content-Type'] = 'application/json';
        return await fetch(endpoint, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(params)
        }).then(this.handleResponse).catch(this.handleError);
    },
    async put(url, params) {
        var endpoint = this.getUrl() + url;
        let headers = this.getHeaders();
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return await fetch(endpoint, {
            headers: headers,
            method: 'PUT',
            body: this.serialize(params)
        }).then(this.handleResponse);
    },

    serialize(obj) {
        return $.param(obj);
    },

    async reactQueryFn({queryKey}) {
        const queryOpts = queryKey[0];
        if (typeof queryOpts !== 'object') {
            return Promise.reject(new Error('First element in query key must be an object'));
        }
        if (!queryOpts.url) {
            return Promise.reject(new Error('No URL set in query key'));
        }
        const query = {
            method: queryOpts.method ? queryOpts.method.toUpperCase() : 'GET',
            url: queryOpts.url,
            params: queryOpts.params ? queryOpts.params : {}
        };
        let e = {};
        const params = query.params;
        const headers = {};
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        switch (query.method) {
            case 'GET':
                let url = query.url;
                if (params) {
                    url = url + '?' + $.param(params);
                }
                e = await fetch(url, {
                    headers: headers,
                    method: 'GET'
                })
                break;
            case 'POST':
                e = await fetch(query.url, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(params)
                })
                break;
            case 'PATCH':
                e = await fetch(query.url, {
                    headers: headers,
                    method: 'PATCH',
                    body: JSON.stringify(params)
                })
                break;
            case 'DELETE':
                e = await fetch(query.url, {
                    headers: headers,
                    method: 'DELETE',
                    body: JSON.stringify(params)
                })
                break;
            default:
                return Promise.reject(new Error('Invalid query type'));
        }
        const body = await e.json();
        if (e.status === 401) {
            EventDispatcher.dispatch('logout');
        }
        if (e.status !== 200 && e.status !== 201) {
            if (body.data?.error?.message) {
                return Promise.reject(new Error(body.data.error.message));
            }
            if (body.data?.errors?.message) {
                return Promise.reject(new Error(body.data.errors.message));
            }
            if (body.errors?.message) {
                return Promise.reject(new Error(body.errors.message));
            }
            if (body.data?.error) {
                return Promise.reject(new Error(body.data.error));
            }
            if (body.error) {
                return Promise.reject(new Error(body.error));
            }

            console.log(body.errors.message);
            return Promise.reject(new Error(body));
        }
        return body;
    }
};