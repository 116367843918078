import React, {Component, Fragment} from 'react';
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import BookingListing from "../Element/BookingListing/BookingListing";
import moment from "moment";
import './Dashboard.scss';
import IntroText from "../../Utility/IntroText/IntroText";
import Button from "../../Form/Element/Button";
import Center from "../../Utility/Center/Center";
import Notice from "../../Utility/Notice/Notice";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_bookings: [],
            previous_bookings: [],
            loading: true,
            error: false,
            show_previous: false
        }
    }

    componentDidMount() {
        this.updateBookings();
    }

    updateBookings() {
        this.setState({loading: true});
        Api.get('rest/my-bookings', {include_previous: this.state.show_previous ? 1 : 0}).then((response) => {
            if (response.ok) {
                let current_bookings = [];
                let previous_bookings = [];
                response.data.data.map((booking) => {
                    let start = moment(booking.booking_date);
                    let cutoff = moment('06:00:00', 'HH:mm:ss');
                    if (start.isAfter(cutoff)) {
                        current_bookings.push(booking);
                    } else {
                        previous_bookings.push(booking);
                    }

                });
                this.setState({
                    current_bookings: current_bookings,
                    previous_bookings: previous_bookings,
                    loading: false
                });
                return;
            }
            this.setState({error: response.error, loading: false});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <div>
                <Notice name="error" variant="error">{this.state.error}</Notice>
            </div>
        }
        return <div>
            <div className="row">
                {this.state.current_bookings.length === 0 ? <div className="no-bookings">
                    <Center>
                        <svg className="sad-face" version="1.0" xmlns="http://www.w3.org/2000/svg" width="181.000000pt" height="188.000000pt" viewBox="0 0 181.000000 188.000000" preserveAspectRatio="xMidYMid meet">
                            <title>No Bookings :&#40;</title>
                            <g transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                <path d="M735 1839 c-161 -24 -311 -105 -436 -235 -157 -165 -250 -362 -285
                                    -610 -17 -115 -17 -125 0 -229 33 -201 106 -353 232 -479 241 -240 582 -316
                                    916 -202 360 123 586 405 623 778 15 155 -42 398 -128 545 -130 223 -292 342
                                    -527 388 -47 9 -112 22 -145 30 -85 19 -180 25 -250 14z m291 -114 c133 -17
                                    199 -36 284 -81 257 -134 418 -474 388 -819 -23 -270 -238 -536 -521 -645
                                    -114 -44 -195 -60 -317 -60 -276 0 -522 133 -670 362 -160 247 -123 670 85
                                    963 52 74 167 184 230 221 107 62 150 74 286 74 69 0 174 -7 235 -15z" />
                                <path d="M610 1130 c-23 -23 -24 -33 -10 -61 21 -39 90 -16 90 30 0 14 -37 51
                                    -50 51 -5 0 -19 -9 -30 -20z"/>
                                <path d="M1106 1124 c-32 -31 -14 -84 29 -84 28 0 45 19 45 50 0 47 -42 66
                                    -74 34z"/>
                                <path d="M805 810 c-97 -20 -229 -91 -280 -152 -27 -32 -23 -58 13 -77 21 -11
                                    36 -4 103 48 24 18 69 45 99 60 47 22 68 26 150 26 115 0 165 -17 257 -86 35
                                    -27 70 -49 77 -49 24 0 49 38 42 63 -6 27 -87 93 -153 126 -91 46 -207 61
                                    -308 41z"/>
                            </g>
                        </svg>
                        <p>Looks like you don't have any bookings.....yet!</p>
                    </Center>
                </div> : null}
                {this.state.current_bookings.map((booking) => {
                    return <div className="col-md-6 col-xs-12 col-sm-6 col-lg-6" key={booking.id}>
                        <BookingListing booking={booking} expanded={true}/>
                    </div>
                })}
            </div>
            <Center>
                <Button text="Make New Booking" name="add-booking" onClick={() => {
                    document.location.href = '/new-booking';
                }}/>
            </Center>
            {this.state.show_previous === false ? <Fragment>
                    <Center><Button text="Show My Previous & Cancelled Bookings" name="show-previous" variant="text"
                                    onClick={(e) => {
                                        this.setState({show_previous: true}, () => this.updateBookings());
                                    }}/></Center>
                </Fragment> :
                <Fragment>
                    {this.state.previous_bookings.length === 0 ?
                        <IntroText name="past-bookings">You don't have any previous bookings with us</IntroText> :
                        <Fragment>
                            <IntroText name="past-bookings">
                                Past Bookings
                            </IntroText>
                            <div className="row">
                                {this.state.previous_bookings.map((booking) => {
                                    return <div className="col-md-6 col-xs-12 col-sm-6 col-lg-6" key={booking.id}>
                                        <BookingListing booking={booking}/>
                                    </div>
                                })}
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
        </div>
    }
}

export default Dashboard;
