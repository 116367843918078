import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {EventDispatcher} from "../../Events/Dispatcher";

class FormElementCheckbox extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            value: this.props.value
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    componentDidMount() {
        EventDispatcher.dispatch('form_element_initialise', {
            detail: {
                type: 'checkbox',
                ref: this.ref.current
            }
        });
    }

    componentWillUnmount() {
        EventDispatcher.dispatch('form_element_destroy', {
            detail: {
                type: 'checkbox',
                ref: this.ref.current
            }
        });
    }

    render() {
        let params = {
            name: this.props.name,
            id: 'cb_' + this.props.name,
            className: 'field__element',
            disabled: this.props.disabled
        };
        if (this.props.onChange) {
            params.onChange = (e) => {
                this.setState({value: e.target.checked});
                this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: e.target.checked === true ? this.props.true_value : this.props.false_value
                    }
                })
            }
        }
        if (this.state.value) {
            params.checked = true;
            params.value = true;
        } else {
            params.checked = false;
            params.value = false;
        }
        if (this.props.label_after === true) {
            return <div ref={this.ref}
                        className={cx('field', 'field--checkbox', 'field--' + this.props.name, {'field--checked': !!this.props.value})}>
                <div className="field__element-wrap">
                    <input type="checkbox" {...params}/>
                    <label htmlFor={params.id}
                           className="field__sub-label">{this.props.label}</label>
                </div>
            </div>
        } else {
            return <div ref={this.ref}
                        className={cx('field', 'field--checkbox', 'field--' + this.props.name, {'field--checked': !!this.props.value})}>
                <div className="field__element-wrap">
                    <label htmlFor={params.id} className="field__sub-label">{this.props.label}</label>
                    <input type="checkbox" {...params}/>
                </div>
            </div>
        }

    }

}

export default FormElementCheckbox;

FormElementCheckbox.defaultProps = {
    true_value: true,
    false_value: false
};

FormElementCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    true_value: PropTypes.any,
    false_value: PropTypes.any,
    label_after: PropTypes.bool
}