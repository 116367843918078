import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import TypeSelect from "../Elements/TypeSelect";
import {BookingData} from "../Data/BookingData";
import UserData from "../Elements/UserData";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreOrder from "../Elements/PreOrder/PreOrder";
import PreBookingInfo from "../Elements/PreBookingInfo";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import DepositBar from "../Elements/Deposit/DepositBar";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import TermsText from "../Elements/TermsText";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";
import moment from "moment";
import {Settings} from "../../Settings/Settings";

export default function TableBooking(props) {
    let booking = props.booking;

    let fallback = <Fragment>
        <BookingStep step={99} name="fallback" type="table-booking" hot_jar_url="enquiry-fallback-to-callback" booking={props.booking}>
            <UserData booking={props.booking} step={99}
                      additional_info={<p>Cool, you want us to help you through your booking? Fill in your details
                          below and we'll get back to you asap!</p>}
                      include_pre_booking={<PreBookingInfo
                          booking={props.booking}
                          updating={props.updating}
                          include_booking_time={true}
                          step={99}
                      />}
            />
            <TermsText booking={props.booking}/>
        </BookingStep>
        <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                    skip_text="Send Enquiry" can_skip={props.booking.can_complete} continue={true} show_deposit={false}
                    continueFunction={() => BookingData.completeBooking()}/>
    </Fragment>
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        let fields_completed = true;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
                fields_completed = false;
            }
            return field;
        });
        return <Fragment>
            <BookingStep step={1} name="pre-booking-info" type="table-booking" hot_jar_url="enquiry-info" booking={props.booking}>
                {fields_completed === true && can_proceed === false ?
                    <Notice name="booking-unavailable" variant="error">Sorry, looks like we can't take this booking
                        right now.</Notice> : null}
                <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
            </BookingStep>
        </Fragment>
    }
    if (booking.step === 2) {
        let types = BookingData.getTypeList().filter((type) => {
            let found = type.value.indexOf('Christmas') > -1 && type.key != props.booking.party_type;
            if (type.key == '97' && props.booking.no_people != 6) {
                found = false;
            }
            return found;
        });
        return <Fragment>
            <BookingStep step={2} name="time-select" type="table-booking" hot_jar_url="enquiry-availability-check" booking={props.booking}>
                <PreBookingInfo booking={props.booking} updating={props.updating}
                                minDate={moment(props.booking.min_booking_date).toDate()} openToDate={BookingData.getOpenToDate(true)}/>
                {props.booking.date !== null && <TimeSelect booking={props.booking} updating={props.updating} show_price={false} stacked={true} fallback_form={fallback}/>}
            </BookingStep>
        </Fragment>
    }
    if (booking.step === 3) {
        var skip_text = 'Skip';
        if (props.booking.variations.data.length) {
            skip_text = 'Continue';
        }
        return <Fragment>
            <BookingStep step={3} name="pre-order" type="table-booking" hot_jar_url="enquiry-pre-orders" booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="A Table is available at this time, would you like to pre-order anything?"
                          sub_text=""/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={4}
                        skip_text={skip_text} can_skip={true}/>
        </Fragment>
    }

    if (booking.step === 4) {
        let skip_text = 'Continue';
        let continueFunction = () => {
            BookingData.completeBooking();
        };
        if (props.booking.can_auto_confirm) {
            skip_text = 'Book now';
        }
        if (props.booking.deposit_required) {
            continueFunction = () => {
            };
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="table-booking" hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <h2>Almost Done</h2>
                <ShortSummary booking={props.booking} additional_class="desktop" show_end_time={true}/>
                <UserData booking={props.booking}/>
                <ShortSummary booking={props.booking} additional_class="mobile" show_end_time={true}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete} show_deposit={false}
                        continueFunction={continueFunction}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return fallback
    }
    return <BookingStep step={999} name="no-step" type="table-booking" hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}

TableBooking.propTypes = {
    booking: PropTypes.object.isRequired
}
