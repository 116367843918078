import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

const TimeCountdown = (props) => {
    const [timeString, setTimeString] = useState('');

    const setTime = () => {
        const time = moment(props.time);
        let template = props.future_copy;
        if (time.isBefore(moment()) && props.past_copy) {
            template = props.past_copy;
        }
        setTimeString(template.replace('{time}', time.fromNow(true)));
    }
    useEffect(() => {
        setTime();
    });
    useEffect(() => {
        let interval = setInterval(() => {
            setTime();
        }, 2000);
        return () => {
            clearInterval(interval);
        }
    }, [props.time]);
    return <>{timeString}</>
}

TimeCountdown.propTypes = {
    time: PropTypes.string.isRequired,
    future_copy: PropTypes.string.isRequired,
    past_copy: PropTypes.string
}

export default TimeCountdown;