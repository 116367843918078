import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Icon(props) {
    let image = null;
    if (props.icon === 'Overview') {
        image = '/assets/icons/Overview.svg';
    }
    if (props.icon === 'Guest') {
        image = '/assets/icons/Guests.svg';
    }
    if (props.icon === 'Package') {
        image = '/assets/icons/Package.svg';
    }
    if (props.icon === 'Payment') {
        image = '/assets/icons/Payment.svg';
    }
    return <img src={image} alt={props.alt} className={cx('icon', 'icon--' + props.icon)}/>
}

Icon.propTypes = {
    icon: PropTypes.oneOf(['Overview', 'Package', 'Guest', 'Payment']).isRequired,
    alt: PropTypes.string.isRequired
}