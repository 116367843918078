import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Tabs.scss';
import Icon from "../../../Utility/Icon/Icon";

export default function Tabs(props) {
    return <div className="booking-tabs">
        <div className="booking-tabs--tabs">
            <div className={cx('booking-tabs--tab tab--overview', {'active': props.tab === 'overview'})}>
                <a href="#overview" onClick={(e) => props.changeTab('overview')}>
                    <span className="bookings-tabs--tab--icon icon--overview"/>
                    Overview
                </a>
            </div>
            {/*<div className={cx('booking-tabs--tab tab--guests', {'active': props.tab === 'guests'})}>*/}
            {/*    <a href="#guests" onClick={(e) => props.changeTab('guests')}>*/}
            {/*        <span className="bookings-tabs--tab--icon icon--guests"/>*/}
            {/*        Guests</a>*/}
            {/*</div>*/}
            <div className={cx('booking-tabs--tab tab--guests', {'active': props.tab === 'pre_order'})}>
                <a href="#pre_order" onClick={(e) => props.changeTab('pre_order')}>
                    <span className="bookings-tabs--tab--icon icon--guests"/>
                    Pre Order</a>
            </div>
            {/*<div className={cx('booking-tabs--tab tab--packages', {'active': props.tab === 'packages'})}>*/}
            {/*    <a href="#packages" onClick={(e) => props.changeTab('packages')}>*/}
            {/*        <span className="bookings-tabs--tab--icon icon--packages"/>*/}
            {/*        Packages</a>*/}
            {/*</div>*/}
            {props.show_payment === true ?
                <div className={cx('booking-tabs--tab tab--payment', {'active': props.tab === 'payment'})}>
                    <a href="#payment" onClick={(e) => props.changeTab('payment')}>
                        <span className="bookings-tabs--tab--icon icon--payment"/>
                        Payment</a>
                </div> : null}
        </div>
    </div>
}

Tabs.propTypes = {
    tab: PropTypes.string.isRequired,
    changeTab: PropTypes.func.isRequired
}