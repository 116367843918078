import React, {Component, Fragment} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import FormElementText from "../../Form/Element/Text";
import './MyDetails.scss';
import Button from "../../Form/Element/Button";
import {EventDispatcher} from "../../Events/Dispatcher";
import FormElementCheckbox from "../../Form/Element/Checkbox";
import _ from 'lodash';
import Notice from "../../Utility/Notice/Notice";

class MyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: {},
            error: null,
            edit_allergens: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.loading !== prevState.loading) {
            this.updateButtons();
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get('customers/me', {full: 1, include: 'bar,allergens'}).then((response) => {
            if (response.ok) {
                this.setState({user: response.data.data, loading: false});
            }
        });
    }

    updateButtons() {
        EventDispatcher.dispatch('set-modal-buttons', <Fragment>
            <Button text="Cancel" name="cancel" variant="outline" onClick={() => {
                EventDispatcher.dispatch('close-modal');
            }}/>
            <Button text="Save" name="save" onClick={(e) => this.handleSave(e)} disabled={this.state.loading}/>
        </Fragment>);
    }

    handleSave(e) {
        this.setState({loading: true, error: null});
        Api.post('customers/my-details', this.state.user).then((response) => {
            if (response.ok) {
                this.setState({loading: false, user: response.data.data});
                return;
            }
            this.setState({loading: false, error: response.error});
        });
    }

    handleFieldChange(e) {
        let user = _.extend({}, this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        return <Fragment>
            <div className="my-details">
                <h2>Your Profile</h2>
                {this.state.error ?
                    <Notice name={'update-details-error'} variant="error">{this.state.error}</Notice> : null}
                <div className="row my-details--info">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <FormElementText name="forename" value={this.state.user.forename} placeholder="Forename"
                                         onChange={(e) => this.handleFieldChange(e)}/>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <FormElementText name="surname" value={this.state.user.surname} placeholder="Surname"
                                         onChange={(e) => this.handleFieldChange(e)}/>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <FormElementText name="email" value={this.state.user.email} placeholder="E-mail address"
                                         onChange={(e) => this.handleFieldChange(e)}/>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <FormElementText name="phone" value={this.state.user.phone} placeholder="Phone Number"
                                         onChange={(e) => this.handleFieldChange(e)}/>
                    </div>
                </div>

                <div className="my-details--optins">
                    <p>Please specify which ways you would like to be contacted by Revolution Bars for marketing and
                        sales updates:</p>
                    <FormElementCheckbox onChange={(e) => this.handleFieldChange(e)}
                                         label="E-Mail" label_after={true}
                                         name="marketing_opt_in" value={this.state.user.marketing_opt_in}/>
                    <FormElementCheckbox onChange={(e) => this.handleFieldChange(e)}
                                         label="Phone" label_after={true}
                                         name="phone_opt_in" value={this.state.user.phone_opt_in}/>
                    <FormElementCheckbox onChange={(e) => this.handleFieldChange(e)}
                                         label="SMS" label_after={true}
                                         name="sms_opt_in" value={this.state.user.sms_opt_in}/>
                </div>
            </div>
        </Fragment>
    }
}

export default MyDetails;