import React, {Component} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import PropTypes from 'prop-types';
import Notice from "../../Utility/Notice/Notice";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import FormElementText from "../../Form/Element/Text";
import Button from "../../Form/Element/Button";
import {Settings} from "../../Settings/Settings";
import moment from "moment";
import './GuestJoinBooking.scss';
import IntroText from "../../Utility/IntroText/IntroText";
import FormElementPassword from "../../Form/Element/Password";

export default class GuestJoinBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            guest: {},
            forename: '',
            surname: '',
            password: '',
            saving: false,
            error: null,
            validation_error: null
        }
    }

    componentDidMount() {
        this.updateGuest();
    }

    updateGuest() {
        Api.get('rest/booking/guest-join/' + this.props.code).then((response) => {
            if (response.ok) {
                this.setState({guest: response.data.data, loading: false, user: response.data.meta.user});
                return;
            }

            this.setState({loading: false, error: response.error});
        })
    }

    handleSave(e) {
        e.preventDefault();
        if (this.state.saving) {
            return;
        }
        this.setState({saving: true});
        let errors = this.validate();
        if (errors.length === 0) {
            Api.post('rest/booking/guest-join/' + this.props.code, {
                forename: this.state.forename,
                surname: this.state.surname,
                password: this.state.password
            }).then((response) => {
                if (response.ok) {
                    document.location.href = '/edit-booking/' + this.state.guest.booking_id;
                }
                this.setState({validation_error: response.error, saving: false});
            });
        }
    }

    validate() {
        let errors = [];
        if (this.state.user && this.state.user.id === this.state.guest.customer_id) {
            return [];
        }
        if (!this.state.forename) {
            errors.push('Forename is required');
        }

        if (!this.state.surname) {
            errors.push('Surname is required');
        }

        if (!this.state.password) {
            errors.push('Password is required');
        }
        this.setState({validation_error: errors.join(', ')});
        return errors;
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }

        if (this.state.error) {
            return <div>
                <Notice name="error" variant="error">{this.state.error}</Notice>
            </div>
        }
        if (!this.state.user) {
            let message = <IntroText title="Nearly there..." name="join-booking">{this.state.guest.lead_booker_name} has
                invited you to their
                booking
                at <strong>{this.state.guest.location}</strong> on <strong>{moment(this.state.guest.date).format(Settings.date_formats.full_date_time)}</strong>.
                Enter your details below and
                you'll be in!</IntroText>
            if (this.state.guest.admin) {
                message = <IntroText title="Nearly there..."
                                     name="join-booking">{this.state.guest.lead_booker_name} has invited you to be a
                    co-host of their booking
                    at <strong>{this.state.guest.location}</strong> on <strong>{moment(this.state.guest.date).format(Settings.date_formats.full_date_time)}</strong>.
                    Enter your details below and
                    you'll be in!</IntroText>
            }
            return <div>
                <ContentBox>
                    {message}
                    {this.state.validation_error ? <Notice name="error">{this.state.validation_error}</Notice> : null}
                    <form onSubmit={(e) => this.handleSave(e)}>
                        <FormElementText name="email" value={this.state.guest.email} email={true} disabled={true}
                                         placeholder="E-mail address"/>
                        <FormElementText name="forename" value={this.state.forename}
                                         placeholder="Forename"
                                         onChange={(e) => this.setState({forename: e.target.value})}/>
                        <FormElementText name="surname" value={this.state.surname}
                                         placeholder="Surname"
                                         onChange={(e) => this.setState({surname: e.target.value})}/>
                        <FormElementPassword name="password" value={this.state.password}
                                             placeholder="Password"
                                             onChange={(e) => this.setState({password: e.target.value})}/>
                        <Button text={'Join the ' + Settings.getPartyName()} name="join-booking"
                                disabled={this.state.saving}/>
                    </form>
                </ContentBox>
            </div>
        }
        if (this.state.user.id !== this.state.guest.customer_id) {
            return <div>
                <ContentBox>
                    <Notice name="different-user">Hmm... The invitation for this booking is
                        for {this.state.guest.email} but you are logged in as {this.state.user.email}. Do you want to
                        logout and continue?</Notice>
                    <Button text="Yes" name="logout" onClick={() => {
                        Api.post('customers/logout', {}).then((response) => {
                            document.location.reload();
                        });
                    }}/>
                </ContentBox>
            </div>
        }
        let logged_in_message = <IntroText title="Nearly there..."
                                           name="join-booking">{this.state.guest.lead_booker_name} has
            invited you to their
            booking
            at <strong>{this.state.guest.location}</strong> on <strong>{moment(this.state.guest.date).format(Settings.date_formats.full_date_time)}</strong>.
            Do you want to join now?</IntroText>
        if (this.state.guest.admin) {
            logged_in_message = <IntroText title="Nearly there..."
                                           name="join-booking">{this.state.guest.lead_booker_name} has invited you to be
                a
                co-host of their booking
                at <strong>{this.state.guest.location}</strong> on <strong>{moment(this.state.guest.date).format(Settings.date_formats.full_date_time)}</strong>.
                Do you want to join now?</IntroText>
        }
        return <div>
            <ContentBox>
                <Notice name="accept" title="Almost there...">
                    {logged_in_message}
                </Notice>
                <Button text={'Join the ' + Settings.getPartyName()} name="join-now" disabled={this.state.saving}
                        onClick={(e) => this.handleSave(e)}/>
            </ContentBox>
        </div>
    }
}

GuestJoinBooking.propTypes = {
    code: PropTypes.string.isRequired
}