import React, {Component, Fragment} from 'react';
import StoreCard from "./StoreCard";
import {BookingData} from "../../Data/BookingData";
import Notice from "../../../Utility/Notice/Notice";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import {Settings} from "../../../Settings/Settings";
import './AddCardToSession.scss';

export default class AddCardToSession extends Component {
    componentDidMount() {
        let el = document.getElementById('main_booking_container');
        window.scrollTo(0, el.offsetTop);
    }

    render() {
        let message = this.props.message;
        if (!message) {
            message = <Fragment>
                <p>To confirm your booking we ask that you provide your card details.</p>
                <p>&nbsp;</p>
                <p><strong><u>No funds will be taken
                    from your
                    account unless you do not show for your booking without prior warning or cancel at short notice
                    in
                    which case you may be
                    charged {Settings.currencyFormatter.format(this.props.fee)} ({Settings.currencyFormatter.format(this.props.pp_fee)} per
                    person) at our discretion.</u></strong></p>
                <p>&nbsp;</p>
                <p>If you are not able to attend your booking, you can cancel it via the "Manage/Cancel Booking" button
                    once you receive your booking confirmation email.</p>
            </Fragment>
        }
        return <div>
            <Notice name="card-details-required">
                <div dangerouslySetInnerHTML={{__html: message}} />
            </Notice>
            <StoreCard session_id={this.props.session_id} onComplete={(data) => {
                BookingData.update({}, () => {
                    BookingData.completeBooking();
                    ModalHelper.closeModal();
                }, true);
            }}/>
        </div>
    }
}