import React, {Fragment, useEffect, useState} from 'react';
import {Api} from "../../../../../Api/Api";
import PropTypes from 'prop-types';
import Loading from "../../../../../Utility/Loading/Loading";
import cx from "classnames";
import Package from "../../../../../Bookings/Elements/PreOrder/Package";

export default function AddNewPackage(props) {
    const [packages, setPackages] = useState({});
    const [loading, setLoading] = useState(true);
    const [hasPackage, setHasPackage] = useState(false);
    const [hasCustom, setHasCustom] = useState(false);
    const [type, setType] = useState('package');
    const [packageList, setPackageList] = useState([]);
    const [customList, setCustomList] = useState([]);
    const setPackageData = (data) => {
        let custom = [];
        let package_list = [];
        data.map((package_option) => {
            if (package_option.display_type === 'package' && !hasPackage) {
                setHasPackage(true);
                package_list.push(package_option);
            }

            if (package_option.display_type === 'custom' && !hasCustom) {
                setHasCustom(true);
                custom.push(package_option);
            }
        });
        if (!hasPackage && hasCustom) {
            setType('custom');
        }
        setPackageList(package_list);
        setCustomList(custom);
        setPackages(data);
    }
    useEffect(() => {
        Api.get('rest/my-bookings/' + props.booking.id + '/packages').then((response) => {
            if (response.ok) {
                setPackageData(response.data.data);
            }
            setLoading(false);
        })
    }, []);
    if (loading) {
        return <Loading/>
    }
    return <div className="pre-order">
        {hasCustom && hasPackage ? <div className="type-select">
            {hasPackage ?
                <button className={cx('package', {active: type === 'package'})} onClick={() => setType('package')}>
                    Packages
                </button> : null}
            {hasCustom ?
                <button className={cx('custom', {active: type === 'custom'})} onClick={() => setType('custom')}>
                    Custom
                </button> : null}
        </div> : null}
        <div className="products">
            {type === 'package' ? <Fragment>
                {packageList.map((option) => {
                    // return <Package option={option} booking={props.booking} key={option.id}/>
                })}
            </Fragment> : null}
            {type === 'custom' ? <Fragment></Fragment> : null}
        </div>
        <pre style={{height: '400px', overflow: 'scroll'}}>
        {JSON.stringify(packages, null, 2)}
        </pre>
    </div>
}
AddNewPackage.propTypes = {
    booking: PropTypes.object.isRequired
}