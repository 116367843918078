import React, {Fragment} from 'react';
import './PackageInfo.scss';
import ItemChoices from "./ItemChoices";
import Notice from "../../../Utility/Notice/Notice";
import nl2br from 'react-nl2br';

export default function PackageInfo(props) {
    let variation = props.variation;
    let current_option = props.booking.variations.data.find((booking_var) => {
        if (booking_var.variation_id === variation.variation_id) {
            return true;
        }
        return false;
    });
    return <Fragment>
        <div className="package-info">
            <h2>{props.variation.name}</h2>
            <p>{nl2br(props.variation.description)}</p>
            {current_option ? <Fragment>
                {props.variation.choices.data.length > 0 && props.option.price_per_booking ?
                    <div className="item-choices">
                        <ItemChoices choices={props.variation.choices.data} variation={props.variation}
                                     selected={current_option}/>
                    </div> : null}
                {props.variation.choices.data.length > 0 && !props.option.price_per_booking ?
                    <Notice name="chose-later">You will be able to choose your pre-order options after completing your booking.</Notice> : null}
            </Fragment> : null}
        </div>
    </Fragment>
}