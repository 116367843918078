import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import DietaryItem from "./DietaryItem";
import './DietaryFilters.scss'
import {dietaryOptions, allergens,} from "../allergens";
import Drawer from "../../../Utility/Drawer/Drawer";

const DietaryFilters = ({intolerances, handleChange, uiFilters, handleUiFiltersChange}) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);
    const checkScreenSize = () => {
        setIsLargeScreen(window.innerWidth > 1200);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const content = <>
        <div className='ui-filters'>
            {uiFilters.map((item, idx) => {
                return <DietaryItem key={idx} title={item.name} checked={item.checked}
                                    handleChange={handleUiFiltersChange}/>
            })}
        </div>
        <hr className='divider'/>
        <div className='dietary-options'>
            {dietaryOptions.map((item, idx) => {
                const intolerance = intolerances.find(intolerance => intolerance.name.toLowerCase() === item.toLowerCase())
                return <DietaryItem key={idx} title={item} checked={intolerance.checked} handleChange={handleChange}/>
            })}
        </div>
        <hr className='divider'/>
        <div className='allergens'>
            {allergens.map((item, idx) => {
                const intolerance = intolerances.find(intolerance => intolerance.name.toLowerCase() === item.toLowerCase());
                return <DietaryItem key={idx} title={item} checked={intolerance.checked} handleChange={handleChange}/>
            })}
        </div>
    </>

    return (
        <div className='allergens-menu-filters'>
            {isLargeScreen && <h2 className='allergens-menu-filters-title'>Filters</h2>}

            {isLargeScreen ? content :
                <Drawer drawerHandleIcon='fa fa-filter'>
                    <h2 className='allergens-menu-filters-title'>Filters</h2>
                    {content}
                </Drawer>
            }
        </div>
    )
}
export default DietaryFilters;

DietaryFilters.propTypes = {
    intolerances: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    uiFilters:PropTypes.array.isRequired,
    handleUiFiltersChange: PropTypes.func.isRequired
}