import React, {Component, Fragment} from 'react';
import {BookingData} from './Data/BookingData';
import Loading from '../Utility/Loading/Loading';
import TypeSelect from './Elements/TypeSelect';
import {EventDispatcher} from '../Events/Dispatcher';
import TableBooking from './Types/TableBooking';
import './Styles/BookingElement.scss';
import $ from 'jquery';
import BrunchBooking from './Types/BrunchBooking';
import DrinksBooking from './Types/DrinksBooking';
import AppModal, {ModalHelper} from '../Utility/Modal/AppModal';
import BookingHeader from './Header/BookingHeader';
import MasterclassBooking from './Types/MasterclassBooking';
import ChristmasBooking from './Types/ChristmasBooking';
import PrivateHire from './Types/PrivateHire';
import CorporateBooking from './Types/CorporateBooking';
import './BookingForm.scss';
import {toast, ToastContainer} from 'react-toastify';
import {NotificationManager} from '../Notifications/NotifcationManager';
import BookingStep from '../Utility/BookingStep/BookingStep';
import cx from 'classnames';
import Notice from '../Utility/Notice/Notice';
import PropTypes, {func} from 'prop-types';
import BoothBooking from './Types/BoothBooking';
import {Settings} from '../Settings/Settings';
import Button from '../Form/Element/Button';
import LeaveDetails from './Elements/LeaveDetails/LeaveDetails';
import Center from '../Utility/Center/Center';
import AtTableMasterclass from './Types/AtTableMasterclass';
import OnlineMasterclass from './Types/OnlineMasterclass';
import NyeBooking from './Types/NyeBooking';
import BarSpecificSlotBooking from './Types/BarSpecificSlotBooking';
import ChristmasMostrador from './Types/ChristmasMostrador';
import nl2br from 'react-nl2br';
import BookingDebug from './Debug/BookingDebug';
import GenericLargerEnquiry from './Types/GenericLargerEnquiry';
import NyeEnquiry from './Types/NyeEnquiry';
import GenericEnquiry from './Types/GenericEnquiry';
import GenericConsumerEnquiry from './Types/GenericConsumerEnquiry';
import Karaoke from "./Types/Karaoke";

class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {},
            updating: false,
            height: 0,
            deposit_bar: false,
            width: 0,
            booking: {},
            saving_booking: false,
            user_data: {
                forename: '',
                surname: '',
                email: '',
                bar_id: ''
            },
            params: {}
        };
    }

    componentDidMount() {
        BookingData.init(this);
        EventDispatcher.subscribe('post_booking_update', () => this.checkHeight());
        EventDispatcher.subscribe('update_height', () => this.checkHeight());
        this.initHotJar();
        if (this.props.data && Object.keys(this.props.data).length > 0) {
            let data = this.props.data;
            window.postMessage({
                type: 'params',
                params: {pre_filled_data: data, brand: Settings.brand}
            }, '*');
        }
        NotificationManager.subscribe((message) => {
            if (message.type === 'error') {
                toast.error(message.message);
            }
            if (message.type === 'info') {
                toast.info(message.message);
            }
            if (message.type === 'success') {
                toast.success(message.message);
            }
        });
        EventDispatcher.subscribe('deposit-bar', (data) => {
            this.setState({deposit_bar: data});
        });
        setTimeout(() => this.checkHeight(), 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.booking.step !== prevState.booking.step && prevState.booking.step !== undefined) {
            let el = document.getElementById('main_booking_container');
            console.log('Scroll', this.state.booking.step, prevState.booking.step);
            window.scrollTo(0, el.offsetTop);
        }
    }

    initHotJar() {
        if (Settings.brand === 'revolution') {
            (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
                h._hjSettings = {hjid: 3187944, hjsv: 6};
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
        if (Settings.brand === 'decuba') {
            (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
                h._hjSettings = {hjid: 1879990, hjsv: 6};
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }

    checkHeight() {
        let container = $('#main_booking_container');
        if (container.length === 0) {
            setTimeout(() => this.checkHeight(), 100);
            return;
        }
        let height = container.height() + 80;
        let width = container.width();
        if (height !== this.state.height) {
            this.setState({height: height});
        }
        if (width !== this.state.width) {
            this.setState({width: width});
        }
        BookingData.sendMessage('size_update', {height: height, width: width});
    }

    render() {
        if (this.state.loading) {
            return <div><Loading/></div>;
        }
        return <Fragment>
            <ToastContainer/>
            <AppModal/>
            {this.props.debug === true && <BookingDebug booking={this.state.booking}/>}
            <div id="main_booking_container" className={cx('clearfix', {
                'has-deposit-bar': this.state.deposit_bar,
                [`${this.state.params.pre_filled_data.container_class}`]:!!this.state.params.pre_filled_data.container_class,
                'has-pre-order-message': this.state.booking.pre_order_required,
                'has-allergen-message': (this.state.booking.step === 99 || this.state.booking.step === 4) &&
                    this.state.booking.no_complete_reason
            })}>
                {this.state.saving_booking ? <div className="loading__block"><Loading/></div> : null}
                <BookingHeader booking={this.state.booking}/>
                {this.state.booking.warning_text ?
                    <Notice variant="error" name="error-text">
                        {nl2br(this.state.booking.warning_text)}
                    </Notice> : null}
                {this.state.booking.collect_details ? <Notice name="collect-details">
                    <Center>
                        {this.state.booking.collect_details_message}
                        <Button text={this.state.booking.collect_details_button_text} name="leave-details"
                                onClick={() => {
                                    ModalHelper.openModal(<LeaveDetails booking={this.state.booking}/>);
                                }}/>
                    </Center>
                </Notice> : null}
                {this.state.booking.bar && this.state.booking.bar.data && this.state.booking.bar.data.close_date &&
                    <Notice variant="info" name="closure-message"><div dangerouslySetInnerHTML={{__html:this.state.booking.closure_message}}/></Notice>}
                {!this.state.booking.party_type || !this.state.booking.bar ?
                    <Fragment>
                        <BookingStep step={1} type="unknown" hot_jar_url="enquiry" booking={this.state.booking}>
                            <TypeSelect booking={this.state.booking} updating={this.state.updating}/>
                        </BookingStep>
                    </Fragment> : <Fragment>
                        {this.state.booking.party_type === 116 &&<Karaoke booking={this.state.booking} updating={this.state.updating}/>}
                        {this.state.booking.party_type === 18 ?
                            <TableBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 5 ?
                            <CorporateBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 49 ?
                            <PrivateHire booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 2 ?
                            <GenericLargerEnquiry booking={this.state.booking}
                                                  updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 11 ?
                            <GenericEnquiry booking={this.state.booking}
                                            updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 113 || this.state.booking.party_type === 114 ?
                            <GenericConsumerEnquiry booking={this.state.booking}
                                                    updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 25 ||
                        this.state.booking.party_type === 69 ?
                            <NyeEnquiry booking={this.state.booking}
                                        updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 27 ?
                            <MasterclassBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 91 ?
                            <BrunchBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 1 || this.state.booking.party_type === 94 ?
                            <DrinksBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 93 ?
                            <BoothBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 95 ?
                            <ChristmasBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 96 ?
                            <ChristmasBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 97 ?
                            <ChristmasBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 105 ?
                            <ChristmasMostrador booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 104 ?
                            <ChristmasBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 98 ?
                            <AtTableMasterclass booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 99 ?
                            <OnlineMasterclass booking={this.state.booking} updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 20 ?
                            <BarSpecificSlotBooking
                                //booking={{...this.state.booking, min_booking_date: '2024-06-01'}} updating={this.state.updating}
                                booking={this.state.booking} updating={this.state.updating}
                                filterDate={(date) => {
                                    const start = new Date('2024-06-14 00:00:00');
                                    const end = new Date('2024-07-14 00:00:00')
                                    return date >= start && date <= end;
                                }}/> : null}
                        {this.state.booking.party_type === 100 ||
                        this.state.booking.party_type === 102 ||
                        this.state.booking.party_type === 101 ||
                        this.state.booking.party_type === 111 ||
                        this.state.booking.party_type === 112 ||
                        this.state.booking.party_type === 103 ?
                            <BarSpecificSlotBooking booking={this.state.booking}
                                                    updating={this.state.updating}/> : null}
                        {this.state.booking.party_type === 14 ?
                            <NyeBooking booking={this.state.booking} updating={this.state.updating}/> : null}
                    </Fragment>}
            </div>
        </Fragment>;
    }
}

Booking.propTypes = {
    data: PropTypes.object
};
export default Booking;
