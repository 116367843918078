import PropTypes from "prop-types";
import React from "react";
import './Ingredients.scss'

const Ingredients = ({ingredients, highlightedAllergens, title}) => {
    const checkIntolerance = (allergen) => {
        return highlightedAllergens?.some(item => item === allergen);
    }

    return (
        <div className='ingredients'>
            <h2 className='ingredients-title'>{title}</h2>
            {ingredients.map((ingredient, index) => {

                const hasIntolerance =
                    ingredient.contains.some(allergen => checkIntolerance(allergen)) ||
                    ingredient.may_contain.some(allergen => checkIntolerance(allergen));

                return <div key={index} className='ingredients-card'>
                    <div className={`ingredients-card-banner ${hasIntolerance ? 'intolerance' : ''}`}></div>
                    <div className='ingredients-card_body'>
                        <p className='ingredients-card_body-name'>
                            {ingredient.name}
                        </p>
                        <hr className='divider'/>
                        <div className='ingredients-card_body-group'>
                            <p className='ingredients-card_body-group-contains'>
                                <strong>Contains:&nbsp;</strong>
                                {!ingredient.contains.length > 0 ?
                                    <em>No major allergens</em> :
                                    ingredient.contains.map((allergen, idx) =>
                                        <em key={idx}>
                                            <span
                                                className={checkIntolerance(allergen) ? 'highlighted' : ''}>
                                                {allergen}
                                            </span>
                                            {idx + 1 !== ingredient.contains.length && <span>,&nbsp;</span>}
                                        </em>
                                    )}
                            </p>
                            {ingredient.may_contain.length > 0 &&
                                <p className='ingredients-card_body-group-may-contain'>
                                    <strong>May Contain:&nbsp;</strong>
                                    {ingredient.may_contain.map((allergen, idx) =>
                                        <em key={idx}>
                                            <span
                                                className={checkIntolerance(allergen) ? 'highlighted' : ''}>
                                                {allergen}
                                            </span>
                                            {idx + 1 !== ingredient.may_contain.length && ', '}
                                        </em>
                                    )}
                                </p>
                            }
                        </div>
                    </div>
                </div>
            })}
        </div>
    );
}

export default Ingredients;

Ingredients.propTypes = {
    hasIntolerance: PropTypes.bool,
    highlightedAllergens: PropTypes.array,
    ingredients: PropTypes.array,
    title: PropTypes.string
}