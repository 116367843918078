import React, {Fragment, useState, useEffect, useRef} from 'react';
import Notice from "../../Utility/Notice/Notice";
import nl2br from "react-nl2br";
import FormElementCheckbox from "../../Form/Element/Checkbox";
import {BookingData} from "../Data/BookingData";
import {ModalHelper} from "../../Utility/Modal/AppModal";

export default function TypeInfo (props) {
    if (props.updating && props.hide_updating) {
        return <Fragment></Fragment>
    }
    if (props.updating) {
        return <Fragment><span className="fa fa-spin fa-circle-o-notch" /> </Fragment>
    }
    if (!props.booking.info_text) {
        return <Fragment>{props.nextButton}</Fragment>
    }

    return <div className="type-select--info"><Notice name="info-text">
        {nl2br(props.booking.info_text)}
        {props.booking.is_standing_type &&
            <FormElementCheckbox
                onChange={(e) => BookingData.update({allow_standing: e.target.value})}
                label={<Fragment><span>Include a mix of seating and standing space</span> <img src="/assets/icons/Info.svg"
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   e.stopPropagation();
                                                   ModalHelper.openModal(<div className="covid-meeting-text">
                                                       We will always try and allocate an area with a seat
                                                       for every guest. However we do offer areas with a
                                                       mixture of seats and standing spaces for
                                                       buffet-style and drinks party bookings. If you are
                                                       happy to see availability for an area with mixed
                                                       seated and standing space, then leave this box
                                                       ticked.
                                                   </div>)
                                               }} alt="Standing information"/></Fragment>}
                true_value="1"
                false_value="0"
                label_after={true}
                name="allow_standing"
                value={props.booking.allow_standing}
            />}
        {props.nextButton}
    </Notice></div>
}