import React, {Component, Fragment} from 'react';
import Loading from "../../Utility/Loading/Loading";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import IntroText from "../../Utility/IntroText/IntroText";
import FormElementPassword from "../../Form/Element/Password";
import {Api} from "../../Api/Api";
import Button from "../../Form/Element/Button";
import './Verify.scss';
import {Settings} from "../../Settings/Settings";
import {withRouter} from "react-router-dom";
import Notice from "../../Utility/Notice/Notice";
import FormElementText from "../../Form/Element/Text";
import Center from "../../Utility/Center/Center";

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: true,
            password: '',
            additional: false,
            error: null,
            initial_user: {},
            save_error: null
        }
    }

    componentDidMount() {
        Api.get('customers/register/verify/' + this.props.token, {brand_id: Settings.getBrandId()}).then((response) => {
            if (response.ok) {
                let requires_additional = true;
                if (!response.data.data.forename || !response.data.data.surname) {
                    requires_additional = true;
                }
                this.setState({
                    loading: false,
                    user: response.data.data,
                    initial_user: response.data.data,
                    additional: requires_additional
                });
                return;
            }
            this.setState({loading: false, error: response.data.errors.message});
        }).catch(() => {
            this.setState({error: 'Woops, looks like we are having issues. Please try again later'});
        });
    }

    handleVerify(e) {
        e.preventDefault();
        let data = {
            customer_id: this.state.user.id,
            password: this.state.password
        };
        if (this.state.additional) {
            data.forename = this.state.forename;
            data.surname = this.state.surname;
        }
        Api.post('customers/register/verify/' + this.props.token, data).then((response) => {
            if (response.ok) {
                this.setState({success: true});
                let link = '/';
                if (response.data.post_login_url) {
                    link = response.data.post_login_url;
                }
                if (this.props.join_token) {
                    link = '/join/' + this.props.join_token;
                }
                this.props.history.push(link);
                return;
            }
            this.setState({loading: false, save_error: response.error});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <ContentBox title="Ooops..." name="error">
                {this.state.error}
            </ContentBox>
        }
        return <ContentBox title="Create my account" name="verify-account">
            <Center>
                <div className="col-md-6">
                    <form onSubmit={(e) => this.handleVerify(e)}>
                        <IntroText name="enter-password">
                            Sweet, enter a password and you'll be done! Try to make it unique, and not just your
                            favourite drink ({Settings.getBrandDrink()} obvs)
                        </IntroText>
                        {this.state.save_error ? <Notice name="verify-error"
                                                         variant="error">{this.state.save_error}</Notice> : null}
                        <FormElementPassword name="password" value={this.state.password} placeholder="Password"
                                             onChange={(e) => this.setState({password: e.target.value})}/>
                        {this.state.additional ? <Fragment>
                            <FormElementText name="forename" placeholder="Forename" value={this.state.forename}
                                             onChange={(e) => this.setState({forename: e.target.value})}/>
                            <FormElementText name="surname" placeholder="Surname" value={this.state.surname}
                                             onChange={(e) => this.setState({surname: e.target.value})}/>
                        </Fragment> : null}
                        <Button text="Create Account" name="create-account"/>
                    </form>
                </div>
            </Center>
        </ContentBox>
    }
}

export default withRouter(Verify);