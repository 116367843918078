import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Api} from "../../../Api/Api";
import moment from "moment";
import {EventDispatcher} from "../../../Events/Dispatcher";
import {NotificationManager} from "../../../Notifications/NotifcationManager";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import Button from "../../../Form/Element/Button";
import IntroText from "../../../Utility/IntroText/IntroText";
import FormElementText from "../../../Form/Element/Text";
import FormElementDatePicker from "../../../Form/Element/DatePicker";
import FormElementTimeSelect from "../../../Form/Element/FormElementTimeSelect";
import Notice from "../../../Utility/Notice/Notice";
import Center from "../../../Utility/Center/Center";

export default function ReBook(props) {
    const [details, setDetails] = useState({
        people: props.booking.people,
        booking_date: props.booking.booking_date
    })
    const [availabilityError, setAvailabilityError] = useState(false);
    const [alternativeDates, setAlternativeDates] = useState([]);
    const [alternativeTimes, setAlternativeTimes] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        Api.post('rest/my-bookings/' + props.booking.id, {
            date: moment(details.booking_date).format('YYYY-MM-DD HH:mm'),
            people: details.people,
            rebook: 1
        }).then((response) => {
            if (response.ok) {
                let data = response.data;
                if (data.success === true) {
                    EventDispatcher.dispatch('booking-update');
                    NotificationManager.showSuccess('Booking has been updated!');
                    ModalHelper.closeModal();
                    return;
                }
                let dates = data.dates;
                let times = data.times;
                setLoading(false);
                setAvailabilityError(true);
                setAlternativeDates(dates);
                setAlternativeTimes(times);
                return;
            }
            NotificationManager.showError(response.error);
            setLoading(false);
        }).catch((error) => {
            NotificationManager.showError('Sorry, an error occurred. Please try again later');
            setLoading(false);
        });
    }
    const handleFieldChange = (e) => {
        setDetails({...details, [e.target.name]: e.target.value});
    }
    const handleDateChange = (e) => {
        if (e.target.name === 'date') {
            setDetails({
                ...details,
                booking_date: e.target.value + ' ' + moment(details.booking_date).format('HH:mm:ss')
            })
        }
        if (e.target.name === 'time') {
            setDetails({
                ...details,
                booking_date: moment(details.booking_date).format('YYYY-MM-DD') + ' ' + e.target.value + ':00'
            })
        }
    }

    const updateButtons = () => {
        const future = moment(details.booking_date).isAfter(moment());
        const same = props.booking.booking_date === details.booking_date;
        ModalHelper.setButtons(<Fragment>
            <Button text="Go Back" name="go-back" variant="outline" onClick={(e) => {
                ModalHelper.closeModal();
            }} disabled={!future}/>
            <Button text="Save Changes" name="save-changes" onClick={(e) => handleSave(e)}
                    disabled={loading || !future || same}/>
        </Fragment>);
    }
    useEffect(() => updateButtons());
    let bar = props.booking.bar.data;
    return <div className="edit-booking-details">
        <h2>Edit your booking</h2>
        <IntroText name="edit-booking">
            Re-book your postponed booking here. If there's anything else you want to change give the
            bar a ring on <a href={'tel:' + bar.phone}>{bar.phone}</a> or email <a
            href={'mailto:' + bar.party_booking_email}>{bar.party_booking_email}</a>.
        </IntroText>
        <form onSubmit={(e) => handleSave(e)} className="edit-booking-details__fields">
            <FormElementText name="people" onChange={(e) => handleFieldChange(e)}
                             value={details.people}
                             number={true} placeholder="How many people?"/>
            <FormElementDatePicker placeholder="Choose date" onChange={(e) => handleDateChange(e)}
                                   inline={true} name="date"
                                   value={moment(details.booking_date).format('YYYY-MM-DD')}/>
            <FormElementTimeSelect name="time" onChange={(e) => handleDateChange(e)}
                                   selectedValue={moment(details.booking_date).format('HH:mm')}/>
        </form>
        {availabilityError && alternativeTimes.length === 0 && alternativeDates.length === 0 ?
            <Notice name="no-availability" variant="error">Oh snap! Looks like we can't automatically confirm
                that
                change. We might be able to confirm this if you get in contact with the bar. You can do this by
                e-mailing <a
                    href={'mailto:' + bar.party_booking_email}>{bar.party_booking_email}</a></Notice> : null}

        {alternativeDates.length > 0 && alternativeTimes.length === 0 ? <Notice name="new-date">
            Looks like we are pretty full on that day. Do you want to try another date instead?

            {alternativeDates.map((date) => {
                return <Button text={date} name={date}
                               onClick={() => handleDateChange({
                                   target: {
                                       name: 'date',
                                       value: date
                                   }
                               })}/>
            })}
        </Notice> : null}

        {alternativeDates.length === 0 && alternativeTimes.length > 0 ?
            <Notice name="new-time">
                <Center>
                    Looks like we're pretty full at that time. Do any of these time suit?
                    <div>
                        {alternativeTimes.map((time) => {
                            return <Button text={time} variant="outline" name={time}
                                           onClick={() => handleDateChange({
                                               target: {
                                                   name: 'time',
                                                   value: time
                                               }
                                           })}/>
                        })}
                    </div>
                </Center>
            </Notice> : null}
    </div>
}

ReBook.propTypes = {
    booking: PropTypes.object.isRequired
}