import React, {Fragment} from 'react';
import {Settings} from "../../Settings/Settings";
import Notice from "../../Utility/Notice/Notice";
import './TermsText.scss';

export default function TermsText(props) {
    return <Fragment>
        {props.booking.terms ? <Notice name="covid-terms" variant="info">
            <div dangerouslySetInnerHTML={{__html: props.booking.terms}}/>
        </Notice> : null}
    </Fragment>
}