import React, {Component} from 'react';
import {Api} from "../../../Api/Api";
import Loading from "../../../Utility/Loading/Loading";
import PropTypes from 'prop-types';

class PaymentCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.order_id ? props.order_id : props.match.params.code,
            data: {},
            loading: true,
            error: false,
            attempts: 0
        }
    }

    componentDidMount() {
        this.checkPayment();
    }

    checkPayment() {
        this.setState({loading: true, attempts: this.state.attempts + 1});
        if (!this.state.code) {
            this.setState({error: true});
            return;
        }
        if (this.state.attempts >= 10) {
            this.props.onComplete({
                auth: false,
                id: 1,
                timeout: true,
                message: 'Request timed out - Please try again later'
            });
        }
        Api.get('rest/deposit/payment/check', {code: this.state.code}).then((response) => {
            if (response && response.ok) {
                let data = response.data.data;
                if (data.received_response === true) {
                    if (this.props.onComplete) {
                        this.props.onComplete(data);
                    }
                    this.setState({data: data, loading: false});
                    return;
                } else {
                    setTimeout(() => this.checkPayment(), 2000);
                }
            } else {
                this.setState({error: true, loading: false});
            }
        }).catch(() => {

        })
    }

    render() {
        if (this.state.loading) {
            return <div className="payment-check">
                <Loading/>
                Verifying Payment....
            </div>
        }
        return <div/>
    }
}

PaymentCheck.propTypes = {
    onComplete: PropTypes.func.isRequired
}
export default PaymentCheck;