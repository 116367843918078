import PropTypes from "prop-types";
import React from "react";
import './tooltip.scss';

const Tooltip = ({ title, placement, children }) => {
	let tooltipPlacement;
	switch (placement) {
		case 'left':
			tooltipPlacement = {
				left: '0',
				top: '50%',
				transform: 'translate(calc(-100% - 5px), -50%)'
			}
			break;
		case 'top':
			tooltipPlacement = {
				top: '0',
				left: '50%',
				transform: 'translate(-50%, calc(-100% - 5px))'
			}
			break;
		case 'right':
			tooltipPlacement = {
				right: '0',
				top: '50%',
				transform: 'translate(calc(100% + 5px), -50%)'
			}
			break;
		case 'bottom':
			tooltipPlacement = {
				bottom: '0',
				left: '50%',
				transform: 'translate(-50%, calc(100% + 5px))'
			}
			break;
	}

	return (
		<div className="tooltip">
			{children}
			<span className="tooltip--text" style={tooltipPlacement}>
				{title}
			</span>
		</div>
	)
};

Tooltip.propTypes = {
	placement: PropTypes.string, 
	title: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
		PropTypes.string,
	]),
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
	])
}

export default Tooltip;
