import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {BookingData} from "../../Data/BookingData";
import Button from "../../../Form/Element/Button";
import _ from 'lodash';
import FormElementSelect from "../../../Form/Element/Select";
import {Settings} from "../../../Settings/Settings";
import {EventDispatcher} from "../../../Events/Dispatcher";

export default class ItemChoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            selected: this.props.selected
        }
    }

    componentDidMount() {
        this.update_listener = EventDispatcher.subscribe('post_booking_update', (data) => {
            let variation = this.props.variation;
            let current_option = data.booking.variations.data.find((booking_var) => {
                if (booking_var.variation_id === variation.variation_id) {
                    return true;
                }
                return false;
            });
            this.setState({selected: current_option});
        })
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update_listener);
    }

    updateIndex(data) {
        let choices = _.extend([], this.state.selected.choices).map((choice) => {
            if (choice.index == data.index) {
                return data;
            }
            return choice;
        });
        BookingData.updateVariation(this.state.selected.variation_id, this.state.selected.quantity, choices);
    }

    chooseItem(index, choice_id, breakdown_id, item_id) {
        let choices = _.extend([], this.state.selected.choices).map((choice) => {
            if (choice.index == index && choice_id == choice.choice_id) {
                if (choice.items) {
                    choice.items = choice.items.map((item) => {
                        if (item.breakdown_id == breakdown_id) {
                            item.item_id = item_id;
                        }
                        return item;
                    });
                } else {
                    choice.items = [{breakdown_id: breakdown_id, item_id: item_id}];
                }
            }
            return choice;
        });
        BookingData.updateVariation(this.state.selected.variation_id, this.state.selected.quantity, choices);
    }

    getChoiceById(id) {
        return this.props.choices.find((choice) => {
            return choice.group_id == id;
        });
    }

    clearSelection(index) {
        let choices = _.extend([], this.state.selected.choices).map((choice) => {
            if (choice.index == index) {
                choice.items = [];
                choice.choice_id = this.props.choices.length === 1 ? this.props.choices[0].group_id : null;
            }
            return choice;
        });
        BookingData.updateVariation(this.state.selected.variation_id, this.state.selected.quantity, choices);
    }

    render() {
        return <Fragment>
            {this.state.selected.choices.map((choice) => {
                if (!choice.choice_id) {
                    return <div className="option-choices__choice" key={choice.index}>
                        <p>Item {choice.index}</p>
                        <div className="option-choices__button">{this.props.choices.map((group) => {
                            return <Button text={group.title} name="choice--choice" key={group.group_id}
                                           onClick={() => {
                                               let index = _.extend({}, choice);
                                               index.choice_id = group.group_id;
                                               index.items = [];
                                               this.updateIndex(index);
                                           }}/>
                        })}</div>
                        <Button text="Clear Section" name="clear-selection" variant="text"
                                onClick={() => this.clearSelection(choice.index)}/>
                    </div>
                }

                let group = this.getChoiceById(choice.choice_id);
                return <div className="option-choices__choice" key={choice.index}>
                    <p>Item {choice.index}</p>
                    {group.choices.map((group_choice) => {
                        let selected = '';
                        if (choice.items) {
                            choice.items.map((it) => {
                                if (it.breakdown_id == group_choice.id) {
                                    selected = it.item_id;
                                }
                            });
                        }
                        return <FormElementSelect name={"option_choice_" + group_choice.id} includeBlankOption={true}
                                                  blankOptionText={'- ' + group_choice.title + ' -'}
                                                  key={group_choice.id}
                                                  selectedValue={selected}
                                                  values={group_choice.items.map((item) => {
                                                      let title = item.title;
                                                      if (item.premium) {
                                                          title = title + ' (+' + Settings.currencyFormatter.format(item.premium) + ')';
                                                      }
                                                      return {
                                                          key: item.id,
                                                          value: title
                                                      }
                                                  })}
                                                  onChange={(e) => this.chooseItem(choice.index, choice.choice_id, group_choice.id, e.target.value)}/>
                    })}
                    <Button text="Clear Section" name="clear-selection" variant="text"
                            onClick={() => this.clearSelection(choice.index)}/>
                </div>
            })}
        </Fragment>
    }
}

ItemChoices.propTypes = {
    choices: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    variation: PropTypes.object.isRequired
}