import FormElementText from "../../Form/Element/Text";
import Button from "../../Form/Element/Button";
import React, {useCallback, useEffect, useState} from "react";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";
import Loading from "../../Utility/Loading/Loading";
import moment from "moment";

const EmailVerification = ({user, updateUser, student}) => {
    const [emailToVerify, setEmailToVerify] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [verificationSentDate, setVerificationSentDate] = useState(null);
    const [saving, setSaving] = useState(false);

    const updateEmailVerification = (id, email) => {
        Api.get('customers/email-verify-status', {id: id, email: email}).then((response) => {
            if (response.ok) {
                if (response.data.data.date_verified !== null) {
                    updateUser();
                    return;
                }
            }
            setTimeout(() => updateEmailVerification(id, email), 5000);
        })
    };
    const verifyEmail = useCallback(() => {
        setError('');
        setSaving(true);
        Api.postJson('customers/email-verify', {
            email_address: emailToVerify,
            rb_customer_id: user.customer_id,
            app_user_id: user.app_user_id,
            student: student
        }).then((response) => {
            if (response.ok) {
                updateUser();
                return;
            }
            setSaving(false);
            setError(response.error);
        });
    }, [user, emailToVerify]);
    useEffect(() => {
        const pending = user.pending_email_verifications.data.sort((email1, email2) => {
            return moment(email1.date_created).isAfter(moment(email2.date_created)) ? -1 : 1;
        }).find((email) => {
            return email.student === student;
        })
        if (pending) {
            setTimeout(() => updateEmailVerification(pending.id, pending.email_address), 5000);
            setEmailToVerify(pending.email_address)
            setVerificationSentDate(pending.date_created);
        }
        setLoading(false);
    }, [user]);
    if (loading) {
        return <Loading/>
    }

    let buttonText = student ? 'Verify my student e-mail address' : 'Verify my e-mail address';
    let buttonDisabled = saving;
    if (saving) {
        buttonText = "Please wait....';"
    }
    if (verificationSentDate) {
        if (moment(verificationSentDate).isAfter(moment().add(-2, 'minute'))) {
            buttonDisabled = true;
            buttonText = 'Check your e-mail!'
        } else {
            buttonText = 'Re-send my verification';
        }
    }
    return <div style={{margin: '20px'}}>
        <h1>Almost there</h1>
        {student ? <p>Please provide your university email address e.g. ac.uk.</p> :
            <p>Please provide a permanent email address e.g. gmail or outlook. Ac.uk or temporary email addresses cannot
                be accepted.</p>}
        <p>Please check your spam for the verification.</p>
        {error ? <Notice name="error">{error}</Notice> : null}
        <FormElementText name="uni_email" value={emailToVerify} onChange={(e) => setEmailToVerify(e.target.value)}
                         placeholder={student ? "Enter your .ac.uk e-mail address here" : 'Enter your personal e-mail address (not .ac.uk)'}
                         disabled={buttonDisabled}/>
        <Button text={buttonText} name="verify" variant="full-width" onClick={() => verifyEmail()}
                disabled={buttonDisabled}/>
    </div>
}

export default EmailVerification;