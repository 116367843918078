import React from 'react';
import PropTypes from "prop-types";
import './MenuItem.scss'
import _ from 'lodash'
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import Ingredients from "./Ingredients/Ingredients";

const MenuItem = ({menu, hasIntolerance, highlightedAllergens, mayContain, showCalories}) => {

    const showIngredients = () => {
        ModalHelper.openModal(<Ingredients title={menu.name} hasIntolerance={hasIntolerance}
                                           ingredients={menu.ingredients} highlightedAllergens={highlightedAllergens}/>)
    }

    const containsHTMLTags = (str) => {
        const regex = /<\/?[a-z][\s\S]*>/i;
        return regex.test(str);
    };
    return (
        <div className='menu-item-card'>
            <div className={`menu-item-card-banner ${mayContain ? 'intolerance' : ''}`}></div>
            <div className='menu-item'>
                <section>
                    <div className='title-group'>
                        <p className='menu-item-title'>
                            <strong>{menu.name}</strong>
                            {showCalories && menu.calories !== null &&
                                <span>&nbsp;{`- ${menu.calories > 0 ? Math.round(menu.calories) : menu.calories}kcal`}</span>}
                        </p>
                        <p className='menu-item-description'
                           dangerouslySetInnerHTML={{__html: containsHTMLTags(menu.description) ? menu.description : _.capitalize(menu.description)}}
                        />
                    </div>
                    <hr className='divider'/>
                    <div className='ingredients-group'>
                        <p className='menu-item-contains'>
                            <strong>Contains:&nbsp;</strong>
                            {!menu.contains.length > 0 ?
                                <em>No major allergens</em> :
                                menu.contains.map((allergen, idx) =>
                                    <em key={idx}>
                                        <span
                                            className={highlightedAllergens.some(item => item === allergen) ? 'highlighted' : ''}
                                        >
                                            {allergen}
                                        </span>
                                        {idx + 1 !== menu.contains.length && <span>,&nbsp;</span>}
                                    </em>
                                )}
                        </p>
                        {menu.may_contain.length > 0 &&
                            <p className='menu-item-may-contain'>
                                <strong>May Contain:&nbsp;</strong>
                                {menu.may_contain.map((allergen, idx) =>
                                    <em key={idx}>
                                         <span
                                             className={highlightedAllergens.some(item => item === allergen) ? 'highlighted' : ''}
                                         >
                                            {allergen}
                                        </span>
                                        {idx + 1 !== menu.may_contain.length && ', '}
                                    </em>
                                )}
                            </p>
                        }
                    </div>
                    <div className='dietary'>
                        {menu.dietary.includes('Vegetarian') &&
                            <img src='/assets/icons/vegetarian.svg' alt='Vegetarian' title='Vegetarian'/>}
                        {menu.dietary.includes('Vegan') &&
                            <img src='/assets/icons/vegan.svg' alt='Vegan' title='Vegan'/>}
                    </div>
                </section>

                <button
                    type='button'
                    className='menu-item-show-ingredients'
                    onClick={showIngredients}
                >
                    Show Ingredients
                </button>
            </div>
        </div>
    );
};

export default MenuItem;

MenuItem.propTypes = {
    menu: PropTypes.object.isRequired,
    hasIntolerance: PropTypes.bool.isRequired,
    highlightedAllergens: PropTypes.array.isRequired,
    showCalories: PropTypes.bool,
    mayContain: PropTypes.bool
}