import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from 'react-select'
import cx from 'classnames'
import { EventDispatcher } from '../../Events/Dispatcher'

class FormElementSelect extends Component {
    static defaultProps = {
        blankOptionText: ' - Select -',
        updateFromProps: false,
        tags: false,
    }

    constructor (props) {
        super(props)
        this.state = {
            id: this.props.id ? this.props.id : Math.random().toString(36).substring(7),
        }
        this.ref = React.createRef()
    }

    componentDidMount () {
        EventDispatcher.dispatch('form_element_initialise', {
            detail: {
                type: 'select',
                ref: this.ref.current,
            },
        })
    }

    componentWillUnmount () {
        EventDispatcher.dispatch('form_element_destroy', {
            detail: {
                type: 'select',
                ref: this.ref.current,
            },
        })
    }

    handleSelectChange (values) {
        if (this.props.multiple) {
            if (values === null) {
                this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: [],
                    },
                })
                return
            }
            let value = values.map(v => {
                return v.value
            })
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value,
                },
            })
            return
        }

        let value = ''
        if (values !== null) {
            value = values.value
        }
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value,
            },
        })
    }

    render () {
        var blankOption = null
        if (this.props.includeBlankOption === true) {
            blankOption =
              <option value="" disabled={this.props.blankOptionDisabled}>{this.props.blankOptionText}</option>
        }
        var className = 'field__element'
        if (this.props.className) {
            className = className + ' ' + this.props.className
        }
        var options = this.props.values
        if (!options) {
            options = []
        }
        options = _.uniq(options)
        var style = {}

        if (this.props.useSelect2) {
            const select2Styles = {
                control: (provided, state) => ({
                    ...provided,
                    border: '1px solid #E9E9E9',
                    height: '50px',
                    paddingLeft: '20px',
                }),
                option: (provided, state) => ({
                    ...provided,
                    paddingLeft: '20px',
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    paddingLeft: '0px',
                }),
            }
            var value = null
            let options = this.props.values.map((o) => {
                if (o.key == this.props.selectedValue) {
                    value = { value: o.key, label: o.value }
                }
                return { value: o.key, label: o.value }
            })
            return (
              <div className="select2-wrapper">
                  <Select styles={select2Styles}
                          value={value}
                          placeholder={this.props.blankOptionText}
                          isClearable={true}
                          onChange={e => this.handleSelectChange(e)}
                          options={options}
                          isDisabled={this.props.disabled}/>
              </div>
            )
        }

        var selectInput = <select name={this.props.name} id={this.state.id} value={this.props.selectedValue}
                                  onChange={this.props.onChange.bind(this)} className={className}
                                  multiple={this.props.multiple}
                                  style={style} disabled={this.props.disabled}>
            {blankOption}
            {options.map(function (option) {
                if (option.disabled) {
                    return <option value={option.key} key={option.key} disabled>{option.value}</option>
                }
                return <option value={option.key} key={option.key}>{option.value}</option>
            })}
        </select>
        if (this.props.group) {
            var group = this.props.group
            var optionList = {}
            var groups = []
            this.props.values.map(function (v) {
                var valueGroup = v[group]
                if (groups.indexOf(valueGroup) === -1) {
                    groups.push(valueGroup)
                    optionList[valueGroup] = {
                        options: [],
                        name: valueGroup,
                    }
                }
                optionList[valueGroup].options.push(v)
                return v
            })
            selectInput = <select name={this.props.name} id={this.state.id} value={this.props.selectedValue}
                                  onChange={this.props.onChange} className={className}
                                  placeholder={this.props.placeholder}
                                  multiple={this.props.multiple}
                                  style={style} disabled={this.props.disabled}>
                {blankOption}
                {groups.map(function (g) {
                    return <optgroup label={g}>
                        {optionList[g].options.map(function (option) {
                            if (option.disabled) {
                                return <option value={option.key} key={option.key} disabled>{option.value}</option>
                            }
                            return <option value={option.key} key={option.key}>{option.value}</option>
                        })}
                    </optgroup>
                })}
            </select>
        }
        if (this.props.append || this.props.prepend) {
            var prepend = <span/>,
              append = <span/>
            if (this.props.append) {
                append = <div className="input-group-addon">{this.props.append}</div>
            }
            if (this.props.prepend) {
                prepend = <div className="input-group-addon">{this.props.prepend}</div>
            }

            return <div className="input-group" ref={this.ref}>
                {prepend}
                {selectInput}
                {append}
            </div>
        }

        return <div ref={this.ref}
                    className={cx('field', 'field--select', 'field--' + this.props.name,
                      { 'field--error': this.props.error })}>
            <div className="field__element-wrap">
                {selectInput}
            </div>
            {this.props.error ? <div className="field__error">{this.props.error}</div> : null}
        </div>
    }
}

FormElementSelect.propTypes = {
    values: PropTypes.array,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    includeBlankOption: PropTypes.bool,
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
    ]),
    wrapperClass: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    useSelect2: PropTypes.bool,
    select2RemoteData: PropTypes.string,
    multiple: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    blankOptionText: PropTypes.string,
    updateFromProps: PropTypes.bool,
    tags: PropTypes.bool,
}

export default FormElementSelect