import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import IntroText from "../../../Utility/IntroText/IntroText";
import FormElementText from "../../../Form/Element/Text";
import FormElementDatePicker from "../../../Form/Element/DatePicker";
import moment from 'moment';
import FormElementTimeSelect from "../../../Form/Element/FormElementTimeSelect";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import Button from "../../../Form/Element/Button";
import './EditBookingDetails.scss';
import {Settings} from "../../../Settings/Settings";
import {Api} from "../../../Api/Api";
import {NotificationManager} from "../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../Events/Dispatcher";
import Notice from "../../../Utility/Notice/Notice";
import Center from "../../../Utility/Center/Center";

class EditBookingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booking: this.props.booking,
            original_people: this.props.booking.no_people,
            original_date: this.props.booking.date,
            loading: false,
            error: false,
            availability_error: false,
            show_alternative: false,
            times: [],
            dates: []
        }
    }

    componentDidMount() {
        this.updateButtons();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.state) !== JSON.stringify(prevState)) {
            this.updateButtons();
        }
    }

    updateButtons() {
        ModalHelper.setButtons(<Fragment>
            <Button text="Go Back" name="go-back" variant="outline" onClick={(e) => {
                ModalHelper.closeModal();
            }}/>
            {this.props.booking.can_cancel?<Button text="Save Changes" name="save-changes" onClick={(e) => this.handleSave(e)}
                    disabled={this.state.loading}/>:null}
        </Fragment>);
    }

    handleSave(e) {
        e.preventDefault();
        this.setState({loading: true});
        Api.post('rest/my-bookings/' + this.props.booking.id, {
            date: moment(this.state.booking.booking_date).format('YYYY-MM-DD HH:mm'),
            people: this.state.booking.people
        }).then((response) => {
            if (response.ok) {
                let data = response.data;
                if (data.success === true) {
                    EventDispatcher.dispatch('booking-update');
                    NotificationManager.showSuccess('Booking has been updated!');
                    ModalHelper.closeModal();
                    return;
                }
                let dates = data.dates;
                let times = data.times;
                this.setState({availability_error: true, dates: dates, times: times, loading: false});
                return;
            }
            NotificationManager.showError(response.error);
            this.setState({loading: false});
        }).catch((error) => {
            NotificationManager.showError('Sorry, an error occurred. Please try again later');
            this.setState({loading: false});
        });
    }

    handleFieldChange(e) {
        let booking = _.extend({}, this.state.booking);
        booking[e.target.name] = e.target.value;
        this.setState({booking: booking});
    }

    handleDateChange(e) {
        let booking_date = moment(this.state.booking.booking_date);
        this.handleFieldChange({
            target: {
                name: 'booking_date',
                value: e.target.value + ' ' + booking_date.format('HH:mm')
            }
        })
    }

    handleTimeChange(e) {
        let booking_date = moment(this.state.booking.booking_date);
        this.handleFieldChange({
            target: {
                name: 'booking_date',
                value: booking_date.format('YYYY-MM-DD') + ' ' + e.target.value
            }
        })
    }

    render() {
        let bar = this.props.booking.bar.data;
        if (this.props.booking.can_cancel === false) {
            return <div className="edit-booking-details">
                <Notice name="not-allowed" variant="error">
                    Sorry, you'll need to contact the bar to edit this booking. Please get in contact with them
                    on <a
                    href={'mailto:' + this.props.booking.bar.data.party_booking_email}>{this.props.booking.bar.data.party_booking_email}</a>
                </Notice>
            </div>
        }
        return <div className="edit-booking-details">
            <h2>Edit your booking</h2>
            <IntroText name="edit-booking">
                You can edit some of your booking details below. If there's anything else you want to change give the
                bar a ring on <a href={'tel:' + bar.phone}>{bar.phone}</a> or email <a
                href={'mailto:' + bar.party_booking_email}>{bar.party_booking_email}</a>.
                The {Settings.getBrandName()} team may need to review before confirming your changes.
            </IntroText>
            <form onSubmit={(e) => this.handleSave(e)} className="edit-booking-details__fields">
                <FormElementText name="people" onChange={(e) => this.handleFieldChange(e)}
                                 value={this.state.booking.people}
                                 number={true} placeholder="How many people?"/>
                <FormElementDatePicker placeholder="Choose date" onChange={(e) => this.handleDateChange(e)}
                                       inline={true} name="date"
                                       value={moment(this.state.booking.booking_date).format('YYYY-MM-DD')}/>
                <FormElementTimeSelect name="time" onChange={(e) => this.handleTimeChange(e)}
                                       selectedValue={moment(this.state.booking.booking_date).format('HH:mm')}/>
            </form>
            {this.state.availability_error ? <Fragment>
                {this.state.dates.length === 0 && this.state.times.length === 0 ?
                    <Notice name="no-availability" variant="error">Oh snap! Looks like we can't automatically confirm
                        that
                        change. We might be able to confirm this if you get in contact with the bar. You can do this by
                        e-mailing <a
                            href={'mailto:' + this.state.booking.bar.data.party_booking_email}>{this.state.booking.bar.data.party_booking_email}</a></Notice> : null}

                {this.state.dates.length > 0 && this.state.times.length === 0 ? <Notice name="new-date">
                    Looks like we are pretty full on that day. Do you want to try another date instead?

                    {this.state.dates.map((date) => {
                        return <Button text={date} name={date}
                                       onClick={() => this.handleDateChange(date)}/>
                    })}
                </Notice> : null}

                {this.state.dates.length === 0 && this.state.times.length > 0 ?
                    <Notice name="new-time">
                        <Center>
                            Looks like we're pretty full at that time. Do any of these time suit?
                            <div>
                                {this.state.times.map((time) => {
                                    return <Button text={time} variant="outline" name={time}
                                                   onClick={() => this.handleTimeChange({
                                                       target: {
                                                           name: 'time',
                                                           value: time
                                                       }
                                                   })}/>
                                })}
                            </div>
                        </Center>
                    </Notice> : null}
            </Fragment> : null}
        </div>
    }
}

export default EditBookingDetails;