import React, {Component, Fragment} from 'react';
import {ModalHelper} from "../../../../../Utility/Modal/AppModal";
import Button from "../../../../../Form/Element/Button";
import FormElementText from "../../../../../Form/Element/Text";
import _ from 'lodash';
import './AddGuest.scss';
import FormElementCheckbox from "../../../../../Form/Element/Checkbox";
import PropTypes from 'prop-types';
import IntroText from "../../../../../Utility/IntroText/IntroText";
import {Api} from "../../../../../Api/Api";
import {NotificationManager} from "../../../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../../../Events/Dispatcher";
import Notice from "../../../../../Utility/Notice/Notice";
import Center from "../../../../../Utility/Center/Center";

class AddGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allergen_open: false,
            error: null,
            saving: false,
            guest: {
                email: '',
                admin: false,
                package: '',
                allergens: []
            },
            field_errors: [],
            guest_limit: this.props.booking.guests.data.length >= this.props.booking.people
        }
    }

    componentDidMount() {
        this.updateButtons();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.saving !== prevState.saving) {
            this.updateButtons();
        }
    }

    handleSave(e) {
        e.preventDefault();
        this.setState({saving: true, field_errors: [], error: null});
        Api.post('rest/booking/' + this.props.booking.id + '/guest', this.state.guest).then((response) => {
            if (response.ok) {
                NotificationManager.showSuccess('Guest has been added to booking');
                EventDispatcher.dispatch('booking-update');
                this.setState({
                    guest_limit: response.data.data.guests.data.length >= response.data.data.people,
                    saving: false,
                    error: null,
                    guest: {
                        forename: '',
                        surname: '',
                        email: '',
                        phone: '',
                        admin: false,
                        package: '',
                        allergens: []
                    }
                });
                return;
            }
            this.setState({
                saving: false,
                error: response.error,
                field_errors: response.data.errors.fields ? response.data.errors.fields : []
            });
        })
    }

    handleFieldChange(e) {
        let guest = _.extend({}, this.state.guest);
        guest[e.target.name] = e.target.value;
        this.setState({guest: guest});
    }

    updateButtons() {
        ModalHelper.setButtons(<Fragment>
            <Button name="go-back" text="Go Back" onClick={() => ModalHelper.closeModal()} variant="outline"/>
            {this.state.guest_limit === false ?
                <Button text="Add Guest" name="add-guest" onClick={(e) => this.handleSave(e)}
                        disabled={this.state.saving}/> : null}
        </Fragment>);
    }

    getError(field) {
        let error = this.state.field_errors.find((err) => {
            if (err.field === field) {
                return err;
            }
        });

        if (error) {
            return error.error;
        }
        return null;
    }

    render() {
        return <div className="add-guest modal-scroll">
            <IntroText title="Add a Guest" name="add-guest"/>
            {this.state.error ? <Notice name="add-guest-error" variant="error">{this.state.error}</Notice> : null}
            {this.state.guest_limit === true ? <Fragment>
                <Center>
                    <img src="/assets/icons/happy-face.png" alt="All guests added!"/>
                    <Notice name="guests-reached">Awesome, you've added all of your guests!</Notice>
                </Center>
            </Fragment> : <Fragment>
                <form onSubmit={(e) => this.handleSave(e)}>
                    <div className="add-guest__fields">
                        <FormElementText name="email" placeholder="Email address" value={this.state.guest.email}
                                         onChange={(e) => this.handleFieldChange(e)} error={this.getError('email')}/>
                        <FormElementCheckbox onChange={(e) => this.handleFieldChange(e)} name="admin" label_after={true}
                                             label="Make this guest a co-host?" value={this.state.guest.admin}
                                             false_value={false} true_value={true}/>
                    </div>
                    {/*<div className="add-guest__allergens">*/}
                    {/*    {this.state.allergen_open === false ?*/}
                    {/*        <Button text="View Allergens / Other Dietaries" name="view-allergens"*/}
                    {/*                onClick={() => this.setState({allergen_open: true})}/>*/}
                    {/*        : <Fragment>*/}
                    {/*            <IntroText name="allergens">*/}
                    {/*                Check all of the allergens that apply*/}
                    {/*            </IntroText>*/}
                    {/*            <AllergenEdit allergens={this.state.guest.allergens}*/}
                    {/*                          handleAllergenChange={(allergens) => this.handleFieldChange({*/}
                    {/*                              target: {*/}
                    {/*                                  name: 'allergens',*/}
                    {/*                                  value: allergens*/}
                    {/*                              }*/}
                    {/*                          })}/>*/}
                    {/*        </Fragment>}*/}
                    {/*</div>*/}
                </form>
            </Fragment>}
        </div>
    }
}

AddGuest.propTypes = {
    booking: PropTypes.object.isRequired
}

export default AddGuest;