import React, {useEffect, useState} from 'react';
import {BookingData} from "../../Bookings/Data/BookingData";
import Loading from "../../Utility/Loading/Loading";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";
import Button from "../../Form/Element/Button";
import {Redirect} from "react-router-dom";

const JoinSocietyQr = (props) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    const [society, setSociety] = useState({});
    const [error, setError] = useState(null);
    const [completed, setComplete] = useState(false);
    useEffect(() => {
        BookingData.checkUser((data) => {
            if (data) {
                setUser(data);
            }
            setInitialLoad(true);
        });
    }, []);

    const doJoinSociety = (e) => {
        e.preventDefault();
        setLoading(true);
        Api.post('customers/societies/society/qr/' + props.match.params.code, {}).then((response) => {
            setLoading(false);
            if (response.ok) {
                setComplete(true);
                return;
            }
            setError(response.error);
        }).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        if (initialLoad === true) {
            Api.get('customers/societies/society/qr/' + props.match.params.code, {}).then((response) => {
                setLoading(false);
                if (response.ok) {
                    setSociety(response.data.data);
                    return;
                }
                setError(response.error);
            }).catch((e) => {
                console.log(e);
            });
        }
    }, [initialLoad])
    if (loading) {
        return <Loading/>
    }
    if (completed) {
        return <Redirect to="/society"/>
    }
    if (user) {
        return <div>
            <h1>Boom!</h1>
            {error ? <Notice name="society-404" variant="error">{error}</Notice> : null}
            <p>You are just about ready to join <strong>{society.name}</strong>.</p>
            {user.type.data.id ?
                <Notice variant="error" name="already-joined">As you are already a member of SOMESOCIETY. You will be
                    removed from this type</Notice> : null}

            <Button text="Get me in!" name="join-society" onClick={(e) => doJoinSociety(e)}/>
            <p>
                <pre>{JSON.stringify(user, null, 2)}</pre>
            </p>
        </div>
    }
    if (error) {
        return <div>
            <Notice name="society-404" variant="error">{error}</Notice>
        </div>
    }
    return <div>
        <h1>Boom!</h1>
        <p>You are just about ready to join <strong>{society.name}</strong>. If you've already signed up to revs then
            click login, else register away!</p>
        <Button text="Register" name="register" variant="full-width" onClick={(e) => {
            document.location.href = '/register';
        }}/>
        <Button text="Login" name="login" variant="full-width" onClick={(e) => {
            document.location.href = '/login';
        }}/>
    </div>
}

export default JoinSocietyQr;