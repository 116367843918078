import React, {Fragment} from 'react'
import Button from '../../../../../Form/Element/Button'
import DeleteConfirm from '../../../../../Form/Element/DeleteConfirm'
import {ModalHelper} from '../../../../../Utility/Modal/AppModal'
import AddEditGuest from './AddEditGuest'
import './PreOrder.scss'
import {Settings} from '../../../../../Settings/Settings'
import AddEditSingleOption from './AddEditSingleOption'
import Notice from '../../../../../Utility/Notice/Notice'
import {Api} from '../../../../../Api/Api'
import {EventDispatcher} from '../../../../../Events/Dispatcher'
import ShareLink from '../../../../../Utility/ShareLink/ShareLink'
import Tooltip from '../../../../../Utility/Tooltip';
import ConfirmDeleteGuest from "./ConfirmDeleteGuest";

export default function PreOrder (props) {
    const addGuest = (e, o) => {
        e.preventDefault()
        ModalHelper.openModal(<AddEditGuest
          token={props.token} choices={o.choices}
          variation_id={o.variation_id}
          guest={{
              forename: '',
              surname: '',
              choices: [],
              id: null,
          }}/>, 'full-page')
    }

    const handleDelete = id => {
        Api.delete('rest/my-bookings/token/' + props.token + '/guest/' + id).then(() => {
            EventDispatcher.dispatch('booking-update')
            ModalHelper.closeModal()
        })
    }

    const options = props.booking.pre_order_state.per_person_options.filter((o) => {
        return o.customer_pre_order_required
    })

    const hasSelectableOptions = opt => {
        var canSelect = false;
        opt.grouped_choices.forEach((choice) => {
            choice.items.forEach((item) => {
                if (item.selectable) {
                    canSelect = true;
                }
            })
        })
        return canSelect;
    }

    const optionHasSelectableChoices = option => {
        var canSelectChoices = false;
        option.available_choices.forEach((choice) => {
            choice.choices.forEach((c) => {
                c.items.forEach((i) => {
                    if (i.selectable) {
                        canSelectChoices = true;
                    }
                })
            })
        })
        return canSelectChoices;
    }

    const isPluSelectable = plu => {
        var canSelect = false;
        props.booking.pre_order_state.single_booking_options.forEach((opt) => {
            opt.grouped_choices.forEach((choice) => {
                choice.items.forEach((item) => {
                    if (item.plu.toString() === plu.toString() && item.selectable) {
                        canSelect = true;
                    }
                })
            })
        })
        return canSelect;
    }

    const hasMultiplePerPersonOrders = options.length > 1
    return <div className="pre-order">
        {props.booking.pre_order_locked ?
          <Notice name="pre-order-locked"><i className="fa fa-info"/> The pre order for this booking is now locked.
              Please contact the bar
              if there are any changes that need to be made.</Notice> : null}
        {props.booking.allow_invite && !hasMultiplePerPersonOrders && <div className="booking-invite-code">
            <Notice name="invite">Invite all of your mates or work colleagues along! Simply give them this link so they
                can select their own
                pre order choices!
                <ShareLink link={props.booking.invite_link} show={true}/>
            </Notice>
        </div>}
        {props.booking.allow_invite && hasMultiplePerPersonOrders && <div className="booking-invite-code">
            <Notice name="invite">Invite all your mates or work colleagues along! Simply click the invite link from the
                option they're having below and they'll be able select their own pre order choices!
            </Notice>
        </div>}
        {props.booking.pre_order_state.per_person_options.map(function (o) {
            let hideItems = []
            o.choices.map((c) => {
                c.choices.map((oc) => {
                    oc.items.map((i) => {
                        if (i.selectable === false) {
                            hideItems.push(c.group_id + '_' + oc.id + '_' + i.plu)
                        }
                    })
                })
            })
            if (o.customer_pre_order_required === false) {
                return <div key={o.variation_id} className="pre-order--option">
                    <div className="pre-order--option--title clearfix">
                        <h2>{o.name}</h2>
                    </div>
                    <Notice name="manual_pre_order_required">To make your selections for this option please contact
                        the bar.</Notice>
                </div>

            }
            return <div key={o.variation_id} className="pre-order--option">
                <div className="pre-order--option--title clearfix">
                    {props.booking.agency_name === null ?
                        <h2>{o.quantity ? o.quantity : props.booking.people} x {o.name} @ {Settings.currencyFormatter.format(o.price)}</h2> :
                        <h2>{o.name}</h2>}
                    {o.guest_added < o.guest_required && !props.booking.pre_order_locked &&
                        <Tooltip placement='bottom' title='Add Guest'>
                             <Button name="add-guest" variant="circular" onClick={(e) => addGuest(e, o)}
                              text={<Fragment><i className="fa fa-plus"/></Fragment>}/>
                        </Tooltip>
                     }
                </div>
                <p className="pre-order--option--status">
                    You have added <strong>{o.guest_added}</strong> out of
                    your <strong>{o.guest_required}</strong> guests for this option.
                    {o.guest_added < o.guest_required && !props.booking.pre_order_locked ?
                      <a href="#" className="add-another" onClick={(e) => addGuest(e, o)}>Add Another</a> : null}
                </p>
                {o.guests.map(function (g) {
                    let id = g.id
                    let is_bespoke_menu = false;
                    return <div key={g.id} className="pre-order--guest-row">
                        <div className="pre-order--guest-row--name-choices">
                            <div className="name">
                                {g.forename || g.surname ? <Fragment>
                                    {g.forename} {g.surname}
                                </Fragment> : <div className="no-name">
                                    No Name
                                </div>}
                            </div>
                            <div className="choices">
                                {g.choices.map(function (c) {
                                    let key = c.choice_id + '_' + c.choice_item_id + '_' + c.plu
                                    if (hideItems.indexOf(key) > -1) {
                                        return
                                    }
                                    let title = c.plu_name
                                    let alternative_description = null;
                                    let premium_description = null;
                                    if (c.alternative_description !== undefined && c.alternative_description !== null && c.alternative_description.trim() !== '') {
                                        is_bespoke_menu = true;
                                        alternative_description = <strong> ({c.alternative_description})</strong>;
                                    }
                                    if (c.premium) {
                                        premium_description = <span> (+{Settings.currencyFormatter.format(c.premium)})</span>
                                    }
                                    return <p key={c.choice_item_id}>{title}{alternative_description}{premium_description}</p>
                                })}
                            </div>
                        </div>
                        <div className="pre-order--guest-row--buttons">
                            {!props.booking.pre_order_locked &&
                              <>
                                  {is_bespoke_menu === false &&
                                  <Button text="Edit" name="edit-pre-order" onClick={() => {
                                      ModalHelper.openModal(<AddEditGuest
                                        choices={o.choices} token={props.token}
                                        guest={g}
                                        variation_id={o.variation_id}/>, 'full-page')
                                  }}/>}
                                  <Button
                                      text={<><i className="fa fa-trash-o"/> Remove</>}
                                      class="btn btn--remove-guest"
                                      variant="outline"
                                      name="remove-guest"
                                      onClick={() => {
                                          ModalHelper.openModal(<ConfirmDeleteGuest is_bespoke_menu={is_bespoke_menu} confirmAction={() => handleDelete(id)} />, 'add-edit-options')
                                      }}
                                  />
                              </>}
                        </div>
                        {is_bespoke_menu === true && <div className="notice notice--info-text">
                            <small>
                                <span className="fa fa-info-circle" /> This guest has a bespoke menu arrangement. To make changes to this please contact our sales team
                            </small>
                        </div>}
                    </div>
                })}
                {(o.guest_added < o.guest_required && !props.booking.pre_order_locked  && hasMultiplePerPersonOrders) && <ShareLink text="Share invite link" link={props.booking.invite_link + '/' + o.variation_id}/> }
            </div>
        })}
        {props.booking.pre_order_state.single_booking_options.length > 0 ? <div className="pre-order--single-options">
            {props.booking.pre_order_state.single_booking_options.map((opt, index) => {
                let canEditChoices = false;
                if (opt.available_choices) {
                    opt.available_choices.forEach((choice_group) => {
                        choice_group.choices.forEach((choice) => {
                            if (choice.items.length > 1) {
                                canEditChoices = true;
                            }
                        })
                    })
                }
                return hasSelectableOptions(opt) && <div className="pre-order--option" key={opt.variation_id + '-' + opt.name + '-' + + index}>
                    <div className="pre-order--option--title clearfix">
                        {props.booking.agency_name === null ?
                            <h2>{opt.quantity} x {opt.name} @ {Settings.currencyFormatter.format(opt.price)}</h2> :
                            <h2>{opt.name}</h2>}

                    </div>
                    {canEditChoices && <Fragment>
                    <p className="pre-order--option--status">
                        You have chosen <strong>{opt.completed}</strong> of <strong>{opt.required}</strong> required
                        options.
                    </p>
                    {opt.grouped_options.map((choice) => {
                        let qty = choice.quantity * choice.multiplier;
                        return isPluSelectable(choice.plu) && <div className="pre-order--option--single-choice" key={choice.name}>
                            <p>{qty} x {choice.name} {choice.premium > 0 ?
                              <Fragment>(+{Settings.currencyFormatter.format(choice.premium)})</Fragment> : null}</p>
                        </div>
                    })}
                    {props.booking.pre_order_locked === false && canEditChoices && optionHasSelectableChoices(opt) ?
                      <Button text="Pick Options" name="pick-options" onClick={() => {
                          ModalHelper.openModal(<AddEditSingleOption option={opt} token={props.token}/>, 'add-edit-options')
                      }}/> : null}
                    </Fragment>}
                </div>
            })}
        </div> : null}
    </div>
}