import React, {Fragment} from 'react';
import moment from "moment";
import {Settings} from "../../../Settings/Settings";
import Center from "../../../Utility/Center/Center";
import Button from "../../../Form/Element/Button";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import ChangeDate from "../ChangeDate/ChangeDate";

export default function Overview(props) {
    let date = moment(props.booking.booking_date);
    let show_date = true;
    if (date.format('MM') === '12' && date.format('DD') === '25') {
        show_date = false;
    }
    return <Fragment>
        <div className="booking-overview">
            <span className="booking-overview__name">{props.booking.party_name}</span>
            <span className="booking-overview__ref">REF: {props.booking.ref_code}</span>
            <span
                className={'booking-overview__status booking-overview__status-' + props.booking.status_id}>{props.booking.status}</span>
            <div className="booking-info grid">
                {show_date ? <div className="grid__item booking-info__item booking-info--date">
                    <span>{moment(props.booking.booking_date).format(Settings.booking_date_format)}</span>
                </div> : null}
                <div className=" grid__item booking-info__item booking-info--people">
                    <span>{props.booking.people} Guests</span>
                </div>
                <div className="grid__item booking-info__item booking-info--bar">
                    <span>{props.booking.bar.data.bar_name}</span>
                </div>
                <div className="grid__item booking-info--balance booking-info__item">
                    <span>{Settings.currencyFormatter.format(props.booking.balance)}</span>
                </div>
            </div>
            {props.booking.status_id === 5 ?
                <Center>
                    <Button text="Choose a new date" name="edit-booking-details" onClick={() => {
                        ModalHelper.openModal(<ChangeDate booking={props.booking}/>, 'full-page');
                    }}/>
                </Center> : null}
        </div>
    </Fragment>
}