import React, {Component} from 'react';
import IntroText from "../../Utility/IntroText/IntroText";
import {Settings} from "../../Settings/Settings";
import FormElementText from "../../Form/Element/Text";
import _ from 'lodash';

export default class CovidCheckin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: '',
                bar: '',
                forename: '',
                surname: '',
                marketing_opt_in: false,
                sms_opt_in: false,
                phone_opt_in: false
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    handleFieldChange(e) {
        let user = _.extend({}, this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    render() {
        return <div>
            <h2>Covid-19 Check-in</h2>
            <IntroText name="covid-intro">Want to enjoy a nice cool {Settings.getBrandDrink()} at our Bars? Want to do
                your bit in
                containing the Coronavirus? Simply add your details below and "check-in".</IntroText>

            <form onSubmit={(e) => this.handleSubmit(e)}>
                <FormElementText name="forename" value={this.state.user.forename} placeholder="Forename"
                                 onChange={(e) => this.handleFieldChange(e)}/>
                <FormElementText name="surname" value={this.state.user.surname} placeholder="Surname"
                                 onChange={(e) => this.handleFieldChange(e)}/>
                <FormElementText name="email" email={true} value={this.state.user.surname} placeholder="E-mail Address"
                                 onChange={(e) => this.handleFieldChange(e)}/>
            </form>
        </div>
    }
}