import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './ContentBox.scss';
import cx from 'classnames';
import Loading from "../Loading/Loading";
import {Link} from "react-router-dom";

export default function ContentBox(props) {
    let additional = null;
    if (props.name) {
        additional = 'content-box--' + props.name;
    }
    return <Fragment>
        <div className={cx('content-box', props.className, {'has-title': !!props.title}, additional)}>
            {props.title ? <div className="content-box-title">
                {props.backFunc ?
                    <button className="back-button" onClick={(e) => props.backFunc(e)}><i className="fa fa-arrow-left"/>
                    </button> : null}
                {props.back_url?<Link to={props.back_url} className="back-button"><i className="fa fa-arrow-left"/></Link>:null}
                {props.title}
            </div> : null}
            {props.loading === true ? <div className="content-box-loading"><Loading/></div> : null}
            {props.children}
        </div>
    </Fragment>
}

ContentBox.propTypes = {
    loading: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    backFunc: PropTypes.func,
    back_url: PropTypes.string,
    name: PropTypes.string
}