import React, {Component} from 'react';
import {Api} from "../../../Api/Api";
import Loading from "../../../Utility/Loading/Loading";
import $ from 'jquery';
import {EventDispatcher} from "../../../Events/Dispatcher";
import {Settings} from "../../../Settings/Settings";
import PaymentCheck from "./PaymentCheck";
import PropTypes from 'prop-types';
import './Payment.scss';


class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            show_loading: false,
            height: '300px',
            width: '100%',
            order_id: null,
            check: false
        }
        this.mounted = false;
        this.payment_check_interval = null;
        this.timeout_check = null;
    }

    componentDidMount() {
        this.mounted = true;
        let code = this.props.code ? this.props.code : null;
        Api.post('rest/deposit/manage/create-request/' + code, {
            amount: this.props.amount,
            deposit_request_token: this.props.deposit_request_token,
            url: Settings.getPaymentResponseUrl()
        }).then((response) => {
            if (response.ok) {
                this.initPayment(response.data.data.gp.data);
            }
        })
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.payment_check_interval) {
            clearInterval(this.payment_check_interval);
        }
        if (this.timeout_check) {
            clearTimeout(this.timeout_check);
        }
    }

    initPayment(data) {
        window.RealexHpp.setHppUrl(Settings.payments.getHppUrl());
        let payment_data = JSON.parse(data.serialised);
        this.setState({order_id: data.order_id, loading: false, data: payment_data}, () => {
            this.payment_check_interval = setInterval(() => this.checkPayment(), 4000);
            window.RealexHpp.embedded.init('pay_now', 'payment_frame', '', payment_data);
            EventDispatcher.dispatch('update_height');
            if (window.addEventListener) {
                window.addEventListener('message', (event) => {
                    this.processMessage(event.data);
                }, false);
            } else {
                window.attachEvent('message', (event) => {
                    this.processMessage(event.data);
                });
            }
            $('#pay_now').click();
        });
    }

    checkPayment() {
        Api.get('rest/deposit/payment/check', {code: this.state.order_id}).then((response) => {
            if (response && response.ok) {
                let data = response.data.data;
                if (data.received_response === true) {
                    if (this.props.onComplete) {
                        this.props.onComplete(data);
                    }
                    clearInterval(this.payment_check_interval);
                }
            } else {
                this.setState({error: true, loading: false});
            }
        }).catch(() => {

        })
    }

    processMessage(data) {
        try {
            let json = JSON.parse(data);
            if (json.iframe) {
                let height = parseInt(json.iframe.height.replace('px', ''));
                if (height <= 70) {
                    this.setState({check: true});
                } else {
                    this.setState({show_loading: false});
                    if (this.mounted) {
                        this.setState({height: json.iframe.height, width: json.iframe.width}, () => {
                            EventDispatcher.dispatch('update_height');
                        });
                    }
                }
            }
        } catch (e) {

        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.check) {
            return <PaymentCheck onComplete={(data) => {
                if (this.props.onComplete) {
                    this.props.onComplete(data);
                }
            }} order_id={this.state.order_id}/>
        }
        return <div>
            <button id="pay_now" style={{display: 'none'}}>Pay Now</button>
            <iframe id="payment_frame" title="Payment" referrerPolicy="origin" style={{
                height: this.state.height,
                width: this.state.width,
                border: 0,
                minHeight: this.state.height
            }}/>
        </div>
    }
}

Payment.propTypes = {
    onComplete: PropTypes.func,
    code: PropTypes.string.isRequired,
    deposit_request_token: PropTypes.string
}

export default Payment;