import React, {Fragment} from 'react';
import Notice from "../../../Utility/Notice/Notice";
import {Settings} from "../../../Settings/Settings";
import moment from 'moment';
import './ShortSummary.scss';
import PropTypes from 'prop-types';
import Button from "../../../Form/Element/Button";
import {BookingData} from "../../Data/BookingData";

export default function ShortSummary(props) {
    let diff = null;
    let string = '';
    if (props.show_end_time === true) {
        diff = moment(props.booking.end_time).diff(props.booking.booking_date, 'minutes');
        if (diff > 60) {
            let hours = Math.floor(diff / 60);
            string = hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
            diff = diff - (hours * 60);
        }
        if (diff > 0) {
            string = string + diff + ' minutes';
        }
    }
    return <>
        <Notice name={"booking-summary-short " + props.additional_class} variant="muted">
            <Fragment>
                <p>{props.booking.party_type_name} - {props.booking.bar.data.branded_name}</p>
                <p>{props.booking.no_people} People
                    - {moment(props.booking.date + ' ' + props.booking.time).format(Settings.date_formats.summary_full_date_time)}</p>
                {props.show_end_time ? <p>Your booking is for {string}</p> : null}
                {(
                    (props.booking.show_standing_message) ||
                    (props.booking.selected_tables.data && props.booking.selected_tables.data.length > 1)
                ) && <p>
                    {(props.booking.show_standing_message) &&
                        <span>Your reserved area will be a mix of seated and standing space.</span>}
                    {(
                        (props.booking.show_standing_message) &&
                        (props.booking.selected_tables.data && props.booking.selected_tables.data.length > 1)
                    ) && <span>{' '}</span>}
                    {(props.booking.selected_tables.data && props.booking.selected_tables.data.length > 1) &&
                        <span>To squeeze you in comfortably we've reserved multiple tables for you- these will be next to each other but may not be movable!</span>}
                </p>}
                <Button text="Change" name="change-details" onClick={(e) => {
                    BookingData.update({step: 1});
                }} variant="text"/>
            </Fragment>
        </Notice>
    </>
}

ShortSummary.propTypes = {
    booking: PropTypes.object.isRequired,
    additional_class: PropTypes.string,
    show_end_time: PropTypes.bool
}