import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormElementSelect from "../../../../../Form/Element/Select";
import FormElementCheckbox from "../../../../../Form/Element/Checkbox";
import Center from "../../../../../Utility/Center/Center";
import Button from "../../../../../Form/Element/Button";
import {Api} from "../../../../../Api/Api";
import {NotificationManager} from "../../../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../../../Events/Dispatcher";

export default class EditGuestDetails extends Component {
    constructor(props) {
        super(props);
        let guest = this.props.guest;
        this.state = {
            variation: guest.variations.data.length ? guest.variations.data[0].id : null,
            admin: guest.admin
        }
    }

    handleSave(e) {
        e.preventDefault();
        Api.put('rest/booking/' + this.props.booking.id + '/guest/' + this.props.guest.id, {
            variation: this.state.variation,
            admin: this.state.admin
        }).then((response) => {
            if (response.ok) {
                this.props.goBack();
                NotificationManager.showSuccess('Guest has been updated');
                EventDispatcher.dispatch('booking-update');
            }
        })
    }

    render() {
        return <div className="edit-guest-details">
            {this.props.booking.has_assignable_package ?
                <FormElementSelect name="variation" selectedValue={this.state.variation ? this.state.variation : ''}
                                   onChange={(e) => {
                                       this.setState({variation: e.target.value})
                                   }} values={this.props.booking.options.data.map((opt) => {
                    return {
                        key: opt.variation_id,
                        value: opt.title
                    }
                })} includeBlankOption={true} blankOptionText="- No Option -"/> : null}
            <FormElementCheckbox onChange={(e) => this.setState({admin: e.target.value})} name="admin"
                                 label_after={true} label="Make this guest a co-host" value={this.state.admin}/>

            <Center>
                <Button text="Save" name="save-details" onClick={(e) => this.handleSave(e)}/>
                <Button text="Cancel" name="go-back" onClick={() => this.props.goBack()} variant="outline"/>
            </Center>
        </div>
    }
}

EditGuestDetails.propTypes = {
    guest: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired
};
