import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {EventDispatcher} from "../../Events/Dispatcher";

class FormElementText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            if (this.props.defferredUpdate !== true || this.props.update_on_props === true) {
                this.setState({value: nextProps.value});
            }
        }
    }

    static defaultProps = {
        defferredUpdate: false
    }

    onChange(e) {
        this.setState({value: e.target.value});
        if (this.props.defferredUpdate !== true) {
            this.props.onChange({
                target: {
                    name: e.target.name,
                    value: e.target.value
                }
            });
        }
    }


    componentDidMount() {
        EventDispatcher.dispatch('form_element_initialise', {
            detail: {
                type: 'text',
                ref: this.ref.current
            }
        });
    }

    componentWillUnmount() {
        EventDispatcher.dispatch('form_element_destroy', {
            detail: {
                type: 'text',
                ref: this.ref.current
            }
        });
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
            return;
        }
        if (this.props.defferredUpdate === true) {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        var className = this.props.className;
        if (!className) {
            className = 'field__element';
        }
        var id = this.props.id;
        if (!id) {
            id = this.props.name;
        }
        var type = 'text';
        var pattern = null;
        if (this.props.number === true) {
            type = 'number';
            pattern = '[0-9]*';
        }
        if (this.props.email === true) {
            type = 'email';
        }
        var inputMode = 'text';
        if (this.props.inputMode) {
            inputMode = this.props.inputMode
        }
        return <div ref={this.ref}
                    className={cx('field', 'field--text', 'field--' + this.props.name, this.props.wrapperClass, {'field--error': this.props.error})}>
            <div className="field__element-wrap">
                <input type={type} name={this.props.name} id={id}
                       value={this.state.value}
                       pattern={pattern}
                       className={className} onChange={e => this.onChange(e)}
                       disabled={this.props.disabled}
                       onBlur={e => this.onBlur(e)}
                       onClick={this.props.onClick}
                       placeholder={this.props.placeholder}
                       readOnly={this.props.read_only}
                       onFocus={this.props.onFocus} maxLength={this.props.maxLength}
                       max={this.props.max}
                       min={this.props.min}
                       autoComplete={this.props.autoComplete}
                       onKeyDown={this.props.onKeyDown}
                       inputMode={inputMode}
                />
                {this.props.append}
            </div>
            {this.props.error ? <div className="field__error">{this.props.error}</div> : null}
        </div>
    }
}

FormElementText.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    error: PropTypes.string,
    wrapperClass: PropTypes.string,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    tabIndex: PropTypes.number,
    email: PropTypes.bool,
    update_on_props: PropTypes.bool,
    read_only: PropTypes.bool,
    onKeyDown: PropTypes.func,
};

export default FormElementText;