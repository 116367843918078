import React, {Component, Fragment} from 'react';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";
import Notice from "../Utility/Notice/Notice";
import {EventDispatcher} from "../Events/Dispatcher";
import moment from 'moment';
import Button from "../Form/Element/Button";
import './ClubSocRegistration.scss';
import {ModalHelper} from "../Utility/Modal/AppModal";
import JoinSocietyCode from "./Society/JoinSocietyCode";

export default class ClubSocRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user_data: {},
            load_error: null,
            register_type: 'card',
            success_message: null
        }
    }

    componentDidMount() {
        this.updateUser();
        document.title = 'Society Registration';
        EventDispatcher.subscribe('reload-user', () => this.updateUser());
        EventDispatcher.subscribe('success-message', (message) => this.setState({success_message: message}));
    }

    updateUser() {
        let current_user = this.state.user_data;
        Api.get('customers/app-user/status/' + this.props.user_id).then((response) => {
            if (response.ok) {
                this.setState({user_data: response.data.data, loading: false});
            } else {
                if (response.status === 404) {
                    this.setState({load_error: response.error, loading: false});
                }
            }
        });
    }

    handleLeave(e) {
        e.preventDefault();
        let customer = this.state.user_data.customer.data;
        let reg = customer.active_type.data;
        let type = reg.type.data;
        let base_type = type.base_type.data;
        ModalHelper.openModal(<Fragment>
            <Notice name="remove" variant="error">
                Are you sure you want to leave {type.title}? Any exclusive offers will stop immediately and anything you spend will
                not contribute to their target (where applicable). To re-join this or any other society you will need a
                valid signup code.</Notice>
        </Fragment>, '', <Fragment>
            <Button variant="outline" name="cancel" text="Cancel" onClick={() => {
                ModalHelper.closeModal();
            }}>Cancel</Button>
            <Button name="confirm" text="Yes" onClick={() => {
                this.handleLeaveConfirm();
            }}/>
        </Fragment>);
    }

    handleLeaveConfirm() {
        Api.post('customers/app-user/society/leave').then((response) => {
            this.updateUser();
            ModalHelper.closeModal();
        });
    }


    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.load_error) {
            return <div>
                <Notice variant="error">{this.state.load_error}</Notice>
            </div>
        }
        let customer = this.state.user_data.customer.data;
        if (customer.active_type.data.id) {
            let reg = customer.active_type.data;
            let type = reg.type.data;
            let base_type = type.base_type.data;
            let message = base_type.registered_message;
            message = message.replace('{type}', type.title);
            message = message.replace('{registration_date}', moment(reg.date_registered).format('DD/MM/YYYY'));

            let leave_message = base_type.leave_message;
            leave_message = leave_message.replace('{type}', type.title);
            leave_message = leave_message.replace('{registration_date}', moment(reg.date_registered).format('DD/MM/YYYY'));
            return <div className="already-registered">
                <Notice variant="success" name="registration-message">{message}</Notice>
                {base_type.can_leave === true ? <Fragment>
                    <p>{leave_message}</p>
                    <div className="btn-holder">
                        <Button variant="outline" name="leave" text={<Fragment>Leave {type.title}</Fragment>}
                                onClick={(e) => this.handleLeave(e)}/>
                    </div>
                </Fragment> : null}
            </div>
        }
        if (this.state.register_type === 'card') {
            return <div>
                <JoinSocietyCode/>
            </div>
        }

        return <div>
            Info here
        </div>
    }
}