import React, {Component} from 'react';
import {Settings} from "../../Settings/Settings";

export default class LiveChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        return;
        var script = document.createElement('script');
        if (Settings.brand === 'decuba') {

            window.InitialiseC4A = () => {
                /* Chat Tool */
                var Tool2 = new window.C4A.Tools(2);
                window.C4A.Run('e62a14b5-783e-45e0-9a93-2003152a9a55');
            };
        } else if (Settings.brand === 'revolution') {
            window.InitialiseC4A = () => {
                /* Chat Tool */
                var Tool2 = new window.C4A.Tools(1);
                window.C4A.Run('e62a14b5-783e-45e0-9a93-2003152a9a55');
            };
        }
        script.src = "https://v4in1-si.click4assistance.co.uk/SI.js";
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    render() {
        if (this.state.loading) {

        }
        return <div>
        </div>
    }
}