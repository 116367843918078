import React, {Fragment, useState} from 'react';
import FormElementText from "../../Form/Element/Text";
import {BookingData} from "../Data/BookingData";
import ChooseCallback from "./Callback/ChooseCallback";
import OptIns from "./OptIn/OptIns";
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormElementTimeSelect from "../../Form/Element/FormElementTimeSelect";
import FormElementCheckbox from '../../Form/Element/Checkbox'
import Notice from '../../Utility/Notice/Notice'
import { Settings } from '../../Settings/Settings'

export default function CorporateUserData(props) {
    const [has_data, setData] = useState({
        forename: !!props.booking.forename,
        surname: !!props.booking.surname,
        email: !!props.booking.email,
        phone_number: !!props.booking.phone,
        company_name: !!props.booking.phone,
        preferred_time: !!props.booking.phone,
    });

    const updateField = (field, value) => {
        let data = _.extend({}, has_data);
        data[field] = true;
        let update_data = {};
        update_data[field] = value;
        setData(data);
        BookingData.update(update_data);
    }
    const getError = (field) => {
        if (has_data[field] === false) {
            return '';
        }

        return BookingData.getValidationErrorMessage(field);
    };
    return <Fragment>
        {props.additional_info ? <p className="intro-text">{props.additional_info}</p> : null}
        {props.include_pre_booking}
        <FormElementText name="forename" value={props.booking.forename ? props.booking.forename : ''}
                         defferredUpdate={true}
                         error={getError('forename')}
                         placeholder="Forename"
                         onChange={(e) => updateField('forename', e.target.value)}/>
        <FormElementText name="surname" value={props.booking.surname ? props.booking.surname : ''}
                         defferredUpdate={true}
                         error={getError('surname')}
                         placeholder="Surname"
                         onChange={(e) => updateField('surname', e.target.value)}/>
        <FormElementText name="company_name" value={props.booking.company_name ? props.booking.company_name : ''}
                         defferredUpdate={true}
                         error={getError('company_name')}
                         placeholder="Company Name"
                         onChange={(e) => updateField('company_name', e.target.value)}/>
        <FormElementText name="email" value={props.booking.email ? props.booking.email : ''}
                         placeholder="Email"
                         error={getError('email')}
                         defferredUpdate={true}
                         onChange={(e) => updateField('email', e.target.value)}/>
        <FormElementText name="phone_number"
                         placeholder="Phone Number"
                         value={props.booking.phone_number ? props.booking.phone_number : ''}
                         error={getError('phone_number')}
                         defferredUpdate={true}
                         number={true}
                         onChange={(e) => updateField('phone_number', e.target.value)}/>
        <OptIns booking={props.booking}/>
        {!props.booking.can_auto_confirm && !props.hide_callback ?
            <ChooseCallback booking={props.booking}/> : null}
    </Fragment>
}

CorporateUserData.propTypes = {
    hide_callback: PropTypes.bool
}