import React from 'react';
import PropTypes from "prop-types";
import SwitchCheckbox from "../../../Form/Element/SwitchCheckbox/SwitchCheckbox";

const DietaryItem = ({title, checked, handleChange}) => {

    return (
        <div className='allergens-menu-filters-item'>
            <span className='allergen-menu-filters-item-name'>{title}</span>
            <SwitchCheckbox value={checked} onChange={handleChange} name={title.toLowerCase()}/>
        </div>
    )
};

export default DietaryItem;

DietaryItem.propTypes = {
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired
};