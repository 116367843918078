import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import TypeSelect from '../Elements/TypeSelect'
import {BookingData} from '../Data/BookingData'
import BookingComplete from '../Elements/BookingComplete/BookingComplete'
import Button from '../../Form/Element/Button'
import BookingStep from '../../Utility/BookingStep/BookingStep'
import Notice from '../../Utility/Notice/Notice'

export default function Karaoke(props) {
    let booking = props.booking
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}/>
    }
    let can_proceed = props.booking.can_take_booking;
    let fields_completed = true;
    ['no_people'].map((field) => {
        if (BookingData.getFieldError(field)) {
            can_proceed = false;
            fields_completed = false;
        }
        return field;
    });
    if (booking.step === 2) {
        let url = null;
        if (booking.bar.data.id === 182) {
            url = 'https://booking.singpods.com/book/d7987806-2efc-4c2c-9a1c-59e28129438a';
        }
        if (booking.bar.data.id === 187) {
            url = 'https://booking.singpods.com/book/1ed33c70-e256-439f-84c5-ba5f350e4157';
        }
        if (url) {
            document.location.href=url;
            return <BookingStep step={2} type="karaoke" booking={booking}>
                Transferring you to our booking partner...
            </BookingStep>
        }
        return <BookingStep step={2} type="karaoke" booking={booking}>
            Sorry, karaoke is not available at {booking.bar.data.bar_name}
        </BookingStep>
    }
    return <Fragment>
        <BookingStep step={1} name="pre-booking-info" type="karaoke" hot_jar_url="karaoke-info" booking={props.booking}>
            {fields_completed === true && can_proceed === false ?
                <Notice name="booking-unavailable" variant="error">Sorry, looks like we can't take this booking
                    right now.</Notice> : null}
            <TypeSelect booking={props.booking} updating={props.updating}
                        nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})}
                                            disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    </Fragment>
}

Karaoke.propTypes = {
    booking: PropTypes.object.isRequired,
    updating: PropTypes.bool.isRequired,
}
