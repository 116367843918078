import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import TypeSelect from "../Elements/TypeSelect";
import {BookingData} from "../Data/BookingData";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import CompleteBooking from "../Elements/CompleteBooking";
import FormElementText from "../../Form/Element/Text";
import FormElementDatePicker from "../../Form/Element/DatePicker";
import FormElementTextArea from "../../Form/Element/TextArea";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import TermsText from "../Elements/TermsText";
import CorporateUserData from "../Elements/CorporateUserData";
import moment from 'moment'
import PreBookingInfo from "../Elements/PreBookingInfo";

export default function GenericLargerEnquiry(props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}/>
    }
    let can_proceed = props.booking.can_take_booking;
    ['no_people'].map((field) => {
        if (BookingData.getFieldError(field)) {
            can_proceed = false;
        }
        return field;
    });
    let minDate = moment();
    let openToDate = null;
    let month = minDate.format('MM');
    if (['11', '12', '01', '02'].indexOf(month) === -1) {
        minDate = minDate.month(10).startOf('month');
        openToDate = minDate.toDate();
    }
    if (booking.step === 1) {
        return <Fragment>
            <BookingStep step={1} name="pre-booking-info" type="corporate" hot_jar_url="enquiry-info" booking={props.booking}>
                <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>} />
            </BookingStep>
        </Fragment>
    }
    if (booking.step === 2) {
        return <Fragment>
            <BookingStep step={2} name="user-data" type="corporate"   hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <FormElementTextArea name="special_requirements" onChange={(e) => {
                    BookingData.update({special_requirements: e.target.value})
                }} placeholder="Any special requirements?"
                                     value={props.booking.special_requirements ? props.booking.special_requirements : ''}
                                     defferredUpdate={true}/>
                <CorporateUserData booking={props.booking} updating={props.updating}
                                   include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                                        placeholder="Proposed date"
                                                                        minDate={moment(props.booking.min_booking_date).toDate()}
                                                                        filterDate={(date) => {
                                                                            let allowed_months = [10, 11, 0, 1];
                                                                            return allowed_months.indexOf(date.getMonth()) > -1;
                                                                        }}
                                                                        openToDate={openToDate} include_booking_time={true}
                                   />}
                />
                <TermsText booking={props.booking}/>
                <CompleteBooking complete_button_text="Send Enquiry" booking={props.booking}/>
            </BookingStep>
        </Fragment>
    }
    return <BookingStep step={999} name="no-step" type="corporate"  hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}

GenericLargerEnquiry.propTypes = {
    booking: PropTypes.object.isRequired
}
