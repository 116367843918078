import {Settings} from "../../../Settings/Settings";
import Tooltip from "../../../Utility/Tooltip";
import BalanceInfo from "./BalanceInfo";
import React from "react";

export default function TotalAmount (props) {
    return <div className="amounts">
        <div className="deposit-main">{Settings.currencyFormatter.format(props.booking.value)}</div>
        <div className="deposit-sub">
            Total {' '}
            <Tooltip placement='right' title={<BalanceInfo booking={props.booking}/>}>
                <button className="info-button">
                    <i className="fa fa-info"/>
                </button>
            </Tooltip>
        </div>
    </div>
}