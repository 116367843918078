import React, {Component} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import Tabs from "./Tabs/Tabs";
import Overview from "./Tabs/Overview/Overview";
import Payment from "./Tabs/Payment/Payment";
import Guests from "./Tabs/Guests/Guests";
import {EventDispatcher} from "../../Events/Dispatcher";
import Packages from "./Tabs/Packages/Packages";
import Notice from "../../Utility/Notice/Notice";
import './EditBooking.scss';
import EditBookingGuest from "./EditBookingGuest";
import $ from 'jquery';
import PreOrder from "./Standalone/Tabs/PreOrder/PreOrder";

class EditBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            booking: {},
            error: null,
            admin: false,
            tab: window.location.hash ? window.location.hash.replace('#', '') : 'overview'
        }
    }

    componentDidMount() {
        this.updateBooking();
        this.listener = window.addEventListener('hashchange', () => this.checkHash());
        this.booking_change_listener = EventDispatcher.subscribe('booking-update', () => this.updateBooking());
        this.booking_data_update_listener = EventDispatcher.subscribe('booking-data', (data) => {
            EventDispatcher.dispatch('booking-data-update', data);
            this.setState({booking: data, loading: false});
        });
        $('body').addClass('edit-booking');

    }

    checkHash() {
        this.setState({tab: window.location.hash ? window.location.hash.replace('#', '') : 'overview'});
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', () => this.checkHash());
        EventDispatcher.unsubscribe(this.booking_change_listener);
        EventDispatcher.unsubscribe(this.booking_data_update_listener);
        $('body').removeClass('edit-booking');
    }

    updateBooking() {
        this.setState({loading: true});
        Api.get('rest/my-bookings/' + this.props.id, {include: 'guests'}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('booking-data-update', response.data.data);
                this.setState({booking: response.data.data, loading: false});
                return;
            }

            this.setState({error: response.error, loading: false});
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (this.state.error) {
            return <Notice name="booking-error">{this.state.error}</Notice>
        }
        let booking = this.state.booking;
        if (booking.admin === false) {
            return <EditBookingGuest booking={booking} guest={booking.guest.data}/>
        }
        return <div className="booking-detail">
            <Tabs tab={this.state.tab} changeTab={(tab) => this.setState({tab: tab})} show_payment={booking.can_customer_pay && booking.pbr > 0}
                  show_options={booking.options.data.length > 0}/>
            {this.state.tab === 'overview' ? <Overview booking={booking}/> : null}
            {/*{this.state.tab === 'guests' ? <Guests booking={booking}/> : null}*/}
            {this.state.tab === 'pre_order' ? <PreOrder booking={booking} token={booking.manage_key}/> : null}
            {this.state.tab === 'packages' ? <Packages booking={booking}/> : null}
            {this.state.tab === 'payment' ? <Payment booking={booking}/> : null}
        </div>
    }
}

export default EditBooking;