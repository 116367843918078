import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import TypeSelect from "../Elements/TypeSelect";
import {BookingData} from "../Data/BookingData";
import UserData from "../Elements/UserData";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreBookingInfo from "../Elements/PreBookingInfo";
import CompleteBooking from "../Elements/CompleteBooking";
import Button from "../../Form/Element/Button";
import Notice from "../../Utility/Notice/Notice";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import moment from "moment/moment";

export default function PrivateHire(props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}/>
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <Fragment>
            <BookingStep step={1} name="pre-booking-info" type="private-hire" hot_jar_url="enquiry-info" booking={props.booking}>
                <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>} />
            </BookingStep>
        </Fragment>
    }
    if (booking.step === 2) {
        return <Fragment>
            <BookingStep step={2} name="user-data" type="private-hire" hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <UserData booking={props.booking} include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                                                       minDate={moment(props.booking.min_booking_date).toDate()} openToDate={BookingData.getOpenToDate()}
                                                                                       include_booking_time={true} />}
                />
            </BookingStep>
            <CompleteBooking complete_button_text="Save Enquiry" booking={props.booking}/>
        </Fragment>
    }
    return <BookingStep step={999} name="no-step" type="private-hire" hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}

PrivateHire.propTypes = {
    booking: PropTypes.object.isRequired
}
