import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './../../Tabs/Tabs.scss';

export default function Tabs(props) {
    return <div className="booking-tabs">
        <div className="booking-tabs--tabs">
            <div className={cx('booking-tabs--tab tab--overview', {'active': props.tab === 'overview'})}>
                <a href="#overview" onClick={(e) => props.changeTab('overview')}>
                    <span className="bookings-tabs--tab--icon icon--overview"/>
                    Overview
                </a>
            </div>
            {props.show_pre_order === true ?
                <div className={cx('booking-tabs--tab tab--pre-order', {'active': props.tab === 'pre-order'})}>
                    <a href="#pre-order" onClick={(e) => props.changeTab('pre-order')}>
                        <span className="bookings-tabs--tab--icon icon--packages"/>
                        Pre Order</a>
                </div> : null}
            {props.show_payment === true ?
                <div className={cx('booking-tabs--tab tab--payment', {'active': props.tab === 'payment'})}>
                    <a href="#payment" onClick={(e) => props.changeTab('payment')}>
                        <span className="bookings-tabs--tab--icon icon--payment"/>
                        Payment</a>
                </div> : null}
        </div>
    </div>
}

Tabs.propTypes = {
    tab: PropTypes.string.isRequired,
    changeTab: PropTypes.func.isRequired
}