import PropTypes from "prop-types";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import Years from './Years/Years'
import Months from "./Months/Months";
import Days from "./Days/Days";
import './scss/DateOfBirthPicker.scss'
import moment from "moment/moment";
import FormElementText from "../../Form/Element/Text";

function DateOfBirthPicker({name, placeholder, handleChange, yearLimit = 18, inputTypePills, value}) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState('');
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [day, setDay] = useState(null)
    const [daysCount, setDaysCount] = useState(null)
    const [step, setStep] = useState('year');
    const [error, setError] = useState('');
    const [containerPos, setContainerPos] = useState({})
    const [arrowPos, setArrowPos] = useState({})
    const [stepHistory, setStepHistory] = useState(['year'])

    const pillsRef = useRef(null);

    const fullInputRef = useRef(null);

    const years = [];
    const months = moment.months();
    const days = [];
    for (let i = 1910; i <= moment().year() - yearLimit; i += 1) {
        years.unshift(i.toString());
    }
    for(let i = 1; i <= daysCount; i += 1) {
            days.push(i);
    }
    const handleToggleDatePicker = (e) => {
        if(inputTypePills) {
            const currentStep = e.target.name.replace(`${name}_`, '');
            setStep(currentStep);
            switch (currentStep) {
                case 'year':
                    setStepHistory(['year']);
                    break;
                case 'month':
                    setStepHistory(['year', 'month']);
                    break;
                case 'day':
                    setStepHistory(['year', 'month', 'day']);
                    break;
                // no default
            }
            setOpen(true)
        } else {
            setOpen(prev => !prev)
        }
    };

    const onBackdropClick = () => setOpen(false);
    const handleYearChange = (value) => {
        setYear(value);
        setStep('month');
        setStepHistory(prev => [...prev, 'month'])
    };
    const handleMonthChange = (value) => {
        setMonth(value);
        setStep('day');
        setStepHistory(prev => [...prev, 'day'])
    };
    const handleDayChange = (value) => {
        setDay(value);
        setOpen(false);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setOpen(false)
        }
    }
    const handleBack = () => {
        if(stepHistory.length > 1) setStepHistory(stepHistory.slice(0, -1));
    };

    useEffect(() => {
        setStep(stepHistory.at(-1));
    },[stepHistory]);

    useEffect(() => {
        if(year && month) {
            setDaysCount(moment(`${year}-${month}`, 'YYYY-MM').daysInMonth())
        }
        setDate(`${year ? year + '/' : ''}${month ? month + '/' : ''}${day || ''}`);
    }, [year, month, day]);
    useEffect(() => {
        if(year && month && day) {
            if(moment(date).isAfter(moment().subtract(yearLimit, 'years'))) {
                setError(`You must be at least ${yearLimit} years old to sign up`)
            } else {
                setError('')
                handleChange(date);
            }
        }
        // eslint-disable-next-line
    }, [date]);
    useEffect(() => {
        const initialDateIso = moment(value, 'YYYY-MM-DD');
        if (initialDateIso) {
            setYear(parseInt(initialDateIso.format('YYYY')));
            setMonth(parseInt(initialDateIso.format('MM')))
            setDay(parseInt(initialDateIso.format('D')))
            return;
        }

        const initialDateUk = moment(value, 'DD/MM/YYYY');
        if (initialDateUk) {
            setYear(parseInt(initialDateUk.format('YYYY')));
            setMonth(parseInt(initialDateUk.format('MM')))
            setDay(parseInt(initialDateUk.format('D')))
        }
    }, [value]);
    useLayoutEffect(()=> {
        document.querySelector('body').style.overflowY = open ? 'hidden' : 'auto';
        const defaultArrowStyles = {
            position: 'absolute',
            left : '50%',
            bottom : '0',
            transform : 'translate(-50%, 50%) rotate(-135deg)'
        };
        if(fullInputRef.current) {
            const input = fullInputRef.current.ref.current.getBoundingClientRect();
            setContainerPos({
                position: "absolute",
                top : `${input.bottom > (window.innerHeight / 2) ? -340 : input.height + 10}px`,
                left: `${input.width / 2 - 150}px`
            });
            setArrowPos(() => {
                if((input.top < window.innerHeight / 2)) {
                    return ({position: 'absolute', left: '50%', top: '0'});
                }
                return defaultArrowStyles;
            });
        }
        if(pillsRef.current) {
            const pillsWrapper = pillsRef.current.getBoundingClientRect();
            setContainerPos({
                position: "absolute",
                left: `50%`,
                transform: 'translate(-50%, 0)',
                top: `${pillsWrapper.bottom > (window.innerHeight / 2) ? -340 : pillsWrapper.height + 10}px`
            })
            setArrowPos(()=> {
                const styles = {
                    ...defaultArrowStyles,
                    top: pillsWrapper.bottom < (window.innerHeight / 2) ? '-16px' : 'unset',
                    transform: pillsWrapper.bottom < (window.innerHeight / 2) ? 'translate(-50%, 50%) rotate(45deg)' : defaultArrowStyles.transform
                }
                switch (step) {
                    case 'year':
                        styles.left = '14px';
                        break;
                    case 'month':
                        styles.left = '50%';
                        break;
                    case 'day':
                        styles.right = '-2px';
                        styles.left = 'unset';
                        break;
                    // no default
                }
                return styles;
            });
        }
    }, [fullInputRef, pillsRef, step, open])


    return (
        <div className='dob-picker'>
            {inputTypePills ?
                <>
                    <div
                        ref={pillsRef}
                        className='dob-picker_pills'
                    >
                        <FormElementText
                            className={`field__element dob-picker_pills-input ${open && step === 'year' ? 'focus': ''} ${open ? 'open': ''}`}
                            name={`${name}_year`}
                            placeholder='Birth Year'
                            value={(year && month && day) ? moment(date).format('YYYY') : year || ''}
                            autoComplete='off'
                            onChange={() => {}}
                            onClick={handleToggleDatePicker}
                            onKeyDown={handleKeyPress}
                        />
                        <FormElementText
                            className={`field__element dob-picker_pills-input ${open && step === 'month' ? 'focus' : ''} ${open ? 'open': ''}`}
                            name={`${name}_month`}
                            placeholder='Birth Month'
                            value={(year && month && day) ? moment(date).format('MMMM') : (month && moment.months(month - 1)) || ''}
                            autoComplete='off'
                            onClick={handleToggleDatePicker}
                            onChange={() => {}}
                            onKeyDown={handleKeyPress}
                        />
                        <FormElementText
                            className={`field__element dob-picker_pills-input ${open && step === 'day' ? 'focus' : ''} ${open ? 'open': ''}`}
                            name={`${name}_day`}
                            placeholder='Birth Day'
                            value={(year && month && day) ? moment(date).format('Do') : ''}
                            autoComplete='off'
                            onClick={handleToggleDatePicker}
                            onChange={() => {}}
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <span className='dob-picker_error'>{error}</span>
                </>
            :
                <FormElementText
                    ref={fullInputRef}
                    className='field__element'
                    name={name} placeholder={placeholder}
                    value={(year && month && day) ? moment(date, 'YYYY/MM/D').format('Do MMMM YYYY') : ''}
                    onClick={handleToggleDatePicker}
                    onChange={() => {}}
                    autoComplete='off'
                    error={error}
                    onKeyDown={handleKeyPress}
                />
            }

            <div className={`dob-picker_backdrop ${open ? 'active' : ''}`} onClick={onBackdropClick}/>
            {open &&
                <div
                    style={containerPos}
                    className='dob-picker_container'
                >
                    <div style={arrowPos} className='dob-picker_container-arrow'></div>
                    <h4 className='dob-picker_container-title'>Select {step}</h4>
                    <div className='dob-picker_container_edit'>
                        {step !== 'year' &&
                            <button className='dob-picker_container_edit-previous' onClick={handleBack}>
                                <i className='fa fa-arrow-left'/>
                                Previous step
                             </button>
                        }
                    </div>
                    <div className='dob-picker_container-options'>
                        {step === 'year' && <Years handleChange={handleYearChange} years={years} selectedYear={year}/>}
                        {step === 'month' && <Months handleChange={handleMonthChange} months={months} selectedMonth={month}/>}
                        {step === 'day' && <Days year={year} handleChange={handleDayChange} days={days} selectedDay={day}/>}
                    </div>
                </div>
            }
        </div>
    )
}
export default DateOfBirthPicker;

DateOfBirthPicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    yearLimit: PropTypes.number,
    inputTypePills: PropTypes.bool
}