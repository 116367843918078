import React, {useCallback, useEffect, useState} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import Notice from "../../Utility/Notice/Notice";
import ContentBox from "../../Utility/ContentBox/ContentBox";
import FormElementSelect from "../../Form/Element/Select";
import './BookingGuest.scss';
import moment from "moment";
import {Settings} from "../../Settings/Settings";
import Button from "../../Form/Element/Button";
import {NotificationManager} from "../../Notifications/NotifcationManager";

const BookingGuest = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [guest, setGuest] = useState({});
    const [saving, setSaving] = useState(false);
    const [preOrder, setPreOrder] = useState([]);
    const [originalPreOrder, setOriginalPreOrder] = useState({});

    const updateGuest = useCallback(() => {
        setLoading(true);
        Api.get('rest/booking/guest/' + props.code, {include: 'booking,customer'}).then((response) => {
            if (response.ok) {
                setGuest(response.data.data);
                setPreOrder(response.data.data.booking.data.guest.data.pre_order.data);
                setOriginalPreOrder(response.data.data.booking.data.guest.data.pre_order.data);
                setLoading(false);
                return;
            }
            if (response.status === 404) {
                setError('Guest not found');
                setLoading(false);
                return;
            }
            setError('Unknown error occurred');
        });
    }, [props.code, setLoading, setGuest]);

    const getPreOrderDetail = (variation_id, choice_id, choice_item_id) => {
        let po = preOrder.find((p) => {
            return p.variation_id === variation_id && p.choice_id === choice_id && p.choice_item_id === choice_item_id;
        });

        return po ? po : null;
    }

    const getPluName = (plu) => {
        let name = null;
        plu = parseInt(plu);
        guest.variations.data.map((v) => {
            v.choice_array.map((g) => {
                g.choices.map((ci) => {
                    ci.items.map((i) => {
                        if (i.plu === plu) {
                            name = i.title;
                        }
                    });
                })
            })
        })
        return name;
    }

    const handleSave = () => {
        setSaving(true);
        Api.postJson('rest/booking/guest/' + props.code + '/pre-order', {
            pre_order: preOrder,
            include: 'booking,customer'
        }).then((response) => {
            if (response.ok) {
                setSaving(false);
                setGuest(response.data.data);
                setPreOrder(response.data.data.booking.data.guest.data.pre_order.data);
                setOriginalPreOrder(response.data.data.booking.data.guest.data.pre_order.data);
                setLoading(false);
                NotificationManager.showSuccess('Pre order has been updated!');
            }
        })
    }

    useEffect(() => {
        updateGuest();
    }, [props.code]);

    if (loading) {
        return <Loading/>
    }

    if (error) {
        return <div>
            <Notice name="guest-error" variant="error">{error}</Notice>
        </div>
    }
    return <div className="booking-guest">
        <h1 className="guest-name">{Settings.getBrandWelcome()} {guest.customer.data.full_name.trim()}!</h1>

        <ContentBox>
            Below are the details for your booking
            at {guest.booking.data.bar.data.bar_name} on {moment(guest.booking.data.booking_date).format('MMMM Do YYYY')} at {moment(guest.booking.data.booking_date).format('HH:mm')}
        </ContentBox>
        {guest.booking.data.pre_order_locked &&
        <Notice variant="error" name="pre-order-locked">The pre order for this booking has been locked. If you want to
            make any further changes
            please get in touch with the bar.</Notice>}
        <Notice name="dietary" variant="info">Please contact us directly to discuss allergy information, dietary requirements or special requests.
            Do not add this information to the name boxes below as our kitchen teams will not see these!<br /><br />
        <a href={Settings.getAllergensUrl()} target="_blank">Click Here</a> to view our current allergens list</Notice>
        {guest.variations.data.map((variation) => {
            return <ContentBox key={variation.id} title={variation.name} name="guest-variation">
                <div className="guest-variation--info clearfix">
                    {variation.image.data.id && <img src={variation.image.data.url} alt={variation.name}/>}
                    <p className="description">{variation.description}</p>
                </div>

                <div className="guest-variation--options">
                    {variation.choice_array.map((choice) => {
                        let choice_id = null;
                        return <div key={choice.group_id}>
                            {choice.choices.map((ci) => {
                                choice_id = ci.id;
                                let options = [];
                                ci.items.map((i) => {
                                    if (i.selectable === true) {
                                        let title = i.title
                                        if (i.premium > 0) {
                                            title = title + ' (+' + Settings.currencyFormatter.format(i.premium) + ')'
                                        }
                                        options.push({
                                            key: i.plu + '',
                                            value: title,
                                        })
                                    }
                                })
                                if (options.length === 0) {
                                    return;
                                }
                                let selected = getPreOrderDetail(variation.id, choice.group_id, choice_id);
                                return <div className="guest-variation--options--choice" key={ci.id}>
                                    <p>{choice.title}</p>
                                    <FormElementSelect name={'' + choice.group_id}
                                                       blankOptionText={' - ' + ci.title + ' -'} onChange={(e) => {
                                        let newSelected = getPluName(e.target.value);
                                        let found = false;
                                        let newPreOrder = JSON.parse(JSON.stringify(preOrder)).map((po) => {
                                            if (po.variation_id === variation.id && po.choice_id === choice.group_id && po.choice_item_id === ci.id) {
                                                po.plu = e.target.value;
                                                found = true;
                                                if (newSelected) {
                                                    po.plu_name = newSelected
                                                }
                                            }
                                            return po;
                                        });
                                        if (found === false) {
                                            newPreOrder.push({
                                                choice_id: choice.group_id,
                                                choice_item_id: choice_id,
                                                choice_name: newSelected,
                                                plu: e.target.value,
                                                variation_id: variation.id,
                                                guest_id: guest.id,
                                                id: null
                                            })
                                        }
                                        setPreOrder(newPreOrder);
                                    }}
                                                       selectedValue={selected ? selected.plu + '' : ''}
                                                       includeBlankOption={true}
                                                       blankOptionDisabled={!!selected} id={choice.group_id}
                                                       disabled={saving || guest.booking.data.pre_order_locked === true}
                                                       values={options}/>
                                </div>
                            })}
                        </div>
                    })}
                </div>
                <Button text={saving ? <Loading/> : 'Save'} name="save" variant="full-width"
                        onClick={(e) => handleSave(e)}
                        disabled={saving === true || guest.booking.data.pre_order_locked === true || JSON.stringify(preOrder) === JSON.stringify(originalPreOrder)}/>
            </ContentBox>
        })}
    </div>
}

export default BookingGuest;