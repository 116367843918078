import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Notice from '../Notice/Notice'
import Loading from '../Loading/Loading'
import './BarFinder.scss'

const BarFinder = (props) => {
    const [loading, setLoading] = useState(true)
    const [barList, setBars] = useState([])
    const [pos, setPos] = useState({})
    const [error, setError] = useState(null)

    const distance = (lat1, lon1, lat2, lon2, unit) => {
        var radlat1 = Math.PI * lat1 / 180
        var radlat2 = Math.PI * lat2 / 180
        var theta = lon1 - lon2
        var radtheta = Math.PI * theta / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
            dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        if (unit === 'K') { dist = dist * 1.609344 }
        if (unit === 'N') { dist = dist * 0.8684 }
        return dist
    }
    useEffect(() => {
        let bars = props.bars.filter((b) => {
            if (props.brand) {
                return b.brand_short_name === props.brand && b.central_bar === false && b.open === true
            }
            return b.central_bar === false && b.open === true
        })
        setBars(bars)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                const lat = pos.coords.latitude
                const lon = pos.coords.longitude
                setPos({
                    lat: lat,
                    lon: lon,
                })
                const new_bars = bars.map((b) => {
                    b.distance = distance(b.latitude, b.longitude, lat, lon)
                    console.log(b.distance)
                    return b
                }).sort((b1, b2) => {
                    return b1.distance > b2.distance ? 1 : -1
                })
                setBars(new_bars)
                setLoading(false)
            })
        }
        else {
            setError('Access to location denied')
            setLoading(false)
        }
    }, [])

    if (error) {
        return <div>
            <Notice name="loc_error" variant="error">{error}</Notice>
        </div>
    }
    if (loading) {
        return <Loading/>
    }
    return <div className="bar-finder">
        <ul className="closest-bar-list">
            {barList.slice(0, 10).map((bar) => {
                return <li key={bar.id}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        props.onChange({
                            target: {
                                value: bar.id
                            }
                        })
                    }}>
                        <span>{bar.name}
                            <strong>{bar.distance.toFixed(1)} miles away</strong>
                            </span>
                        <i className="fa fa-chevron-right"/>
                    </a>
                </li>
            })}
        </ul>
    </div>
}

BarFinder.propTypes = {
    bars: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}
export default BarFinder