export const NotificationManager = {
    listeners: [],
    subscribe: function (callback) {
        if (this.listeners.length === 0) {
            return this.listeners.push(callback);
        }
        console.log('Ignoring');
        return -1;
    },
    unsubscribe: function (index) {
        this.listeners = this.listeners.splice(index, 1);
    },
    showError: function (message) {
        this.sendNotification({
            type: 'error',
            pauseOnFocusLoss: true,
            message: message
        });
    },
    showSuccess: function (message) {
        this.sendNotification({
            type: 'success',
            pauseOnFocusLoss: true,
            message: message
        })
    },
    showInfo: function (message) {
        this.sendNotification({
            type: 'info',
            pauseOnFocusLoss: true,
            message: message
        })
    },
    sendNotification: function (notification) {
        this.listeners.map((listener) => {
            listener(notification);
            return listener;
        });
    }
}