import React, {useEffect, useRef, useState} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";
import Notice from "../../Utility/Notice/Notice";
import './SignContract.scss';
import SignatureCanvas from "react-signature-canvas";
import Button from "../../Form/Element/Button";

const SignContract = (props) => {
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState({});
    const [error, setError] = useState(null);
    const [viewed, setViewed] = useState(false);
    const [signature, setSignature] = useState(null);
    const [saving, setSaving] = useState(false);

    const sigCanvas = useRef({});
    useEffect(() => {
        window.onmessage = (e) => {
            if (e.data.type) {
                let data = e.data;
                if (data.type === 'viewed') {
                    setViewed(true);
                }
                if (data.type === 'get_content') {
                    if (!contract.id) {
                        document.getElementById('contract_iframe').contentWindow.postMessage({
                            type: 'still_loading'
                        }, '*');
                        return;
                    }
                    document.getElementById('contract_iframe').contentWindow.postMessage({
                        type: 'contract_data',
                        data: contract
                    }, '*');
                }
            }
        }
    }, [contract]);

    const reloadContract = () => {
        Api.get('customers/societies/society-portal/contract/' + props.match.params.code + '/' + props.match.params.admin_code, {}).then((response) => {
            setLoading(false);
            if (response.ok) {
                setContract(response.data.data);
                return;
            }
            setError(response.error);
        });
    }

    useEffect(() => {
        if (props.match.params.code) {
            reloadContract();
        }
    }, [props.match.params.code, props.match.params.admin_code]);

    if (loading || !contract.id) {
        return <Loading/>
    }
    if (error) {
        return <div>
            <Notice name="error" variant="error">{error}</Notice>
        </div>
    }
    return <div>
        {contract.status.data.completed === true ? <Notice variant="info" name="contract-completed">
            This contract is {contract.status.data.name}
        </Notice> : null}
        <iframe src={'/society/contract-copy/' + props.match.params.code + '/' + props.match.params.admin_code}
                className="copy-container"
                id="contract_iframe"
                title="Contract Content"/>
        {contract.status.data.completed ? <></> :
            <>
                {!viewed ?
                    <Notice variant="warning" name="scroll-warning">Please read full contract before
                        proceeding</Notice> : <div>
                        <p>By signing below I, <strong>{contract.current_user_name}</strong> confirm I agree to the all
                            of the
                            stipulations in the contract above.</p>
                        <div className="contract-signature">
                            <div>
                                <SignatureCanvas canvasProps={{
                                    width: 300,
                                    height: 100,
                                    className: 'signature'
                                }}
                                                 penColor={'black'}
                                                 ref={sigCanvas} onEnd={() => {
                                    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/svg+xml'));
                                }}
                                />
                            </div>
                            {signature ?
                                <div className="contract-buttons"><Button text="Clear" name="clear-button"
                                                                          onClick={() => {
                                                                              sigCanvas.current.clear();
                                                                              setSignature(null);
                                                                          }}/>
                                    <Button text="Sign Contract" name="sign-contract" onClick={() => {
                                        setSaving(true);
                                        Api.post('customers/societies/society-portal/contract/' + props.match.params.code + '/' + props.match.params.admin_code + '/sign', {signature_data: signature}).then((response) => {
                                            setSaving(false);
                                            document.location.reload();
                                        })
                                    }}/>
                                </div> : null}
                        </div>
                    </div>}
            </>}
    </div>
}

export default SignContract;