import React, {Fragment, useEffect, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";
import ContentBox from "../Utility/ContentBox/ContentBox";
import Notice from "../Utility/Notice/Notice";
import FormElementText from "../Form/Element/Text";
import _ from 'lodash';
import Button from "../Form/Element/Button";
import moment from 'moment';
import './TrackTrace.scss';
import FormElementSelect from "../Form/Element/Select";

export default function TrackTrace(props) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [visitData, setVisitData] = useState({});
    const [bar, setBar] = useState({});
    const [groupSelect, setGroupSelect] = useState([]);
    const [user, setUser] = useState({
        forename: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        post_code: '',
        group_size: '',
        contact_type: 'phone'
    });
    const [error, setError] = useState(null);
    const [canSubmit, setCanSubmit] = useState(false);
    const updateVisitData = () => {
        Api.get('customers/track-trace/' + props.bar).then((response) => {
            if (response.ok) {
                setVisitData(response.data.data);
                setBar(response.data.meta.bar);
                setLoading(false);
                let groups = [];
                for (let x = 1; x <= response.data.meta.max_group_size; x++) {
                    groups.push({
                        key: x,
                        value: x
                    })
                }
                setGroupSelect(groups);
            }
        });
    };

    const extendVisit = (id) => {
        Api.post('customers/track-trace/' + props.bar + '/' + id + '/extend').then((response) => {
            if (response.ok) {
                setVisitData(response.data.data);
                setLoading(false);
            }
        });
    }
    useEffect(() => {
        updateVisitData();
    }, []);

    const saveVisit = function (e) {
        e.preventDefault();
        setError(null);
        setSaving(true);
        Api.post('customers/track-trace/' + props.bar, user).then((response) => {
            setSaving(false);
            if (!response.ok) {
                setError(response.error);
                return;
            }
            setVisitData(response.data.data);
            setUser({
                forename: '',
                surname: '',
                email: '',
                phone: '',
                address: '',
                post_code: '',
                group_size: user.group_size,
                contact_type: user.contact_type
            });

            window.scrollTo({top: 50});
        });
    }

    const setField = (e) => {
        let data = _.extend({}, user);
        data[e.target.name] = e.target.value;
        setUser(data);
        let valid = true;
        if (data.forename.length <= 2) {
            valid = false;
        }
        if (data.surname.length <= 2) {
            valid = false;
        }
        if (data.contact_type === 'email' && data.email.length < 8) {
            valid = false;
        }
        if (data.contact_type === 'phone' && data.phone.length < 8) {
            valid = false;
        }
        setCanSubmit(valid === true);
    }

    if (loading) {
        return <Loading/>
    }
    let others = [];
    if (visitData.length > 1) {
        others = visitData.map(function (v) {
            return v.display_name;
        });
        others.shift();
    }
    return <div className="customer-visit" id="content_top">
        <div className="row">
            <div className="col-md-12">
                <ContentBox name="title-box">
                    <h1>{bar.name} - Track & Trace</h1>
                    <i>{bar.address.join(', ')}</i>
                </ContentBox>
            </div>
            <div className="col-md-12">
                <ContentBox>
                    {visitData.length === 0 ? <Fragment>
                        <Notice name="visit-info" variant="warning">{bar.track_trace_content}</Notice>
                    </Fragment> : <Fragment>
                        {visitData.length > 1 ?
                            <p className="visit-count">{visitData.length} guests checked in</p> : null}
                        <ul className="visit-list">
                            {visitData.map(function (v) {
                                return <li
                                    key={v.id}>
                                    <span className="name">{v.display_name}</span>
                                    <span
                                        className="time">Expires: {moment(v.date_finished).format('HH:mm')} ({moment(v.date_finished).fromNow()})</span>
                                    {moment(v.date_finished).isBefore(moment()) ?
                                        <Button variant="outline" name="add-more-time" text="Extend my visit"
                                                onClick={() => extendVisit(v.id)}/> : null}
                                </li>
                            })}
                        </ul>
                        <Notice name="checked-in">
                            {visitData.length === 1 ? <Fragment>
                                Thanks {visitData[0].forename}. You are checked in! We've checked you in for 2 hours,
                                you'll have a chance to extend your visit if you are still
                                here by coming back to this page.
                            </Fragment> : <Fragment>
                                Thanks {visitData[0].forename}. {others.join(', ')} and you are all set! We've
                                checked you in for 2 hours, you'll have a chance to extend your visit if you are still
                                here by coming back to this page.
                            </Fragment>}
                        </Notice>
                    </Fragment>}

                    {visitData.length >= 1 ? <p>Add another check-in</p> : null}
                    {visitData.length >= 6 ?
                        <Notice variant="error" name="check-in-max">You cannot add any more guests as you've checked in
                            the maximum of 6 people</Notice> :
                        <form onSubmit={(e) => saveVisit(e)} className="add-visit">
                            <div className="row">
                                <div className="col-md-6">
                                    <FormElementText name="forename" placeholder="Forename"
                                                     onChange={(e) => setField(e)} tabIndex={1}
                                                     value={user.forename}/>
                                </div>
                                <div className="col-md-6">
                                    <FormElementText name="surname" placeholder="Surname"
                                                     onChange={(e) => setField(e)} tabIndex={2}
                                                     value={user.surname}/>
                                </div>
                                <div className="col-md-12">
                                    <FormElementSelect name="group_size"
                                                       placeholder="How Many People Are In Your Group?"
                                                       includeBlankOption={true}
                                                       blankOptionText=" - How Many People Are in Your Group?"
                                                       onChange={(e) => setField(e)} tabIndex={3}
                                                       selectedValue={user.group_size}
                                                       values={groupSelect}/>
                                </div>
                            </div>
                            <div className="row contact-buttons">
                                <div className="col-md-6 text-center">
                                    <Button name="phone" type="button" text={user.contact_type === 'phone' ?
                                        <Fragment>I want to provide my Phone Number <i
                                            className="fa fa-check"/></Fragment> : 'I want to provide my Phone Number '}
                                            onClick={(e) => setField({
                                                target: {
                                                    name: 'contact_type',
                                                    value: 'phone'
                                                }
                                            })} tabIndex={21}/>
                                </div>
                                <div className="col-md-6 text-center">
                                    <Button name="email" type="button" text={user.contact_type === 'email' ?
                                        <Fragment>I want to provide my e-mail address <i
                                            className="fa fa-check"/></Fragment> : 'I want to provide my e-mail address '}
                                            onClick={(e) => setField({
                                                target: {
                                                    name: 'contact_type',
                                                    value: 'email'
                                                }
                                            })} tabIndex={20}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {user.contact_type === 'email' ? <FormElementText name="email" value={user.email}
                                                                                      placeholder="E-mail Address"
                                                                                      email={true}
                                                                                      tabIndex={4}
                                                                                      onChange={(e) => setField(e)}/> : null}
                                    {user.contact_type === 'phone' ?
                                        <FormElementText name="phone" value={user.phone}
                                                         placeholder="Phone Number" tabIndex={5}
                                                         onChange={(e) => setField(e)}/> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Button text="Check me in" disabled={canSubmit === false || saving === true}
                                            onClick={(e) => saveVisit(e)} tabIndex={5} name="submit"/>
                                </div>
                            </div>
                        </form>}
                </ContentBox>
            </div>
        </div>
    </div>
}