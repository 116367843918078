import React, {Fragment, useEffect, useState} from 'react';
import './Manage.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {BookingData} from "../Data/BookingData";
import Link from "react-router-dom/Link";
import {EventDispatcher} from "../../Events/Dispatcher";
import MyDetails from "../../Portal/MyDetails/MyDetails";
import Tooltip from '../../Utility/Tooltip';

export default function ManageLayout(props) {
    const [staffLoggedIn, setStaffLoggedIn] = useState(false);
    let portal_class = props.name ? 'portal-page--' + props.name : '';
    let variant = '';
    if (props.variant) {
        variant = 'portal-page--' + props.variant;
    }
    const handleOpenMyDetails = (e) => {
        EventDispatcher.dispatch('open-modal', {
            content: <MyDetails/>,
            style: 'full-page'
        });
    }

    useEffect(() => {
        const user = window.localStorage.getItem('staff_login');
        if (user) {
            setStaffLoggedIn(true);
        }
    }, []);

    return <Fragment>
        <div className={cx('portal-page', portal_class, variant, {'logged-in': !!BookingData.user.id})}>
            <header>
                <div className="container">
                    <a href="/" className="header-logo"/>
                    {BookingData.loggedIn() &&
                        <div className="user">
                            <Tooltip title='User Details' placement='bottom'>
                                <Link to="#" onClick={handleOpenMyDetails}>
                                    <i className="fa fa-user-circle"/>
                                </Link>
                            </Tooltip>
                            <Tooltip title='Logout' placement='bottom'>
                                <Link to="/logout">
                                    <i className="fa fa-sign-out" />
                                </Link>
                            </Tooltip>
                        </div>}
                    {staffLoggedIn && <div className="user">
                        <Tooltip title='Logout' placement='bottom'>
                            <Link to="/staff/logout">
                                <i className="fa fa-sign-out" />
                            </Link>
                        </Tooltip>
                    </div>}
                </div>
            </header>
            <main className="container">
                <div className={cx('main-content', props.mainContentClassName)}>
                    {props.children}
                </div>
            </main>
            <footer>
                <div className="container">
                    <div className="copy">&copy; Revolution Bars Group {moment().format('YYYY')}</div>
                </div>
            </footer>
        </div>
    </Fragment>
}

ManageLayout.propTypes = {
    mainContentClassName: PropTypes.string
}