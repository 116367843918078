import React, {Component, Fragment, useState} from 'react'
import {ModalHelper} from "../../../../../Utility/Modal/AppModal";
import Button from "../../../../../Form/Element/Button";
import PropTypes from "prop-types";

export default class ConfirmDeleteGuest extends Component {

    componentDidMount() {
        this.setButtons()
    }

    setButtons () {
        ModalHelper.setButtons(<Fragment>
            <Button variant="outline" text="Cancel" onClick={() => ModalHelper.closeModal()} name="cancel"/>
            <Button text="Confirm Delete" onClick={() => {
                this.props.confirmAction()
            }} name="confirm-delete-guest"/>
        </Fragment>)
    }

    render() {
        return <div>
            {this.props.is_bespoke_menu &&
            <div className="notice notice--warning">
                <p>This guest has a bespoke menu arrangement. If you delete this you will need to contact our sales
                    team to re-instate this</p>
            </div>}
            <p>Are you sure you want to remove this guest?</p>
        </div>
    }
}

ConfirmDeleteGuest.propTypes = {
    confirmAction: PropTypes.func.isRequired,
    is_bespoke_menu: PropTypes.bool
}