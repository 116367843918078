import React, {Fragment, useEffect, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import FormElementText from "../Form/Element/Text";
import FormElementSelect from "../Form/Element/Select";
import Button from "../Form/Element/Button";
import moment from "moment";
import './DataCollection.scss';
import universities from "./universities";
import {Settings} from "../Settings/Settings";
import {Api} from "../Api/Api";
import DateOfBirthPicker from "../Utility/DateOfBirthPicker/DateOfBirthPicker";
import FormElementCheckbox from "../Form/Element/Checkbox";
import Notice from "../Utility/Notice/Notice";

function DataCollection({bar_id}) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const [saveError, setSaveError] = useState(null);
    const [dates, setDates] = useState([]);
    const [uniList, setUniList] = useState(universities);
    const [bars, setBars] = useState([]);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [collect, setCollect] = useState({
        forename: '',
        surname: '',
        email: '',
        mobile: '',
        university: '',
        date_of_birth: '',
        email_opt_in: 0,
        sms_opt_in: 0,
        phone_opt_in: 0,
        bar_id: window.localStorage.getItem('last_bar') ? window.localStorage.getItem('last_bar') : '',
        graduation_year: '',
        postcode: ''
    })

    const handleFieldChange = (e) => {
        setCollect({...collect, [e.target.name]: e.target.value});
    }

    const handleDOBchange = (date) => {
        console.log('date change', date);
        setCollect({...collect, date_of_birth: moment(date, 'YYYY/MM/D').format('YYYY-MM-DD')});
    }

    useEffect(() => {
        let year = parseInt(moment().format('Y'));
        const end = year + 5;
        const years = [];
        while (year <= end) {
            years.push(year);
            year = year + 1;
        }
        setDates(years);
        Api.get('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            if (response.ok) {
                const barList = response.data.filter((bar) => {
                    return bar.open === true && bar.central_bar === false && bar.brand_id === Settings.getBrandId()
                });
                setBars(barList);
                if (bar_id) {
                    const selected = barList.find((bar) => (bar_id === bar.slug ) || (+bar_id === bar.id))
                    if(selected) {
                        window.localStorage.setItem('last_bar', selected.id);
                        setCollect(prev => ({...prev, bar_id: selected.id}))
                    }
                }
                setLoading(false);
            } else {
                setError('Sorry, you seem to be offline');
            }
        })
        // setUser(JSON.parse(user));
    }, []);

    const distance = (lat1, lon1, lat2, lon2, unit) => {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        } else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === "K") {
                dist = dist * 1.609344
            }
            if (unit === "N") {
                dist = dist * 0.8684
            }
            return dist;
        }
    }
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        window.localStorage.setItem('last_bar', collect.bar_id);
        setSaving(true);
        setSaveError(null);
        Api.post('customers/student/data-collect', collect).then((response) => {
            setSaving(false);
            if (response.ok) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
                setCollect({
                    forename: '',
                    surname: '',
                    email: '',
                    mobile: '',
                    university: '',
                    date_of_birth: '',
                    email_opt_in: 0,
                    sms_opt_in: 0,
                    phone_opt_in: 0,
                    bar_id: window.localStorage.getItem('last_bar') ? window.localStorage.getItem('last_bar') : '',
                    graduation_year: '',
                    postcode: ''
                })
            } else {
                setSaveError(response.error);
            }
            scrollToTop()
        });
    }
    useEffect(() => {
        const bar = bars.find((b) => {
            return b.id === parseInt(collect.bar_id);
        })
        if (bar) {
            setUniList(universities.map((uni) => {
                const dist = distance(bar.latitude, bar.longitude, uni.latitude, uni.longitude, 'M');
                uni.display_name = uni.name + ' (' + dist.toFixed(2) + 'm)';
                uni.dist = dist;
                return uni;
            }).sort((uni1, uni2) => {
                return uni1.dist > uni2.dist ? 1 : -1;
            }))
        } else {
            setUniList(universities);
        }
    }, [collect.bar_id, bars, universities]);
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Notice name="error" variant="error">{error}</Notice>
    }
    return <Fragment>
        <div style={{paddingTop: '10px'}}/>
        {success && <Notice name="success" variant="success">Thanks! Check your e-mail for what to do next!</Notice>}
        {saveError && <Notice name="error">{saveError}</Notice>}
        <form className="data-collection" onSubmit={(e) => e.preventDefault()}>
            <FormElementSelect name="bar_id" onChange={(e) => handleFieldChange(e)} selectedValue={collect.bar_id}
                               includeBlankOption={true} blankOptionText=" - Nearest Term Time Bar - "
                               values={bars.map((bar) => {
                                   return {
                                       key: bar.id,
                                       value: bar.name
                                   }
                               })}/>
            <div className='data-collection__group'>
                <FormElementText email={true} name="email" value={collect.email} placeholder="E-Mail"
                                 onChange={(e) => handleFieldChange(e)}/>
                <small> Please provide a permanent email address e.g. gmail or outlook. Ac.uk or temporary email addresses cannot be accepted.</small>
            </div>

            <div className='data-collection__group'>
                <DateOfBirthPicker name='dob_picker' placeholder='Date Of Birth (yyyy/mm/dd)'
                                   handleChange={handleDOBchange} value={collect.date_of_birth}/>
                <small>Please provide to validate that you are over 18. We will also send you a treat on your birthday.</small>
            </div>

            <FormElementText name="forename" value={collect.forename} placeholder="Forename"
                             onChange={(e) => handleFieldChange(e)} disabled={saving}/>
            <FormElementText name="surname" value={collect.surname} placeholder="Surname"
                             onChange={(e) => handleFieldChange(e)} disabled={saving}/>
            <FormElementText number={true} name="mobile" value={collect.mobile} placeholder="Mobile Number"
                             onChange={(e) => handleFieldChange(e)} disabled={saving}/>
            <FormElementSelect name="university" selectedValue={collect.university} values={uniList.map((uni) => {
                return {
                    key: uni.name,
                    value: uni.display_name ? uni.display_name : uni.name,
                    object: uni
                }
            })}
                               useSelect2={true}
                               includeBlankOption={true} disabled={saving}
                               blankOptionText=" - University - "
                               onChange={(e) => handleFieldChange(e)}
            />
            <FormElementSelect name="graduation_year" onChange={(e) => handleFieldChange(e)}
                               values={dates.map((year) => {
                                   return {
                                       key: year, value: year
                                   }
                               })}
                               includeBlankOption={true} disabled={saving}
                               selectedValue={collect.graduation_year} blankOptionText=" - Graduation Year - "/>

            <FormElementText name="postcode" placeholder="Term Time Postcode (Optional)" onChange={(e) => handleFieldChange(e)}
                             value={collect.postcode} disabled={saving}/>

            <div className='opt-ins'>
                <p>Select which ways you'd like to be contacted by us to get exclusive offers, discounts and news.
                    We promise we won't spam you with boring stuff - only the latest and greatest
                    from {Settings.getBrandName()}. Let's stay connected, shall we?
                </p>

                <div className='opt-ins__fields'>
                    <FormElementCheckbox onChange={(e) => handleFieldChange(e)}
                                         label="E-Mail" true_value="1" false_value="0" label_after={true}
                                         name="email_opt_in" value={collect.email_opt_in} disabled={saving}/>
                    <FormElementCheckbox onChange={(e) => handleFieldChange(e)}
                                         label="SMS" true_value="1" false_value="0" label_after={true}
                                         name="sms_opt_in" value={collect.sms_opt_in} disabled={saving}/>
                    <FormElementCheckbox onChange={(e) => handleFieldChange(e)}
                                         label="Phone" true_value="1" false_value="0" label_after={true}
                                         name="phone_opt_in" value={collect.phone_opt_in} disabled={saving}/>
                </div>
            </div>
            <Button text="Submit" name="save" variant="full-width" onClick={(e) => handleSubmit(e)} disabled={saving}/>
        </form>

        <p className='privacy-statement'>
                By signing up to this mailing list you are opting in to receive marketing emails by Revolution Bars
                Group and agreeing to <a href='https://www.revolution-bars.co.uk/privacy-policy/' target='_blank'>
                Revolution Bars Group Privacy Policy</a>. We may personalise emails and online
                advertising based on the information you provide, your location and interests. At Revolution Bars Group,
                we care about protecting your customer data and will process this in accordance with our
                <a href='https://www.revolution-bars.co.uk/cookies/' target='_blank'> Privacy and
                Cookie Policy</a>. By clicking submit you are accepting our Privacy and Cookie policy.
        </p>
    </Fragment>
}

export default DataCollection;