import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import FormElementText from '../../Form/Element/Text'
import { NotificationManager } from '../../Notifications/NotifcationManager'
import copy from 'copy-to-clipboard'
import './ShareLink.scss'

const ShareLink = (props) => {
    const [link, showLink] = useState(props.show === true || props.text === '')
    if (link === false) {
        return <div className="share-link" id="share-link">
            <a href="#share-link" onClick={(e) => {
                e.preventDefault()
                showLink(true)
            }}>{props.text}</a>
        </div>
    }
    return <div className="share-link">
        <FormElementText name="invite_link" read_only={true} value={props.link}
                         append={<button className="copy" onClick={() => {
                             NotificationManager.showSuccess('Link copied to clipboard')
                             copy(props.link)
                         }}><i className="fa fa-copy"/>
                         </button>}/>
    </div>
}

ShareLink.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string.isRequired,
    show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default ShareLink