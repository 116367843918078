import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {Settings} from "../../Settings/Settings";
import Button from "../../Form/Element/Button";
import {EventDispatcher} from "../../Events/Dispatcher";
import MyDetails from "../MyDetails/MyDetails";
import PayAmount from "../../Bookings/Elements/Payment/PayAmount";
import {Api} from "../../Api/Api";
import {NotificationManager} from "../../Notifications/NotifcationManager";
import {ModalHelper} from "../../Utility/Modal/AppModal";
import PreOrder from "./Tabs/Guests/EditGuest/PreOrder";

export default class EditBookingGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_loading: false
        }
    }

    createPaymentAmount(amount) {
        this.setState({payment_loading: true});
        Api.post('rest/booking/' + this.props.booking.id + '/create-guest-deposit/' + this.props.guest.id, {amount: amount}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('open-modal', {
                    content: <PayAmount amount={amount} deposit_request_token={response.data.data.token}
                                        token={this.props.booking.payment_token} onComplete={() => {
                        EventDispatcher.dispatch('booking-update');
                        ModalHelper.closeModal();
                    }}/>,
                    style: 'full-page'
                });
                this.setState({payment_loading: false});
                return;
            }

            this.setState({payment_loading: false});
            NotificationManager.showError(response.error);
        })
    }

    openPreOrder(e) {
        e.preventDefault();
        ModalHelper.openModal(<PreOrder guest={this.props.guest} booking={this.props.booking}
                                        goBack={() => {
                                            ModalHelper.closeModal();
                                        }}/>);
    }

    render() {
        let props = this.props;
        let guest = props.booking.guest.data;
        return <Fragment>
            <div className="booking-overview">
                <span className="booking-overview__name">{props.booking.party_name}</span>
                <span className="booking-overview__ref">REF: {props.booking.ref_code}</span>
                <div className="booking-info grid">
                    <div className="grid__item booking-info__item booking-info--date">
                        <span>{moment(props.booking.booking_date).format(Settings.booking_date_format)}</span>
                    </div>
                    <div className=" grid__item booking-info__item booking-info--people">
                        <span>{props.booking.people} Guests</span>
                    </div>
                    <div className="grid__item booking-info__item booking-info--bar">
                        <span>{props.booking.bar.data.bar_name}</span>
                    </div>
                    {guest.share > 0 ? <div className="grid__item booking-info--balance booking-info__item">
                        <span>{Settings.currencyFormatter.format(guest.deposit_total)} of {Settings.currencyFormatter.format(guest.share)} Paid</span>
                    </div> : null}
                </div>

                <p className="line-through-text"><span>Manage your booking</span></p>

                <div className="booking-actions">
                    <div className="booking-actions--action">
                        <a href="#profile" onClick={(e) => {
                            e.preventDefault();
                            EventDispatcher.dispatch('open-modal', {
                                content: <MyDetails/>,
                                style: 'full-page'
                            });
                        }}>
                            <span>Fill out your profile</span>
                            <Button text={<i className="fa fa-arrow-right"/>} name="invite-guests" variant="circular"/>
                        </a>
                    </div>
                    {guest.deposit_balance > 0 ? <div className="booking-actions--action">
                        <a href="#pay_deposit"
                           onClick={(e) => this.createPaymentAmount(guest.deposit_balance)}>
                            <span>Pay Your Deposit {Settings.currencyFormatter.format(guest.deposit_balance)}</span>
                            <Button text={<i className={this.state.payment_loading ? 'fa fa-spin fa-cog' : "fa fa-arrow-right"}/>}
                                name="pay-deposit" variant="circular"
                                onClick={() => this.createPaymentAmount(guest.balance)}/>
                        </a>
                    </div> : null}
                    {guest.balance > 0 ? <div className="booking-actions--action">
                        <a href="#pay_balance" onClick={(e) => this.createPaymentAmount(guest.balance)}>
                            <span>Pay Your Full Balance {Settings.currencyFormatter.format(guest.balance)}</span>
                            <Button text={<i className={this.state.payment_loading ? 'fa fa-spin fa-cog' : "fa fa-arrow-right"}/>}
                                    name="pay-balance" variant="circular"
                                    onClick={() => this.createPaymentAmount(guest.balance)}/>
                        </a>
                    </div> : null}
                    {guest.has_pre_order ? <div className="booking-actions--action">
                        <a href="#pay_balance" onClick={(e) => this.openPreOrder(e)}>
                            <span>Select your pre-order options</span>
                            <Button text={<i className="fa fa-plus"/>} name="select-pre-order" variant="circular"/>
                        </a>
                    </div> : null}
                </div>
            </div>
        </Fragment>
    }
}

EditBookingGuest.propTypes = {
    booking: PropTypes.object.isRequired
}