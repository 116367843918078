import React, {Component, Fragment} from 'react';
import {Settings} from "../../../Settings/Settings";
import {Api} from "../../../Api/Api";
import {EventDispatcher} from "../../../Events/Dispatcher";
import $ from "jquery";
import Notice from "../../../Utility/Notice/Notice";
import Loading from "../../../Utility/Loading/Loading";
import _ from 'lodash';

export default class StoreCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            height: '300px',
            width: '100%',
            order_id: null,
            check: false,
            show_payment: true,
            button_id: _.uniqueId('button')
        }
    }

    componentDidMount() {
        window.RealexHpp.setHppUrl(Settings.payments.getHppUrl());
        this.startPayment();
    }

    componentWillUnmount() {
        if (this.payment_check_interval) {
            clearInterval(this.payment_check_interval);
        }
    }

    startPayment() {
        let params = {};
        if (this.props.session_id) {
            params.session_id = this.props.session_id;
        }
        if (this.props.booking_id) {
            params.booking_id = this.props.booking_id;
        }
        Api.post('rest/deposits/store-card', params).then((response) => {
            if (response.ok) {
                this.initPayment(response.data.data.order_data);
            }
        })
    }

    initPayment(payment_data) {
        this.setState({order_id: payment_data.ORDER_ID, show_payment: true}, () => {
            window.RealexHpp.embedded.init(this.state.button_id, 'payment_frame', '', payment_data);
            //window.RealexHpp.lightbox.init(this.state.button_id, this.state.button_id, payment_data);

            EventDispatcher.dispatch('update_height');
            this.payment_check_interval = setInterval(() => this.checkPayment(), 4000);
            if (window.addEventListener) {
                window.addEventListener('message', (event) => {
                    this.processMessage(event.data);
                }, false);
            } else {
                window.attachEvent('message', (event) => {
                    this.processMessage(event.data);
                });
            }
            $('#' + this.state.button_id).click();
        });
    }

    checkPayment() {
        Api.get('rest/deposits/store-card/' + this.state.order_id).then((response) => {
            if (response && response.ok) {
                let data = response.data.data;
                if (data.received_response === true) {
                    if (data.error !== null) {
                        this.setState({error: data.error, show_payment: false, button_id: _.uniqueId('button')}, () => {
                            setTimeout(() => this.startPayment(), 500);
                        });
                    } else {
                        if (this.props.onComplete) {
                            this.props.onComplete(data);
                        }
                    }
                    clearInterval(this.payment_check_interval);
                }
            } else {
                this.setState({error: true, loading: false});
            }
        }).catch(() => {

        })
    }

    processMessage(data) {
        try {
            let json = JSON.parse(data);
            if (json.iframe) {
                let height = parseInt(json.iframe.height.replace('px', ''));
                if (height <= 70) {
                    if (this.mounted) {
                        this.setState({height: json.iframe.height, width: json.iframe.width}, () => {
                            EventDispatcher.dispatch('update_height');
                        });
                    }
                }
            }
        } catch (e) {

        }
    }

    render() {
        return <div>
            {this.state.error ?
                <Notice name="payment-error" variant="error">{this.state.error}<br/>Please check your payment
                    information and try again</Notice> : null}
            {this.state.show_payment ? <Fragment>
                <button id={this.state.button_id} style={{display: 'none'}}>Pay Now</button>
                <iframe id="payment_frame" title="Payment" referrerPolicy="origin" style={{
                    height: this.state.height,
                    width: this.state.width,
                    border: 0,
                    minHeight: this.state.height
                }}/>
            </Fragment> : <Loading/>}
        </div>
    }
}