import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FormRow extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('form_element_initialise', {
            detail: {
                type: 'label',
                child_type: this.getChildType(),
                child_name: this.props.children ? this.props.children.name : null,
                ref: this.ref.current
            }
        }));
    }

    getChildType() {
        let child_type = this.props.children ? this.props.children.type.name : '';
        if (child_type === 'FormElementSelect') {
            return 'Select';
        } else if (child_type === 'FormElementText') {
            return 'Text';
        } else if (child_type === 'FormElementCheckbox') {
            return 'Checkbox';
        } else if (child_type === 'FormElementDatePicker') {
            return 'DatePicker';
        } else if (child_type === 'FormElementTimeSelect') {
            return 'Select';
        }

        return null;
    }

    componentWillUnmount() {
        window.dispatchEvent(new CustomEvent('form_element_destroy', {
            detail: {
                type: 'label',
                child_type: this.getChildType(),
                child_name: this.props.children.props ? this.props.children.name : null,
                ref: this.ref.current
            }
        }));
    }

    render() {
        let className = 'form-row';
        if (this.props.error) {
            className = className + ' has-error';
        }
        return (
            <div className={className} ref={this.ref}>
                <label className="form-label"
                       htmlFor={this.props.children ? this.props.children.props.name : ''}>{this.props.label}</label>
                {this.props.children}
            </div>
        )
    }
}

FormRow.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string
};
export default FormRow;