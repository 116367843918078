import React, {Component, Fragment} from 'react';
import {Settings} from "../../../Settings/Settings";
import {BookingData} from "../../Data/BookingData";
import PropTypes from 'prop-types';
import './DepositBar.scss';
import {EventDispatcher} from "../../../Events/Dispatcher";
import DepositInfo from "./DepositInfo";
import cx from 'classnames';
import Button from "../../../Form/Element/Button";
import BalanceInfo from "./BalanceInfo";
import Notice from "../../../Utility/Notice/Notice";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import Tooltip from '../../../Utility/Tooltip';
import PaymentNotice from "../PaymentNotice";
import moment from "moment";
import CompleteBooking from "../CompleteBooking";
import BalanceAmount from "./BalanceAmount";
import TotalAmount from "./TotalAmount";

class DepositBar extends Component {
    componentDidMount() {
        EventDispatcher.dispatch('deposit-bar', true);
    }

    componentWillUnmount() {
        EventDispatcher.dispatch('deposit-bar', false);

    }

    render() {
        let props = this.props;
        let continue_button;
        if (!props.continueFunction && !props.continue) {
            continue_button = true;
        } else {
            continue_button = this.props.continue;
        }
        let continueFunc = props.continueFunction ? props.continueFunction : () => {
            BookingData.update({step: props.next_step})
        }
        let show_deposit = props.show_deposit !== false;
        if (props.booking.step === 99) {
            show_deposit = false;
        }

        let preorderText = <div className={`row mt-3`}>
            <div className={`col-xs-12`}><Notice name="pre_order_required" variant="info">
                We'll need a pre-order for one of your selections but don't worry you can easily do this online after making your booking!
            </Notice></div>
        </div>

        return <div className="deposit-bar clearfix">
            {props.booking.pre_order_required && Settings.customerPreOrderHasCustomerSelectableItems(props.booking) && preorderText}
            {(props.booking.step === 99 || props.booking.step === 4) && props.booking.no_complete_reason ?
                <Notice name="no-complete-info" variant="error">{props.booking.no_complete_reason}</Notice> : null}

            <PaymentNotice booking={props.booking} />

            <div className={cx('deposit-inner', {'hide-deposit': !show_deposit, 'deposit-values': continue_button})}>
                {show_deposit && <Fragment>
                    {props.booking.full_payment_required === false || props.booking.party_type === 91
                        ? <BalanceAmount booking={props.booking} />
                        : <TotalAmount booking={props.booking} />}
                </Fragment>}

                {continue_button ? <Button
                        text={props.complete_text ? props.complete_text : props.booking.complete_text}
                        name="continue"
                        onClick={() => continueFunc()}
                        disabled={!props.can_skip || props.updating}
                    /> : <CompleteBooking
                    booking={props.booking}
                    text={props.complete_text ? props.complete_text : props.booking.complete_text}
                /> }
            </div>
        </div>
    }
}

export default DepositBar;
DepositBar.defaultProps = {
    skip_text: 'Skip',
    next_step: 4,
};

DepositBar.propTypes = {
    booking: PropTypes.object.isRequired,
    next_step: PropTypes.number,
    updating: PropTypes.bool.isRequired,
    skip_text: PropTypes.string,
    continueFunction: PropTypes.func
}