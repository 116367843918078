import React, {useState} from 'react';
import Notice from "../../../../../Utility/Notice/Notice";
import Center from "../../../../../Utility/Center/Center";
import Button from "../../../../../Form/Element/Button";
import {Api} from "../../../../../Api/Api";
import PropTypes from 'prop-types';
import {ModalHelper} from "../../../../../Utility/Modal/AppModal";
import {NotificationManager} from "../../../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../../../Events/Dispatcher";

export default function Remove(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    return <div className="remove-guest">
        <Notice variant="error" name="delete-confirm">
            Are you sure you want to remove this customer from this booking? All of their pre order information will be
            lost.
        </Notice>
        {error ? <Notice name="delete-error" variant="error">{error}</Notice> : null}
        <Center>
            <Button text="Yes, remove this guest" name="remove-guest" onClick={() => {
                setLoading(true);
                Api.delete('rest/booking/' + props.booking.id + '/guest/' + props.guest.id).then((response) => {
                    if (response.ok) {
                        ModalHelper.closeModal();
                        NotificationManager.showSuccess('Guest has been deleted');
                        EventDispatcher.dispatch('booking-update');
                        return;
                    }
                    setLoading(false);
                    setError(response.error);
                })
            }} disabled={loading}/><br/>
            <Button text="Cancel" name="cancel" onClick={(e) => props.goBack(e)} variant="outline"/>
        </Center>
    </div>
}

Remove.propTypes = {
    booking: PropTypes.object.isRequired,
    guest: PropTypes.object.isRequired
}