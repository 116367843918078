const universities = [
    {
        "name": "The University of Aberdeen",
        "latitude": 57.165019,
        "longitude": -2.099122
    },
    {
        "name": "University of Abertay Dundee",
        "latitude": 56.46334,
        "longitude": -2.973441
    },
    {
        "name": "Aberystwyth University",
        "latitude": 52.403473,
        "longitude": -4.043584
    },
    {
        "name": "Anglia Ruskin University",
        "latitude": 51.741381,
        "longitude": 0.474495
    },
    {
        "name": "Aston University",
        "latitude": 52.486637,
        "longitude": -1.890952
    },
    {
        "name": "Bangor University",
        "latitude": 53.229193,
        "longitude": -4.129437
    },
    {
        "name": "Bath Spa University",
        "latitude": 51.373209,
        "longitude": -2.440912
    },
    {
        "name": "The University of Bath",
        "latitude": 51.380441,
        "longitude": -2.330673
    },
    {
        "name": "University of Bedfordshire",
        "latitude": 51.87825,
        "longitude": -0.411539
    },
    {
        "name": "The Queen's University of Belfast",
        "latitude": 54.584772,
        "longitude": -5.934438
    },
    {
        "name": "Birkbeck College",
        "latitude": 51.521891,
        "longitude": -0.130359
    },
    {
        "name": "Birmingham City University",
        "latitude": 52.517271,
        "longitude": -1.897287
    },
    {
        "name": "The University of Birmingham",
        "latitude": 52.451408,
        "longitude": -1.930047
    },
    {
        "name": "Bishop Grosseteste University",
        "latitude": 53.243563,
        "longitude": -0.537139
    },
    {
        "name": "The University of Bolton",
        "latitude": 53.573511,
        "longitude": -2.436238
    },
    {
        "name": "The Arts University Bournemouth",
        "latitude": 50.74137,
        "longitude": -1.898107
    },
    {
        "name": "Bournemouth University",
        "latitude": 50.742979,
        "longitude": -1.897168
    },
    {
        "name": "The University of Bradford",
        "latitude": 53.791242,
        "longitude": -1.766231
    },
    {
        "name": "The University of Brighton",
        "latitude": 50.842454,
        "longitude": -0.11917
    },
    {
        "name": "The University of Bristol",
        "latitude": 51.459066,
        "longitude": -2.603291
    },
    {
        "name": "Brunel University London",
        "latitude": 51.532838,
        "longitude": -0.472856
    },
    {
        "name": "The University of Buckingham",
        "latitude": 51.995998,
        "longitude": -0.991839
    },
    {
        "name": "Buckinghamshire New University",
        "latitude": 51.628497,
        "longitude": -0.753097
    },
    {
        "name": "The University of Cambridge",
        "latitude": 52.205296,
        "longitude": 0.117067
    },
    {
        "name": "The Institute of Cancer Research",
        "latitude": 51.490831,
        "longitude": -0.181284
    },
    {
        "name": "Canterbury Christ Church University",
        "latitude": 51.279643,
        "longitude": 1.089364
    },
    {
        "name": "Cardiff University",
        "latitude": 51.489057,
        "longitude": -3.179825
    },
    {
        "name": "Cardiff Metropolitan University",
        "latitude": 51.483608,
        "longitude": -3.223624
    },
    {
        "name": "The University of Central Lancashire",
        "latitude": 53.761338,
        "longitude": -2.707384
    },
    {
        "name": "University of Chester ",
        "latitude": 53.198641,
        "longitude": -2.898061
    },
    {
        "name": "The University of Chichester",
        "latitude": 50.84431,
        "longitude": -0.77501
    },
    {
        "name": "The City University",
        "latitude": 51.527704,
        "longitude": -0.102614
    },
    {
        "name": "Conservatoire for Dance and Drama",
        "latitude": 51.526206,
        "longitude": -0.129115
    },
    {
        "name": "Courtauld Institute of Art",
        "latitude": 51.510732,
        "longitude": -0.116938
    },
    {
        "name": "Coventry University",
        "latitude": 52.405314,
        "longitude": -1.500063
    },
    {
        "name": "Cranfield University",
        "latitude": 52.073918,
        "longitude": -0.628755
    },
    {
        "name": "University for the Creative Arts ",
        "latitude": 51.215684,
        "longitude": -0.805973
    },
    {
        "name": "University of Cumbria",
        "latitude": 54.891163,
        "longitude": -2.921417
    },
    {
        "name": "De Montfort University",
        "latitude": 52.629286,
        "longitude": -1.137817
    },
    {
        "name": "University of Derby",
        "latitude": 52.938527,
        "longitude": -1.496681
    },
    {
        "name": "The University of Dundee",
        "latitude": 56.457345,
        "longitude": -2.978269
    },
    {
        "name": "University of Durham",
        "latitude": 54.768112,
        "longitude": -1.571874
    },
    {
        "name": "The University of East Anglia",
        "latitude": 52.622369,
        "longitude": 1.241391
    },
    {
        "name": "The University of East London",
        "latitude": 51.507485,
        "longitude": 0.064759
    },
    {
        "name": "Edge Hill University",
        "latitude": 53.559608,
        "longitude": -2.873836
    },
    {
        "name": "The University of Edinburgh",
        "latitude": 55.947691,
        "longitude": -3.187347
    },
    {
        "name": "The University of Essex",
        "latitude": 51.876715,
        "longitude": 0.946666
    },
    {
        "name": "The University of Exeter",
        "latitude": 50.735156,
        "longitude": -3.535048
    },
    {
        "name": "Falmouth University",
        "latitude": 50.149625,
        "longitude": -5.071079
    },
    {
        "name": "Glasgow Caledonian University",
        "latitude": 55.86729,
        "longitude": -4.250057
    },
    {
        "name": "Glasgow School of Art",
        "latitude": 55.865958,
        "longitude": -4.263405
    },
    {
        "name": "The University of Glasgow",
        "latitude": 55.871751,
        "longitude": -4.28836
    },
    {
        "name": "University of Gloucestershire",
        "latitude": 51.887496,
        "longitude": -2.088788
    },
    {
        "name": "Glynd?r University",
        "latitude": 53.052626,
        "longitude": -3.006109
    },
    {
        "name": "Goldsmiths College",
        "latitude": 51.474144,
        "longitude": -0.035401
    },
    {
        "name": "The University of Greenwich",
        "latitude": 51.484468,
        "longitude": -0.004005
    },
    {
        "name": "Guildhall School of Music and Drama",
        "latitude": 51.519517,
        "longitude": -0.092374
    },
    {
        "name": "Harper Adams University",
        "latitude": 52.779896,
        "longitude": -2.425558
    },
    {
        "name": "Heriot-Watt University",
        "latitude": 55.909385,
        "longitude": -3.320682
    },
    {
        "name": "University of Hertfordshire",
        "latitude": 51.753128,
        "longitude": -0.240975
    },
    {
        "name": "Heythrop College",
        "latitude": 51.499883,
        "longitude": -0.190908
    },
    {
        "name": "The University of Huddersfield",
        "latitude": 53.643723,
        "longitude": -1.776864
    },
    {
        "name": "The University of Hull",
        "latitude": 53.771778,
        "longitude": -0.366384
    },
    {
        "name": "Imperial College of Science, Technology and Medicine",
        "latitude": 51.498317,
        "longitude": -0.176923
    },
    {
        "name": "Institute of Education",
        "latitude": 51.522977,
        "longitude": -0.127965
    },
    {
        "name": "The University of Keele",
        "latitude": 53.003262,
        "longitude": -2.273581
    },
    {
        "name": "The University of Kent",
        "latitude": 51.298503,
        "longitude": 1.070975
    },
    {
        "name": "King's College London",
        "latitude": 51.511612,
        "longitude": -0.116253
    },
    {
        "name": "Kingston University",
        "latitude": 51.406309,
        "longitude": -0.30786
    },
    {
        "name": "Leeds College of Art ",
        "latitude": 53.808429,
        "longitude": -1.551775
    },
    {
        "name": "Liverpool School of Tropical Medicine",
        "latitude": 53.408563,
        "longitude": -2.969135
    },
    {
        "name": "University of Wales Trinity Saint David",
        "latitude": 51.858361,
        "longitude": -4.328713
    },
    {
        "name": "The University of Lancaster",
        "latitude": 54.010418,
        "longitude": -2.786451
    },
    {
        "name": "Leeds Beckett University",
        "latitude": 53.826337,
        "longitude": -1.59075
    },
    {
        "name": "Leeds Trinity University",
        "latitude": 53.84878,
        "longitude": -1.64735
    },
    {
        "name": "The University of Leeds",
        "latitude": 53.807958,
        "longitude": -1.553329
    },
    {
        "name": "The University of Leicester",
        "latitude": 52.622086,
        "longitude": -1.124531
    },
    {
        "name": "The University of Lincoln",
        "latitude": 53.228709,
        "longitude": -0.548641
    },
    {
        "name": "Liverpool Hope University",
        "latitude": 53.391475,
        "longitude": -2.891936
    },
    {
        "name": "The Liverpool Institute for Performing Arts ",
        "latitude": 53.399657,
        "longitude": -2.972271
    },
    {
        "name": "Liverpool John Moores University",
        "latitude": 53.403685,
        "longitude": -2.973176
    },
    {
        "name": "The University of Liverpool",
        "latitude": 53.380314,
        "longitude": -2.976431
    },
    {
        "name": "London Business School",
        "latitude": 51.526581,
        "longitude": -0.161378
    },
    {
        "name": "University of London (Institutes and activities)",
        "latitude": 51.520642,
        "longitude": -0.128724
    },
    {
        "name": "London Metropolitan University",
        "latitude": 51.551471,
        "longitude": -0.110381
    },
    {
        "name": "London School of Economics and Political Science",
        "latitude": 51.514114,
        "longitude": -0.116971
    },
    {
        "name": "London School of Hygiene and Tropical Medicine",
        "latitude": 51.520925,
        "longitude": -0.130687
    },
    {
        "name": "University of the Arts, London",
        "latitude": 51.517765,
        "longitude": -0.116388
    },
    {
        "name": "Loughborough University",
        "latitude": 52.764828,
        "longitude": -1.22952
    },
    {
        "name": "The Manchester Metropolitan University",
        "latitude": 53.470917,
        "longitude": -2.239115
    },
    {
        "name": "The University of Manchester",
        "latitude": 53.467926,
        "longitude": -2.233224
    },
    {
        "name": "Middlesex University",
        "latitude": 51.589826,
        "longitude": -0.229909
    },
    {
        "name": "Edinburgh Napier University",
        "latitude": 55.91805,
        "longitude": -3.24006
    },
    {
        "name": "University of Newcastle-upon-Tyne",
        "latitude": 54.98032,
        "longitude": -1.615713
    },
    {
        "name": "Newman University",
        "latitude": 52.434122,
        "longitude": -1.995028
    },
    {
        "name": "The University of Northampton",
        "latitude": 52.271992,
        "longitude": -0.883103
    },
    {
        "name": "University of Northumbria at Newcastle",
        "latitude": 54.976897,
        "longitude": -1.607575
    },
    {
        "name": "Norwich University of the Arts",
        "latitude": 52.630494,
        "longitude": 1.297272
    },
    {
        "name": "The Nottingham Trent University",
        "latitude": 52.956436,
        "longitude": -1.152077
    },
    {
        "name": "University of Nottingham",
        "latitude": 52.938184,
        "longitude": -1.19438
    },
    {
        "name": "Oxford Brookes University",
        "latitude": 51.75438,
        "longitude": -1.2232
    },
    {
        "name": "The University of Oxford",
        "latitude": 51.758038,
        "longitude": -1.26201
    },
    {
        "name": "Plymouth College of Art",
        "latitude": 50.37341,
        "longitude": -4.137411
    },
    {
        "name": "University of Plymouth",
        "latitude": 50.375329,
        "longitude": -4.138735
    },
    {
        "name": "The University of Portsmouth",
        "latitude": 50.795187,
        "longitude": -1.093628
    },
    {
        "name": "Queen Margaret University, Edinburgh",
        "latitude": 55.931281,
        "longitude": -3.073057
    },
    {
        "name": "Queen Mary University of London",
        "latitude": 51.524636,
        "longitude": -0.04071
    },
    {
        "name": "Ravensbourne",
        "latitude": 51.501677,
        "longitude": 0.005651
    },
    {
        "name": "The University of Reading",
        "latitude": 51.457625,
        "longitude": -0.945636
    },
    {
        "name": "The Robert Gordon University",
        "latitude": 57.118344,
        "longitude": -2.14474
    },
    {
        "name": "Roehampton University ",
        "latitude": 51.455585,
        "longitude": -0.247673
    },
    {
        "name": "Rose Bruford College",
        "latitude": 51.439271,
        "longitude": 0.106446
    },
    {
        "name": "Royal Academy of Music",
        "latitude": 51.523497,
        "longitude": -0.1517
    },
    {
        "name": "Royal Agricultural University",
        "latitude": 51.709508,
        "longitude": -1.994916
    },
    {
        "name": "The Royal Central School of Speech and Drama",
        "latitude": 51.544325,
        "longitude": -0.174205
    },
    {
        "name": "Royal College of Art",
        "latitude": 51.501354,
        "longitude": -0.178473
    },
    {
        "name": "Royal College of Music",
        "latitude": 51.499279,
        "longitude": -0.176913
    },
    {
        "name": "Royal Conservatoire of Scotland",
        "latitude": 55.865813,
        "longitude": -4.257595
    },
    {
        "name": "Royal Holloway and Bedford New College",
        "latitude": 51.424814,
        "longitude": -0.566745
    },
    {
        "name": "Royal Northern College of Music",
        "latitude": 53.468557,
        "longitude": -2.236963
    },
    {
        "name": "The Royal Veterinary College",
        "latitude": 51.536851,
        "longitude": -0.133524
    },
    {
        "name": "SRUC",
        "latitude": 55.921623,
        "longitude": -3.176228
    },
    {
        "name": "The University of St Andrews",
        "latitude": 56.341239,
        "longitude": -2.793287
    },
    {
        "name": "St George's Hospital Medical School",
        "latitude": 51.427798,
        "longitude": -0.175228
    },
    {
        "name": "University of St Mark and St John",
        "latitude": 50.420866,
        "longitude": -4.110758
    },
    {
        "name": "St Mary's University College",
        "latitude": 54.593162,
        "longitude": -5.961323
    },
    {
        "name": "St Mary's University, Twickenham",
        "latitude": 51.436152,
        "longitude": -0.335907
    },
    {
        "name": "The University of Salford",
        "latitude": 53.483487,
        "longitude": -2.270651
    },
    {
        "name": "The School of Oriental and African Studies",
        "latitude": 51.52235,
        "longitude": -0.129259
    },
    {
        "name": "Sheffield Hallam University",
        "latitude": 53.379194,
        "longitude": -1.465255
    },
    {
        "name": "The University of Sheffield",
        "latitude": 53.381363,
        "longitude": -1.48853
    },
    {
        "name": "London South Bank University",
        "latitude": 51.498708,
        "longitude": -0.101772
    },
    {
        "name": "University of South Wales",
        "latitude": 51.587622,
        "longitude": -3.327445
    },
    {
        "name": "Southampton Solent University",
        "latitude": 50.907747,
        "longitude": -1.400166
    },
    {
        "name": "The University of Southampton",
        "latitude": 50.934099,
        "longitude": -1.395714
    },
    {
        "name": "Staffordshire University",
        "latitude": 52.812975,
        "longitude": -2.081855
    },
    {
        "name": "The University of Stirling",
        "latitude": 56.145463,
        "longitude": -3.920023
    },
    {
        "name": "Stranmillis University College",
        "latitude": 54.571733,
        "longitude": -5.936458
    },
    {
        "name": "The University of Strathclyde",
        "latitude": 55.86125,
        "longitude": -4.244706
    },
    {
        "name": "The University of Sunderland",
        "latitude": 54.904456,
        "longitude": -1.390973
    },
    {
        "name": "The University of Surrey",
        "latitude": 51.242572,
        "longitude": -0.587946
    },
    {
        "name": "The University of Sussex",
        "latitude": 50.86447,
        "longitude": -0.087076
    },
    {
        "name": "Swansea University",
        "latitude": 51.609627,
        "longitude": -3.980616
    },
    {
        "name": "The National Film and Television School",
        "latitude": 51.604752,
        "longitude": -0.638385
    },
    {
        "name": "Teesside University",
        "latitude": 54.572076,
        "longitude": -1.234694
    },
    {
        "name": "The University of West London ",
        "latitude": 51.507259,
        "longitude": -0.303013
    },
    {
        "name": "The Open University",
        "latitude": 52.024914,
        "longitude": -0.709747
    },
    {
        "name": "The University of Wales",
        "latitude": 51.485538,
        "longitude": -3.181031
    },
    {
        "name": "Trinity Laban Conservatoire of Music and Dance",
        "latitude": 51.483587,
        "longitude": -0.007198
    },
    {
        "name": "University of the Highlands and Islands",
        "latitude": 57.469405,
        "longitude": -4.230246
    },
    {
        "name": "University of Ulster",
        "latitude": 55.149335,
        "longitude": -6.676089
    },
    {
        "name": "University Campus Suffolk",
        "latitude": 52.052392,
        "longitude": 1.163459
    },
    {
        "name": "University College Birmingham",
        "latitude": 52.48184,
        "longitude": -1.905939
    },
    {
        "name": "University College London",
        "latitude": 51.523569,
        "longitude": -0.132424
    },
    {
        "name": "The University of Warwick",
        "latitude": 52.385576,
        "longitude": -1.564787
    },
    {
        "name": "University of the West of England, Bristol",
        "latitude": 51.501079,
        "longitude": -2.547458
    },
    {
        "name": "The University of the West of Scotland",
        "latitude": 55.843316,
        "longitude": -4.429782
    },
    {
        "name": "The University of Westminster",
        "latitude": 51.516792,
        "longitude": -0.143367
    },
    {
        "name": "The University of Winchester",
        "latitude": 51.060002,
        "longitude": -1.32674
    },
    {
        "name": "The University of Wolverhampton",
        "latitude": 52.587529,
        "longitude": -2.12806
    },
    {
        "name": "University of Worcester",
        "latitude": 52.197366,
        "longitude": -2.243085
    },
    {
        "name": "Writtle College",
        "latitude": 51.737196,
        "longitude": 0.428133
    },
    {
        "name": "York St John University",
        "latitude": 53.964844,
        "longitude": -1.079543
    },
    {
        "name": "The University of York",
        "latitude": 53.948278,
        "longitude": -1.051673
    }
];

export default universities;