import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ContentBox from "../../Utility/ContentBox/ContentBox";
import FormRow from "../../Form/Row";
import FormElementText from "../../Form/Element/Text";
import './PaymentRequest.scss';
import {Api} from "../../Api/Api";
import moment from 'moment';
import {Settings} from "../../Settings/Settings";

class PaymentRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            code: '',
            stage: 'code',
            request: {},
            error: null
        }
    }

    checkCode(e) {
        e.preventDefault();
        Api.get('rest/deposits/payment-request', {code: this.state.code, include: 'booking'}).then((response) => {
            if (!response.ok) {
                this.setState({error: response.data.errors.message});
            } else {
                this.setState({request: response.data.data, stage: 'request'});
            }
        }).catch(function (e) {

        })
    }

    handleNonAmountChange = (e) => {
        e.preventDefault();
        Api.post('rest/deposits/payment-request/create-deposit', {
            payment_code: this.state.code,
            amount: this.state.request.amount
        }).then((response) => {
            if (response.ok === false) {
                this.setState({error: response.data.errors.message});
                return;
            }
            window.location = '/deposit/' + response.data.data.token
        });
    }

    render() {
        let content = <Fragment>
            <p>Some people say money can’t buy happiness, but it can buy vodka which is pretty much the same
                thing.</p>

            <p>We can’t wait for you to join us, before you do we need to get the boring bit out of the way
                first.</p>
        </Fragment>

        if (Settings.brand === 'decuba') {
            content = <Fragment>
                <p>Some people say money can’t buy happiness, but it can buy rum which is pretty much the same
                    thing.</p>

                <p>We can’t wait for you to join us, before you do we need to get the boring bit out of the way
                    first.</p>
            </Fragment>
        }
        return <Fragment>
            <ContentBox className="payment-request" title="Payment Request">
                {this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : null}
                {this.state.stage === 'code' ?
                    <form onSubmit={(e) => this.checkCode(e)}>
                        {content}
                        <FormRow label="Payment Code">
                            <FormElementText name="code" onChange={(e) => this.setState({code: e.target.value})}
                                             value={this.state.code} placeholder="Enter your payment code"/>
                        </FormRow>
                        <button className="submit" type="submit">Check Code</button>
                    </form> : null}

                {this.state.stage === 'request' ? <form>
                    <p>You are about to
                        pay <strong>{Settings.currencyFormatter.format(this.state.request.amount)}</strong> for
                        booking <strong>{this.state.request.booking.data.ref_code}</strong> on <strong>
                            {moment(this.state.request.booking.data.booking_date).format('DD/MM/YYYY HH:mm')}</strong>
                    </p>
                    <p>
                        <button className="btn btn-success"
                                onClick={(e) => this.handleNonAmountChange(e)}>Yes
                        </button>
                    </p>
                </form> : null}
            </ContentBox>
        </Fragment>
    }
}

PaymentRequest.propTypes = {
    code: PropTypes.string.isRequired
}


export default PaymentRequest;