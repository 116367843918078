import 'intl';
import React from 'react'


export const Settings = {
    currencyFormatter: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2
    }),
    currencyFormatterRounded: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
    }),
    environment: window.AppSettings ? window.AppSettings.env : 'local',
    brand: window.brand,
    getBrandId: () => {
        if (Settings.brand === 'revolution') {
            return 1;
        }
        if (Settings.brand === 'decuba') {
            return 2;
        }
        if (Settings.brand === 'playhouse') {
            return 4;
        }

        return 0;
    },
    getPartyName: () => {
        if (Settings.brand === 'decuba') {
            return 'Fiesta';
        }

        return 'Party';
    },
    getCustomerIncludes: () => {
        if (Settings.getBrandId() === 1) {
            return [];
        }
        return [];
    },
    date_formats: {
        summary_full_date_time: 'HH:mm on dddd Do MMMM YYYY',
        human_full_date_time: 'dddd Do MMM, YYYY HH:mm',
        human_full_date: 'dddd Do MMM, YYYY',
        full_date_time: 'MMM Do, YYYY HH:mm',
        full_date: 'MMM Do, YYYY'
    },
    booking_date_format: 'MMM Do, YYYY HH:mm',
    booking_date_format_short: 'MMM Do, YYYY',
    drink: null,
    getBrandWelcome: () => {
        if (Settings.brand === 'decuba') {
            return 'Hola ';
        }
        return 'Hello ';
    },
    getBrandDrink: () => {
        if (!Settings.drink) {
            let drinks = ['Classic Mojito'];
            if (Settings.brand === 'revolution') {
                drinks = ['Pornstar Martini', 'Long Island Iced Tea', 'Bubblegum Daiquiri', 'Vodka Revbull']
            }
            if (Settings.brand === 'playhouse') {
                drinks = ['Pornstar Martini']
            }
            if (Settings.brand === 'decuba') {
                drinks = ['Pornstar Rumtini', 'Daiquiri', 'Espresso Rumtini', 'Zombie', 'Cuba Libre'];
            }
            Settings.drink = drinks[Math.ceil(Math.random() * drinks.length) - 1];
        }
        return Settings.drink;
    },
    getGooglePayEnv: () => {
        return window.AppSettings.google_pay_env ? window.AppSettings.google_pay_env : 'TEST';
    },
    getPaymentResponseUrl: () => {
        if (Settings.getEnvironment() === 'local') {
            return 'https://rev-bookings.eu.ngrok.io';
        }
        if (Settings.brand === 'decuba') {
            return process.env.REACT_APP_HOST_RDC;
        }
        if (Settings.brand === 'revolution') {
            return process.env.REACT_APP_HOST_REV;
        }
        if (Settings.brand === 'revolution') {
            return process.env.REACT_APP_HOST_PLAYHOUSE;
        }
    },
    getBookingsUrl: function () {
        if (Settings.getEnvironment() === 'local') {
            return 'https://rev-bookings.eu.ngrok.io';
        }
        if (Settings.brand === 'decuba') {
            return process.env.REACT_APP_HOST_RDC;
        }
        if (Settings.brand === 'revolution') {
            return process.env.REACT_APP_HOST_REV;
        }
        if (Settings.brand === 'playhouse') {
            return process.env.REACT_APP_HOST_REV;
        }
    },
    getBrandName: function () {
        if (this.brand === 'revolution') {
            return 'Revolution Bars';
        }
        if (this.brand === 'decuba') {
            return 'Revolución de Cuba';
        }
        if (this.brand === 'playhouse') {
            return 'Playhouse';
        }
    },
    getTermsUrl: function () {
        if (this.brand === 'revolution') {
            return 'https://www.revolution-bars.co.uk/terms-and-conditions/';
        }
        if (this.brand === 'decuba') {
            return 'http://www.revoluciondecuba.com/terms-conditions/';
        }
        if (this.brand === 'playhouse') {
            return 'http://www.playhouse-bars.com/terms-conditions/';
        }
    },
    getAllergensUrl: function () {
        if (this.brand === 'revolution') {
            return 'https://revbars.com/allergens';
        }
        if (this.brand === 'decuba') {
            return 'https://revcuba.com/allergens';
        }
        if (this.brand === 'playhouse') {
            return 'https://book.playhouse-bars.com/allergens';
        }
    },
    getEnvironment: function () {
        return (window.AppSettings && window.AppSettings.env) ? window.AppSettings.env : 'local';
    },
    getApiUrl() {
        return '/api/';
    },
    payments: {
        getHppUrl: function () {
            if (Settings.getEnvironment() === 'local') {
                return 'https://pay.sandbox.realexpayments.com/pay'
            } else {
                return 'https://pay.realexpayments.com/pay'
            }
        },
        getMerchantResponseUrl() {
            if (Settings.environment === 'local') {
                return 'https://rbgapi.eu.ngrok.io/rest/deposit/payment/callback';
            }
            return Settings.getApiUrl() + 'rest/deposit/payment/callback';
        }
    },
    getStatusDescription(status_id) {
        if (status_id === 0) {
            return 'Provisional bookings mean we\'re waiting on your deposit to get you booked in!';
        }
        if (status_id === 1) {
            return 'Your booking is confirmed and you\'re ready to party the night away!';
        }
        if (status_id === 2) {
            return 'No show? Come on, you didn\'t show up?!';
        }
        if (status_id === 3) {
            return 'Your booking is pending. Could be that we\'re waiting on further information from you, or a deposit payment to be completed';
        }
        if (status_id === 4) {
            return 'Your booking is cancelled';
        }
        if (status_id === 5) {
            return 'Your booking is postponed. We could be waiting on a new date from you, or waiting on additional information';
        }
    },
    isBespokePlu(plu) {
        return [9461, 9462, 9463, 9464, 9573, 9574].indexOf(parseInt(plu)) > -1;
    },
    bookingHasSelectablePreOrder(booking) {
        let hasSelectableItems = false;
        booking.available_options && booking.available_options.data.forEach((option) => {
            option.variations.data.forEach((variation) => {
                variation.choices.data.forEach((choice) => {
                    choice.choices.forEach((c) => {
                        c.items.forEach((item) => {
                            if (item.selectable) {
                                hasSelectableItems = true;
                            }
                        })
                    })
                })
            })
        })
        return hasSelectableItems;
    },
    customerPreOrderHasCustomerSelectableItems: function(booking) {
        let returnValue = false;
        booking.variations.data.length > 0 && booking.variations.data.forEach(function (pov) {
            booking.available_options && booking.available_options.data.forEach((option) => {
                option.variations.data.forEach((variation) => {
                    if (variation.id === pov.variation_id) {
                        variation.choices.data.forEach((choice) => {
                            choice.choices.forEach((c) => {
                                c.items.forEach((item) => {
                                    if (item.selectable) {
                                        returnValue = true;
                                    }
                                })
                            })
                        })
                    }

                })
            })
        })
        return returnValue;
    },
    getEnquiryMessage: function () {
        if (this.brand === 'decuba') {
            return <p>Sorry Amigo, we can’t book this in automatically. Just fill in your details below and one of our team will be in touch to talk through the details and get your enquiry confirmed.</p>;
        }

        return <p>Sorry, we can’t book this in automatically. Just fill in your details below and one of our team will be in touch to talk through the details and get your enquiry confirmed.</p>;
    }
}