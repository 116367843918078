import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Payment from "./Payment";
import {Settings} from "../../../Settings/Settings";
import {Api} from "../../../Api/Api";
import {BookingData} from "../../Data/BookingData";
import Loading from "../../../Utility/Loading/Loading";
import {NotificationManager} from "../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../Events/Dispatcher";
import Button from "../../../Form/Element/Button";

class PayAmount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            has_apple_pay: false,
            has_google_pay: false,
            apple_loading: false,
            ap_order_id: '',
            error: null
        }
    }

    componentDidMount() {
        this.checkPaymentTypes();
    }

    checkPaymentTypes() {
        this.checkApplePay(() => {
            this.checkGooglePay(() => {
                if (this.state.has_google_pay) {
                    const button =
                        this.paymentsClient.createButton({onClick: (e) => this.handleGooglePay(e)});
                    document.getElementById('payment_buttons').appendChild(button);
                }
                if (!this.state.has_apple_pay && !this.state.has_google_pay) {
                    this.setState({type: 'card'});
                }
            });
        });
    }

    handleGooglePay() {
        this.setState({payment_loading: true});
        Api.post('rest/deposit/google-pay/verify', {
            amount: this.props.amount.toFixed(2),
            deposit_request_token: this.props.deposit_request_token,
            token: this.props.token
        }).then((response) => {
            let google_pay_data = response.data.meta.google_pay;
            const cardPaymentMethod = Object.assign(
                {
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            'gateway': 'globalpayments',
                            'gatewayMerchantId': google_pay_data.gp_merchant
                        }
                    }
                },
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: google_pay_data.allowed_methods,
                        allowedCardNetworks: google_pay_data.allowed_networks
                    }
                }
            );
            const paymentDataRequest = Object.assign({}, this.getGooglePayBaseRequest());
            paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
            paymentDataRequest.transactionInfo = {
                totalPriceStatus: 'FINAL',
                totalPrice: response.data.data.amount.toFixed(2),
                currencyCode: 'GBP',
                countryCode: 'GB'
            };
            paymentDataRequest.merchantInfo = {
                merchantName: google_pay_data.merchant_name,
                merchantId: google_pay_data.merchant_id
            };
            if (response.ok) {
                let data = response.data.data;
                let gp = data.gp.data;
                this.setState({ap_order_id: gp.order_id});
                this.paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
                    // if using gateway tokenization, pass this token without modification
                    let paymentToken = paymentData.paymentMethodData.tokenizationData.token;
                    Api.post('rest/deposit/digital-wallet/process', {
                        order_id: this.state.ap_order_id,
                        type: 'google_pay',
                        token: paymentToken
                    }).then((response) => {
                        if (response.ok) {
                            this.props.onComplete(response.data.data);
                        }
                        this.setState({
                            payment_loading: false,
                            error: 'There was an error in authorising your google pay payment'
                        });
                    });
                }).catch((err) => {
                    this.setState({
                        payment_loading: false,
                        error: 'There was an error in authorising your google pay payment'
                    });
                });
            }
        });
    }

    getGooglePayBaseRequest() {
        const baseRequest = {
            apiVersion: 2,
            apiVersionMinor: 0
        };
        return Object.assign({}, baseRequest);
    }

    checkGooglePay(callback) {
        if (!window.google) {
            callback();
            return;
        }
        this.paymentsClient =
            new window.google.payments.api.PaymentsClient({environment: Settings.getGooglePayEnv()});
        const isReadyToPayRequest = this.getGooglePayBaseRequest();
        isReadyToPayRequest.allowedPaymentMethods = [{
            type: 'CARD',
            parameters: {
                allowedAuthMethods: ['CRYPTOGRAM_3DS'],
                //allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'], //Use pan only for testing locally
                allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA']
            }
        }];
        this.paymentsClient.isReadyToPay(isReadyToPayRequest)
            .then((response) => {
                if (response.result) {
                    this.setState({has_google_pay: true}, () => callback());
                } else {
                    callback();
                }
            })
            .catch(function (err) {
                NotificationManager.showError(err);
            });
    }

    checkApplePay(callback) {
        let ApplePay = window.ApplePaySession;
        if (ApplePay && ApplePay.canMakePayments) {
            this.setState({has_apple_pay: true}, () => callback());
        } else {
            callback();
        }
    }

    startApplePay() {
        let amount = this.props.amount;
        if (BookingData.inIframe() === true) {
            BookingData.sendMessage('apple_pay', {
                token: this.props.token,
                label: Settings.getBrandName(),
                deposit_request_token: this.props.deposit_request_token,
                amount: amount.toFixed(2)
            });
            EventDispatcher.subscribe('apple_pay_complete', (data) => {
                this.props.onComplete(data);
            });
            return;
        }
        let ApplePay = window.ApplePaySession;
        if (ApplePay) {
            this.setState({apple_loading: true, payment_loading: true});
            let session = new ApplePay(6, {
                currencyCode: 'GBP',
                countryCode: 'GB',
                merchantCapabilities: [
                    'supports3DS',
                    'supportsCredit',
                    'supportsDebit'
                ],
                supportedNetworks: ['masterCard', 'maestro', 'visa', 'amex'],
                total: {
                    label: Settings.getBrandName(),
                    amount: amount.toFixed(2)
                }
            });
            session.onvalidatemerchant = (event) => {
                console.log('Validate');
                Api.post('rest/deposit/apple-pay/verify', {
                    url: event.validationURL,
                    amount: amount,
                    deposit_request_token: this.props.deposit_request_token,
                    token: this.props.token
                }).then((response) => {
                    if (response.ok) {
                        let data = response.data.data;
                        let gp = data.gp.data;
                        this.setState({ap_order_id: gp.order_id})
                        session.completeMerchantValidation(gp.ap_data);
                    }
                });
            }
            session.oncancel = (event) => {
                this.setState({payment_loading: false, error: 'Apple Pay Cancelled'});
            }
            session.onpaymentauthorized = (event) => {
                Api.post('rest/deposit/digital-wallet/process', {
                    order_id: this.state.ap_order_id,
                    type: 'apple_pay',
                    token: JSON.stringify(event.payment.token)
                }).then((response) => {
                    this.setState({payment_loading: false});
                    if (response.ok) {
                        if (response.data.data.success) {
                            session.completePayment(session.STATUS_SUCCESS);
                            setTimeout(() => {
                                this.props.onComplete(response.data.data);
                            }, 1000);
                        } else {
                            this.setState({error: 'There was an error in authorising your apple pay payment'});
                            session.completePayment(session.STATUS_FAILURE)
                        }
                    } else {
                        this.setState({error: 'There was an error in authorising your apple pay payment'});
                        session.completePayment(session.STATUS_FAILURE)
                    }
                });
            }
            session.begin();
        }
    }

    render() {
        if (this.state.type === 'card') {
            return <Fragment>
                <div className="pay-amount">
                    {this.state.payment_loading ? <div className="payment-loading"><Loading/></div> : null}
                    <p className="payment-summary">
                        Payment Amount: <span>{Settings.currencyFormatter.format(this.props.amount)}</span>
                    </p>
                    <Payment code={this.props.token} amount={this.props.amount}
                             deposit_request_token={this.props.deposit_request_token}
                             onComplete={(data) => this.props.onComplete(data)}/>
                </div>
            </Fragment>
        }
        return <Fragment>
            <div className="pay-amount">
                {this.state.payment_loading ? <div className="payment-loading"><Loading/></div> : null}
                {this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : null}
                <p className="payment-summary">
                    Payment Amount: {Settings.currencyFormatter.format(this.props.amount)}.
                </p>
                <div id="payment_buttons">
                    <Button onClick={() => {
                        this.setState({type: 'card'})
                    }} text="Pay by card" name="pay-by-card"/>
                    {this.state.has_apple_pay ?
                        <button className="apple-pay-button" disabled={this.state.payment_loading}
                                onClick={() => this.startApplePay()}>
                        </button> : null}
                </div>
            </div>
        </Fragment>
    }
}

PayAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    deposit_request_token: PropTypes.string
}

export default PayAmount;
