import React from 'react';
import PropTypes from 'prop-types'
import './SwitchCheckbox.scss'

const SwitchCheckbox = ({value, name='', label, onChange, labelPlacement = 'left'}) => {
    return (
        <div className={`switch-container ${labelPlacement}`}>
            <span className='label'>{label}</span>
            <label className='switch'>
                <input type="checkbox" name={name} checked={value} value={value} onChange={onChange}/>
                <div></div>
            </label>

        </div>
    )
}

export default SwitchCheckbox;

SwitchCheckbox.propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    labelPlacement: PropTypes.oneOf(['left', 'top', 'right', 'bottom'])
}