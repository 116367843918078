import _ from 'lodash';

export const EventDispatcher = {
    events: [],
    subscribe: function (event, callback) {
        var id = Math.floor(Math.random() * 10000000);
        this.events.push({
            id: id,
            event: event,
            callback: callback
        });
        return id;
    },
    unsubscribe: function (id) {
        var index = this.getIndexForId(id);
        if (index > -1) {
            this.events.splice(index, 1);
        }
    },
    getIndexForId: function (id) {
        for (var x = 0; x < this.events.length; x++) {
            if (this.events[x].id === id) {
                return x;
            }
        }
        return null;
    },
    closeModal: function () {
        this.dispatch('close-modal');
    },
    dispatch: function (event_name, data, completeCallback) {
        var events = _.filter(this.events, function (event) {
            return event.event === event_name;
        });

        for (var x = 0; x < events.length; x++) {
            events[x].callback(data);
        }

        if (completeCallback) {
            completeCallback();
        }
    }
};