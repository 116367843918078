import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Notice from "../../../../Utility/Notice/Notice";
import Button from "../../../../Form/Element/Button";
import {Api} from "../../../../Api/Api";
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import '../../CancelBooking/CancelBooking.scss';
import {EventDispatcher} from "../../../../Events/Dispatcher";

export default function CancelBooking(props) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        ModalHelper.setButtons(<Fragment>
            <Button text="Go Back" name="go-back" variant="outline" onClick={(e) => {
                ModalHelper.closeModal();
            }}/>
            {props.booking.can_cancel ? <Button text="Confirm" name="cancel cancel-confirm" onClick={() => {
                setLoading(true);
                Api.delete('rest/my-bookings/token/' + props.token).then((response) => {
                    if (response.ok) {
                        EventDispatcher.dispatch('booking-update');
                        ModalHelper.closeModal();
                        return;
                    }
                    setLoading(false);
                })
            }} disabled={loading}/> : null}
        </Fragment>);
    });
    if (props.booking.can_customer_pay === false) {
        return <div className="cancel-booking">
            <Notice name="cancel" variant="error">
                Please contact <strong>{props.booking.agency_name}</strong> to cancel this booking.
            </Notice>
        </div>
    }
    if (props.booking.can_cancel === false) {
        return <div className="cancel-booking">
            <Notice name="cancel" variant="error">
                Sorry, you'll need to contact the bar to cancel this booking. Please get in contact with them
                on <a
                href={'mailto:' + props.booking.bar.data.party_booking_email}>{props.booking.bar.data.party_booking_email}</a>
            </Notice>
        </div>
    }
    return <div className="cancel-booking">
        <Notice name="cancel" variant="error">
            <p>To cancel your booking just click confirm below and we'll be sad to see you go!</p>
        </Notice>
    </div>
}

CancelBooking.propTypes = {
    booking: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
}