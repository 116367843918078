import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";

function Months({handleChange, months, selectedMonth}) {
    const selectedRef = useRef(null);
    useEffect(() => {
        if(!selectedRef.current) return;
        selectedRef.current.scrollIntoView({behavior:'smooth', block: "center"});
    },[selectedRef])
    return (
        <div className='dob-picker_container-options_months'>
            {months.map((month, idx) =>
                <button
                    ref={selectedMonth === idx + 1 ? selectedRef : null}
                    key={idx}
                    className={`dob-picker_container-options_months-button ${selectedMonth === idx + 1 ? 'selected' : ''}`}
                    onClick={() => handleChange(idx + 1)}
                >
                    {month}
                </button>
            )}
        </div>
    )
}

export default Months;

Months.propTypes = {
    handleChange: PropTypes.func.isRequired,
    months: PropTypes.array.isRequired,
    selectedMonth: PropTypes.number,
}