import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import IntroText from "../../../../../Utility/IntroText/IntroText";
import Button from "../../../../../Form/Element/Button";
import {Api} from "../../../../../Api/Api";
import Loading from "../../../../../Utility/Loading/Loading";
import cx from 'classnames';
import './PreOrder.scss';
import {NotificationManager} from "../../../../../Notifications/NotifcationManager";
import {EventDispatcher} from "../../../../../Events/Dispatcher";

export default class PreOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            choices: [],
            group_open: null,
            show_all: false,
            pre_order: this.props.guest.pre_order.data
        }
    }

    componentDidMount() {
        let variations = this.props.guest.variations.data.map((v) => {
            return v.id;
        })
        this.update_listener = EventDispatcher.subscribe('booking-data-update', (data) => {
            if (data.is_guest === true) {
                this.setState({pre_order: data.guest.data.pre_order.data});
                return;
            }
            let guest = data.guests.data.find((g) => {
                return g.id === this.props.guest.id;
            });
            if (guest) {
                this.setState({pre_order: guest.pre_order.data});
            }
        });
        Api.get('rest/booking/pre-order-choices', {variations: variations}).then((response) => {
            if (response.ok) {
                this.setState({choices: response.data.data, loading: false});
            }
        });
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update_listener);
    }

    updatePreOrder(variation_id, choice_id, choice_item, plu) {
        Api.post('rest/booking/' + this.props.booking.id + '/pre-order', {
            guest_id: this.props.guest.id,
            variation_id: variation_id,
            choice_id: choice_id,
            choice_item_id: choice_item,
            plu: plu
        }).then((response) => {
            if (!response.ok) {
                NotificationManager.showError(response.error);
                this.setState({error: response.error});
                return;
            }
            EventDispatcher.dispatch('booking-data', response.data.data);
        })
    }

    isSelected(variation_id, group_id, choice_id, plu) {
        let found = false;
        this.state.pre_order.map((pre_order) => {
            if (pre_order.variation_id == variation_id && pre_order.choice_id == group_id && pre_order.choice_item_id == choice_id && pre_order.plu == plu) {
                found = true;
            }
            return pre_order;
        });
        return found;
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        return <div className="edit-pre-order">
            <IntroText name="pre-order-title" title="Edit pre-orders">Choose options below.</IntroText>
            {this.state.choices.map((variation) => {
                return <div className="menu-choice" key={variation.id}>
                    {variation.groups.map((group) => {
                        if (group.has_selectable === false) {
                            return;
                        }
                        return <div key={group.group_id}
                                    className={cx('menu-choice__choice', {'menu-choice__choice--open': this.state.group_open === group.id})}>
                            <a href="#" className="menu-choice__choice__title" onClick={(e) => {
                                e.preventDefault();
                                if (this.state.group_open === group.id) {
                                    this.setState({group_open: null});
                                } else {
                                    this.setState({group_open: group.id});
                                }
                            }}>{variation.title} - {group.title}</a>
                            <div className="menu-choice__choice__items">
                                {group.choices.map((choice) => {
                                    if (choice.has_selectable === false) {
                                        return;
                                    }
                                    return <Fragment key={choice.id}>
                                        <h2>{choice.title}</h2>
                                        {choice.items.map((item) => {
                                            return <div
                                                className={cx('menu-choice__choice__items__item', {'item-selected': this.isSelected(variation.id, group.group_id, choice.id, item.plu)})}
                                                key={item.id}
                                                onClick={() => {
                                                    this.updatePreOrder(variation.id, group.group_id, choice.id, item.plu);
                                                }}>
                                                <span className="menu-choice__choice__items__item__selected"/>
                                                <div
                                                    className="menu-choice__choice__items__item__info">
                                                    <div
                                                        className="menu-choice__choice__items__item__info--title">{item.title}</div>
                                                    <div
                                                        className="menu-choice__choice__items__item__info--icons">
                                                        {item.vegan ? <span className="vegan"/> : null}
                                                        {item.vegetarian ? <span className="vegetarian"/> : null}
                                                        {!item.contains_gluten ? <span className="gluten"/> : null}
                                                    </div>
                                                </div>

                                            </div>
                                        })}
                                    </Fragment>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            })}
            <Button text="Go Back" name="go-back" variant="outline" onClick={() => this.props.goBack()}>Go Back</Button>
        </div>
    }
}
PreOrder.propTypes = {
    guest: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired
}