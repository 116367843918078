import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TopBar from "./TopBar";
import './Payment.scss';
import cx from 'classnames';
import Button from "../../../../Form/Element/Button";
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import Center from "../../../../Utility/Center/Center";
import {Settings} from "../../../../Settings/Settings";
import PayAmount from "../../../../Bookings/Elements/Payment/PayAmount";
import {EventDispatcher} from "../../../../Events/Dispatcher";
import {NotificationManager} from "../../../../Notifications/NotifcationManager";
import Notice from "../../../../Utility/Notice/Notice";

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_select: '',
            error: null
        }
    }


    handleContinue(e) {
        e.preventDefault();
        if (this.state.type_select === 'balance') {
            ModalHelper.openModal(<PayAmount amount={this.props.booking.balance}
                                             token={this.props.booking.payment_token}
                                             onComplete={(data) => {
                                                 EventDispatcher.dispatch('booking-update');
                                                 ModalHelper.closeModal();
                                                 if (data.success === true) {
                                                     EventDispatcher.dispatch('booking-update');
                                                     this.setState({error: false});
                                                     NotificationManager.showSuccess('Payment has been made');
                                                     return;
                                                 }
                                                 this.setState({error: true})
                                                 NotificationManager.showError('Sorry, we were unable to take your payment');
                                             }}
                                             booking={this.props.booking}/>, 'full-page');
        }
        if (this.state.type_select === 'deposit') {
            ModalHelper.openModal(<PayAmount amount={this.props.booking.deposit_balance}
                                             onComplete={(data) => {
                                                 ModalHelper.closeModal();
                                                 if (data.success === true) {
                                                     EventDispatcher.dispatch('booking-update');
                                                     this.setState({error: false});
                                                     NotificationManager.showSuccess('Payment has been made');
                                                     return;
                                                 }
                                                 this.setState({error: true})
                                                 NotificationManager.showError('Sorry, we were unable to take your payment');
                                             }}
                                             token={this.props.booking.payment_token}
                                             booking={this.props.booking}/>, 'full-page');
        }
    }

    render() {
        if (this.props.booking.status === 'Deleted') {
            return <Fragment>
                <div className="booking-paid">
                    <img src="/assets/icons/sad-face.png" alt="You've paid!"/>
                    <p>Your booking has been cancelled</p>
                </div>
            </Fragment>
        }
        if (!this.props.booking.payment_type) {
            return <Fragment>
                <div className="payment-tab">
                    <div className="edit-booking__payment">
                        <h2>Paying your balance</h2>
                        <TopBar booking={this.props.booking}/>
                    </div>
                    {this.props.booking.balance === 0 ? <Fragment>
                        <div className="booking-paid">
                            <img src="/assets/icons/happy-face.png" alt="You've paid!"/>
                            <p>You've paid! Time to get ready for {this.props.booking.party_name}</p>
                        </div>
                    </Fragment> : <Fragment>
                        <h3>Choose a payment type</h3>
                        {this.state.error ?
                            <Notice name="payment-error" variant="error">Sorry, there has been an error in taking your
                                payment</Notice> : null}
                        <div className="edit-bookings__payment-options">
                            {this.props.booking.balance > 0 ? <button
                                className={cx('payment-option', {'payment-option--selected': this.state.type_select === 'balance'})}
                                onClick={() => this.setState({type_select: 'balance'})}>
                                    <span className='radio-wrapper'>
                                        <span className="payment-option__indicator"/>
                                    </span>
                                <div className="payment-option__info">
                                    <span
                                        className="payment-option__title">Pay balance - {Settings.currencyFormatter.format(this.props.booking.balance)}</span>
                                    <span className="payment-option__desc">Pay the full outstanding balance of your booking</span>
                                </div>
                            </button> : null}
                            {this.props.booking.deposit_balance > 0 && !this.props.booking.balance_due ? <button
                                className={cx('payment-option', {'payment-option--selected': this.state.type_select === 'deposit'})}
                                onClick={() => this.setState({type_select: 'deposit'})}>
                                <span className='radio-wrapper'>
                                        <span className="payment-option__indicator"/>
                                </span>
                                <div className="payment-option__info">
                                    <span
                                        className="payment-option__title">Pay deposit - {Settings.currencyFormatter.format(this.props.booking.deposit_balance)}</span>
                                    <span className="payment-option__desc">Pay the booking deposit now to get your booking secured!</span>
                                </div>
                            </button> : null}
                            {/*<button*/}
                            {/*    className={cx('payment-option', {'payment-option--selected': this.state.type_select === 'set_balance'})}*/}
                            {/*    onClick={() => this.setState({type_select: 'set_balance'})}>*/}
                            {/*    <span className="payment-option__indicator"/>*/}
                            {/*    <div className="payment-option__info">*/}
                            {/*        <span className="payment-option__title">Set your guests balance</span>*/}
                            {/*        <span*/}
                            {/*            className="payment-option__desc">Set a balance for yourself and each individual guest</span>*/}
                            {/*    </div>*/}
                            {/*</button>*/}
                        </div>
                        <Center>
                            <Button text="Continue" name="continue-payment"
                                    onClick={(e) => this.handleContinue(e)} disabled={!this.state.type_select}/>
                        </Center>
                    </Fragment>}
                </div>
            </Fragment>
        }
    }
}

export default Payment;
Payment.propTypes = {
    booking: PropTypes.object.isRequired
}