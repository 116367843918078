import Loading from "../Utility/Loading/Loading";
import React, {useEffect} from "react";
import {Api} from "../Api/Api";

function StaffLogout() {
    useEffect(() => {
        Api.post('rest/logout').then(() => {
            window.localStorage.removeItem('staff_login')
            setTimeout(() => {
                window.location.href = '/staff/login';
            }, 1000);
        });
    }, []);
    return <Loading/>
}

export default StaffLogout;