import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function IntroText(props) {
    return <div className={cx('intro', 'intro--' + props.name)}>
        {props.title ? <div className="intro__title">{props.title}</div> : null}
        <div className="intro__content">
            {props.children}
        </div>
    </div>
}

IntroText.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string.isRequired
}