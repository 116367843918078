import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default function BookingStep (props) {
    let additional = {}
    const [hotJarUrl, setHotJarUrl] = useState(null)
    if (props.name) {
        additional['step--' + props.name] = true
    }
    useEffect(() => {
        if (props.hot_jar_url !== hotJarUrl) {
            setHotJarUrl(props.hot_jar_url)
            if (window.hj) {
                window.hj(props.hot_jar_url, '/' + props.hot_jar_url)
            }
            window.dataLayer = window.dataLayer || []
            const data = {
                'event': props.booking.is_completed ? 'partypro_booking_complete' : 'partypro_booking_update',
                'session_id': props.booking.session_id,
                'step': props.booking.step,
                'booking_date': props.booking.booking_date,
                'no_people': props.booking.no_people,
                'location': props.booking.bar ? props.booking.bar.data.bar_name : null,
                'location_code': props.booking.bar ? props.booking.bar.data.cost_centre : null,
                'booking_type_id': props.booking.party_type,
                'booking_type_name': props.booking.party_type_name,
                'can_auto_confirm': props.booking.can_auto_confirm,
                'no_auto_confirm_reason': props.booking.no_autoconfirm_reason,
                'pbr': props.booking.value,
            };
            if (props.booking.is_completed) {
                data.booking_ref = props.booking.booking.data.ref_code
            }
            window.dataLayer.push(data)
        }
    })
    return <div
      className={cx('step', 'step--type--' + props.type, 'step--' + props.step, props.additionalClass, additional)}>
        {props.children}
    </div>
}

BookingStep.propTypes = {
    step: PropTypes.number.isRequired,
    additionalClass: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    hot_jar_url: PropTypes.string,
    booking: PropTypes.object.isRequired,
}