import React, {Component} from 'react';
import {Api} from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";

export default class Logout extends Component {

    componentDidMount() {
        Api.post('customers/logout').then((response) => {
            document.location.href = '/';
        });
    }

    render() {
        return <Loading/>
    }
}