import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '../../../Settings/Settings';
import './Package.scss';
import { EventDispatcher } from '../../../Events/Dispatcher';
import PackageInfo from './PackageInfo';
import { BookingData } from '../../Data/BookingData';
import cx from 'classnames';
import Button from '../../../Form/Element/Button';
import FormElementText from '../../../Form/Element/Text';

export default function Package(props) {
    let option = props.option;

    const custom_package = (props.booking.mix_match_packages === false && option.display_type === 'package')

    return (
        <>
            {option.variations.data.map((variation) => {
                let current_option = props.booking.variations.data.find((booking_var) => {
                    return booking_var.variation_id === variation.variation_id;
                });
                let variationImage = variation.image.data.id ? variation.image.data.url : option.image.data.url;
                let className = option.name.replace(/ /g, '-').toLowerCase();
                // -- Template --
                const template = variation.template.data;
                const contentAlign = template.align_content || 'center'; // left|center|right
                const packageImage = template.background_image?.data.uri || variationImage;
                const backgroundColor = template.background_colour || undefined;
                const backgroundPosition = template.background_position || 'center'; // top|right|bottom|left|center
                const cardType = template.card_type || 'minimal'; // full|minimal
                const cardHeadline = template.headline;
                const backgroundSize = template.background_size || 'cover';
                const cardHeadlineColor = template.headline_text_colour || 'inherit';
                const gradientButton = `linear-gradient(90deg, ${template.button_highlight_colour || 'inherit'}, ${template.button_lowlight_colour || 'inherit'})`;
                const buttonTextColor = template.button_text_colour || undefined;
                const description = template.description || variation.description;
                const descriptionColor = template.description_text_colour || 'inherit';
                const infoText = template.info;
                const infoTextColor = template.info_text_colour || 'inherit';
                const title = template.title || variation.name;
                const titleTextColor = template.title_text_colour || 'inherit';

                const priceText = `${Settings.currencyFormatter.format(variation.calculated_price)}  
                       ${option.price_per_booking === false ? 'pp' : ''}`;

                return (
                    <div
                        key={variation.id}
                        style={{backgroundColor, backgroundImage: `url("${packageImage}")`, backgroundPosition, backgroundSize}}
                        className={cx('product', { 'product--no-image': !packageImage }, 'product--' + className, cardType)}
                    >
                        {cardType !== 'minimal' && <div className='product-backdrop'/>}
                        <div className={`grid align-${contentAlign}`}>
                            {packageImage && cardType === 'minimal' && <img className='product__image' src={packageImage} alt={variation.name}/>}
                            <div className="grid__item item-description">
                                <p className="product__title">
                                    {cardHeadline && <span style={{color: cardHeadlineColor}} className='popular'>{cardHeadline}</span>}
                                    <span style={{color: titleTextColor}}>
                                        {title}
                                        <i
                                            style={{color: titleTextColor}}
                                            className="fa fa-info"
                                            onClick={(e) => {
                                                EventDispatcher.dispatch('open-modal', {
                                                    content: <PackageInfo option={option} variation={variation}
                                                                          booking={props.booking}/>,
                                                    top: e.pageY
                                                });
                                            }}
                                        />
                                    </span>
                                </p>
                                {description &&
                                    <p style={{color: descriptionColor}} className="product__desc">{description}</p>}
                                {infoText && <p style={{color: infoTextColor}} className="product__info">{infoText}</p>}
                            </div>
                            <div
                                className={`product__cta ${current_option && !custom_package ? 'custom-package' : ''}`}>
                                {!current_option &&
                                    <Button
                                        style={{backgroundImage: gradientButton, color: buttonTextColor}}
                                        text={
                                            <span className='price-checkbox'>
                                                <span className="price">{priceText}</span>
                                                <i className="fa fa-circle-o"/>
                                            </span>
                                        }
                                        name="add-product"
                                        variant="circular"
                                        disabled={BookingData.updating}
                                        onClick={() => {
                                            let qty = 1;
                                            if (
                                                option.price_per_booking === false &&
                                                (props.booking.variations.data.length === 0 ||
                                                    props.booking.mix_match_packages === false)
                                            ) {
                                                qty = props.booking.no_people;
                                            }
                                            BookingData.updateVariation(variation.id, qty);
                                        }}
                                    />
                                }
                                {current_option && (
                                    custom_package ? (
                                        <Button
                                            style={{backgroundImage: gradientButton, color: buttonTextColor}}
                                            text={
                                                <span className='price-checkbox'>
                                                    <span className="price">{priceText}</span>
                                                    <i style={{color: `${buttonTextColor}!important`}} className="fa fa-check-circle"/>
                                                </span>
                                            }
                                            name="add-product"
                                            variant="circular"
                                            disabled={BookingData.updating}
                                            onClick={() => {
                                                BookingData.updateVariation(variation.id, 0);
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <p className="price">
                                                <strong style={{color: buttonTextColor, borderColor: buttonTextColor}}>{priceText}</strong>
                                            </p>
                                            <div className='package-count'>
                                                <Button
                                                    style={{backgroundImage: gradientButton, color: buttonTextColor}}
                                                    text={<i style={{color: `${buttonTextColor}!important`}} className="fa fa-minus"/>}
                                                    name="add-product"
                                                    variant="circular"
                                                    onClick={() => {
                                                        BookingData.updateVariation(variation.id, current_option.quantity - 1);
                                                    }}
                                                />
                                                <FormElementText
                                                    name="count"
                                                    value={current_option.quantity}
                                                    defferredUpdate={true}
                                                    update_on_props={true}
                                                    wrapperClass="field--circular"
                                                    onChange={(e) => {
                                                        BookingData.updateVariation(variation.id, e.target.value);
                                                    }}
                                                />
                                                <Button
                                                    style={{backgroundImage: gradientButton, color: buttonTextColor}}
                                                    text={<i style={{color: `${buttonTextColor}!important`}} className="fa fa-plus"/>}
                                                    name="add-product"
                                                    variant="circular"
                                                    onClick={() => {
                                                        BookingData.updateVariation(variation.id, current_option.quantity + 1);
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

Package.propTypes = {
    option: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired
};
