import PropTypes from "prop-types";
import React, {useState, useEffect, useRef} from 'react';
import './Drawer.scss';

const Drawer = ({children, drawerHandleIcon}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [startPos, setStartPos] = useState({x: 0, y: 0});
    const [startedSwipe, setStartedSwipe] = useState(false);
    const drawerWidth = useRef(null);
    const drawer = useRef(null);

    const closeDrawer = () => {
        drawer.current.className = '';
        setTimeout(() => setDrawerOpen(false), 300);
    };

    const toggleDrawer = () => {
        if (!drawerOpen) {
            drawer.current.classList.add('open');
            setTimeout(() => setDrawerOpen(true), 300);
        } else {
            closeDrawer()
        }
    };

    useEffect(() => {

        const handleTouchStart = (event) => {
            const touch = event.targetTouches[0];
            if (drawerOpen || (!drawerOpen && touch.pageX < 100)) {
                setStartPos({x: touch.pageX, y: touch.pageY});
                drawerWidth.current = drawer.current.offsetWidth;
                setStartedSwipe(true);
            }
        };

        const handleTouchMove = (event) => {
            const touch = event.targetTouches[0];
            if (startedSwipe && drawerOpen && touch.pageX < startPos.x) {
                if (event.target.offsetParent.id === 'drawer') return;
                let position = Math.min(touch.pageX - (startPos.x - drawerWidth.current), drawerWidth.current);
                drawer.current.style.transition = 'none';
                drawer.current.style.webkitTransition = 'none';
                drawer.current.style.transform = `translate(${position}px, 0)`;
                drawer.current.style.webkitTransform = `translate(${position}px, 0)`;
            } else if (startedSwipe && !drawerOpen && touch.pageX > startPos.x) {
                let position = Math.min(touch.pageX - startPos.x, drawerWidth.current)
                drawer.current.style.transition = 'none';
                drawer.current.style.webkitTransition = 'none';
                drawer.current.style.transform = `translate(${position}px, 0)`;
                drawer.current.style.webkitTransform = `translate(${position}px, 0)`;
            }
        };

        const handleTouchEnd = (event) => {

            const touch = event.changedTouches[0];
            if (startedSwipe && touch.pageX <= drawerWidth.current / 2) {
                if (event.target.offsetParent.id === 'drawer') return;
                setDrawerOpen(false);
                setStartedSwipe(false);
                drawer.current.style.transition = '';
                drawer.current.style.webkitTransition = '';
                drawer.current.className = '';
                drawer.current.style.transform = '';
                drawer.current.style.webkitTransform = '';
            } else if (startedSwipe && touch.pageX > drawerWidth.current / 2) {
                setDrawerOpen(true);
                setStartedSwipe(false);
                drawer.current.style.transition = '';
                drawer.current.style.webkitTransition = '';
                drawer.current.className = 'open';
                drawer.current.style.transform = '';
                drawer.current.style.webkitTransform = '';
            }
        };

        document.body.addEventListener('touchstart', handleTouchStart);
        document.body.addEventListener('touchmove', handleTouchMove);
        document.body.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.body.removeEventListener('touchstart', handleTouchStart);
            document.body.removeEventListener('touchmove', handleTouchMove);
            document.body.removeEventListener('touchend', handleTouchEnd);
        };
    }, [drawerOpen, startPos.x, startPos.y, startedSwipe]);

    return (
        <>
            <div
                style={drawerOpen ? {
                    visibility: 'visible',
                    opacity: 1
                } : {
                    transition: 'visibility 0.1s, opacity 0.1s linear'
                }}
                className='drawer-backdrop' onClick={closeDrawer}/>
            <div id='drawer' className='drawer' ref={drawer}>
                <div className='drawer-content'>
                    {children}
                </div>
                <button type='button' className='handle' onClick={toggleDrawer}>
                    <i className={drawerHandleIcon}/>
                </button>
            </div>
        </>
    )
};

export default Drawer;

Drawer.propTypes = {
    children: PropTypes.node,
    drawerHandleIcon: PropTypes.string
}