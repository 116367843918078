import React, { Fragment, useEffect, useState } from 'react'
import { Api } from '../../../Api/Api'
import Loading from '../../../Utility/Loading/Loading'
import ContentBox from '../../../Utility/ContentBox/ContentBox'
import Notice from '../../../Utility/Notice/Notice'

const StaffTipComplete = (props) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [tip, setTip] = useState({})
    useEffect(() => {
        Api.get('rest/tip/' + props.match.params.id + '/outcome', {}).then((response) => {
            if (response.ok) {
                setTip(response.data.data)
                setLoading(false)
                return
            }
            console.log(response.error)
            setError(response.error)
            setLoading(false)
        })
    }, [])
    if (loading) {
        return <Loading/>
    }

    if (error) {
        return <Fragment>
            <ContentBox title="Sorry">
                <Notice name="error" variant="error">{error}</Notice>
            </ContentBox>
        </Fragment>
    }

    if (tip.completed === false) {
        return <Fragment>
            <ContentBox title="Thank you!">
            </ContentBox>
        </Fragment>
    }

    return <Fragment>
        <ContentBox title="Thank you!">
            Thank you <strong>{tip.forename}</strong>. The team at <strong>{tip.bar.data.bar_name}</strong> really
            appreciate your
            support. You should receive an e-mail shortly with your receipt, for reference your authorisation code
            is <strong>{tip.outcome.data.bank_auth_code}</strong>
        </ContentBox>
    </Fragment>
}

export default StaffTipComplete
