import React, {Component, Fragment} from 'react';
import IntroText from "../../../Utility/IntroText/IntroText";
import moment from 'moment';
import FormElementDatePicker from "../../../Form/Element/DatePicker";
import Button from "../../../Form/Element/Button";
import Center from "../../../Utility/Center/Center";
import Loading from "../../../Utility/Loading/Loading";
import FormElementTimeSelect from "../../../Form/Element/FormElementTimeSelect";
import './ChangeDate.scss';
import {Api} from "../../../Api/Api";
import Notice from "../../../Utility/Notice/Notice";
import TimeSelectButton from "../../../Form/Element/TimeSelectButton";
import {Settings} from "../../../Settings/Settings";
import {EventDispatcher} from "../../../Events/Dispatcher";
import {ModalHelper} from "../../../Utility/Modal/AppModal";
import {NotificationManager} from "../../../Notifications/NotifcationManager";

class ChangeDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: props.booking.booking_date,
            checked: false,
            date_loading: false,
            availability: {},
            error: null,
            saving: false
        }
    }

    checkAvailability() {
        this.setState({date_loading: true, error: null, checked: true, availability: {}});
        Api.get('rest/my-bookings/' + this.props.booking.manage_key + '/re-book', {date: this.state.date}).then((response) => {
            if (response.ok) {
                this.setState({availability: response.data, date_loading: false});
                return;
            }
            this.setState({availability: {}, date_loading: false, error: response.error, checked: false});
        });
    }

    handleDateChange(e) {
        this.setState({date: e.target.value + ' ' + moment(this.state.date).format('HH:mm:ss')}, () => {
            if (this.state.checked === true) {
                this.checkAvailability();
            }
        })
    }

    handleTimeChange(e) {
        this.setState({date: moment(this.state.date).format('YYYY-MM-DD') + ' ' + e.target.value + ':00'}, () => {
            if (this.state.checked === true) {
                this.checkAvailability();
            }
        })
    }

    handleAlternativeTime(time) {
        this.setState({
            availability: {},
            date: moment(this.state.date).format('YYYY-MM-DD') + ' ' + time + ':00'
        }, () => {
            this.checkAvailability();
        })
    }

    handleAlternativeDate(date) {
        this.setState({availability: {}, date: date + ' ' + moment(this.state.date).format('HH:mm:ss')}, () => {
            this.checkAvailability();
        })
    }

    handleSave(e) {
        e.preventDefault();
        this.setState({saving: true});
        Api.post('rest/my-bookings/' + this.props.booking.manage_key + '/re-book', {date: this.state.date}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('booking-update');
                EventDispatcher.dispatch('show-rebooked', true);
                NotificationManager.showSuccess('You are re-booked!');
                ModalHelper.closeModal();
                return;
            }

            this.setState({error: response.error, saving: false});
        })
    }

    render() {
        let date = moment(this.state.date);

        let nextStep = null;
        if (this.state.saving === true) {
            return <Loading/>
        }
        if (this.state.availability.success !== undefined) {
            if (this.state.availability.success === true) {
                nextStep = <Fragment>
                    <Notice name="available-message" variant="success">
                        Sweet! That time is a available. Do you want to confirm this now?
                    </Notice>
                    <Center><Button text="Get me them cocktails!" name="complete" onClick={(e) => this.handleSave(e)}/></Center>
                </Fragment>;
            } else if (this.state.availability.times.length) {
                nextStep = <Fragment>
                    <Notice name="available-message" variant="warning">
                        Oh Snap! That time isn't available, will any of the times below help quench your
                        cocktail thirst?
                    </Notice>
                    <div className="time-slot-holder clearfix">
                        {this.state.availability.times.map((time) => {
                            return <TimeSelectButton active={true} text={time} key={time}
                                                     onClick={() => this.handleAlternativeTime(time)}/>
                        })}
                    </div>
                </Fragment>
            } else if (this.state.availability.dates.length) {
                nextStep = <Fragment>
                    <Notice name="available-message" variant="warning">
                        Oh Snap! Looks like we don't have any availability on that day. Can we interest you in one of
                        these dates instead?
                    </Notice>
                    <div className="time-slot-holder clearfix">
                        {this.state.availability.dates.map((date) => {
                            return <TimeSelectButton active={true} key={date}
                                                     text={moment(date).format(Settings.date_formats.full_date)}
                                                     onClick={() => this.handleAlternativeDate(date)}/>
                        })}
                    </div>
                </Fragment>
            }
        }
        return <div className="re-book__change-date">
            <h2>Re-Book</h2>
            <IntroText name={'check-availability'}>
                <p>Enter your new date below, we'll check availability and get you booked in!</p>
            </IntroText>
            {this.state.error ? <Notice name="change-error" variant="error">{this.state.error}</Notice> : null}
            <div className="change-date__inputs">
                <FormElementDatePicker value={date.format('YYYY-MM-DD')}
                                       onChange={(e) => this.handleDateChange(e)}/>
                <FormElementTimeSelect name="time" onChange={(e) => this.handleTimeChange(e)}
                                       selectedValue={date.format('HH:mm')}/>
            </div>
            {this.state.checked === false ? <Center><Button text="Check Availability" name="check-availability"
                                                            onClick={(e) => this.checkAvailability(e)}/></Center> : null}
            {this.state.date_loading ? <Loading/> : null}
            {nextStep}
        </div>
    }
}


export default ChangeDate;