import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './TimeSelectButton.scss';

export default function TimeSelectButton(props) {
    const [clicked, setClicked] = useState(false)
    return <button
        className={cx('timeslot', {
            'timeslot--inactive': !props.active && !props.hasFallback && props.disabled,
            'timeslot--deselected': !props.selected,
            'timeslot--stacked': props.stacked === true,
            'timeslot--fallback': props.hasFallback === true
        })}
        onClick={(e) => {setClicked(true); props.onClick(e)}} disabled={props.disabled}>
        <span>{props.isSelectable && <input readOnly={true} style={{marginRight: '10px'}} type="checkbox" checked={props.selected} />} {props.text}</span>
        {clicked
            ? <span><span><span style={{background: 'none', display: 'inline'}}  className="fa fa-spin fa-circle-o-notch" /> One moment...</span></span>
            : (props.additional && <span>{props.additional}</span>)}
    </button>
}

TimeSelectButton.propTypes = {
    text: PropTypes.string.isRequired,
    stacked: PropTypes.bool,
    additional: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    isSelectable: PropTypes.bool,
    hasFallback: PropTypes.bool,
}