import React, {Component} from 'react';
import ContentBox from "../../Utility/ContentBox/ContentBox";
import FormElementText from "../../Form/Element/Text";
import './Register.scss';
import Button from "../../Form/Element/Button";
import {Api} from "../../Api/Api";
import IntroText from "../../Utility/IntroText/IntroText";
import Notice from "../../Utility/Notice/Notice";
import {Settings} from "../../Settings/Settings";
import Center from "../../Utility/Center/Center";
import {NotificationManager} from "../../Notifications/NotifcationManager";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email_address: '',
            loading: false,
            complete: false,
            error: null
        }
    }

    validateEmail(email) {
        let pattern = new RegExp(/@.*?\./);
        return pattern.test(email);
    };

    handleRegister(e) {
        e.preventDefault();
        let email = this.state.email_address;
        if(!this.validateEmail(email)){
            NotificationManager.showError(`Email address "${email}" is invalid`);
            return;
        }
        this.setState({loading: true, complete: false, error: false});
        Api.post('customers/register', {
            email: this.state.email_address,
            join_token: this.props.join_token
        }).then((response) => {
            if (response.ok) {
                this.setState({complete: true, email_address: '', loading: false});
                return;
            }
            this.setState({error: response.error, loading: false});
        });
    }

    render() {
        return <ContentBox name="register" title="Register Your Account" back_url="/login">
            <Center>
                <div className="col-md-6 col-sm-8 col-lg-6">
                    <IntroText name="register" variant="success">
                        Enter your E-mail below, we'll send you an e-mail, click on the link and you'll be in.
                        Simple as!
                    </IntroText>
                    {this.state.complete ? <Notice name="registration-success"
                                                   variant="success">
                        Check your e-mail, click on the link and you'll be drinking a {Settings.getBrandDrink()} in no
                        time!
                    </Notice> : null}
                    {this.state.error ? <Notice name="registration-error" variant="error">
                        {this.state.error}
                    </Notice> : null}
                    <form onSubmit={(e) => this.handleRegister(e)}>
                        <FormElementText name="email" value={this.state.email_address}
                                         onChange={(e) => this.setState({email_address: e.target.value})}
                                         placeholder="E-mail Address"/>
                        <Button text="Register" name="register" disabled={this.state.loading}/>
                    </form>
                </div>
            </Center>
        </ContentBox>
    }
}

export default Register;