import React, {Fragment} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import TypeSelect from "../Elements/TypeSelect";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreOrder from "../Elements/PreOrder/PreOrder";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import UserData from "../Elements/UserData";
import moment from 'moment';
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";

export default function AtTableMasterclass(props) {
    let booking = props.booking;
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }

    let open_to_date = moment().toDate();
    if (props.booking.date) {
        open_to_date = moment(props.booking.date).toDate();
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} name="pre-booking-info" type="at_table_masterclass" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        let booking_date = moment(props.booking.date);
        booking_date = booking_date.add(booking_date.day(6), 'day');
        let message = 'Whoops! We don\'t serve do at table masterclass on ' + moment(props.booking.date).format('dddd') + ', do you want to check a different day?';
        let show_alternative = true;
        if (booking.can_cater_for === true) {
            message = 'Oh dear! Looks like we are fully booked for at table masterclass on this day. Do you want to try another day?';
        }
        if (booking.date && ((booking.alternative_dates.length === 0 && booking.time_slots.length === 0) || booking.can_cater_for === false)) {
            return <Fragment>
                <BookingStep step={2} name="time-select" type="at_table_masterclass"
                             hot_jar_url="enquiry-availability-none" booking={booking}>
                    <Notice name="no-brunch">
                        Sorry, we can’t automatically book this in for you. It might be because your group size is too small or too big for the At Table Masterclass option (it’s designed for groups of 4-6 guests).
                            <br />
                        If you are a group of 8+, then you need to pick Masterclass booking type instead.
                            <br />
                        Or if it’s all a little too confusing, you can submit an enquiry to our team instead and they will call or email you as soon as they can

                    </Notice>
                    <Button text="Enquire now" name="do-callback" onClick={() => {
                        BookingData.update({step: 99});
                    }}/>
                </BookingStep>
            </Fragment>
        }
        return <Fragment>
            <BookingStep step={2} name="time-select" type="at_table_masterclass"
                         hot_jar_url="enquiry-availability-check" booking={booking}>
                <PreBookingInfo booking={props.booking} updating={props.updating}
                                minDate={moment(props.booking.min_booking_date).toDate()}
                                openToDate={open_to_date}
                />
                {props.booking.date !== null &&
                    <TimeSelect booking={props.booking} updating={props.updating} show_price={true} stacked={true}
                            show_alternative={true} allow_fallback={false}
                            unavailable_message={message}/>}
            </BookingStep>
        </Fragment>
    }

    if (booking.step === 3) {
        let skip_text = 'Skip';
        if (props.booking.value > 0) {
            skip_text = 'Continue';
        }
        return <Fragment>
            <BookingStep step={3} name="pre-order" type="at_table_masterclass" hot_jar_url="enquiry-pre-orders" booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="Yay! We’ve added the masterclass package for you. Just click “Continue” to save this as a provisional booking and to find out how to make a payment to secure your spot!"
                          can_skip={true}
                          skip_text={skip_text}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={'Continue'} can_skip={props.booking.can_last_step}/>
        </Fragment>
    }
    if (booking.step === 4) {
        let skip_text = 'Save Booking';
        if (props.booking.deposit === 0) {
            skip_text = 'Complete Booking';
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="at_table_masterclass" hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <h2>Almost Done</h2>
                <ShortSummary booking={props.booking} show_end_time={true}/>
                <UserData booking={props.booking}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return <Fragment>
            <BookingStep step={99} name="fallback" type="at_table_masterclass"
                         hot_jar_url="enquiry-fallback-to-callback" booking={props.booking}>
                <UserData booking={props.booking}
                          additional_info={<p>Cool, so just fill in your details below and one of our team will get in touch with you as soon as they can via phone or email to talk business (joking, to talk party!)</p>}
                          include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                               minDate={moment(props.booking.min_booking_date).toDate()}
                                                               openToDate={open_to_date} include_booking_time={true}
                          />}
                />
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text="Send Enquiry" can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    return <BookingStep step={999} name="no-step" type="at_table_masterclass" hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}