import React, {Fragment, useEffect, useState} from 'react'
import ContentBox from '../../../Utility/ContentBox/ContentBox'
import FormElementText from '../../../Form/Element/Text'
import FormElementTextArea from '../../../Form/Element/TextArea'
import Button from '../../../Form/Element/Button'
import {Api} from '../../../Api/Api'
import Loading from '../../../Utility/Loading/Loading'
import './StaffTip.scss'
import {Settings} from '../../../Settings/Settings'
import Notice from '../../../Utility/Notice/Notice'
import {ModalHelper} from '../../../Utility/Modal/AppModal'
import PayAmount from '../Payment/PayAmount'
import cx from 'classnames'
import FormBarSelect from '../../../Form/Element/FormBarSelect'

const StaffTip = (props) => {
    return <Fragment>
        <ContentBox title="Send a tip!">
            Ooops! Sorry, due to changes in tipping legislation we can no longer accept tips through this medium. Please
            ask your server for a tip jar link.
        </ContentBox>
    </Fragment>
    const [tip, setTip] = useState({
        amount: '',
        bar_id: '',
        message: '',
        forename: '',
        surname: '',
        email: '',
        id: null,
    })
    const [bar, setBar] = useState(null)
    const [locationError, setLocationError] = useState(false)
    const [barSelect, setBarSelect] = useState(true)
    const [bars, setBars] = useState([])
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [paymentError, setPaymentError] = useState(null)
    const createTip = (e) => {
        e.preventDefault()
        setPaymentError(null)
        Api.post('rest/tips', tip).then((response) => {
            if (response.status === 400) {
                setErrors(response.data.errors.fields)
            }
            if (response.ok) {
                const tip_id = response.data.data.id
                ModalHelper.openModal(<PayAmount booking={{}} amount={parseFloat(tip.amount)}
                                                 deposit_request_token={response.data.data.deposit_request.data.token}
                                                 token={'tip'} onComplete={(payment_data) => {
                    if (payment_data.success === true) {
                        Api.post('rest/tips/' + tip_id + '/complete').then((response) => {
                            document.location.href = '/tip/complete/' + response.data.data.id
                        })
                    }
                    else {
                        ModalHelper.closeModal()
                        setPaymentError(
                          'There was an error in processing your payment. Please check your details and try again.')
                    }
                }}/>)
            }
        })
    }
    const setAmount = (e, number) => {
        e.preventDefault()
        setTip({ ...tip, amount: number })
    }
    const getErrorMessage = (field) => {
        const error = errors.find((e) => {
            return e.field === field
        })
        return error ? error.error : null
    }

    useEffect(() => {
        Api.getAbsolute('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            if (response.ok) {
                setBars(response.data)
                if (props.match.params.site_code) {
                    const bar = response.data.find((b) => {
                        return b.cost_centre === props.match.params.site_code && b.brand_short_name === Settings.brand
                    })
                    if (!bar) {
                        setLocationError(true)
                    }
                    else {
                        setBar(bar)
                        setTip({ ...tip, bar_id: bar.id })
                        setBarSelect(false)
                    }
                }
                setLoading(false)
            }
        })
    }, [setBarSelect, setLoading, setTip])
    if (loading) {
        return <Fragment>
            <Loading/>
        </Fragment>
    }
    if (locationError) {
        return <div>
            <Notice name="no-location" variant="error">
                Sorry, that location does not exist
            </Notice>
        </div>
    }
    return <Fragment>
        <ContentBox title="Send a tip!">
            We love giving our guests an amazing customer experience, and we'd be delighted if you wanted to reward our
            hard working team with a tip!
            All tips go directly into team wage packets
            {paymentError && <Notice name="payment_error" variant="error">{paymentError}</Notice>}
            <form onSubmit={(e) => createTip(e)}>
                <div className="amount-holder">
                    <div className="btn-group">
                        {['5', '10', '20', '50'].map((amount) => {
                            return <button key={amount}
                                           className={cx('btn', 'amount', { 'selected': tip.amount === amount })}
                                           onClick={(e) => setAmount(e, amount)}>£{amount}
                            </button>
                        })}
                    </div>
                    <FormElementText name="amount" number={true} value={tip.amount}
                                     error={getErrorMessage('amount')}
                                     onChange={(e) => setTip({ ...tip, amount: e.target.value })}
                                     placeholder="Amount *"/>
                </div>
                {barSelect ?
                  <FormBarSelect blankOptionText="Start typing bar name..." brand={Settings.brand} allow_location={true}
                                 onChange={(e) => {
                                     const bar = bars.find((b) => {
                                         return b.id === parseInt(e.target.value)
                                     })
                                     setBar(bar)
                                     setTip({ ...tip, bar_id: e.target.value })
                                 }}
                                 name={'bar_id'} value={tip.bar_id}/> : <FormElementText name="bar_id" disabled={true}
                                                                                         value={bar.name}/>}
                <FormElementText name="email" email={true} value={tip.email} error={getErrorMessage('email')}
                                 onChange={(e) => setTip({ ...tip, email: e.target.value })}
                                 placeholder="Your Email Address *"/>
                <FormElementText name="forename" value={tip.forename} error={getErrorMessage('forename')}
                                 onChange={(e) => setTip({ ...tip, forename: e.target.value })}
                                 placeholder="Forename"/>
                <FormElementText name="surname" value={tip.surname} error={getErrorMessage('surname')}
                                 onChange={(e) => setTip({ ...tip, surname: e.target.value })}
                                 placeholder="Surname"/>
                <FormElementTextArea name="message" value={tip.message}
                                     onChange={(e) => setTip({ ...tip, message: e.target.value })}
                                     placeholder="Any Message you want to send?"/>
                <Button text="Send Tip" name="tip" variant="full-width"/>
            </form>
        </ContentBox>
    </Fragment>
}

export default StaffTip
