import React, {useState} from "react";
import FormElementText from "../Form/Element/Text";
import {Api} from "../Api/Api";
import Button from "../Form/Element/Button";
import Notice from "../Utility/Notice/Notice";

const AppVoucherRedemption = (props) => {
    const [voucher, setVoucherCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const checkVoucher = (e) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setLoading(true);
        setSuccess(null);
        Api.post('customers/voucher', {
            voucher_code: voucher,
            app_user_id: props.match.params.app_user_id
        }).then((response) => {
            setLoading(false);
            if (response.ok) {
                setVoucherCode('');
                setSuccess('Your voucher has been applied! Please close this window and refresh your app');
                return;
            }

            setError(response.error);
        });
    }
    return <div style={{margin: '20px'}}>
        <p style={{textAlign: 'center'}}>Please enter your voucher code below and hit "Check"</p>
        {error && <Notice name="error">{error}</Notice>}
        {success && <Notice name="success" variant="info">{success}</Notice>}
        <form onSubmit={(e) => checkVoucher(e)}>
            <FormElementText name="voucher" value={voucher} onChange={(e) => setVoucherCode(e.target.value)}
                             disabled={loading} placeholder="Voucher Code"/>

            <Button text="Check" name="check-voucher" variant="full-width" disabled={loading}/>
        </form>
    </div>
}

export default AppVoucherRedemption;