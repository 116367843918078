import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Settings} from "../../../../Settings/Settings";

export default function TopBar(props) {
    return <Fragment>
        <div className="payment-summary">
            <span className="payment-summary__item payment-summary--balance">
                Booking Value: {Settings.currencyFormatter.format(props.booking.pbr)}
            </span>
            <span className="payment-summary__item payment-summary--paid">
                Paid: {Settings.currencyFormatter.format(props.booking.payments_made)}
            </span>
        </div>
    </Fragment>
}

TopBar.propTypes = {
    booking: PropTypes.object.isRequired
}