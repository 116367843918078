import React, {useState} from 'react';
import FormElementText from "../../Form/Element/Text";
import Button from "../../Form/Element/Button";
import {Api} from "../../Api/Api";
import Notice from "../../Utility/Notice/Notice";
import {EventDispatcher} from "../../Events/Dispatcher";
import nl2br from 'react-nl2br';

const JoinSocietyCode = (props) => {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        const new_code = code.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        setCode(new_code);
        if (new_code.length !== 6) {
            setError('Code must be exactly 6 characters');
            setLoading(false);
            return;
        }
        Api.post('customers/societies/society-portal/invite-code/' + new_code, {}).then((response) => {
            setLoading(false);
            if (response.ok) {
                EventDispatcher.dispatch('reload-user');
                return;
            }

            setError(response.error);
        });
    }

    return <div className="card-register">
        {error ? <Notice name="register-error" variant="error">{nl2br(error)}</Notice> : null}
        <form onSubmit={(e) => handleSave(e)}>
            <Notice name="society-intro">Please enter your invite code below. You can obtain this from the leadership of
                your club or society once they've signed up with us!</Notice>
            <FormElementText name="code" disabled={loading} value={code}
                             onChange={(e) => {
                                 setCode('' + (e.target.value).toUpperCase());
                             }} placeholder="Invite Code (6 Characters)" maxLength={6}/>
            <div className="btn-holder">
                <Button name="submit" text="Register" onClick={(e) => handleSave(e)}
                        disabled={loading}/>
            </div>
            <div className="soc-register-link">
                <p><a href="https://www.revolution-bars.co.uk/clubs-and-societies/" target="_blank" rel="noreferrer noopener">
                    If you haven't registered, you can sign up here.</a></p>
            </div>
        </form>
    </div>
}

export default JoinSocietyCode;