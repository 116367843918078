import React from 'react';
import PropTypes from 'prop-types';
import {BookingData} from "../Data/BookingData";
import Button from "../../Form/Element/Button";

export default function CompleteBooking(props) {
    return <div className="complete-booking" >
        {props.booking.payment_options.length > 0 ? <div id="payment_options">
            {props.booking.payment_options.map((opt) => {
                return <Button key={opt.type}
                               disabled={!props.booking.can_complete}
                               onClick={function () {
                                   BookingData.setPaymentType(opt.type)
                                   BookingData.completeBooking()
                               }}
                               text={opt.text}
                               name={`pay-deposit ${opt.type}`}/>
            })}</div> :
            <Button disabled={!props.booking.can_complete} name="complete-booking" text={BookingData.booking_data.complete_text}
                    variant="full-width"
                                                      onClick={(e) => BookingData.completeBooking()}/>}
    </div>
}

CompleteBooking.defaultProps = {
    complete_button_text: 'Complete Booking'
};
CompleteBooking.propTypes = {
    complete_button_text: PropTypes.string
};